import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

import AddSpecialMenu from "../AddNewMenu/AddSpecialMenu";
import AddMenu from "../AddNewMenu/AddMenu";
import MenuList from "./MenuList";
import MenuList2 from "./MenuList2.js";
import { changeSearchMenu, clearSearchMenu } from "../../Filters/ducks";
import { isShownModalSelector } from "../../Modal/selectors";
import { closeModal, showModal } from "../../Modal/ducks";

class Menus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isShowModalSpecialMenu: false,
      search: "",
      editMenu: {}
    };
  }

  componentWillUnmount() {
    this.props.clearSearchMenu();
  }

  handleEdit = menu => {
    this.setState(
      {
        editMenu: menu
      },
      () => this.props.showModal()
    );
  };

  handleNew = () => {
    this.setState(
      {
        editMenu: {}
      },
      () => this.props.showModal()
    );
  };

  handleChange = e => {
    const { value, name } = e.target;
    this.setState(
      () => ({ [name]: value }),
      () => this.props.changeSearchMenu(this.state.search)
    );
  };

  handleCloseModal = e => {
    this.setState(() => ({
      isShowModalSpecialMenu: !this.state.isShowModalSpecialMenu
    }));
  };

  render() {
    const { editMenu, isShowModalSpecialMenu } = this.state;
    const { closeModal, isShownModal } = this.props;

    return (
      <Fragment>
        <div className="content__head">
          <div className="content__title title-content">
            <h2 className="title-content__name">Меню</h2>
            <button onClick={this.handleNew} className="title-content__add" />
          </div>
        </div>

        <MenuList handleEdit={this.handleEdit} />
        {isShownModal ? (
          <AddMenu
            closeModal={closeModal}
            wizardTitle={
              Object.values(editMenu).length
                ? "Редактировать меню"
                : "Новое меню"
            }
            isEditView={Object.values(editMenu).length}
            partCount={1}
            {...editMenu}
          />
        ) : null}
        <hr style={{ margin: "20px 0" }} />
        <div className="content__head">
          <div className="content__title title-content">
            <h2 className="title-content__name">Специальное меню</h2>
            <button
              onClick={this.handleCloseModal}
              className="title-content__add"
            />
          </div>
        </div>
        <MenuList2 />
        {isShowModalSpecialMenu ? (
          <AddSpecialMenu
            isOpen={isShowModalSpecialMenu}
            closeModal={this.handleCloseModal}
            wizardTitle={
              Object.values(editMenu).length
                ? "Редактировать меню"
                : "Новое меню"
            }
            isEditView={Object.values(editMenu).length}
            partCount={1}
            {...editMenu}
          />
        ) : null}
      </Fragment>
    );
  }
}

export default connect(
  state => ({
    isShownModal: isShownModalSelector(state)
  }),
  { changeSearchMenu, clearSearchMenu, showModal, closeModal }
)(Menus);
