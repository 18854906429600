import React, { Component } from "react";

class Profile extends Component {
  handleEdit = (e, id) => {
    const { handleEdit } = this.props;
    handleEdit(e, id);
  };
  render() {
    const { profile } = this.props;
    const { title, fullName } = profile;
    return (
      <div className="table-list__row">
        <div className="table-list__name">{title}</div>
        <div className="table-list__name">
          {fullName.lastName} {fullName.firstName}
        </div>
        {/*<ListControls
          handleEdit={this.handleEdit}
          id={id}
          handleDelete={this.handleDelete}
        />*/}
      </div>
    );
  }
}

export default Profile;
