import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { isShownModalSelector } from "../Modal/selectors";
import { closeModal, showModal } from "../Modal/ducks";
import AccountsList from "./AccountsList";
import EditPhone from "./EditPhone";

class AccountsMain extends Component {
  state = {
    search: "",
    editAccount: null,
    isShownDialog: false
  };

  showDialog = () => {
    this.setState({
      isShownDialog: true
    });
  };

  closeDialog = () => {
    this.setState({
      isShownDialog: false
    });
  };

  handleChange = e => {
    const { value, name } = e.target;
    this.setState(() => ({ [name]: value }));
  };

  handleEdit = account => {
    this.setState(
      {
        editAccount: account
      },
      () => this.showDialog()
    );
  };

  handleNew = () => {
    this.setState(
      {
        editAccount: null
      },
      () => this.props.showModal()
    );
  };

  render() {
    const { editAccount, isShownDialog } = this.state;
    return (
      <Fragment>
        <div className="content__head header-content">
          <div className="title__content title-content">
            <h2 className="title-content__name">Пользователи</h2>
          </div>
        </div>
        <AccountsList handleEditPhone={this.handleEdit} />
        {isShownDialog && (
          <EditPhone closeDialog={this.closeDialog} phone={editAccount.phone} />
        )}
      </Fragment>
    );
  }
}

export default connect(
  state => ({
    isShownModal: isShownModalSelector(state)
  }),
  {
    showModal,
    closeModal
  }
)(AccountsMain);
