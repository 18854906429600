import React from "react";
import Button from "../Button/Button";
import InputSubmit from "../Input/InputSubmit";

const Form = ({ handleSubmit, handleCancel, title, children }) => {
  return (
    <div className="form">
      <div className="form__title">{title}</div>
      <form
        onSubmit={e => {
          e.preventDefault();
          handleSubmit(e);
        }}
      >
        {children}
        <Button onClick={handleCancel} className="form__btn">
          Отменить
        </Button>
        <InputSubmit className="form__btn" value="Сохранить" />
      </form>
    </div>
  );
};

export default Form;
