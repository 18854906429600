import React from "react";
import Label from "../Label/Label";

const InputCheckbox = ({
  label,
  name,
  value,
  handleChange,
  checked,
  disabled,
  className
}) => {
  return (
    <Label className={"input-checkbox__container " + className}>
      {label}
      <input
        type="checkbox"
        name={name}
        value={value}
        checked={checked}
        disabled={disabled}
        onChange={handleChange}
      />
      <span className="input-checkbox__checkmark" />
    </Label>
  );
};

export default InputCheckbox;
