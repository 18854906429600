import React, { Component } from "react";
import classes from "./style.module.scss";
import ShowForm from "../../../components/Form/ShowForm";
import Calorie from "./Calorie";
import { menuTypes } from "../../Meals/utils";
import InputCheckbox from "../../../components/Input/InputCheckbox";
import { calorieTypes } from "../constants";

class FirstPart extends Component {
  state = {
    formCollection: []
  };
  static getDerivedStateFromProps(nextProps, prevState) {
    const { title, description, caloriesName } = nextProps;
    return {
      formCollection: [
        {
          type: "input",
          name: `title`,
          value: title,
          placeholder: "Название"
        },
        {
          type: "textarea",
          name: `description`,
          value: description,
          placeholder: "Описание"
        },
        {
          type: "select",
          name: `caloriesName`,
          value: calorieTypes[caloriesName],
          placeholder: "Тип меню",
          values: Object.keys(calorieTypes),
          options: Object.values(calorieTypes)
        }
      ]
    };
  }

  render() {
    const {
      handleChange,
      addCalorie,
      proportions,
      handleChangeCalories,
      handleDeleteCalorie,
      isEditView,
      handleChangeMealTypes
    } = this.props;
    const { formCollection } = this.state;
    const calorieList = proportions.map(proportion => (
      <Calorie
        key={proportion.id}
        {...proportion}
        handleChange={handleChangeCalories}
        deleteCalorie={handleDeleteCalorie}
      />
    ));
    const mealsList = menuTypes.map(menuType => (
      <InputCheckbox
        key={menuType.name}
        name={menuType.name}
        label={menuType.value}
        handleChange={handleChangeMealTypes}
      />
    ));
    return (
      <div className="adding-menu__main">
        <h4 className="form__title">Добавление меню</h4>
        <ShowForm formCollection={formCollection} handleChange={handleChange} />
        {isEditView ? null : (
          <div>
            <div className="adding-menu__meals">
              <div className="adding-meals__title">Выберите тип питания</div>
              <div className="adding-meals__list">{mealsList}</div>
            </div>
            <div className="adding-menu__calorie">
              <div className={classes["calorie__title"]}>
                Список килокалорий:
              </div>
              {calorieList}
              <button onClick={addCalorie} className={classes["calorie__btn"]}>
                Добавить калорийность
              </button>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default FirstPart;
