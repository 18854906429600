import React, { Component } from "react";
import { connect } from "react-redux";
import { clearError } from "../Confirmation/ducks";
import { securityConfirmPhone } from "./ducks";
import InputPhone from "../../components/Input/InputPhone";

class ConfirmPhone extends Component {
  handleEnter = () => {
    const { phone, securityConfirmPhone, clearError } = this.props;
    securityConfirmPhone(phone);
    clearError();
  };
  render() {
    const { handleChange, phone, error } = this.props;
    return (
      <div>
        <InputPhone
          value={phone}
          placeholder="Номер телефона"
          name="phone"
          handleChange={handleChange}
          error={error === 400 && { message: "Неверный номер телефона" }}
        />
        <button onClick={this.handleEnter} className="authorisation__button">
          Отправить SMS пароль
        </button>
      </div>
    );
  }
}

export default connect(
  null,
  { clearError, securityConfirmPhone }
)(ConfirmPhone);
