import React, { Component } from "react";
import ShowForm from "../../../components/Form/ShowForm";
import Button from "../../../components/Button/Button";
import ImagePicker from "../../../components/ImagePicker/ImagePicker";
import Input from "../../../components/Input/Input";

class Step extends Component {
  state = {
    formCollection: []
  };
  static getDerivedStateFromProps(nextProps, prevState) {
    const { guide, id, stepIndex } = nextProps;
    return {
      formCollection: [
        {
          type: "label",
          name: `step${stepIndex}`,
          value: `Шаг ${stepIndex}`,
          className: "step__label"
        },
        {
          type: "textarea",
          id: id,
          name: `guide`,
          value: guide,
          placeholder: "Описание",
          className: "step__guide"
        }
      ]
    };
  }
  render() {
    const { formCollection } = this.state;
    const {
      handleChange,
      deleteStep,
      id,
      hint,
      guide,
      disabled,
      cookingStepCover,
      handleChangeCover
    } = this.props;
    return (
      <div className="step">
        <ImagePicker
          className="step__cover"
          name={`${id}_cookingStep`}
          title="Нажмите для добавления фотографии шага"
          data={cookingStepCover && cookingStepCover.path}
          afterLoad={handleChangeCover}
          afterClear={handleChangeCover}
        />
        <ShowForm formCollection={formCollection} handleChange={handleChange} />
        {!!guide.trim().length && (
          <Input
            value={hint}
            className="step__hint"
            id={id}
            handleChange={handleChange}
            placeholder="Шпаргалка"
            name="hint"
          />
        )}
        <Button
          onClick={e => deleteStep(e, id)}
          className="step__delete"
          disabled={disabled}
        >
          Удалить
        </Button>
      </div>
    );
  }
}

export default Step;
