import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { recipeProportionByIdSelector } from "./selectors";
import { recipesSelector } from "../Recipes/selectors";
import Input from "../../components/Input/Input";
import { editRecipeStore, editRecipeLast } from "../Recipes/ducks";
import { APPROVED_DRAFT, PUBLISHED } from "../../commons/constants";
import { statuses } from "../Recipes/utils";
import { setCurrentDate } from "../Meals/ducks";
import Button from "../../components/Button/Button";
import ButtonConfirm from "../../components/Button/ButtonConfirm";

class RecipeProportionDay extends Component {
  state = {
    recipe: {}
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { recipeProportion, recipes } = nextProps;
    const { recipe } = recipeProportion;
    return {
      recipe: recipes[recipe] || {}
    };
  }

  handleChangeTitle = e => {
    const { value } = e.target;
    const { editRecipeStore, editRecipeLast } = this.props;
    const { recipe } = this.state;
    editRecipeStore(recipe.id, {
      ...recipe,
      title: value
    });
    editRecipeLast(recipe.id);
  };

  render() {
    const { recipe } = this.state;
    const { setCurrentDate, meal, handleDeleteRecipe, id } = this.props;
    return (
      <div
        className={`table-day__recipe day-recipe ${
          recipe.status === APPROVED_DRAFT ? "day-recipe_approved" : ""
        }`}
      >
        <div className="day-recipe__title">
          <Input
            value={recipe.title}
            //placeholder={"Название"}
            handleChange={this.handleChangeTitle}
            disabled={recipe.status === PUBLISHED}
            onBlur={e => console.log(e.target)}
          />
          <div>
            Статус рецепта{" "}
            {recipe.status &&
              statuses.find(({ name }) => name === recipe.status).title}
          </div>
        </div>
        <div className="day-recipe__controls">
          <Link
            to={`day?mealType=${meal.mealType}`}
            onClick={() => setCurrentDate(new Date(meal.date).toISOString())}
            className="day-recipe__link"
            title="Перейти к рецепту"
          />
          <ButtonConfirm
            handleConfirm={() => handleDeleteRecipe(id)}
            confirmTitle="Подтвердить удаление из меню"
            className="day-recipe__delete"
            title="Удалить"
          />
        </div>
      </div>
    );
  }
}

export default connect(
  (state, ownProps) => ({
    recipeProportion: recipeProportionByIdSelector(state, ownProps),
    recipes: recipesSelector(state)
  }),
  {
    editRecipeStore,
    editRecipeLast,
    setCurrentDate
  }
)(RecipeProportionDay);
