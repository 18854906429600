import React, { Component } from "react";
import { createPortal } from "react-dom";
import SelectRecipe from "../Recipes/SelectRecipe/SelectRecipe";
import connect from "react-redux/es/connect/connect";
import {
  createMeal,
  editMeal,
  editMealCreateRecipeProportion
} from "../Meals/ducks";
import { dateFormat } from "../../commons/utils";
import { loadRecipeProportion } from "./ducks";

class NewRecipeProportionControls extends Component {
  state = {
    showModal: false
  };
  showDialog = () => {
    this.setState({
      showModal: true
    });
  };

  closeModal = () => {
    this.setState({
      showModal: false
    });
  };

  selectRecipe = (recipe, weight) => {
    const {
      meal,
      editMealCreateRecipeProportion,
      loadRecipeProportion,
      menuId,
      proportionId,
      createMeal
    } = this.props;
    const recipeProportion = recipe.proportions[0];
    if (meal && Object.entries(meal).length) {
      loadRecipeProportion(recipe.id, recipeProportion.id);
      editMealCreateRecipeProportion(menuId, proportionId, meal.id, meal, {
        recipeProportion,
        weight
      });
    } else
      createMeal(
        menuId,
        proportionId,
        this.prepareMeal(),
        { recipeProportion, weight },
        {
          withCreateRecipe: true
        }
      );
    this.closeModal();
  };

  handleNewRecipe = () => {
    const {
      meal,
      editMealCreateRecipeProportion,
      menuId,
      proportionId,
      createMeal
    } = this.props;
    meal && Object.entries(meal).length
      ? editMealCreateRecipeProportion(menuId, proportionId, meal.id, meal)
      : createMeal(menuId, proportionId, this.prepareMeal(), null, {
          withCreateRecipe: true
        });
  };

  prepareMeal = () => {
    const { type, currentDate } = this.props;
    return {
      mealType: type,
      date: dateFormat(currentDate)
    };
  };

  render() {
    const { className } = this.props;
    const { showModal } = this.state;
    return (
      <div>
        <div className={className}>
          <button
            onClick={this.handleNewRecipe}
            className={className + "__add"}
            title="Создать новый рецепт"
          />
          <button
            onClick={this.showDialog}
            className={className + "__load"}
            title="Загрузить существующий рецепт"
          />
        </div>
        {showModal
          ? createPortal(
              <SelectRecipe
                closeModal={this.closeModal}
                selectRecipe={this.selectRecipe}
              />,
              document.querySelector("#dialog-dom")
            )
          : null}
      </div>
    );
  }
}

export default connect(
  null,
  { createMeal, editMeal, editMealCreateRecipeProportion, loadRecipeProportion }
)(NewRecipeProportionControls);
