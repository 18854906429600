import React, { Component, Fragment } from "react";
import { Route, Switch } from "react-router-dom";
import PickAntiTags from "./Select/PickAntiTags";
import TagsMain from "./TagsMain";
import PickFriendlyTags from "./Select/PickFriendlyTags";

class TagsCategoryRoute extends Component {
  render() {
    const { match } = this.props;
    return (
      <Fragment>
        <Switch>
          <Route exact path={`${match.url}`} component={TagsMain} />
          <Route
            path={`${match.url}/:categoryId/tag/:id/antitag`}
            render={({ match, history }) => (
              <PickAntiTags
                id={match.params.id}
                history={history}
                categoryId={match.params.categoryId}
              />
            )}
          />
          <Route
            path={`${match.url}/:categoryId/tag/:id/friendlytag`}
            render={({ match, history }) => (
              <PickFriendlyTags
                id={match.params.id}
                history={history}
                categoryId={match.params.categoryId}
              />
            )}
          />
          <Route path="/*" render={() => <h3>Page not found</h3>} />
        </Switch>
      </Fragment>
    );
  }
}

export default TagsCategoryRoute;
