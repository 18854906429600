import React, { Fragment } from "react";
import Preloader from "../Preloader/Preloader";
import Button from "../Button/Button";
import { createPortal } from "react-dom";

class ImagePicker extends React.Component {
  constructor(props) {
    super(props);
    const { loading, data } = props;
    this.state = {
      data: data || null,
      fullscreen: false,
      loading: loading || false,
      isWrongSize: false,
      error: false
    };
  }
  componentWillReceiveProps(nextProps) {
    const { loading, data, isNotPickLinkedCover } = nextProps;
    if (isNotPickLinkedCover) return;
    if (loading) {
      this.setState({ loading: true });
    } else {
      if (!data) return this.setState({ loading: false });
      if (data === this.props.data) return;
      this.loadImage(data);
    }
  }

  componentDidMount() {
    const { data } = this.state;
    if (!data) return;
    this.loadImage(data);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  rightSize = (file, data) => {
    const { afterLoad } = this.props;
    this.setState(
      {
        data,
        error: false,
        isWrongSize: false
      },
      () => afterLoad && afterLoad(file)
    );
  };

  wrongSize = () => {
    this.setState({
      isWrongSize: true
    });
  };

  defaultRightSize = (width, height) => {
    return width >= 128 && width <= 2048 && height >= 128 && height <= 2048;
  };

  checkRightSize = (width, height) => {
    const { checkRightSize } = this.props;
    if (checkRightSize)
      return (
        this.defaultRightSize(width, height) && checkRightSize(width, height)
      );
    return this.defaultRightSize(width, height);
  };

  handleFileChange = event => {
    const { target } = event;
    const { files } = target;
    if (files && files[0]) {
      const reader = new FileReader();
      reader.onload = event => {
        const image = new Image();
        image.src = event.target.result;
        const parentThis = this;
        image.onload = function() {
          if (parentThis.checkRightSize(this.width, this.height)) {
            parentThis.rightSize(files[0], reader.result);
          } else {
            parentThis.wrongSize();
          }
        };
      };

      reader.readAsDataURL(files[0]);
    }
  };

  handleClearClick = () => {
    const { afterClear } = this.props;
    this.setState(
      () => ({
        data: null,
        error: false,
        fullScreen: false
      }),
      () => afterClear && afterClear()
    );
  };

  handlePreviewClick = () => {
    const { data, fullScreen } = this.state;
    if (!data) {
      return;
    }
    this.setState({ fullScreen: !fullScreen });
  };

  loadImage = data => {
    const image = new Image();
    image.src = data;
    this.setState({
      loading: true,
      error: false,
      data: null
    });
    this._isMounted = true;
    const parentThis = this;
    image.onload = function() {
      if (parentThis._isMounted) {
        parentThis.setState({
          loading: false,
          data
        });
      }
    };
    image.onerror = function() {
      if (parentThis._isMounted) {
        parentThis.setState({
          loading: false,
          error: true
        });
      }
    };
  };

  render() {
    const { data, fullScreen, loading, isWrongSize, error } = this.state;
    const { name, disabled, className, title } = this.props;
    if (loading) return <Preloader />;
    const backgroundImage = data ? { backgroundImage: `url(${data})` } : null;
    const previewClasses = [
      "picker__preview"
      //fullScreen ? "picker__preview_fullscreen" : ""
    ].join(" ");
    return (
      <Fragment>
        <div className={"picker " + className}>
          <input
            id={name}
            type="file"
            accept="image/*"
            capture="camera"
            onClick={event => {
              event.target.value = null;
            }}
            onChange={this.handleFileChange}
            className="picker__input"
            disabled={disabled}
          />

          <div
            className={previewClasses}
            style={backgroundImage}
            onClick={this.handlePreviewClick}
          >
            {!data && !loading && (
              <label htmlFor={name} className="picker__label">
                {!error
                  ? isWrongSize
                    ? "Неверный размер изображения"
                    : title
                    ? title
                    : "Нажмите для загрузки изображения"
                  : "Ошибка загрузки изображения"}
              </label>
            )}
          </div>

          <Button
            type="button"
            onClick={this.handleClearClick}
            className="picker__button"
            disabled={disabled}
          >
            Clear Image
          </Button>
        </div>
        {fullScreen &&
          createPortal(
            <div
              className="image-fullscreen__wrapper"
              onClick={this.handlePreviewClick}
            >
              <img src={data} className="image-fullscreen" />
            </div>,
            document.querySelector("#image-dom")
          )}
      </Fragment>
    );
  }
}

export default ImagePicker;
