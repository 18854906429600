import { call, put, takeLatest, select, takeEvery } from "redux-saga/effects";
import { errorMassages, FAIL, START, SUCCESS } from "../../commons/constants";
import * as API from "./api";
import {
  CREATE_RECIPE,
  DELETE_RECIPE,
  EDIT_RECIPE,
  EDIT_RECIPE_STORE,
  LOAD_RECIPES,
  LOAD_RECIPE,
  EDIT_RECIPE_STATUS,
  LOAD_RECIPES_FILTERED,
  CLEAR_RECIPES_LAST,
  EDIT_RECIPES_CHANGED,
  CREATE_COOKING_STEP,
  createCookingStepSuccess,
  EDIT_COOKING_STEP,
  editCookingStepSuccess,
  createCompoundIngredientSuccess,
  createCompoundIngredientFail,
  CREATE_COMPOUND_INGREDIENT,
  EDIT_RECIPE_WITH_PROPORTION,
  EDIT_RECIPE_DATABASE,
  clearCookingStepLast,
  EDIT_COOKING_STEP_STORE,
  editCookingStepLast
} from "./ducks";
import {
  getLastChangedCookingSteps,
  getLastChangedRecipes,
  recipeByIdSelector
} from "./selectors";
import {
  normaliseCookingStep,
  normaliseFromLoad,
  normaliseRecipe
} from "./normalisers";

import { normaliseFromLoad as normaliseTags } from "../Tags/normalisers";

import fetchAPI, { fetchReqAsync, fetchResAsync } from "../../commons/api";
import fecthAPI from "../../commons/api";
import {
  createRecipeProportion,
  deleteRecipeProportion,
  editRecipeProportion
} from "../RecipeProportions/sagas";

export function* getRecipes({ filters }) {
  try {
    yield put({ type: LOAD_RECIPES + START });
    const res = yield fetchReqAsync(API.getRecipes, filters);
    const recipes = normaliseFromLoad(res);
    yield put({
      type: LOAD_RECIPES + SUCCESS,
      payLoad: { data: recipes },
      count: res.count,
      total: res.total
    });
  } catch (error) {
    yield call(alert, errorMassages.load("Рецептов", error));
    yield put({
      type: LOAD_RECIPES + FAIL,
      error
    });
  }
}

export function* getFilteredRecipes({ filter }) {
  try {
    yield put({ type: LOAD_RECIPES_FILTERED + START });

    const recipes = normaliseFromLoad(
      yield fetchReqAsync(API.getRecipes, filter)
    );
    yield put({
      type: LOAD_RECIPES_FILTERED + SUCCESS,
      payLoad: { data: recipes }
    });
  } catch (error) {
    yield call(alert, errorMassages.load("Рецептов", error));
    yield put({
      type: LOAD_RECIPES_FILTERED + FAIL,
      error
    });
  }
}

export function* getRecipe({ payLoad, url, options }) {
  try {
    yield put({ type: LOAD_RECIPE + START });
    const res = url
      ? normaliseRecipe(yield fetchReqAsync(fecthAPI.get, url))
      : normaliseRecipe(yield fetchReqAsync(API.getRecipe, payLoad.id));
    yield put({
      type: LOAD_RECIPE + SUCCESS,
      payLoad: { data: { ...res }, id: res.id }
    });
    return res;
  } catch (error) {
    console.log(error);
    yield call(alert, errorMassages.load("Рецептов", error));
    yield put({
      type: LOAD_RECIPE + FAIL,
      error
    });
  }
}

function* editRecipe({ payLoad: { id, data }, options }) {
  try {
    yield put({
      type: EDIT_RECIPE + START
    });
    const cookingSteps = yield select(getLastChangedCookingSteps, { id });
    for (let step of cookingSteps) {
      yield call(editCookingStep, {
        payLoad: { id: step.id, recipeId: id, data: step }
      });
      yield put(clearCookingStepLast(step.id));
    }
    if (data.status.isChanged)
      yield call(editRecipeStatus, {
        payLoad: { id, status: data.status.value }
      });
    yield fetchResAsync(API.putRecipe, id, data);
    yield put({
      type: EDIT_RECIPE + SUCCESS
    });
  } catch (error) {
    yield call(alert, errorMassages.edit("Рецептов", error));
    yield put({
      type: EDIT_RECIPE + FAIL,
      error
    });
  }
}

export function* editRecipeWithGet(action) {
  yield call(editRecipe, action);
  yield call(getRecipe, action);
}

export function* editRecipeWithProportion({
  payLoad: { recipe, proportion },
  options
}) {
  yield call(editRecipe, { payLoad: { id: recipe.id, data: recipe } });
  yield call(editRecipeProportion, {
    payLoad: { recipeId: recipe.id, id: proportion.id, data: proportion },
    options
  });
  //yield call(getRecipe, { payLoad: { id: recipe.id } });
}

export function* editRecipeDatabase({ payLoad: { id, data } }) {
  try {
    yield fetchResAsync(API.putRecipe, id, data);
    const resRecipe = normaliseRecipe(yield fetchReqAsync(API.getRecipe, id));
    yield put({
      type: EDIT_RECIPE_DATABASE + SUCCESS,
      payLoad: {
        id,
        data: resRecipe
      }
    });
  } catch (error) {
    yield call(alert, errorMassages.edit("Рецептов", error));
    yield put({
      type: EDIT_RECIPE_DATABASE + FAIL,
      error
    });
  }
}

export function* editRecipeStore({ payLoad: { id, data } }) {
  try {
    yield put({
      type: EDIT_RECIPE_STORE + SUCCESS,
      payLoad: {
        id,
        data
      }
    });
  } catch (error) {
    yield call(alert, errorMassages.edit("Рецептов", error));
    yield put({
      type: EDIT_RECIPE_STORE + FAIL,
      error
    });
  }
}

function* editRecipeStatus({ payLoad: { id, status } }) {
  try {
    //yield put({ type: EDIT_RECIPE_STATUS + START });
    yield fetchResAsync(API.putRecipeStatus, id, status);
    // yield put({
    //   type: EDIT_RECIPE_STATUS + SUCCESS
    // });
  } catch (error) {
    yield call(alert, errorMassages.edit("Рецептов", error));
    // yield put({
    //   type: EDIT_RECIPE_STATUS + FAIL,
    //   error
    // });
  }
}

function* editRecipesChanged(action) {
  try {
    const lastChangedRecipes = yield select(getLastChangedRecipes);
    for (let lastChangedRecipe of lastChangedRecipes) {
      yield fetchResAsync(
        API.putRecipe,
        lastChangedRecipe.id,
        lastChangedRecipe
      );
    }
    yield put({
      type: CLEAR_RECIPES_LAST
    });
  } catch (e) {
    console.log(e);
    yield call(alert, errorMassages.edit("recipes"));
  }
}

function* editCookingStep({ payLoad: { id, recipeId, data } }) {
  try {
    yield fetchResAsync(API.putCookingStep, id, data);
    const resCookingSteps = normaliseCookingStep(
      yield fetchReqAsync(fecthAPI.get, data.self.href)
    );
    yield put(editCookingStepSuccess(id, recipeId, resCookingSteps));
  } catch (e) {
    yield call(alert, errorMassages.edit("recipes", e));
  }
}

function* editCookingStepStore({ payLoad: { id, recipeId, data } }) {
  yield put(editCookingStepLast(id));
  yield put(editCookingStepSuccess(id, recipeId, data));
}

export function* createRecipe({ payLoad, history }) {
  try {
    yield put({ type: CREATE_RECIPE + START });
    const { data, proportion } = payLoad;
    const res = yield fetchResAsync(API.postRecipe, data);
    const resRecipe = normaliseRecipe(
      yield fetchReqAsync(fecthAPI.get, res.headers.get("Location"))
    );
    const id = resRecipe.id;
    const recipeProportionID = yield call(createRecipeProportion, {
      payLoad: {
        id,
        data: {
          ...proportion
        }
      }
    });
    yield put({
      type: CREATE_RECIPE + SUCCESS,
      payLoad: {
        id,
        data: {
          ...resRecipe,
          proportions: [{ ...proportion }]
        }
      }
    });
    if (history)
      history.push(`/recipes/${id}/proportions/${recipeProportionID}`);
    return recipeProportionID;
  } catch (error) {
    yield call(alert, errorMassages.create("Рецептов", error));
    yield put({
      type: CREATE_RECIPE + FAIL,
      error
    });
  }
}

function* createCompoundIngredient({ payload: { id } }) {
  try {
    yield fetchResAsync(API.postCompoundIngredient, id);
    yield put(createCompoundIngredientSuccess());
  } catch (error) {
    yield call(alert, errorMassages.create("Ингридиента из рецепта", error));
    yield put(createCompoundIngredientFail(error));
  }
}

function* createCookingStep({ payLoad: { data, recipeId } }) {
  try {
    const res = yield fetchResAsync(API.postCookingStep, data);
    const resCookingStep = normaliseCookingStep(
      yield fetchReqAsync(fecthAPI.get, res.headers.get("Location"))
    );
    yield put(createCookingStepSuccess(resCookingStep, recipeId));
  } catch (error) {
    yield call(alert, errorMassages.create("Шагов рецепта", error));
  }
}

function* deleteRecipe({ payLoad: { id } }) {
  try {
    yield put({ type: DELETE_RECIPE + START });
    const recipe = yield select(recipeByIdSelector, { id });
    for (let proportion of recipe.proportions)
      yield call(deleteRecipeProportion, {
        payLoad: {
          recipeId: id,
          id: proportion.id
        }
      });
    yield fetchResAsync(API.deleteRecipeAPI, id);
    yield put({
      type: DELETE_RECIPE + SUCCESS,
      payLoad: {
        id
      }
    });
  } catch (error) {
    yield call(alert, errorMassages.delete("Рецептов", error));
    yield put({
      type: DELETE_RECIPE + FAIL,
      error
    });
  }
}

function* watchRecipes() {
  yield takeLatest(LOAD_RECIPES, getRecipes);
  yield takeLatest(LOAD_RECIPES_FILTERED, getFilteredRecipes);
  yield takeLatest(LOAD_RECIPE, getRecipe);
  yield takeLatest(EDIT_RECIPE, editRecipeWithGet);
  yield takeLatest(EDIT_RECIPE_DATABASE, editRecipeDatabase);
  yield takeLatest(EDIT_RECIPE_STORE, editRecipeStore);
  yield takeLatest(EDIT_RECIPES_CHANGED, editRecipesChanged);
  yield takeLatest(EDIT_RECIPE_STATUS, editRecipeStatus);
  yield takeLatest(EDIT_COOKING_STEP, editCookingStep);
  yield takeLatest(EDIT_COOKING_STEP_STORE, editCookingStepStore);
  yield takeLatest(EDIT_RECIPE_WITH_PROPORTION, editRecipeWithProportion);
  yield takeLatest(CREATE_RECIPE, createRecipe);
  yield takeLatest(CREATE_COMPOUND_INGREDIENT, createCompoundIngredient);
  yield takeLatest(CREATE_COOKING_STEP, createCookingStep);
  yield takeLatest(DELETE_RECIPE, deleteRecipe);
}

export default watchRecipes;
