import React, { Component } from "react";
import TreeNode from "./TreeNode";
import { deleteChildTag } from "../Tags/ducks";
import { connect } from "react-redux";

class Tree extends Component {
  setTree() {
    const {
      tree,
      openTrees,
      handleOpenNode,
      handleCloseNode,
      isConstructor,
      handleSelect,
      handleUnselect,
      handleCreate,
      handleCreateIcon,
      handleClearIcon,
      selectedTags,
      isDisabledTreeNode,
      isSelectChildView,
      isSelectView,
      isPickedTreeNode,
      handleDelete,
      deleteChildTag,
      withIcon
    } = this.props;
    const nodes = [];
    const isOpen = !!openTrees.find(openTree => openTree === tree.id);
    const isList = tree.parent;
    if (tree) {
      nodes.push(
        <TreeNode
          key={tree.id}
          node={tree}
          isList={isList}
          isOpen={isOpen}
          handleCloseNode={handleCloseNode}
          handleOpenNode={handleOpenNode}
          withIcon={withIcon}
          handleSelect={() =>
            handleSelect(tree.parent, tree.childContainer, tree)
          }
          handleUnselect={() =>
            handleUnselect(tree.parent, tree.childContainer, tree)
          }
          handleDelete={handleDelete}
          handleCreate={handleCreate}
          handleCreateIcon={handleCreateIcon}
          handleClearIcon={handleClearIcon}
          isConstructor={isConstructor}
          isSelectView={isSelectView}
          isPicked={isPickedTreeNode && isPickedTreeNode(tree.parent, tree.id)}
          isDisabled={
            isDisabledTreeNode && isDisabledTreeNode(tree.parent, tree.id)
          }
          isSelected={
            !!(selectedTags && selectedTags.find(({ id }) => id === tree.id))
          }
        />
      );
    }
    if (isOpen && tree.childContainer)
      Object.values(tree.childContainer).forEach(child =>
        nodes.push(
          <Tree
            key={child.id}
            tree={child}
            openTrees={openTrees}
            handleSelect={handleSelect}
            handleUnselect={handleUnselect}
            handleDelete={() => deleteChildTag(tree.id, child.id)}
            handleCreateIcon={(id, file) => handleCreateIcon(tree.id, file, id)}
            handleClearIcon={node => handleClearIcon(tree, node)}
            selectedTags={selectedTags}
            isDisabledTreeNode={isDisabledTreeNode}
            isPickedTreeNode={isPickedTreeNode}
            isConstructor={isConstructor}
            withIcon={withIcon}
            isSelectView={isSelectChildView || isSelectView}
          />
        )
      );
    return nodes;
  }

  render() {
    const treeNodes = this.setTree();
    return <ul className="tree__container">{treeNodes}</ul>;
  }
}

export default connect(
  null,
  { deleteChildTag }
)(Tree);
