import React, { Component } from "react";
import { compareDates, dateFormat } from "../../../commons/utils";
import MenuProportionsMonthView from "./MenuProportionsMonthView";
import { DateTime } from "luxon";

class MenuProportionMonthModel extends Component {
  componentDidMount() {
    this.loadMealsOfMonth();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.currentCalorie !== this.props.currentCalorie ||
      !compareDates(prevProps.date, this.props.date)
    ) {
      this.loadMealsOfMonth();
    }
  }

  loadMealsOfMonth = () => {
    const { loadMeals, menu, proportions, currentCalorie, date } = this.props;
    const month = date.getMonth();
    const year = date.getFullYear();
    const dateFrom = new Date(year, month, 1);
    const dateTo = new Date(
      year,
      month,
      new Date(year, month + 1, 0).getDate()
    );
    const proportion = Object.values(proportions).find(
      proportion => proportion.kilocalories === currentCalorie
    );
    if (proportion)
      loadMeals(
        menu.id,
        proportion.id,
        dateFormat(dateFrom),
        dateFormat(dateTo),
        {
          isNotLoadRecipeProportions: true
        }
      );
  };

  handleDecrMonth = () => {
    const { date, setCurrentDate } = this.props;
    setCurrentDate(
      DateTime.fromJSDate(date)
        .minus({ months: 1 })
        .toISODate()
    );
  };

  handleIncrMonth = () => {
    const { date, setCurrentDate } = this.props;
    setCurrentDate(
      DateTime.fromJSDate(date)
        .plus({ months: 1 })
        .toISODate()
    );
  };

  render() {
    const { currentCalorie, handleSelectProportion, date } = this.props;
    return (
      <MenuProportionsMonthView
        date={date}
        currentCalorie={currentCalorie}
        handleDecrMonth={this.handleDecrMonth}
        handleSelectProportion={handleSelectProportion}
        handleIncrMonth={this.handleIncrMonth}
      />
    );
  }
}

export default MenuProportionMonthModel;
