import { createSelector } from "reselect";

const filtersGetter = state => state.filters;

export const ingredientFilterSelector = createSelector(
  filtersGetter,
  ({ filterIngredient }) => filterIngredient
);

export const tagFilterSelector = createSelector(
  filtersGetter,
  ({ searchTag }) => searchTag
);

export const recipeFilterSelector = createSelector(
  filtersGetter,
  ({ searchRecipe }) => searchRecipe
);

export const menuFilterSelector = createSelector(
  filtersGetter,
  ({ searchMenu }) => searchMenu
);

export const materialsFilterSelector = createSelector(
  filtersGetter,
  ({ filterMaterials }) => filterMaterials
);
