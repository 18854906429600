import { createSelector } from "reselect";

const securityGetter = state => state.confirmation;

export const isLoadingConfirmationSelector = createSelector(
  securityGetter,
  ({ loading }) => {
    return loading;
  }
);

export const getConfirmationPhoneSelector = createSelector(
  securityGetter,
  ({ isConfirmPhone }) => {
    return isConfirmPhone;
  }
);

export const getConfirmationCodeSelector = createSelector(
  securityGetter,
  ({ isConfirmCode }) => {
    return isConfirmCode;
  }
);

export const confirmationErrorSelector = createSelector(
  securityGetter,
  ({ error }) => {
    return error;
  }
);
