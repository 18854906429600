import { createSelector } from "reselect";

const commentsGetter = state => state.comments;
const commentGetter = (state, ownProps) => state.comments.data[ownProps.id];

export const commentsSelector = createSelector(
  commentsGetter,
  ({ data }) => data
);

export const commentByIdSelector = createSelector(
  commentGetter,
  data => data
);

export const isLoadingCommentsSelector = createSelector(
  commentsGetter,
  ({ loading }) => {
    return loading;
  }
);

export const isLoadedCommentsSelector = createSelector(
  commentsGetter,
  ({ loaded }) => {
    return loaded;
  }
);
