import { call, put, takeLatest } from "redux-saga/effects";
import { errorMassages, FAIL, START, SUCCESS } from "../../commons/constants";
import * as API from "./api";
import { LOAD_ACCOUNTS } from "./ducks";
import { normaliseFromLoad } from "./normalisers";
import { fetchReqAsync } from "../../commons/api";

export function* getAccounts() {
  try {
    yield put({ type: LOAD_ACCOUNTS + START });
    const res = normaliseFromLoad(yield fetchReqAsync(API.getAccounts));
    yield put({
      type: LOAD_ACCOUNTS + SUCCESS,
      payLoad: res
    });
  } catch (error) {
    yield call(alert, errorMassages.load("accounts"));
    yield put({
      type: LOAD_ACCOUNTS + FAIL,
      error
    });
  }
}

function* watchAccounts() {
  yield takeLatest(LOAD_ACCOUNTS, getAccounts);
}

export default watchAccounts;
