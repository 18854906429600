import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

function sleep(delay = 0) {
  return new Promise(resolve => {
    setTimeout(resolve, delay);
  });
}

export default function GetTagCategoryAutocomplete({
  recipeId,
  handleSetCategoryId
}) {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState("");

  useEffect(
    () => {
      let active = true;
      (async () => {
        try {
          const ACCESS_TOKEN = localStorage.getItem("ACCESS_TOKEN");

          const response = await fetch(
            `${process.env.API_URL}/admin/tag/category/list`,
            {
              headers: {
                Accept: "application/json",
                "Content-type": "application/json; charset=utf-8",
                Authorization: "Bearer " + ACCESS_TOKEN
              }
            }
          );
          if (response.ok) {
            const json = await response.json();
            const items = await json["_embedded"]["items"];

            setOptions(items);
          } else {
            const error = await response.json();
            throw new Error(JSON.stringify(error));
          }
        } catch (e) {
          // console.log(e);
        }
        // const items = await response["_embedded"]["items"];
        // console.log(items)
      })();

      return () => {};
    },
    [recipeId]
  );

  return (
    <Autocomplete
      id="category"
      style={{ width: 300, margin: "10px" }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      onChange={(e, value) => {
        value && value.id
          ? handleSetCategoryId(value.id)
          : handleSetCategoryId(null);
        return value;
      }}
      onInputChange={(e, value) => {
        setInputValue(value);
        return value;
      }}
      getOptionLabel={option => option.title}
      options={options}
      renderInput={params => (
        <TextField
          {...params}
          label="Выбор категории"
          fullWidth
          variant="outlined"
        />
      )}
    />
  );
}
