import { weightTypesConstants } from "../../commons/constants";
import { isEmpty } from "../../commons/utils";

export const cookingMethodInputTypes = [
  {
    name: "massChanges",
    placeholder: "Вес:"
  },
  {
    name: "caloriesPerHundredGramsChanges",
    placeholder: "Калл:"
  },
  {
    name: "proteins",
    placeholder: "Б:"
  },
  {
    name: "fats",
    placeholder: "Ж:"
  },
  {
    name: "carbohydrates",
    placeholder: "У:"
  }
];

export const weightTypes = [
  {
    name: weightTypesConstants.GRAM,
    title: "граммы"
  },
  {
    name: weightTypesConstants.HUNDRED__MILLILITERS,
    title: "мл"
  },
  {
    name: weightTypesConstants.ITEM,
    title: "шт"
  },
  {
    name: weightTypesConstants.TEA_SPOON,
    title: "ч.л."
  },
  {
    name: weightTypesConstants.TABLE_SPOON,
    title: "с.л."
  }
];

export const elementsTypes = [
  {
    name: "alcohol",
    title: "Алкоголь (этиловый спирт)"
  },
  {
    name: "water",
    title: "Вода"
  },
  {
    name: "mono_and_disaccharides",
    title: "Моно- и дисахариды (сахара)"
  },
  {
    name: "starch_and_dextrins",
    title: "Крахмал и декстрины"
  },
  {
    name: "alimentary_fiber",
    title: "Пищевые волокна"
  },
  {
    name: "organic_acids",
    title: "Органические кислоты"
  },
  {
    name: "ash",
    title: "Зола"
  },
  {
    name: "retinol",
    title: "Ретинол"
  },
  {
    name: "vitamin_b1",
    title: "Витамин В1, тиамин"
  }
];

export const cookTypes = [
  {
    name: "frying_without_oil",
    title: "Жарка без масла"
  },
  {
    name: "frying_in_oil",
    title: "Жарка на масле (3 г масла)"
  },
  {
    name: "baking_in_the_oven",
    title: "Запекание в духовке"
  },
  {
    name: "cooking_with_broth",
    title: "Варка (с бульоном)"
  },
  {
    name: "cooking_broth_merge",
    title: "Варка (бульон сливается)"
  },
  {
    name: "couple",
    title: "На пару"
  },
  {
    name: "extinguishing",
    title: "Тушение"
  },
  {
    name: "gutting",
    title: "Потрошение"
  },
  {
    name: "cleaning",
    title: "Очистка"
  },
  {
    name: "gutting_and_cleaning",
    title: "Потрошение и очистка"
  }
];

export function allIngredientsCalc(ingredients) {
  let amount = 0;
  let amountChanged = 0;
  let proteins = 0;
  let fats = 0;
  let carbohydrates = 0;
  let calories = 0;
  let cellulose = 0;
  ingredients.forEach(ingredient => {
    const calc = ingredientCalc(ingredient);
    amount += calc.amount;
    amountChanged += calc.amountChanged;
    proteins += calc.proteins;
    fats += calc.fats;
    carbohydrates += calc.carbohydrates;
    calories += calc.calories;
    cellulose += calc.cellulose;
  });
  return {
    amount,
    amountChanged,
    proteins,
    fats,
    carbohydrates,
    calories,
    cellulose
  };
}

export function ingredientCalc(ingredient) {
  const {
    amount,
    gramsPerVolumeUnit = {},
    cookingMethods = {},
    cookingMethod,
    nutrients = {},
    kilocaloriesPerHundredGrams,
    cellulosePerHundredGrams
  } = ingredient;
  // метод приготовления
  const method = cookingMethod ? cookingMethods[cookingMethod] : null;
  // вес
  const weight = amount * gramsPerVolumeUnit.amount;
  const calcWeight = weight;
  // вес, приготовленний данным методом
  const weightChanged = weight * (method ? method.massChanges / 100 : 1);
  // белки на 100 грамм исходного веса
  const proteins = nutrients["proteins"];
  // жиры на 100 грамм исходного веса
  const fats = nutrients["fats"];
  // углеводы на 100 грамм исходного веса
  const carbohydrates = nutrients["carbohydrates"];
  // клетчатка в измененном весе
  const cellulose = (cellulosePerHundredGrams * calcWeight) / 100;

  const methodProteins = method ? method.proteins : null;

  const methodFats = method ? method.fats : null;

  const methodCarbohydrates = method ? method.carbohydrates : null;
  // измененное содержание белков
  const proteinsChanged =
    ((proteins
      ? proteins.amountPerHundredGrams *
        (methodProteins ? methodProteins / 100 : 1)
      : 0) /
      100) *
    calcWeight;
  // измененное содержание жиров
  const fatsChanged =
    ((fats
      ? fats.amountPerHundredGrams * (methodFats ? methodFats / 100 : 1)
      : 0) /
      100) *
    calcWeight;
  // измененное содержание углеводов
  const carbohydratesChanged =
    ((carbohydrates
      ? carbohydrates.amountPerHundredGrams *
        (methodCarbohydrates ? methodCarbohydrates / 100 : 1)
      : 0) /
      100) *
    calcWeight;
  // измененное содержание калорий
  const caloriesChanged =
    ((kilocaloriesPerHundredGrams *
      (method ? method.caloriesPerHundredGramsChanges / 100 : 1)) /
      100) *
    calcWeight;
  return {
    amount: weight,
    amountChanged: weightChanged,
    proteins: proteinsChanged,
    fats: fatsChanged,
    carbohydrates: carbohydratesChanged,
    calories: caloriesChanged,
    cellulose
  };
}
