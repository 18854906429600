import { ACTIVE, BLOCKED } from "../../commons/constants";

export const statuses = [
  {
    name: ACTIVE,
    title: "Активный"
  },
  {
    name: BLOCKED,
    title: "Заблокированный"
  }
];
