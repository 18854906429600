import React, { Component } from "react";
import { connect } from "react-redux";
import { createAntiTag, deleteAntiTag, loadAntiTags } from "../ducks";
import { antiTagsSelector, isLoadingAntiTagsSelector } from "../selectors";
import Preloader from "../../../HOCs/Preloader";
import TagsCategoryMain from "../../TagsCategory/TagsCategoryMain";
import { objFilter, objToArray } from "../../../commons/utils";
import { tagsCategorySelector } from "../../TagsCategory/selectors";

class PickAntiTags extends Component {
  componentDidMount() {
    const { id, loadAntiTags } = this.props;
    loadAntiTags(id);
  }

  isPickedTreeNode = (parent, child) => {
    const { pickedAntiTags } = this.props;
    return !!((parent && pickedAntiTags[parent]) || pickedAntiTags[child]);
  };

  getSelectedTags() {
    const { pickedAntiTags } = this.props;
    return objToArray(pickedAntiTags);
  }

  handleSelectAntiTag = (parent, children, antiTag) => {
    const { id, createAntiTag, pickedAntiTags, deleteAntiTag } = this.props;
    // если выбираем потомка, а родитель уже выбран => нужно удалить родителя и выбрать потомка
    if (pickedAntiTags[parent]) deleteAntiTag(id, parent);
    // если выбираем родителя, а выбраны потомки у этого родителя => удаляем потомков и выбираем родителя
    if (children)
      Object.values(children).forEach(child => {
        if (pickedAntiTags[child.id]) deleteAntiTag(id, child.id);
      });
    createAntiTag(id, antiTag.id);
  };

  handleUnselectAntiTag = (parent, children, antiTag) => {
    const { id, deleteAntiTag } = this.props;
    deleteAntiTag(id, antiTag.id);
  };

  handleBack = () => {
    const { history } = this.props;
    history.goBack();
  };

  getFilteredTagsCategory() {
    const { categoryId, tagsCategory } = this.props;
    return objFilter(tagsCategory, ({ id }) => id !== categoryId);
  }

  render() {
    return (
      <div>
        <div className="content__head">
          <div className="title__content title-content">
            <button className="title-content__back" onClick={this.handleBack} />
            <h2 className="title-content__name">Добавление антитега</h2>
          </div>
        </div>
        <TagsCategoryMain
          tagsCategory={this.getFilteredTagsCategory()}
          isPickedTreeNode={this.isPickedTreeNode}
          selectedTags={this.getSelectedTags()}
          handleSelect={this.handleSelectAntiTag}
          handleUnselect={this.handleUnselectAntiTag}
          isSelectView
        />
      </div>
    );
  }
}

export default connect(
  state => ({
    pickedAntiTags: antiTagsSelector(state),
    tagsCategory: tagsCategorySelector(state),
    loading: isLoadingAntiTagsSelector(state)
  }),
  { loadAntiTags, createAntiTag, deleteAntiTag }
)(Preloader(PickAntiTags));
