import { call, put, takeLatest } from "redux-saga/effects";
import { errorMassages, FAIL, START, SUCCESS } from "../../commons/constants";
import fetchAPI, { fetchReqAsync, fetchResAsync } from "../../commons/api";
import * as API from "./api";
import {
  CREATE_TAGS_CATEGORY,
  DELETE_TAGS_CATEGORY,
  LOAD_TAGS_CATEGORIES,
  LOAD_TAGS_CATEGORY,
  UPLOAD_TAGS_CATEGORY_FILE,
  uploadTagsCategoryFileFail,
  uploadTagsCategoryFileSuccess
} from "./ducks";
import { normaliseFromLoad, normaliseItem } from "./normalisers";

export function* getTagsCategories(action) {
  try {
    yield put({ type: LOAD_TAGS_CATEGORIES + START });
    const res = yield fetchReqAsync(API.getTagsCategories);
    yield put({
      type: LOAD_TAGS_CATEGORIES + SUCCESS,
      payLoad: { ...normaliseFromLoad(res) }
    });
  } catch (e) {
    yield call(alert, errorMassages.load("category", e));
    yield put({
      type: LOAD_TAGS_CATEGORIES + FAIL,
      payLoad: {
        data: e
      }
    });
  }
}

export function* getTagsCategory({ payLoad, url }) {
  try {
    yield put({ type: LOAD_TAGS_CATEGORY + START });
    const res = normaliseItem(
      url
        ? yield fetchReqAsync(fetchAPI.get, url)
        : yield fetchReqAsync(API.getTagsCategory, payLoad.id)
    );
    yield put({
      type: LOAD_TAGS_CATEGORY + SUCCESS,
      payLoad: { data: { ...res }, id: res.id }
    });
  } catch (e) {
    yield call(alert, errorMassages.load("category", e));
    yield put({
      type: LOAD_TAGS_CATEGORY + FAIL,
      payLoad: e
    });
  }
}

export function* createTagsCategory({ payLoad }) {
  try {
    yield put({ type: CREATE_TAGS_CATEGORY + START });
    const res = yield fetchResAsync(API.postTagsCategory, payLoad.data);
    yield put({
      type: CREATE_TAGS_CATEGORY + SUCCESS
    });
    yield call(getTagsCategory, { url: res.headers.get("Location") });
  } catch (e) {
    yield call(alert, errorMassages.create("category", e));
    yield put({
      type: CREATE_TAGS_CATEGORY + FAIL,
      payLoad: {
        data: e
      }
    });
  }
}

export function* deleteTagsCategory({ payLoad: { id } }) {
  try {
    yield put({ type: DELETE_TAGS_CATEGORY + START });
    yield fetchResAsync(API.deleteTagsCategory, id);
    yield put({
      type: DELETE_TAGS_CATEGORY + SUCCESS,
      payLoad: {
        id
      }
    });
  } catch (error) {
    yield call(alert, errorMassages.load("category", error));
    yield put({
      type: DELETE_TAGS_CATEGORY + FAIL,
      error
    });
  }
}

function* uploadTagsCategoryFile({ payLoad }) {
  try {
    yield fetchResAsync(API.postTagsCategoryFile, payLoad);
    yield put(uploadTagsCategoryFileSuccess());
    yield call(getTagsCategory);
  } catch (e) {
    yield call(alert, errorMassages.load("tag", e));
    yield put(uploadTagsCategoryFileFail(e));
  }
}

function* watchTagsCategory() {
  yield takeLatest(LOAD_TAGS_CATEGORIES, getTagsCategories);
  yield takeLatest(LOAD_TAGS_CATEGORY, getTagsCategory);
  yield takeLatest(UPLOAD_TAGS_CATEGORY_FILE, uploadTagsCategoryFile);
  yield takeLatest(CREATE_TAGS_CATEGORY, createTagsCategory);
  yield takeLatest(DELETE_TAGS_CATEGORY, deleteTagsCategory);
}

export default watchTagsCategory;
