import React, { Component } from "react";
import { connect } from "react-redux";
import Menu from "./Menu";
import { menusSelector, isLoadingMenusSelector } from "../selectors";
import { loadMenus } from "../ducks";
import Preloader from "../../../components/Preloader/Preloader";

const columns = ["Название", "Описание", "Калорийность", "", ""];

class MenuList extends Component {
  componentDidMount() {
    this.props.loadMenus();
  }

  handleEdit = (e, id) => {
    const { handleEdit, menus } = this.props;
    handleEdit({
      ...menus[id]
    });
  };

  getMenuList() {
    const { menus } = this.props;
    return Object.values(menus).map(menu => (
      <Menu key={menu.id} {...menu} handleEdit={this.handleEdit} />
    ));
  }

  render() {
    const { loading } = this.props;
    if (loading) return <Preloader />;
    return (
      <div>
        <div className="table-list">
          <div className="table-list__row table-list__row_menu table-list__row_head">
            {columns.map((column, index) => (
              <div key={index}>{column}</div>
            ))}
          </div>
          {this.getMenuList()}
        </div>
      </div>
    );
  }
}

export default connect(
  state => ({
    menus: menusSelector(state),
    loading: isLoadingMenusSelector(state)
  }),
  { loadMenus }
)(MenuList);
