import React, { Component } from "react";
import { connect } from "react-redux";
import { commentsSelector, isLoadingCommentsSelector } from "./selectors";
import Preloader from "../../components/Preloader/Preloader";
import Comment from "./Comment";
import { loadComments } from "./ducks";

const columns = ["Дата", "Пользователь", "Сообщение", "Статус", "", ""];

class CommentsList extends Component {
  componentDidMount() {
    const { id, loadComments } = this.props;
    loadComments(id);
  }
  render() {
    const { loading, comments, id } = this.props;
    if (loading) return <Preloader />;
    const commentList = Object.values(comments).map(comment => (
      <Comment
        key={comment.id}
        comment={comment}
        materialId={id}
        handleEdit={this.handleEdit}
      />
    ));
    let index = 0;
    return (
      <div>
        <div className="table-list table-list__comment">
          <div className="table-list__row table-list__row_head">
            {columns.map(column => (
              <div key={index++}>{column}</div>
            ))}
          </div>
          {commentList}
        </div>
      </div>
    );
  }
}

export default connect(
  state => ({
    comments: commentsSelector(state),
    loading: isLoadingCommentsSelector(state)
  }),
  { loadComments }
)(CommentsList);
