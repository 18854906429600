import { normaliseToFetch } from "./normalisers";
import { COMMENT_URL, MENUS_URL, SERVER_URL } from "../../commons/constants";
import fecthAPI from "../../commons/api";
import { setQueryFilterString } from "../../commons/utils";

export const getMeals = (menuId, proportionId, dateFrom, dateTo) =>
  fecthAPI.get(
    `${SERVER_URL}/${MENUS_URL}/${menuId}/proportions/${proportionId}/meals${
      dateFrom ? `?dateFrom=${dateFrom}&dateTo=${dateTo}` : ""
    }`
  );

export const getMeal = (menuId, proportionId, id) =>
  fecthAPI.get(
    `${SERVER_URL}/${MENUS_URL}/${menuId}/proportions/${proportionId}/meals/${id}`
  );

export const getMealComments = query =>
  fecthAPI.get(
    `${SERVER_URL}/${COMMENT_URL}/meal${setQueryFilterString(query)}`
  );

export const postMealComment = data =>
  fecthAPI.post(`${SERVER_URL}/${COMMENT_URL}/meal`, data);

export const deleteMealComment = id =>
  fecthAPI.delete(`${SERVER_URL}/${COMMENT_URL}/meal/${id}`);

export const putMeal = (menuId, proportionId, id, data) =>
  fecthAPI.put(
    `${SERVER_URL}/${MENUS_URL}/${menuId}/proportions/${proportionId}/meals/${id}`,
    normaliseToFetch(data)
  );

export const postMeal = (menuId, proportionId, data) =>
  fecthAPI.post(
    `${SERVER_URL}/${MENUS_URL}/${menuId}/proportions/${proportionId}/meals`,
    normaliseToFetch(data)
  );

export const deleteMeal = (menuId, proportionId, id) =>
  fecthAPI.delete(
    `${SERVER_URL}/${MENUS_URL}/${menuId}/proportions/${proportionId}/meals/${id}`
  );
