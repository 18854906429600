import React, { Component } from "react";

class TagsBubbleController extends Component {
  isPickedTreeNode = (parent, child) => {
    const { selectedTags } = this.props;
    console.log(selectedTags);
    console.log(child);
    return (
      selectedTags.find(selectedTag => selectedTag.parent === child) ||
      selectedTags.find(({ id }) => id === child)
    );
  };

  isDisabledTreeNode = (parent, child) => {
    const { disabledTags } = this.props;
    return !!(
      disabledTags &&
      (disabledTags.find(({ parent }) => parent === child) ||
        disabledTags.find(({ id }) => id === child))
    );
  };

  handleSelect = (parent, children, tag) => {
    const { selectedTags, deleteTag, createTag } = this.props;
    // если выбираем потомка, а родитель уже выбран => нужно удалить родителя и выбрать потомка
    if (selectedTags.find(({ id }) => id === parent)) deleteTag(parent);
    // если выбираем родителя, а выбраны потомки у этого родителя => удаляем потомков и выбираем родителя
    if (children)
      Object.values(children).forEach(child => {
        if (selectedTags.find(({ id }) => id === child.id)) deleteTag(child.id);
      });
    createTag(tag);
  };

  handleUnselect = (parent, children, tag) => {
    this.props.deleteTag(tag.id);
  };

  render() {
    const { selectedTags } = this.props;
    return (
      <div>
        {React.cloneElement(this.props.children, {
          handleSelect: this.handleSelect,
          handleUnselect: this.handleUnselect,
          isPickedTreeNode: this.isPickedTreeNode,
          isDisabledTreeNode: this.isDisabledTreeNode,
          selectedTags: selectedTags,
          isSelectView: true
        })}
      </div>
    );
  }
}

export default TagsBubbleController;
