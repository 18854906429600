import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Wizard from "../../../HOCs/Wizard";
import { editAdmin } from "../ducks";
import MainPart from "./MainPart";
import withConfirmModal from "../../../HOCs/withConfirmModal";

class EditMain extends Component {
  constructor(props) {
    super(props);
    const { id, fullName, phone } = props;

    this.state = {
      id: id || "",
      firstName: (fullName && fullName.firstName) || "",
      middleName: (fullName && fullName.middleName) || "",
      lastName: (fullName && fullName.lastName) || "",
      phone: (phone && phone.e164Format) || "",
      errors: {}
    };
  }

  componentWillReceiveProps(nextProps) {
    const { editAdmin, toggleSubmit } = this.props;
    const { id } = this.state;
    if (nextProps.isSubmit === true) {
      const data = this.prepareData();
      id && editAdmin(id, data);
      toggleSubmit();
    }
  }

  prepareData() {
    const { firstName, middleName, lastName, phone } = this.state;
    return {
      fullName: {
        firstName,
        middleName,
        lastName
      },
      phone
    };
  }

  handleChange = e => {
    const { value, name } = e.target;
    this.setState(() => ({ [name]: value }));
  };

  render() {
    const { currentPart } = this.props;
    const partList = [
      <MainPart handleChange={this.handleChange} {...this.state} />
    ];
    return <Fragment>{partList[currentPart - 1]}</Fragment>;
  }
}

export default connect(
  null,
  { editAdmin }
)(withConfirmModal(Wizard(EditMain)));
