import React, { Component } from "react";
import { ReactComponent as MoreVert } from "../widgets/MoreVert.svg";
import "../commons/styles/More.scss";

const Preloader = ChildComponent => {
  return class extends Component {
    state = {
      isOpen: false
    };
    componentDidMount() {
      document.addEventListener("mousedown", this.handleOutsideClick);
    }

    componentWillUnmount() {
      document.removeEventListener("mousedown", this.handleOutsideClick);
    }

    handleToggle = () => {
      this.setState(({ isOpen }) => ({
        isOpen: !isOpen
      }));
    };

    // при наличии в childComponent модалки, теперь при клике на модалку не закрывается childComponent
    // была так, что открывалась модалка, вызванная из childComponent и срабатывал на ней handleOutsideClick
    //  и в итоге закрывался childComponent
    handleOutsideClick = ({ target }) => {
      const initTarget = target;
      while (target.className !== "modal") {
        if (target.className === "modal__main") {
          return;
        }
        target = target.parentNode;
        if (!target) break;
      }
      if (target && target.className === "modal") {
        return;
      }
      if (!this.node.contains(initTarget))
        this.setState(() => ({
          isOpen: false
        }));
    };

    render() {
      const { isOpen } = this.state;
      return (
        <div className="more__wrapper" ref={node => (this.node = node)}>
          <MoreVert onClick={this.handleToggle} className="more" />
          {isOpen && (
            <ChildComponent {...this.props} handleToggle={this.handleToggle} />
          )}
        </div>
      );
    }
  };
};

export default Preloader;
