import { normaliseToPost, normaliseToPut } from "./normalisers";
import { RECIPE_URL, SERVER_URL } from "../../commons/constants";
import fecthAPI from "../../commons/api";

export const getRecipeProportions = id =>
  fecthAPI.get(`${SERVER_URL}/${RECIPE_URL}/${id}/proportions`);

export const getRecipeProportion = (recipeId, id) =>
  fecthAPI.get(`${SERVER_URL}/${RECIPE_URL}/${recipeId}/proportions/${id}`);

export const postRecipeProportion = (id, data) =>
  fecthAPI.post(
    `${SERVER_URL}/${RECIPE_URL}/${id}/proportions`,
    normaliseToPost(data)
  );

export const putRecipeProportion = (recipeID, id, data) =>
  fecthAPI.put(
    `${SERVER_URL}/${RECIPE_URL}/${recipeID}/proportions/${id}`,
    normaliseToPut(data)
  );

export const deleteRecipeProportion = (recipeId, id) =>
  fecthAPI.delete(`${SERVER_URL}/${RECIPE_URL}/${recipeId}/proportions/${id}`);
