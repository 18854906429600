import React, { Component } from "react";
import Confirm from "./Confirm";
import Button from "./Button";

class ButtonConfirm extends Component {
  state = {
    isOpenConfirm: false
  };
  closeModal = () => {
    this.setState({
      isOpenConfirm: false
    });
  };
  openModal = () => {
    this.setState({
      isOpenConfirm: true
    });
  };
  handleConfirm = isConfirm => {
    const { handleConfirm } = this.props;
    handleConfirm(isConfirm);
    this.closeModal();
  };
  render() {
    const { isOpenConfirm } = this.state;
    const { className, children, confirmTitle, ...attrs } = this.props;
    return (
      <div>
        <Button onClick={this.openModal} className={className} {...attrs}>
          {children}
        </Button>
        {isOpenConfirm ? (
          <Confirm
            closeModal={this.closeModal}
            title={confirmTitle}
            handleConfirm={this.handleConfirm}
          />
        ) : null}
      </div>
    );
  }
}

export default ButtonConfirm;
