import React, { Component, Fragment } from "react";
import { rounding } from "../../../commons/utils";
import InputNumber from "../../../components/Input/InputNumber";

class PortionsData extends Component {
  state = {
    numbersOfServings: 0
  };
  static getDerivedStateFromProps(nextProps, prevState) {
    const { recipe } = nextProps;
    return {
      numbersOfServings: recipe.numbersOfServings || 0
    };
  }
  getInputFields() {
    const { disabled, ingredients, handleChange, recipe } = this.props;
    const { numbersOfServings } = this.state;
    return (
      <Fragment>
        <InputNumber
          name="numbersOfServings"
          value={numbersOfServings}
          placeholder="Количесвто порций"
          className="portions-dish__input"
          handleChange={handleChange}
          disabled={disabled}
        />
        <InputNumber
          name="amount"
          value={rounding(recipe.totalWeight / numbersOfServings, 10)}
          placeholder="Вес 1 порции"
          className="portions-dish__input"
          disabled
        />
      </Fragment>
    );
  }
  render() {
    const fields = this.getInputFields();
    return (
      <div className="tail-dish__property property-dish__portions">
        {fields}
      </div>
    );
  }
}

export default PortionsData;
