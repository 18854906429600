import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import ShowForm from "../../../components/Form/ShowForm";
import { getWeeksInMonth } from "../../../commons/utils";
import MealsWeek from "../../Meals/MealsMonth/MealsWeek";
import { menuProportionsSelector } from "../selectors";
import DateSlider from "../DateSlider";

class MenuProportionMonth extends Component {
  render() {
    const {
      proportions,
      menu,
      currentWeek,
      currentCalorie,
      handleDecrMonth,
      handleSelectProportion,
      handleIncrMonth,
      history,
      location
    } = this.props;

    const formCollection = [
      {
        type: "select",
        name: "calories",
        value: currentCalorie,
        placeholder: "Каллории",
        disabled: false,
        values: [
          ...Object.values(proportions).map(proportion => proportion.id)
        ],
        options: [
          ...Object.values(proportions).map(
            proportion => proportion.kilocalories
          )
        ],
        className: "header-edit__calories"
      }
    ];
    const weekList = getWeeksInMonth(currentWeek.month, currentWeek.year).map(
      (weekItem, index) => (
        <li
          key={index}
          className={
            weekItem.start === currentWeek.start &&
            weekItem.end === currentWeek.end
              ? "week-edit__item week-edit__item_active"
              : "week-edit__item"
          }
        >{`${weekItem.start}-${weekItem.end}`}</li>
      )
    );
    return (
      <Fragment>
        <div className="header-edit__week">
          <ShowForm
            formCollection={formCollection}
            handleChange={handleSelectProportion}
          />
          {new Date(currentWeek.year, currentWeek.month, 1).toLocaleString(
            "ru",
            {
              month: "long",
              year: "numeric"
            }
          )}
        </div>
        <DateSlider
          handleDecr={handleDecrMonth}
          handleIncr={handleIncrMonth}
          list={weekList}
        />
        <MealsWeek
          menu={menu}
          currentWeek={currentWeek}
          currentCalorie={currentCalorie}
          history={history}
          location={location}
          proportion={Object.values(proportions).find(
            proportion => proportion.kilocalories === currentCalorie
          )}
        />
      </Fragment>
    );
  }
}

export default connect(state => ({
  proportions: menuProportionsSelector(state)
}))(MenuProportionMonth);
