import { connect } from "react-redux";
import MenuProportionsMonthModel from "./MenuProportionsMonthModel";
import {
  currentMenuProportionKilocalories,
  menuProportionsSelector
} from "../selectors";
import {
  getCurrentDateSelector,
  isLoadingMealsSelector,
  mealsSelector
} from "../../Meals/selectors";
import { loadMeals, setCurrentDate } from "../../Meals/ducks";

const mapStateToProps = state => ({
  meals: mealsSelector(state),
  currentCalorie: currentMenuProportionKilocalories(state),
  proportions: menuProportionsSelector(state),
  loading: isLoadingMealsSelector(state),
  date: getCurrentDateSelector(state)
});

const mapDispatchToProps = {
  loadMeals,
  setCurrentDate
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MenuProportionsMonthModel);
