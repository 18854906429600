import React, { Component } from "react";
import { connect } from "react-redux";
import IngredientCalc from "../Ingredients/IngredientCalc";
import { recipeProportionByIdSelector } from "../RecipeProportions/selectors";
import { ingredientByIDSelector } from "../Ingredients/selectors";
import {
  calcAmountOfDose,
  withCalcDoseAmount
} from "../RecipeProportions/utils";
import { recipeByIdSelector } from "../Recipes/selectors";
import { allIngredientsCalc } from "../Ingredients/utils";
import { objectMap } from "../../commons/utils";

class Dashboard extends Component {
  render() {
    const { data, isShort } = this.props;
    return <IngredientCalc data={data || {}} isShort={isShort} />;
  }
}

function mapStateToProps(state, ownProps) {
  const recipeProportions = [];
  let recipeProportionsWeight = 0;
  let data = {
    amount: 0,
    amountChanged: 0,
    calories: 0,
    proteins: 0,
    fats: 0,
    carbohydrates: 0,
    cellulose: 0
  };
  ownProps.recipeProportions.forEach(({ recipeProportion, weight }) => {
    recipeProportions.push(
      recipeProportionByIdSelector(state, { id: recipeProportion })
    );
    recipeProportionsWeight += weight;
  });
  recipeProportions.forEach(({ doses, recipe, id }) => {
    const recipeData = recipeByIdSelector(state, { id: recipe });
    const fixWeight = ownProps.recipeProportions.find(
      ({ recipeProportion }) => recipeProportion === id
    ).weight;
    if (doses) {
      const newDoses = withCalcDoseAmount(
        doses.map(dose => ({
          ...dose,
          ...ingredientByIDSelector(state, {
            id: dose.ingredient
          })
        })),
        recipeData
      );
      let calc = allIngredientsCalc(newDoses);
      let cof = fixWeight / (calc.amountChanged || 1);
      calc = {
        ...calc,
        calories: calc.calories * cof,
        proteins: calc.proteins * cof,
        fats: calc.fats * cof,
        carbohydrates: calc.carbohydrates * cof,
        cellulose: calc.cellulose * cof,
        amountChanged:
          calc.amountChanged *
          (recipeData.massChange ? recipeData.massChange / 100 : 1)
      };
      data = Object.keys(data).reduce(
        (result, key) => ({
          ...result,
          [key]: result[key] + calc[key]
        }),
        data
      );
    }
  });
  return {
    data: { ...data, weight: recipeProportionsWeight }
  };
}

export default connect(mapStateToProps)(Dashboard);
