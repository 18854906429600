const initialState = {
  isAuthorised: !!localStorage.getItem("ACCESS_TOKEN")
};

export const SECURITY_AUTHORISE = "SECURITY_AUTHORISE";
export const SECURITY_CONFIRM_PHONE = "SECURITY_CONFIRM_PHONE";
export const SECURITY_CONFIRM_CODE = "SECURITY_CONFIRM_CODE";

export function securityConfirmPhone(data) {
  return {
    type: SECURITY_CONFIRM_PHONE,
    payLoad: {
      data
    }
  };
}

export function securityConfirmCode(phone, code) {
  return {
    type: SECURITY_CONFIRM_CODE,
    payLoad: {
      phone,
      code
    }
  };
}

export function securityAuthorise() {
  return {
    type: SECURITY_AUTHORISE
  };
}

export default (security = initialState, action) => {
  const { type } = action;
  switch (type) {
    case SECURITY_AUTHORISE:
      return {
        ...security,
        isAuthorised: true
      };
    default:
      return security;
  }
};
