import { createSelector } from "reselect";

const menuProportionsGetter = state => state.menuProportions;
const currentMenuProportionGetter = state =>
  state.menuProportions.currentProportion;
const menuProportionGetter = (state, ownProps) =>
  state.menuProportions.data[ownProps.id];

export const menuProportionsSelector = createSelector(
  menuProportionsGetter,
  ({ data }) => data || {}
);

export const menuProportionByIdSelector = createSelector(
  menuProportionGetter,
  menuProportion => menuProportion || {}
);

export const isLoadingMenuProportionsSelector = createSelector(
  menuProportionsGetter,
  ({ loading }) => loading
);

export const isLoadedMenuProportionsSelector = createSelector(
  menuProportionsGetter,
  ({ loaded }) => loaded
);

export const currentMenuProportionKilocalories = createSelector(
  menuProportionsGetter,
  currentMenuProportionGetter,
  ({ data }, id) => {
    return data[id] ? data[id].kilocalories : 0;
  }
);
