import React, { Component } from "react";
import Search from "./Search";
import debounce from "lodash/debounce";

class SearchWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: props.value || "",
      minWordLength: props.minWordLength || 0
    };
    this.debounceAfterSearch = debounce(
      value => props.afterSearch(value),
      props.time || 0,
      {
        leading: false,
        trailing: true
      }
    );
  }
  handleChange = e => {
    const { value } = e.target;
    const { search, minWordLength } = this.state;
    let curValue = value;
    let isChangeSearch = false;
    if (value.length >= minWordLength) isChangeSearch = true;
    if (search.length >= minWordLength && value.length < minWordLength) {
      isChangeSearch = true;
      curValue = "";
    }
    this.setState(
      () => ({ search: value }),
      () => {
        if (isChangeSearch) this.debounceAfterSearch(curValue);
      }
    );
  };

  render() {
    const { search } = this.state;
    const { placeholder, className } = this.props;
    return (
      <Search
        name="search"
        value={search}
        placeholder={placeholder}
        handleChange={this.handleChange}
        className={className}
      />
    );
  }
}

export default SearchWrapper;
