import React from "react";

function DateSlider({ handleDecr, handleIncr, list }) {
  return (
    <div className="menu-month__picker">
      <button
        className="week-edit__control week-edit__control_prev"
        onClick={handleDecr}
      />
      <ul className="menu-month__list">{list}</ul>
      <button
        className="week-edit__control week-edit__control_next"
        onClick={handleIncr}
      />
    </div>
  );
}

export default DateSlider;
