import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Wizard from "../../../HOCs/Wizard";
import FirstPart from "./FirstPart";
import SecondPart from "./SecondPart";
import ThirdPart from "./ThirdPart";
import { ingredientRequiredShema } from "../shema";
import { editIngredient, createIngredient } from "../ducks";
import TagsPart from "./TagsPart";
import { weightTypes } from "../utils";
import { getCoverSelector, getIconSelector } from "../../Cover/selectors";
import { clearCoverLast } from "../../Cover/ducks";
import { isEmpty, objectMap } from "../../../commons/utils";
import withConfirm from "../../../HOCs/withConfirmModal";

class AddIngredient extends Component {
  constructor(props) {
    super(props);
    const {
      id,
      title,
      alternativeTitles,
      kilocaloriesPerHundredGrams,
      cellulosePerHundredGrams,
      description,
      internalDescription,
      gramsPerVolumeUnit,
      nutrients,
      cookingMethods,
      ingredientGroup,
      tags,
      cover,
      icon,
      manufacturer,
      barcode
    } = props;

    this.state = {
      id: id || "",
      title: title || "",
      alternativeTitles: alternativeTitles ? alternativeTitles.join(", ") : "",
      kilocaloriesPerHundredGrams: isEmpty(kilocaloriesPerHundredGrams)
        ? ""
        : kilocaloriesPerHundredGrams,
      cellulosePerHundredGrams: isEmpty(cellulosePerHundredGrams)
        ? ""
        : cellulosePerHundredGrams,
      description: description || "",
      internalDescription: internalDescription || "",
      gramsPerVolumeUnit:
        (gramsPerVolumeUnit && gramsPerVolumeUnit.type) || weightTypes[0].name,
      gramsPerVolumeUnitAmount:
        (gramsPerVolumeUnit && gramsPerVolumeUnit.amount) || "",
      nutrients: nutrients || {},
      cookingMethods: cookingMethods || {},
      barcode: barcode || "",
      manufacturer: manufacturer || "",
      ingredientGroup: ingredientGroup || "",
      tags: tags || [],
      cover: (cover && cover.path) || "",
      icon: (icon && icon.path) || "",
      errors: {}
    };
  }

  componentWillUnmount() {
    this.props.clearCoverLast();
  }

  componentWillReceiveProps(nextProps) {
    const { editIngredient, createIngredient, toggleSubmit } = this.props;
    const { id } = this.state;
    if (nextProps.isSubmit === true) {
      const data = this.prepareData();
      id ? editIngredient(id, data) : createIngredient(data);
      toggleSubmit();
    }
  }

  prepareData() {
    const {
      title,
      gramsPerVolumeUnit,
      gramsPerVolumeUnitAmount,
      alternativeTitles,
      kilocaloriesPerHundredGrams,
      cellulosePerHundredGrams,
      description,
      internalDescription,
      nutrients,
      cookingMethods,
      manufacturer,
      ingredientGroup,
      tags,
      barcode
    } = this.state;
    const { lastChangedCover, lastChangedIcon } = this.props;
    return {
      title,
      alternativeTitles: alternativeTitles.length
        ? alternativeTitles.split(", ")
        : null,
      cellulosePerHundredGrams: isEmpty(cellulosePerHundredGrams)
        ? null
        : +cellulosePerHundredGrams,
      kilocaloriesPerHundredGrams: isEmpty(kilocaloriesPerHundredGrams)
        ? null
        : +kilocaloriesPerHundredGrams,
      description,
      internalDescription,
      nutrients: objectMap(nutrients, nutrient => ({
        ...nutrient,
        amountPerHundredGrams: isNaN(+nutrient.amountPerHundredGrams)
          ? 0
          : +nutrient.amountPerHundredGrams
      })),
      cookingMethods: objectMap(cookingMethods, method => ({
        ...objectMap(method, value => (isNaN(+value) ? 0 : +value))
      })),
      ingredientGroup,
      barcode,
      manufacturer,
      tags,
      cover: lastChangedCover,
      icon: lastChangedIcon,
      gramsPerVolumeUnit: isEmpty(gramsPerVolumeUnitAmount)
        ? null
        : {
            type: gramsPerVolumeUnit,
            amount: +gramsPerVolumeUnitAmount
          }
    };
  }

  handleChange = e => {
    const { value, name } = e.target;
    this.setState(() => ({ [name]: value }));
  };

  handleChangeNutrients = e => {
    const { value, name } = e.target;
    this.setState(({ nutrients }) => ({
      nutrients: {
        ...nutrients,
        [name]: {
          ...nutrients[name],
          amountPerHundredGrams: value,
          type: name
        }
      }
    }));
  };

  handleChangeCooks = e => {
    const { value, name, dataset } = e.target;
    this.setState(({ cookingMethods }) => ({
      cookingMethods: {
        ...cookingMethods,
        [dataset.id]: {
          ...cookingMethods[dataset.id],
          [name]: value
        }
      }
    }));
  };

  deleteTag = delId => {
    this.setState(({ tags }) => ({
      tags: tags.filter(({ id }) => id !== delId)
    }));
  };

  createTag = tag => {
    this.setState(({ tags }) => ({
      tags: [...tags, tag]
    }));
  };

  isValid = currentPart => {
    try {
      if (currentPart === 1)
        ingredientRequiredShema.validateSync(this.getValidationObject(), {
          abortEarly: false
        });
    } catch (err) {
      this.setState(() => {
        const errors = {};
        err.inner.forEach(e => {
          Object.assign(errors, {
            [e.path]: e
          });
        });
        return {
          errors
        };
      });
      return false;
    }
    return true;
  };

  getValidationObject = () => {
    const {
      title,
      alternativeTitles,
      kilocaloriesPerHundredGrams,
      nutrients,
      description,
      internalDescription,
      gramsPerVolumeUnit,
      cellulosePerHundredGrams,
      gramsPerVolumeUnitAmount
    } = this.state;
    const { currentPart } = this.props;
    if (currentPart === 1)
      return {
        title,
        alternativeTitles,
        kilocaloriesPerHundredGrams,
        cellulosePerHundredGrams,
        gramsPerVolumeUnitAmount,
        fats: nutrients["fats"]
          ? nutrients["fats"].amountPerHundredGrams
          : null,
        proteins: nutrients["proteins"]
          ? nutrients["proteins"].amountPerHundredGrams
          : null,
        carbohydrates: nutrients["carbohydrates"]
          ? nutrients["carbohydrates"].amountPerHundredGrams
          : null,
        description,
        internalDescription,
        gramsPerVolumeUnit
      };
  };

  render() {
    const { currentPart } = this.props;
    const { cookingMethods, tags } = this.state;
    const partList = [
      <FirstPart
        handleChange={this.handleChange}
        handleChangeNutrients={this.handleChangeNutrients}
        {...this.state}
      />,
      <SecondPart
        handleChangeNutrients={this.handleChangeNutrients}
        nutrients={this.state.nutrients}
      />,
      <ThirdPart
        handleChangeCooks={this.handleChangeCooks}
        cookingMethods={cookingMethods}
      />,
      <TagsPart
        handleCreateTag={this.createTag}
        handleDeleteTag={this.deleteTag}
        selectedTags={tags}
      />
    ];
    return <Fragment>{partList[currentPart - 1]}</Fragment>;
  }
}

export default connect(
  state => ({
    lastChangedCover: getCoverSelector(state),
    lastChangedIcon: getIconSelector(state)
  }),
  { editIngredient, createIngredient, clearCoverLast }
)(withConfirm(Wizard(AddIngredient)));
