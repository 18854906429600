import React, { Component } from "react";
import { connect } from "react-redux";
import {
  createFriendlyTag,
  deleteFriendlyTag,
  loadFriendlyTags
} from "../ducks";
import { friendlyTagsSelector, isLoadingAntiTagsSelector } from "../selectors";
import Preloader from "../../../HOCs/Preloader";
import TagsCategoryMain from "../../TagsCategory/TagsCategoryMain";
import { objFilter, objToArray } from "../../../commons/utils";
import { tagsCategorySelector } from "../../TagsCategory/selectors";

class PickAntiTags extends Component {
  componentDidMount() {
    const { id, loadFriendlyTags } = this.props;
    loadFriendlyTags(id);
  }

  isPickedTreeNode = (parent, child) => {
    const { pickedFriendlyTags } = this.props;
    return !!(
      (parent && pickedFriendlyTags[parent]) ||
      pickedFriendlyTags[child]
    );
  };

  getSelectedTags() {
    const { pickedFriendlyTags } = this.props;
    return objToArray(pickedFriendlyTags);
  }

  handleSelectFriendlyTag = (parent, children, friendlyTag) => {
    const {
      id,
      createFriendlyTag,
      pickedFriendlyTags,
      deleteFriendlyTag
    } = this.props;
    // если выбираем потомка, а родитель уже выбран => нужно удалить родителя и выбрать потомка
    if (pickedFriendlyTags[parent]) deleteFriendlyTag(id, parent);
    // если выбираем родителя, а выбраны потомки у этого родителя => удаляем потомков и выбираем родителя
    if (children)
      Object.values(children).forEach(child => {
        if (pickedFriendlyTags[child.id]) deleteFriendlyTag(id, child.id);
      });
    createFriendlyTag(id, friendlyTag.id);
  };

  handleUnselectFriendlyTag = (parent, children, friendlyTag) => {
    const { id, deleteFriendlyTag } = this.props;
    deleteFriendlyTag(id, friendlyTag.id);
  };

  handleBack = () => {
    const { history } = this.props;
    history.goBack();
  };

  getFilteredTagsCategory() {
    const { categoryId, tagsCategory } = this.props;
    return objFilter(tagsCategory, ({ id }) => id !== categoryId);
  }

  render() {
    return (
      <div>
        <div className="content__head">
          <div className="title__content title-content">
            <button className="title-content__back" onClick={this.handleBack} />
            <h2 className="title-content__name">
              Добавление дружественного тега
            </h2>
          </div>
        </div>
        <TagsCategoryMain
          tagsCategory={this.getFilteredTagsCategory()}
          isPickedTreeNode={this.isPickedTreeNode}
          selectedTags={this.getSelectedTags()}
          handleSelect={this.handleSelectFriendlyTag}
          handleUnselect={this.handleUnselectFriendlyTag}
          isSelectView
        />
      </div>
    );
  }
}

export default connect(
  state => ({
    pickedFriendlyTags: friendlyTagsSelector(state),
    tagsCategory: tagsCategorySelector(state),
    loading: isLoadingAntiTagsSelector(state)
  }),
  { loadFriendlyTags, createFriendlyTag, deleteFriendlyTag }
)(Preloader(PickAntiTags));
