import React, { Component } from "react";
import { connect } from "react-redux";
import Header from "./layouts/Header";
import Body from "./layouts/Body";
import Authorisation from "./features/Authorisation/Authorisation";

class Entry extends Component {
  render() {
    const { isAuthorised } = this.props;
    return (
      <div>
        {isAuthorised ? (
          <div className="wrapper">
            <Header />
            <Body />
          </div>
        ) : (
          <Authorisation />
        )}
      </div>
    );
  }
}

export default connect(state => ({
  isAuthorised: state.security.isAuthorised
}))(Entry);
