import { call, put, takeLatest } from "redux-saga/effects";
import { errorMassages, FAIL, START, SUCCESS } from "../../commons/constants";
import * as API from "./api";
import { LOAD_PROFILES } from "./ducks";
import { normaliseFromLoad } from "./normalisers";
import { fetchReqAsync } from "../../commons/api";

export function* getProfiles(action) {
  const { id } = action;
  try {
    yield put({ type: LOAD_PROFILES + START });
    const res = normaliseFromLoad(yield fetchReqAsync(API.getProfiles, id));
    console.log(res);
    yield put({
      type: LOAD_PROFILES + SUCCESS,
      payLoad: res
    });
  } catch (e) {
    console.log(e);
    yield call(alert, errorMassages.load("profiles"));
    yield put({
      type: LOAD_PROFILES + FAIL,
      payLoad: {
        data: e
      }
    });
  }
}

function* watchProfiles() {
  yield takeLatest(LOAD_PROFILES, getProfiles);
}

export default watchProfiles;
