import React, { Component } from "react";
import { connect } from "react-redux";
import { createCompoundIngredient, deleteRecipe } from "../ducks";
import ListControls from "../../../components/ListControls/ListControls";
import Button from "../../../components/Button/Button";
import { Link } from "react-router-dom";
import { PATH_RECIPES_ALL } from "../../../commons/constants";

class Recipe extends Component {
  getControlsButtons = () => {
    const {
      deleteRecipe,
      createCompoundIngredient,
      id,
      isIngredient
    } = this.props;
    return [
      {
        title: "Создать ингридиент",
        handleClick: () => createCompoundIngredient(id),
        options: {
          disabled: isIngredient
        }
      },
      {
        title: "Удалить",
        handleClick: deleteRecipe,
        isConfirm: true,
        confirmTitle: "Подтвердить удаление"
      }
    ];
  };

  render() {
    const { title, id, proportions } = this.props;
    return (
      <div className="table-list__row table-list__row_recipe">
        <div className="table-list__name">{title}</div>
        <ListControls id={id} buttons={this.getControlsButtons()}>
          <Link
            to={`${PATH_RECIPES_ALL}/${id}/proportions/${proportions[0] &&
              proportions[0].id}`}
            className="list-controls__link"
          >
            <Button className="list-controls__btn">Редактировать</Button>
          </Link>
        </ListControls>
      </div>
    );
  }
}

export default connect(
  null,
  { deleteRecipe, createCompoundIngredient }
)(Recipe);
