import React, { Component } from "react";
import Comment from "./Comment";
import Input from "@material-ui/core/Input";
import IconButton from "@material-ui/core/IconButton";
import { Send } from "@material-ui/icons";
import "./style.scss";
import Loader from "../../components/Preloader/Preloader";
import { CircularProgress } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

class Comments extends Component {
  state = {
    text: ""
  };

  commentsEndRef = React.createRef();

  componentDidUpdate(prevProps) {
    const {
      isRequesting,
      isRequestingCreateComment,
      isRequestingDeleteComment
    } = this.props;
    if (
      prevProps.isRequesting === isRequesting &&
      prevProps.isRequestingCreateComment !== isRequestingCreateComment
    )
      this.scrollToBottom("smooth");
    if (
      prevProps.isRequesting === isRequesting &&
      prevProps.isRequestingDeleteComment !== isRequestingDeleteComment
    )
      this.scrollToBottom("auto");
  }

  scrollToBottom = behavior => {
    if (this.commentsEndRef.current)
      this.commentsEndRef.current.scrollIntoView({ behavior });
  };

  handleChange = ({ target: { name, value } }) => {
    this.setState({
      [name]: value
    });
  };

  handleCreateComment = () => {
    const { text } = this.state;
    const { createComment } = this.props;
    if (text.trim()) {
      this.setState(
        {
          text: ""
        },
        () => createComment(text.trim())
      );
    }
  };

  render() {
    const {
      authorId,
      isRequesting,
      isRequestingCreateComment,
      isRequestingDeleteComment,
      data,
      title,
      deleteComment,
      isDeleteComment
    } = this.props;
    const { text } = this.state;
    return (
      <div className="comments__wrapper">
        <Typography variant="h6">{title}</Typography>
        {isRequesting || isRequestingDeleteComment ? (
          <Loader />
        ) : (
          <div className="comments__content">
            {data.map(comment => (
              <Comment
                {...comment}
                key={comment.id}
                isDeleteComment={isDeleteComment}
                deleteComment={() => deleteComment(comment.id)}
                align={authorId === comment.id ? "right" : "left"}
              />
            ))}
            <div ref={this.commentsEndRef} />
          </div>
        )}

        <div className="comments__message">
          <Input
            name="text"
            onChange={this.handleChange}
            value={text}
            placeholder="Написать сообщение..."
            autoComplete="off"
            fullWidth
          />
          <IconButton>
            {isRequestingCreateComment ? (
              <CircularProgress size={25} />
            ) : (
              <Send onClick={this.handleCreateComment} />
            )}
          </IconButton>
        </div>
      </div>
    );
  }
}

export default Comments;
