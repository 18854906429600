import React, { Component } from "react";
import Preloader from "../../components/Preloader/Preloader";
import { connect } from "react-redux";
import {
  filteredRecipesSelector,
  isFilteredLoadingRecipesSelector
} from "./selectors";

const columns = ["Название", "Описание"];

class SelectRecipeList extends Component {
  render() {
    const {
      recipes,
      loading,
      selectRecipe,
      search,
      minWordLength
    } = this.props;
    if (loading) return <Preloader />;
    const recipesList = Object.values(recipes).map((recipe, index) => (
      <li
        className="table-list__row table-list__row_recipe_select"
        onClick={() => selectRecipe(recipe)}
      >
        <div className="table-list__name">{recipe.title}</div>
        <div className="table-list__description">
          {recipe.proportions[0].description}
        </div>
      </li>
    ));
    return (
      <div>
        <ul className="table-list select-recipe__list">
          <div className="table-list__row table-list__row_recipe_select table-list__row_head">
            {columns.map((column, index) => (
              <div key={index + 1}>{column}</div>
            ))}
          </div>
          {search.length < minWordLength && !recipesList.length
            ? "Поиск начнется после введение как минимум 3-х символов"
            : !recipesList.length
            ? "Не найдено ни одного рецепта"
            : recipesList}
        </ul>
      </div>
    );
  }
}

export default connect(state => ({
  recipes: filteredRecipesSelector(state),
  loading: isFilteredLoadingRecipesSelector(state)
}))(SelectRecipeList);
