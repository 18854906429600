import React, { Component } from "react";
import RecipeProportionDay from "../../RecipeProportions/RecipeProportionDay";
import NewRecipeProportionControls from "../../RecipeProportions/NewRecipeProportionControls";
import TableCell from "../../../components/Table/TableCell";
import { connect } from "react-redux";
import { deleteRecipeFromMeal } from "../ducks";

class MealDay extends Component {
  handleDeleteRecipe = id => {
    const { meal, menuId, proportionId, deleteRecipeFromMeal } = this.props;
    deleteRecipeFromMeal(menuId, proportionId, meal.id, id);
  };

  render() {
    const {
      meal,
      currentCalorie,
      menuId,
      proportionId,
      type,
      currentDate
    } = this.props;
    const recipeProportions = meal
      ? meal.mealRecipes.map(({ recipeProportion }) => (
          <RecipeProportionDay
            key={recipeProportion}
            id={recipeProportion}
            handleDeleteRecipe={this.handleDeleteRecipe}
            meal={meal}
            currentCalorie={currentCalorie}
          />
        ))
      : [];
    return (
      <TableCell>
        <div className="table-day__list">
          {recipeProportions}
          {recipeProportions.length < 3 ? (
            <NewRecipeProportionControls
              className="table-day__controls controls-day"
              meal={meal}
              type={type}
              menuId={menuId}
              currentDate={currentDate}
              proportionId={proportionId}
            />
          ) : null}
        </div>
      </TableCell>
    );
  }
}

export default connect(
  null,
  { deleteRecipeFromMeal }
)(MealDay);
