import React, { Component } from "react";
import { connect } from "react-redux";
import PreloaderHOC from "../../../HOCs/Preloader";
import TagsCategoryMain from "../../TagsCategory/TagsCategoryMain";
import TagsBubbleController from "../../Tags/TagsBubbleController";
import { objFilter } from "../../../commons/utils";
import { tagsCategorySelector } from "../../TagsCategory/selectors";

class Tags extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTags: props.tags
    };
  }

  getFilteredTagsCategory() {
    const { tagsCategory } = this.props;
    return objFilter(tagsCategory, ({ title }) => title !== "Инвентарь");
  }

  handleChange = value => {
    const { handleChange } = this.props;
    handleChange({ target: { name: "tags", value } });
  };

  handleDeleteTag = delId => {
    this.setState(
      ({ selectedTags }) => ({
        selectedTags: selectedTags.filter(({ id }) => id !== delId)
      }),
      () => this.handleChange(this.state.selectedTags)
    );
  };

  handleCreateTag = tag => {
    this.setState(
      ({ selectedTags }) => ({
        selectedTags: [...selectedTags, { ...tag }]
      }),
      () => this.handleChange(this.state.selectedTags)
    );
  };

  render() {
    const { ingredientTags } = this.props;
    const { selectedTags } = this.state;
    return (
      <div className="tail-dish__property property-dish__tags">
        <div className="tags-dish">Теги:</div>
        {/*<ul>*/}
        {/*  {ingredientTags.map((picked, index) => (*/}
        {/*    <li key={picked.id}>*/}
        {/*      <div>*/}
        {/*        {index + 1}. {picked.title}*/}
        {/*      </div>*/}
        {/*    </li>*/}
        {/*  ))}*/}
        {/*</ul>*/}
        <TagsBubbleController
          selectedTags={selectedTags}
          disabledTags={ingredientTags}
          deleteTag={this.handleDeleteTag}
          createTag={this.handleCreateTag}
        >
          <TagsCategoryMain tagsCategory={this.getFilteredTagsCategory()} />
        </TagsBubbleController>
      </div>
    );
  }
}

export default connect((state, ownProps) => ({
  tagsCategory: tagsCategorySelector(state)
}))(PreloaderHOC(Tags));
