import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { DateTime } from "luxon";
import { compareDates } from "../../../commons/utils";
import { menuProportionsSelector } from "../selectors";
import Select from "../../../components/Select/Select";
import DateSlider from "../DateSlider";

class MenuProportionsMonthView extends Component {
  render() {
    const {
      proportions,
      date,
      currentCalorie,
      handleDecrMonth,
      handleSelectProportion,
      handleIncrMonth
    } = this.props;

    const monthList = [
      DateTime.fromJSDate(date).minus({ months: 1 }),
      DateTime.fromJSDate(date),
      DateTime.fromJSDate(date).plus({ months: 1 })
    ].map((monthDate, index) => (
      <li
        key={index}
        className={
          compareDates(monthDate.toJSDate(), date)
            ? "menu-month__item menu-month__item_active"
            : "menu-month__item"
        }
      >
        {monthDate.setLocale("ru").toFormat("LLLL")}
      </li>
    ));

    return (
      <Fragment>
        <div className="header-edit__week">
          <Select
            name="calories"
            value={currentCalorie}
            placeholder="Каллории"
            values={[
              ...Object.values(proportions).map(proportion => proportion.id)
            ]}
            options={[
              ...Object.values(proportions).map(
                proportion => proportion.kilocalories
              )
            ]}
            className="header-edit__calories"
            handleChange={handleSelectProportion}
          />

          {DateTime.fromJSDate(date)
            .setLocale("ru")
            .toFormat("LLLL yyyy")}
        </div>
        <DateSlider
          handleDecr={handleDecrMonth}
          handleIncr={handleIncrMonth}
          list={monthList}
        />
      </Fragment>
    );
  }
}

export default connect(state => ({
  proportions: menuProportionsSelector(state)
}))(MenuProportionsMonthView);
