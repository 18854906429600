import React, { Component, Fragment } from "react";
import Select from "../../../components/Select/Select";
import { listOfDifficulty } from "../utils";

class Difficulty extends Component {
  state = {
    difficulty: listOfDifficulty.easy
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { recipe } = nextProps;
    return {
      difficulty: listOfDifficulty[recipe.difficulty] || listOfDifficulty.easy
    };
  }
  getInputFields() {
    const { disabled, handleChange } = this.props;
    const { difficulty } = this.state;
    return (
      <Fragment>
        <Select
          name="difficulty"
          value={difficulty}
          placeholder="Сложность"
          handleChange={handleChange}
          disabled={disabled}
          options={[...Object.values(listOfDifficulty)]}
          values={[...Object.keys(listOfDifficulty)]}
        />
      </Fragment>
    );
  }
  render() {
    const fields = this.getInputFields();
    return (
      <div className="tail-dish__property property-dish__difficulty">
        {fields}
      </div>
    );
  }
}

export default Difficulty;
