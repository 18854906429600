import React, { Component } from "react";
import { connect } from "react-redux";
import {
  filteredIngredientsSelector,
  isLoadingFilteredIngredientsSelector
} from "./selectors";
import {
  changeFilterIngredient,
  clearSearchIngredient
} from "../Filters/ducks";
import { clearFilteredIngredients } from "./ducks";
import DropDownSearch from "../../components/Search/DropDownSearch";
import { objectMap } from "../../commons/utils";

const SearchIngredientsContainer = WrappedComponent => {
  return class extends Component {
    afterSelect = ingredient => {
      const { handleSelect, clearFilteredIngredients } = this.props;
      clearFilteredIngredients();
      handleSelect(ingredient);
    };

    afterSearch = title => {
      this.props.changeFilterIngredient({ title });
    };

    render() {
      const { items } = this.props;
      return (
        <WrappedComponent
          {...this.props}
          items={objectMap(items, item =>
            item.linkedRecipe
              ? { ...item, title: `${item.title} (рецепт)` }
              : item
          )}
          afterSearch={this.afterSearch}
          afterSelect={this.afterSelect}
          minWordLength={3}
          time={400}
        />
      );
    }
  };
};

export default connect(
  state => ({
    items: filteredIngredientsSelector(state),
    isRequesting: isLoadingFilteredIngredientsSelector(state)
  }),
  { changeFilterIngredient, clearSearchIngredient, clearFilteredIngredients }
)(SearchIngredientsContainer(DropDownSearch));
