import React, { Component } from "react";
import { connect } from "react-redux";
import {
  editMeal,
  loadMeal,
  deleteRecipeFromMeal,
  createMealComment,
  deleteMealComment
} from "./ducks";
import RecipeProportionContainer from "../RecipeProportions/RecipeProportionContainer";
import NewRecipeProportionControls from "../RecipeProportions/NewRecipeProportionControls";
import { clearRecipeProportionsLast } from "../RecipeProportions/ducks";
import { clearRecipesLast } from "../Recipes/ducks";
import { clearSearchIngredient } from "../Filters/ducks";
import {
  isLoadingCreateMealCommentSelector,
  isLoadingDeleteMealCommentsSelector,
  isLoadingMealCommentsSelector,
  isLoadingMealSelector,
  mealCommentsSelector
} from "./selectors";
import Preloader from "../../components/Preloader/Preloader";
import Comments from "../../components/Comments/Comments";
import "./style.scss";
import { dateFormat } from "../../commons/utils";
import { selfAccountSelector } from "../SelfAccount/selectors";
import { isLoadingRecipesSelector } from "../Recipes/selectors";

class Meal extends Component {
  state = {
    isOpenConfirm: false
  };

  componentDidMount() {
    const { meal, loadMeal, menuId, proportionId } = this.props;
    if (meal.id) {
      loadMeal(menuId, proportionId, meal.id, true);
    }
  }

  componentWillUnmount() {
    const {
      clearRecipesLast,
      clearRecipeProportionsLast,
      clearSearchIngredient
    } = this.props;
    clearRecipesLast();
    clearRecipeProportionsLast();
    clearSearchIngredient();
  }

  handleCreateComment = text => {
    const {
      createMealComment,
      meal,
      menuId,
      proportionId,
      type,
      currentDate
    } = this.props;
    const options = !!meal.id
      ? {}
      : {
          withCreateMeal: true,
          menuId,
          proportionId,
          data: { mealType: type, date: dateFormat(currentDate) }
        };
    createMealComment({ text }, meal, options);
  };

  handleDeleteRecipe = id => {
    const { meal, menuId, proportionId, deleteRecipeFromMeal } = this.props;
    deleteRecipeFromMeal(menuId, proportionId, meal.id, id);
  };

  handleChangeRecipeWeight = (id, weight) => {
    const { meal, menuId, proportionId, editMeal } = this.props;
    editMeal(menuId, proportionId, meal.id, {
      ...meal,
      mealRecipes: meal.mealRecipes.map(mealRecipe =>
        mealRecipe.recipeProportion !== id
          ? mealRecipe
          : { ...mealRecipe, weight }
      )
    });
  };

  isDeleteComment = id => {
    const { comments, self } = this.props;
    const comment = comments.find(comment => comment.id === id);
    const date = new Date(comment.createdAt);
    const nextDate = new Date();
    nextDate.setDate(nextDate.getDate() + 1);
    nextDate.setHours(0, 0, 0, 0);
    const prevDate = new Date();
    prevDate.setDate(prevDate.getDate() - 1);
    prevDate.setHours(23, 59, 59);
    return (
      self.id === comment.admin.id &&
      date.getTime() < nextDate.getTime() &&
      prevDate.getTime() < date.getTime()
    );
  };

  render() {
    const {
      meal,
      menuId,
      proportionId,
      type,
      currentDate,
      loading,
      loadingComments,
      loadingCreateComment,
      loadingDeleteComment,
      comments,
      deleteMealComment
    } = this.props;
    if (loading) return <Preloader />;
    const recipeProportions = Object.entries(meal).length
      ? meal.mealRecipes.map(({ recipeProportion, weight }, index) => (
          <RecipeProportionContainer
            key={recipeProportion}
            handleDeleteRecipe={this.handleDeleteRecipe}
            handleChangeRecipeWeight={this.handleChangeRecipeWeight}
            id={recipeProportion}
            weight={weight}
          />
        ))
      : [];
    return (
      <div>
        {recipeProportions.length < 3 ? (
          <NewRecipeProportionControls
            meal={meal}
            menuId={menuId}
            proportionId={proportionId}
            type={type}
            currentDate={currentDate}
            className="controls-dish"
          />
        ) : null}
        <div>{recipeProportions.reverse()}</div>
        <div className="meal__comments">
          <Comments
            title="Комментарии"
            data={
              comments.length
                ? comments.map(comment => ({
                    ...comment,
                    mealType: comment.meal.mealType,
                    name: `${comment.admin.fullName.lastName} ${
                      comment.admin.fullName.firstName
                    }`
                  }))
                : []
            }
            isRequesting={loadingComments}
            isRequestingCreateComment={loadingCreateComment}
            isRequestingDeleteComment={loadingDeleteComment}
            isDeleteComment={this.isDeleteComment}
            createComment={this.handleCreateComment}
            deleteComment={deleteMealComment}
          />
        </div>
      </div>
    );
  }
}

export default connect(
  state => ({
    loading: isLoadingMealSelector(state),
    loadingComments: isLoadingMealCommentsSelector(state),
    loadingCreateComment: isLoadingCreateMealCommentSelector(state),
    loadingDeleteComment: isLoadingDeleteMealCommentsSelector(state),
    comments: mealCommentsSelector(state),
    self: selfAccountSelector(state)
  }),
  {
    editMeal,
    deleteRecipeFromMeal,
    loadMeal,
    deleteMealComment,
    createMealComment,
    clearRecipeProportionsLast,
    clearRecipesLast,
    clearSearchIngredient
  }
)(Meal);
