import { call, put, takeLatest, select, takeEvery } from "redux-saga/effects";
import { errorMassages, FAIL, START, SUCCESS } from "../../commons/constants";
import { fetchReqAsync, fetchResAsync } from "../../commons/api";
import * as API from "./api";
import {
  CREATE_ANTITAG,
  CREATE_CHILD_TAG,
  CREATE_FRIENDLYTAG,
  CREATE_TAG,
  CREATE_TAG_ICON,
  DELETE_ANTITAG,
  DELETE_CHILD_TAG,
  DELETE_FRIENDLYTAG,
  DELETE_TAG,
  EDIT_TAG,
  editChildTagSuccess,
  editTagFail,
  editTagSuccess,
  LOAD_ANTITAGS,
  LOAD_CHILD_TAGS_OF_CATEGORY,
  LOAD_FRIENDLYTAGS,
  LOAD_TAG,
  LOAD_TAGS,
  LOAD_TAGS_FILTERED,
  LOAD_TAGS_OF_CATEGORY
} from "./ducks";
import { normaliseFromLoad, normaliseItem } from "./normalisers";
import fetchAPI from "../../commons/api";
import { tagFilterSelector } from "../Filters/selectors";
import { Edit } from "@material-ui/icons";

export function* getTags({ url }) {
  try {
    yield put({ type: LOAD_TAGS + START });
    const res = url
      ? yield fetchReqAsync(fetchAPI.get, url)
      : yield fetchReqAsync(API.getTags);
    yield put({
      type: LOAD_TAGS + SUCCESS,
      payLoad: { ...normaliseFromLoad(res) }
    });
  } catch (e) {
    console.log(e);
    yield call(alert, errorMassages.load("tags"));
    yield put({
      type: LOAD_TAGS + FAIL,
      payLoad: {
        data: e
      }
    });
  }
}

export function* getAntiTags({ url, payLoad }) {
  try {
    yield put({ type: LOAD_ANTITAGS + START });
    const res = url
      ? yield fetchReqAsync(fetchAPI.get, url)
      : yield fetchReqAsync(API.getAntiTags, payLoad.id);
    yield put({
      type: LOAD_ANTITAGS + SUCCESS,
      payLoad: { ...normaliseFromLoad(res) }
    });
  } catch (e) {
    console.log(e);
    yield call(alert, errorMassages.load("tags"));
    yield put({
      type: LOAD_ANTITAGS + FAIL,
      payLoad: {
        data: e
      }
    });
  }
}

export function* getFriendlyTags({ url, payLoad }) {
  try {
    yield put({ type: LOAD_FRIENDLYTAGS + START });
    const res = url
      ? yield fetchReqAsync(fetchAPI.get, url)
      : yield fetchReqAsync(API.getFriendlyTags, payLoad.id);
    yield put({
      type: LOAD_FRIENDLYTAGS + SUCCESS,
      payLoad: { ...normaliseFromLoad(res) }
    });
  } catch (e) {
    console.log(e);
    yield call(alert, errorMassages.load("tags"));
    yield put({
      type: LOAD_FRIENDLYTAGS + FAIL,
      payLoad: {
        data: e
      }
    });
  }
}

export function* getTag({ url, payLoad }) {
  try {
    yield put({ type: LOAD_TAG + START });
    const res = url
      ? normaliseItem(yield fetchReqAsync(fetchAPI.get, url))
      : normaliseItem(yield fetchReqAsync(API.getTag, payLoad.id));
    yield put({
      type: LOAD_TAG + SUCCESS,
      payLoad: { data: res, id: res.id }
    });
  } catch (e) {
    yield call(alert, errorMassages.load("tag"));
    yield put({
      type: LOAD_TAG + FAIL,
      payLoad: {
        data: e
      }
    });
  }
}

export function* getFilteredTags(action) {
  try {
    yield put({ type: LOAD_TAGS_FILTERED + START });
    const filter = yield select(tagFilterSelector);
    const tags = normaliseFromLoad(yield fetchReqAsync(API.getTags, filter));
    yield put({
      type: LOAD_TAGS_FILTERED + SUCCESS,
      payLoad: { data: { ...tags } }
    });
  } catch (e) {
    console.log(e);
    yield call(alert, errorMassages.load("tags"));
    yield put({ type: LOAD_TAGS_FILTERED + FAIL, payLoad: { data: e } });
  }
}

export function* getTagsOfCategory({ payLoad }) {
  try {
    const { categoryId } = payLoad;
    yield put({ type: LOAD_TAGS_OF_CATEGORY + START });
    const res = yield fetchReqAsync(API.getTagsOfCategory, categoryId);
    yield put({
      type: LOAD_TAGS_OF_CATEGORY + SUCCESS,
      payLoad: { ...normaliseFromLoad(res) }
    });
  } catch (e) {
    console.log(e);
    yield call(alert, errorMassages.load("tags"));
    yield put({
      type: LOAD_TAGS_OF_CATEGORY + FAIL,
      payLoad: {
        data: e
      }
    });
  }
}

export function* getChildTagsOfCategory({ payLoad, url }) {
  try {
    yield put({ type: LOAD_CHILD_TAGS_OF_CATEGORY + START });
    const res = yield fetchReqAsync(fetchAPI.get, url);
    yield put({
      type: LOAD_CHILD_TAGS_OF_CATEGORY + SUCCESS,
      payLoad: {
        id: payLoad.id,
        data: normaliseFromLoad(res)
      }
    });
  } catch (e) {
    yield call(alert, errorMassages.load("tags"));
    yield put({
      type: LOAD_CHILD_TAGS_OF_CATEGORY + FAIL,
      payLoad: {
        data: e
      }
    });
  }
}

export function* editTag({ payLoad: { data, id, childId } }) {
  try {
    if (childId) {
      yield fetchResAsync(API.putTag, childId, data);
      const res = normaliseItem(yield fetchReqAsync(API.getTag, childId));
      yield put(editChildTagSuccess(id, childId, res));
    } else {
      yield fetchResAsync(API.putTag, id, data);
      const res = normaliseItem(yield fetchReqAsync(API.getTag, id));
      yield put(editTagSuccess(id, res));
    }
  } catch (e) {
    console.log(e);
    yield call(alert, errorMassages.edit("tag"));
    yield put(editTagFail(e));
  }
}

export function* createTag({ payLoad }) {
  try {
    yield put({ type: CREATE_TAG + START });
    const res = yield fetchResAsync(API.postTag, payLoad.data);
    yield put({
      type: CREATE_TAG + SUCCESS
    });
    yield call(getTag, { url: res.headers.get("Location") });
  } catch (e) {
    console.log(e);
    yield call(alert, errorMassages.load("tag"));
    yield put({
      type: CREATE_TAG + FAIL,
      payLoad: {
        data: e
      }
    });
  }
}

export function* createAntiTag({ payLoad }) {
  try {
    yield put({ type: CREATE_ANTITAG + START });
    yield fetchResAsync(API.postAntiTag, payLoad.id, {
      tag: payLoad.antiTagId
    });
    yield put({
      type: CREATE_ANTITAG + SUCCESS,
      payLoad: {
        id: payLoad.antiTagId,
        data: { id: payLoad.antiTagId }
      }
    });
  } catch (e) {
    console.log(e);
    yield call(alert, errorMassages.load("tag"));
    yield put({
      type: CREATE_ANTITAG + FAIL,
      payLoad: {
        data: e
      }
    });
  }
}

export function* createFriendlyTag({ payLoad }) {
  try {
    yield put({ type: CREATE_FRIENDLYTAG + START });
    yield fetchResAsync(API.postFriendlyTag, payLoad.id, {
      tag: payLoad.friendlyTagId
    });
    yield put({
      type: CREATE_FRIENDLYTAG + SUCCESS,
      payLoad: {
        id: payLoad.friendlyTagId,
        data: { id: payLoad.friendlyTagId }
      }
    });
  } catch (e) {
    console.log(e);
    yield call(alert, errorMassages.load("tag"));
    yield put({
      type: CREATE_FRIENDLYTAG + FAIL,
      payLoad: {
        data: e
      }
    });
  }
}

export function* createChildTag({ payLoad: { data, id } }) {
  try {
    yield put({ type: CREATE_CHILD_TAG + START });
    const res = yield fetchResAsync(API.postTag, data);
    const resTag = normaliseItem(
      yield fetchReqAsync(fetchAPI.get, res.headers.get("Location"))
    );
    yield fetchResAsync(API.postChildTag, id, {
      tag: resTag.id
    });
    const resChildTag = normaliseItem(
      yield fetchReqAsync(fetchAPI.get, resTag.self.href)
    );
    yield put({
      type: CREATE_CHILD_TAG + SUCCESS,
      payLoad: {
        id,
        data: resChildTag
      }
    });
  } catch (e) {
    console.log(e);
    yield call(alert, errorMassages.load("tag"));
    yield put({
      type: CREATE_CHILD_TAG + FAIL,
      payLoad: {
        data: e
      }
    });
  }
}

export function* deleteTag({ payLoad: { id } }) {
  try {
    yield put({ type: DELETE_TAG + START });
    yield fetchResAsync(API.deleteTag, id);
    yield put({
      type: DELETE_TAG + SUCCESS,
      payLoad: { id }
    });
  } catch (e) {
    console.log(e);
    yield call(alert, errorMassages.load("tag"));
    yield put({
      type: DELETE_TAG + FAIL,
      payLoad: {
        data: e
      }
    });
  }
}

export function* deleteAntiTag({ payLoad: { id, antiTagId } }) {
  try {
    yield put({ type: DELETE_ANTITAG + START });
    yield fetchResAsync(API.deleteAntiTag, id, antiTagId);
    yield put({
      type: DELETE_ANTITAG + SUCCESS,
      payLoad: { id: antiTagId }
    });
  } catch (e) {
    console.log(e);
    yield call(alert, errorMassages.load("tag"));
    yield put({
      type: DELETE_ANTITAG + FAIL,
      payLoad: {
        data: e
      }
    });
  }
}

export function* deleteFriendlyTag({ payLoad: { id, friendlyTagId } }) {
  try {
    yield put({ type: DELETE_FRIENDLYTAG + START });
    yield fetchResAsync(API.deleteFriendlyTag, id, friendlyTagId);
    yield put({
      type: DELETE_FRIENDLYTAG + SUCCESS,
      payLoad: { id: friendlyTagId }
    });
  } catch (e) {
    console.log(e);
    yield call(alert, errorMassages.load("tag"));
    yield put({
      type: DELETE_FRIENDLYTAG + FAIL,
      payLoad: {
        data: e
      }
    });
  }
}

export function* deleteChildTag({ payLoad: { parentId, id } }) {
  try {
    yield put({ type: DELETE_CHILD_TAG + START });
    yield fetchResAsync(API.deleteTag, id);
    yield put({
      type: DELETE_CHILD_TAG + SUCCESS,
      payLoad: { parentId, id }
    });
  } catch (e) {
    console.log(e);
    yield call(alert, errorMassages.load("tag"));
    yield put({
      type: DELETE_CHILD_TAG + FAIL,
      payLoad: {
        data: e
      }
    });
  }
}

function* watchTags() {
  yield takeLatest(LOAD_TAGS, getTags);
  yield takeLatest(LOAD_ANTITAGS, getAntiTags);
  yield takeLatest(LOAD_FRIENDLYTAGS, getFriendlyTags);
  yield takeLatest(LOAD_TAG, getTag);
  yield takeLatest(LOAD_TAGS_FILTERED, getFilteredTags);
  yield takeLatest(LOAD_TAGS_OF_CATEGORY, getTagsOfCategory);
  yield takeLatest(LOAD_CHILD_TAGS_OF_CATEGORY, getChildTagsOfCategory);
  yield takeLatest(EDIT_TAG, editTag);
  yield takeLatest(CREATE_TAG, createTag);
  yield takeLatest(CREATE_ANTITAG, createAntiTag);
  yield takeLatest(CREATE_FRIENDLYTAG, createFriendlyTag);
  yield takeLatest(CREATE_CHILD_TAG, createChildTag);
  yield takeLatest(DELETE_TAG, deleteTag);
  yield takeEvery(DELETE_ANTITAG, deleteAntiTag);
  yield takeEvery(DELETE_FRIENDLYTAG, deleteFriendlyTag);
  yield takeLatest(DELETE_CHILD_TAG, deleteChildTag);
}

export default watchTags;
