import React from "react";
import { isEmpty } from "../../commons/utils";

const Input = props => {
  const {
    name,
    value,
    handleChange,
    placeholder,
    onBlur,
    id,
    error,
    disabled,
    className,
    isValid,
    autoComplete,
    required,
    autofocus
  } = props;
  return (
    <div className={`${className} input__wrapper`}>
      <input
        // isValid если будет undefined, то выдаст error, хотя у нас просто не проверяется на валидность
        className={
          isValid === false || (error && isEmpty(value))
            ? "input input_error"
            : !isEmpty(value)
            ? "input input_valid"
            : "input"
        }
        name={name}
        value={value}
        onChange={handleChange}
        data-id={id}
        placeholder="&nbsp;"
        disabled={disabled ? "disabled" : null}
        onBlur={onBlur}
        autoComplete={autoComplete}
        required={required}
        autoFocus={autofocus}
      />
      <span className="input__label">{placeholder}</span>
      <span className="input__border" />
      <div className="input__error">{error && error.message}</div>
    </div>
  );
};

export default Input;
