import React, { Component } from "react";
import TagsItem from "./TagsItem";

class TagsList extends Component {
  render() {
    const { tags, handleDelete } = this.props;
    return (
      <ul className="tags__list">
        {tags.map(tag => (
          <li key={tag.id} className="tags__item tags-item">
            <TagsItem
              title={tag.title}
              handleDelete={() => handleDelete(tag.parent, tag.child, tag)}
            />
          </li>
        ))}
      </ul>
    );
  }
}

export default TagsList;
