import React, { Component } from "react";
import { connect } from "react-redux";
import Ingredient from "./Ingredient";
import {
  getCountIngredientsSelector,
  getTotalIngredientsSelector,
  ingredientsSelector,
  isLoadingIngredientsSelector
} from "./selectors";
import { loadIngredients } from "./ducks";
import Preloader from "../../components/Preloader/Preloader";
import Pagination from "../../components/Table/Pagination";

const columns = [
  "Название",
  "Калории",
  "Клетчатка",
  "Белки",
  "Жиры",
  "Углеводы",
  "",
  ""
];

class IngredientList extends Component {
  handleEdit = (e, id) => {
    const { handleEdit, ingredients } = this.props;
    handleEdit({
      ...ingredients[id]
    });
  };

  render() {
    const {
      ingredients,
      loading,
      total,
      handleChangePage,
      handleChangeRowsPerPage,
      rowsPerPage,
      page,
      countArr
    } = this.props;
    if (loading) return <Preloader />;
    const ingredientList = Object.values(ingredients).map(ingredient => (
      <Ingredient
        key={ingredient.id}
        {...ingredient}
        handleEdit={this.handleEdit}
      />
    ));
    return (
      <div>
        <div className="table-list">
          <div className="table-list__row table-list__row_ingredient table-list__row_head">
            {columns.map((column, index) => (
              <div key={index}>{column}</div>
            ))}
          </div>
          {ingredientList}
          <div className="table-list__pagination">
            <Pagination
              total={total}
              page={page}
              countArr={countArr}
              rowsPerPage={rowsPerPage}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  state => ({
    ingredients: ingredientsSelector(state),
    loading: isLoadingIngredientsSelector(state),
    total: getTotalIngredientsSelector(state),
    count: getCountIngredientsSelector(state)
  }),
  { loadIngredients }
)(IngredientList);
