import { call, put, takeLatest } from "redux-saga/effects";
import { errorMassages, FAIL, START, SUCCESS } from "../../commons/constants";
import * as API from "./api";
import {
  CREATE_MENUPROPORTION,
  LOAD_MENUPROPORTIONS,
  EDIT_MENUPROPORTION,
  SET_CURRENT_MENUPROPORTION,
  DELETE_MENUPROPORTION
} from "./ducks";
import {
  normaliseMenuProportions,
  normaliseMenuProportion
} from "./normalisers";
import { fetchReqAsync, fetchResAsync } from "../../commons/api";
import fetchAPI from "../../commons/api";
import { LOAD_MENUPROPORTION } from "./ducks";
import { deleteMeal } from "../Meals/sagas";

function* getMenuProportions(action) {
  const {
    payLoad: { menuId }
  } = action;
  try {
    yield put({ type: LOAD_MENUPROPORTIONS + START });
    const res = normaliseMenuProportions(
      yield fetchReqAsync(API.getMenuProportions, menuId)
    );
    yield put({
      type: SET_CURRENT_MENUPROPORTION,
      payLoad: Object.values(res)[0].id
    });
    yield put({
      type: LOAD_MENUPROPORTIONS + SUCCESS,
      payLoad: { data: res }
    });
  } catch (e) {
    console.log(e);
    yield call(alert, errorMassages.load("menu proportions"));
    yield put({ type: LOAD_MENUPROPORTIONS + FAIL });
  }
}

export function* getMenuProportion(action) {
  const {
    payLoad: { menuId, id }
  } = action;
  try {
    yield put({ type: LOAD_MENUPROPORTION + START });
    const res = yield fetchReqAsync(API.getMenuProportion, menuId, id);
    yield put({
      type: LOAD_MENUPROPORTION + SUCCESS,
      payLoad: { data: normaliseMenuProportion(res), id }
    });
  } catch (e) {
    console.log(e);
    yield call(alert, errorMassages.load("menu proportions"));
    yield put({ type: LOAD_MENUPROPORTION + FAIL });
  }
}

export function* editMenuProportion(action) {
  const { data, id } = action.payLoad;
  try {
    yield put({ type: EDIT_MENUPROPORTION + START });
    //yield call(API.putMenuProportion, menuId, id, data);
    yield put({
      type: EDIT_MENUPROPORTION + SUCCESS,
      payLoad: {
        id,
        data
      }
    });
  } catch (e) {
    yield call(alert, errorMassages.edit("menu proportion"));
    yield put({ type: EDIT_MENUPROPORTION + FAIL });
  }
}

export function* createMenuProportion(action) {
  const { data, id } = action.payLoad;
  try {
    yield put({ type: CREATE_MENUPROPORTION + START });
    const res = yield fetchResAsync(API.postMenuProportion, id, data);
    const resMenuProportion = yield fetchReqAsync(
      fetchAPI.get,
      res.headers.get("Location")
    );
    yield put({
      type: CREATE_MENUPROPORTION + SUCCESS,
      payLoad: {
        data,
        id: resMenuProportion.id
      }
    });
    return resMenuProportion;
  } catch (e) {
    console.log(e);
    yield call(alert, errorMassages.create("menu proportion"));
    yield put({ type: CREATE_MENUPROPORTION + FAIL });
  }
}

export function* deleteMenuProportion(action) {
  try {
    yield put({ type: DELETE_MENUPROPORTION + START });
    const { data, menuId } = action.payLoad;
    for (let meal of data.meals) {
      yield call(deleteMeal, {
        payLoad: {
          menuId,
          proportionId: data.id,
          id: meal
        }
      });
    }
    yield fetchResAsync(API.deleteMenuProportion, menuId, data.id);
    yield put({
      type: DELETE_MENUPROPORTION + SUCCESS,
      payLoad: {
        id: data.id
      }
    });
  } catch (e) {
    console.log(e);
    yield call(alert, errorMassages.delete("menu proportion"));
    yield put({
      type: DELETE_MENUPROPORTION + FAIL,
      payLoad: {
        data: e
      }
    });
  }
}

function* watchMenuProportions() {
  yield takeLatest(LOAD_MENUPROPORTIONS, getMenuProportions);
  yield takeLatest(EDIT_MENUPROPORTION, editMenuProportion);
  yield takeLatest(CREATE_MENUPROPORTION, createMenuProportion);
  yield takeLatest(DELETE_MENUPROPORTION, deleteMenuProportion);
}

export default watchMenuProportions;
