import React, { Component } from "react";
import { connect } from "react-redux";
import { isLoadingAdminsSelector, adminsSelector } from "./selectors";
import Preloader from "../../components/Preloader/Preloader";
import { loadAdmins } from "./ducks";
import Admin from "./Admin";

const columns = ["Имя", "Телефон", "Статус", "", ""];

class AdminsList extends Component {
  componentDidMount() {
    const { loadAdmins } = this.props;
    loadAdmins();
  }

  handleEdit = (e, id) => {
    const { handleEdit, admins } = this.props;
    handleEdit({
      ...admins[id]
    });
  };

  render() {
    const { loading, admins } = this.props;
    if (loading) return <Preloader />;
    const adminsList = Object.values(admins).map(admin => (
      <Admin key={admin.id} admin={admin} handleEdit={this.handleEdit} />
    ));
    let index = 0;
    return (
      <div>
        <div className="table-list table-list__admin">
          <div className="table-list__row table-list__row_head">
            {columns.map(column => (
              <div key={index++}>{column}</div>
            ))}
          </div>
          {adminsList}
        </div>
      </div>
    );
  }
}

export default connect(
  state => ({
    admins: adminsSelector(state),
    loading: isLoadingAdminsSelector(state)
  }),
  { loadAdmins }
)(AdminsList);
