import React, { Component } from "react";

class Dashboard extends Component {
  render() {
    const { ingredient } = this.props;
    const proteins = ingredient.nutrients["proteins"];
    const fats = ingredient.nutrients["fats"];
    const carbohydrates = ingredient.nutrients["carbohydrates"];
    return (
      <ul className="table-ingredient__dashboard">
        На 100 грамм:
        <li>Кал: {ingredient.kilocaloriesPerHundredGrams}</li>
        <li>К: {ingredient.cellulosePerHundredGrams}</li>
        <li>Б: {proteins && proteins.amountPerHundredGrams}</li>
        <li>Ж: {fats && fats.amountPerHundredGrams}</li>
        <li>У: {carbohydrates && carbohydrates.amountPerHundredGrams}</li>
      </ul>
    );
  }
}

export default Dashboard;
