import React, { Component } from "react";
import { Route } from "react-router-dom";
import MenuProportionWeekContainer from "./Week/MenuProportionWeekContainer";
import MenuProportionsContainer from "./Day/MenuProportionsContainer";
import MenuProportionMonthContainer from "./Month/MenuProportionMonthContainer";

class MenuProportionRoute extends Component {
  render() {
    const {
      match: { path }
    } = this.props;
    return (
      <div>
        <Route
          path={`${path}day`}
          render={({ match, history }) => (
            <MenuProportionsContainer
              {...this.props}
              match={match}
              history={history}
            />
          )}
        />
        <Route
          path={`${path}week`}
          render={() => <MenuProportionWeekContainer {...this.props} />}
        />
        <Route
          path={`${path}month`}
          render={() => <MenuProportionMonthContainer {...this.props} />}
        />
      </div>
    );
  }
}

export default MenuProportionRoute;
