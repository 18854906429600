import React, { Component } from "react";
import Button from "../../../components/Button/Button";
import Step from "../../Recipes/AddNewRecipes/Step";
import { connect } from "react-redux";
import { editCookingStepCover } from "../../Cover/ducks";
import {
  createCookingStep,
  deleteCookingStep,
  editCookingStepStore
} from "../../Recipes/ducks";
import PreloaderHOC from "../../../HOCs/Preloader";
import {
  getCookingSteps,
  isLoadingCookingStepSelector
} from "../../Recipes/selectors";

class CookingSteps extends Component {
  handleChange = e => {
    const { value, dataset, name } = e.target;
    const { recipe, editCookingStepStore } = this.props;
    editCookingStepStore(dataset.id, recipe.id, {
      ...recipe.cookingSteps.find(step => step.id === dataset.id),
      [name]: value
    });
  };

  handleAdd = () => {
    const { createCookingStep, recipe } = this.props;
    createCookingStep(
      {
        guide: "Описание",
        position: recipe.cookingSteps.length + 1,
        cookingStepCover: ""
      },
      recipe.id
    );
  };

  handleDelete = (e, id) => {
    const { recipe, deleteCookingStep } = this.props;
    deleteCookingStep(id, recipe.id);
  };

  handleChangeCover = (id, data) => {
    const { editCookingStepCover, recipe } = this.props;
    editCookingStepCover(recipe.id, data, id);
  };

  render() {
    const { cookingSteps, disabled } = this.props;
    const cookingStepsList = cookingSteps
      ? cookingSteps
          .sort((a, b) => a.position - b.position)
          .map((step, index) => (
            <li key={step.id} className="cookingSteps-dish__step">
              <Step
                key={step.id}
                {...step}
                handleChange={this.handleChange}
                handleChangeCover={data =>
                  this.handleChangeCover(step.id, data)
                }
                deleteStep={this.handleDelete}
                stepIndex={index + 1}
                disabled={disabled}
              />
            </li>
          ))
      : [];
    return (
      <div className="tail-dish__property property-dish__cookingSteps">
        <div className="cookingSteps-dish">
          <div className="cookingSteps-dish__title">Шаги приготовления</div>
          {cookingStepsList}
          <Button
            onClick={this.handleAdd}
            className="cookingSteps-dish__btn"
            disabled={disabled}
          >
            Добавить
          </Button>
        </div>
      </div>
    );
  }
}

export default connect(
  (state, ownProps) => {
    return {
      loading: isLoadingCookingStepSelector(state),
      cookingSteps: getCookingSteps(state, {
        id: ownProps.recipe && ownProps.recipe.id
      })
    };
  },
  {
    editCookingStepCover,
    createCookingStep,
    editCookingStepStore,
    deleteCookingStep
  }
)(PreloaderHOC(CookingSteps));
