import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getConfirmationPhoneSelector,
  isLoadingConfirmationSelector,
  confirmationErrorSelector
} from "../Confirmation/selectors";
import Preloader from "../../components/Preloader/Preloader";
import "../../../src/commons/styles/Authorisation.scss";
import ConfirmCode from "./ConfirmCode";
import ConfirmPhone from "./ConfirmPhone";
import { unConfirmCode, unConfirmPhone } from "../Confirmation/ducks";

class Authorisation extends Component {
  state = {
    phone: "",
    code: ""
  };

  componentWillUnmount() {
    const { unConfirmCode, unConfirmPhone } = this.props;
    unConfirmCode();
    unConfirmPhone();
  }

  handleChange = e => {
    const { value, name } = e.target;
    this.setState({
      [name]: value
    });
  };

  handleChangePhone = () => {
    this.setState({
      phone: ""
    });
  };

  render() {
    const { phone, code } = this.state;
    const { isConfirm, loading, error } = this.props;
    if (loading) return <Preloader />;
    return (
      <div className="authorisation__wrapper">
        <div className="authorisation__title">Вход</div>
        <div className="authorisation">
          {isConfirm ? (
            <ConfirmCode
              handleChange={this.handleChange}
              code={code}
              error={error}
              phone={phone}
              handleChangePhone={this.handleChangePhone}
            />
          ) : (
            <ConfirmPhone
              handleChange={this.handleChange}
              error={error}
              phone={phone}
            />
          )}
        </div>
      </div>
    );
  }
}

export default connect(
  state => ({
    isConfirm: getConfirmationPhoneSelector(state),
    loading: isLoadingConfirmationSelector(state),
    error: confirmationErrorSelector(state)
  }),
  { unConfirmPhone, unConfirmCode }
)(Authorisation);
