import { START, SUCCESS, FAIL } from "../../commons/constants";
import {
  CREATE_MENU,
  DELETE_MENU,
  EDIT_MENU,
  LOAD_MENU,
  LOAD_MENUS,
  LOAD_MENUS_FAIL,
  LOAD_MENUS_SUCCESS,
  SAVE_MENU
} from "./actionTypes";

const initialState = {
  data: {},
  loading: false,
  loaded: false,
  error: false
};

export const loadMenus = () => ({
  type: LOAD_MENUS
});

export const loadMenusSuccess = data => ({
  type: LOAD_MENUS_SUCCESS,
  payload: {
    data
  }
});

export const loadMenusFail = error => ({
  type: LOAD_MENUS_FAIL,
  error
});

export function loadMenu(id) {
  return {
    type: LOAD_MENU,
    payLoad: {
      id
    }
  };
}

export function editMenu(id, data) {
  return {
    type: EDIT_MENU,
    payLoad: {
      id,
      data
    }
  };
}

export function createMenu(data, proportions) {
  return {
    type: CREATE_MENU,
    payLoad: {
      data,
      proportions
    }
  };
}

export function saveMenu(id, data) {
  return {
    type: SAVE_MENU,
    payLoad: {
      id,
      data
    }
  };
}

export function deleteMenu(id) {
  return {
    type: DELETE_MENU,
    payLoad: {
      id
    }
  };
}

export default (menus = initialState, { type, payLoad, error, payload }) => {
  switch (type) {
    case LOAD_MENUS:
      return {
        ...menus,
        loading: true,
        loaded: false
      };
    case LOAD_MENUS_SUCCESS:
      return {
        ...menus,
        data: { ...payload.data },
        loading: false,
        loaded: true
      };
    case LOAD_MENUS_FAIL:
      return {
        ...menus,
        loading: false,
        loaded: false,
        error
      };
    case LOAD_MENU + START:
      return {
        ...menus,
        loading: true,
        loaded: false
      };
    case LOAD_MENU + SUCCESS:
      return {
        ...menus,
        data: {
          ...menus.data,
          [payLoad.id]: {
            ...payLoad.data
          }
        },
        loading: false,
        loaded: true
      };
    case LOAD_MENU + FAIL:
      return {
        ...menus,
        loading: false,
        loaded: false,
        error: payLoad.data
      };
    case EDIT_MENU + START:
      return {
        ...menus,
        loading: true,
        loaded: false
      };
    case EDIT_MENU + SUCCESS:
      return {
        ...menus,
        data: {
          ...menus.data,
          [payLoad.id]: {
            ...payLoad.data,
            id: payLoad.id
          }
        },
        loading: false,
        loaded: true
      };
    case EDIT_MENU + FAIL:
      return {
        ...menus,
        loading: false,
        loaded: false,
        error: payLoad.data
      };
    case CREATE_MENU + START:
      return {
        ...menus,
        loading: true,
        loaded: false
      };
    case CREATE_MENU + SUCCESS:
      return {
        ...menus,
        data: {
          ...menus.data,
          [payLoad.id]: {
            ...payLoad.data,
            id: payLoad.id
          }
        },
        loading: false,
        loaded: true
      };
    case CREATE_MENU + FAIL:
      return {
        ...menus,
        loading: false,
        loaded: false,
        error: payLoad.data
      };
    case DELETE_MENU + START:
      return {
        ...menus,
        loading: true,
        loaded: false
      };
    case DELETE_MENU + SUCCESS:
      const menuDelete = { ...menus.data };
      delete menuDelete[payLoad.id];
      return {
        ...menus,
        data: { ...menuDelete },
        loading: false,
        loaded: true
      };
    case DELETE_MENU + FAIL:
      return {
        ...menus,
        loading: false,
        loaded: false,
        error: payLoad.data
      };
    default:
      return menus;
  }
};
