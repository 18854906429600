import React, { Component } from "react";
import "../../src/commons/styles/Header.scss";

class Header extends Component {
  handleLogout = () => {
    localStorage.removeItem("ACCESS_TOKEN");
    window.location.reload();
  };
  render() {
    return (
      <div className="header">
        <h1 className="header__title">Online kitchen</h1>
        <button onClick={this.handleLogout} className="header__button">
          Logout
        </button>
      </div>
    );
  }
}

export default Header;
