import React, { Component } from "react";
import { connect } from "react-redux";
import { deleteComment, publishComment, unpublishComment } from "./ducks";
import ListControls from "../../components/ListControls/ListControls";
import { PUBLISHED } from "../../commons/constants";
import { statuses } from "./utils";
import { formatShowDate } from "../../commons/utils";

class Comment extends Component {
  getControlsButtons = () => {
    const { deleteComment, comment } = this.props;
    return [
      {
        ...this.getStatusButton(comment.status)
      },
      {
        title: "Удалить",
        handleClick: deleteComment,
        isConfirm: true,
        confirmTitle: "Подтвердить удаление"
      }
    ];
  };

  getStatusButton = status => {
    const { publishComment, unpublishComment, comment } = this.props;
    if (status === PUBLISHED)
      return {
        title: "Отменить публикацию",
        handleClick: () => unpublishComment(comment.id)
      };
    return {
      title: "Опубликовать",
      handleClick: () => publishComment(comment.id)
    };
  };

  render() {
    const { comment } = this.props;
    const { message, id, status, createdAt, user } = comment;
    const { fullName } = user;
    return (
      <div className="table-list__row">
        <div className="table-list__date">
          {formatShowDate(new Date(createdAt))}
        </div>
        <div className="table-list__title">
          {fullName.firstName} {fullName.lastName}
        </div>
        <div className="table-list__name">{message}</div>
        <div className="table-list__status">
          {statuses.find(({ name }) => name === status).title}
        </div>
        <ListControls id={id} buttons={this.getControlsButtons()} />
      </div>
    );
  }
}

export default connect(
  null,
  { deleteComment, publishComment, unpublishComment }
)(Comment);
