import {
  normaliseToFetch,
  normaliseToFetchCookingStep,
  normaliseToPutRecipe
} from "./normalisers";
import {
  RECIPES_STATUS,
  RECIPE_URL,
  RECIPES_URL,
  SERVER_URL
} from "../../commons/constants";
import fecthAPI from "../../commons/api";
import { setQueryFilterString } from "../../commons/utils";

export const getRecipes = filters =>
  fecthAPI.get(`${SERVER_URL}/${RECIPES_URL}${setQueryFilterString(filters)}`);

export const getRecipe = id =>
  fecthAPI.get(`${SERVER_URL}/${RECIPE_URL}/${id}`);

export const postRecipe = data =>
  fecthAPI.post(`${SERVER_URL}/${RECIPE_URL}`, normaliseToFetch(data));

export const postCompoundIngredient = id =>
  fecthAPI.post(`${SERVER_URL}/${RECIPE_URL}/${id}/compound-ingredient`);

export const postCookingStep = data =>
  fecthAPI.post(`${SERVER_URL}/${RECIPE_URL}/cooking-step`, data);

export const putRecipeStatus = (id, status) =>
  fecthAPI.post(`${SERVER_URL}/${RECIPE_URL}/${id}/${RECIPES_STATUS}`, {
    status
  });

export const putRecipe = (id, data) =>
  fecthAPI.put(`${SERVER_URL}/${RECIPE_URL}/${id}`, normaliseToPutRecipe(data));

export const putCookingStep = (id, data) =>
  fecthAPI.put(
    `${SERVER_URL}/${RECIPE_URL}/cooking-step/${id}`,
    normaliseToFetchCookingStep(data)
  );

export const patchRecipe = (id, data) =>
  fecthAPI.patch(
    `${SERVER_URL}/${RECIPES_URL}/${id}`,
    normaliseToPutRecipe(data)
  );

export const deleteRecipeAPI = id =>
  fecthAPI.delete(`${SERVER_URL}/${RECIPE_URL}/${id}`);
