import { MATERIALS_URL, SERVER_URL } from "../../commons/constants";
import fecthAPI from "../../commons/api";
import { dateFormat, setQueryString } from "../../commons/utils";
import { normaliseToFetch } from "./normalisers";

export const getMaterials = filter => {
  return fecthAPI.get(
    `${SERVER_URL}/${MATERIALS_URL}${
      filter
        ? setQueryString(
            { name: "category", value: filter.category },
            { name: "status", value: filter.status },
            { name: "title", value: filter.search },
            { name: "dateFrom", value: dateFormat(filter.dateFrom) },
            { name: "dateTo", value: dateFormat(filter.dateTo) }
          )
        : ""
    }`
  );
};

export const getMaterial = id =>
  fecthAPI.get(`${SERVER_URL}/${MATERIALS_URL}/${id}`);

export const postMaterial = data =>
  fecthAPI.post(`${SERVER_URL}/${MATERIALS_URL}`, normaliseToFetch(data));

export const publishMaterial = id =>
  fecthAPI.post(`${SERVER_URL}/${MATERIALS_URL}/${id}/publish`);

export const unpublishMaterial = id =>
  fecthAPI.post(`${SERVER_URL}/${MATERIALS_URL}/${id}/unpublish`);

export const putMaterial = (id, data) =>
  fecthAPI.put(`${SERVER_URL}/${MATERIALS_URL}/${id}`, normaliseToFetch(data));

export const deleteMaterial = id =>
  fecthAPI.delete(`${SERVER_URL}/${MATERIALS_URL}/${id}`);
