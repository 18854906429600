export const PROTOCOL = "https:"; //window.location.href.split("/")[0];
export const PATH_INGREDIENTS_ALL = "/ingredients/all";
export const PATH_RECIPES_ALL = "/recipes";
export const PATH_MENU = "/menu";
export const PATH_ADMINS_ALL = "/admins/all";
export const PATH_TAGS = "/tags";
export const PATH_MATERIALS = "/materials";
export const PATH_ACCOUNTS = "/accounts";

export const SUCCESS = "_SUCCESS";
export const FAIL = "_FAIL";
export const START = "_START";

export const TEST_ACCESS_TOKEN = "e1c60470-ab89-4934-998e-87e641316011";
export const SERVER_URL = process.env.API_URL;
export const ACCESS_TOKEN_URL = "security/generate-admin-access-token";

export const INGREDIENTS_URL = "admin/ingredients";
export const MENUS_URL = "admin/menus";
export const RECIPES_URL = "admin/recipes";
export const RECIPE_URL = "admin/recipe";
export const MATERIALS_URL = "admin/materials";
export const MATERIALS_COMMENTS_URL = "comments";
export const PROFILES_URL = "admin/profiles";

export const ACCOUNTS_URL = "admin/accounts";
export const ACCOUNT_URL = "admin/account";

export const ADMINS_URL = "superadmin/admins";
export const ADMIN_URL = "superadmin/admin";

export const COMMENTS_URL = "admin/comments";
export const COMMENT_URL = "admin/comment";
export const CATEGORY_URL = "category";

export const TAG_URL = "admin/tag";
export const TAGS_URL = "admin/tags";

export const UPLOADS_URL = "admin/uploads";
export const RECIPE_COVER = "recipe-cover";
export const RECIPE_TECHCOVER = "recipe-tech-cover";
export const TAG_CSV = "tag-csv";
export const RECIPE_COOKINGSTEPS_COVER = "recipe-cooking-steps-cover";
export const INGREDIENT_COVER = "ingredient-cover";
export const INGREDIENT_ICON = "ingredient-icon";
export const TAG_ICON = "tag-icon";
// api.onkitchen.spiks.dev
// api.onkitchen.softsetters-dev.tk
export const COVER_URL = `${PROTOCOL}//api.onkitchen.spiks.dev/static/uploads`;
export const RECIPES_STATUS = "update-status";
export const CALORIES_MEASURE = 100;

//statuses
export const DRAFT = "draft";
export const APPROVED_DRAFT = "draft_approved";
export const PUBLISHED = "published";
export const RECIPE_APPROVED = "recipe_approved";
export const PHOTO_ADDED = "photo_added";
export const UNPUBLISHED = "unpublished";
export const DELETED = "deleted";
export const ACTIVE = "active";
export const BLOCKED = "blocked";

export const ARTICLE = "article";
export const NEWS = "news";

export const weekDays = [
  {
    id: 1,
    name: "Monday",
    value: "Понедельник",
    short: "Пн"
  },
  {
    id: 2,
    name: "Tuesday",
    value: "Вторник",
    short: "Вт"
  },
  {
    id: 3,
    name: "Wednesday",
    value: "Среда",
    short: "Ср"
  },
  {
    id: 4,
    name: "Thursday",
    value: "Четверг",
    short: "Чт"
  },
  {
    id: 5,
    name: "Friday",
    value: "Пятница",
    short: "Пт"
  },
  {
    id: 6,
    name: "Saturday",
    value: "Суббота",
    short: "Сб"
  },
  {
    id: 0,
    name: "Sunday",
    value: "Воскресенье",
    short: "Вс"
  }
];

export const errorMassages = {
  load: (data, error) =>
    `Ошибка загрузки ${data}. ${generateError(data, error)}`,
  edit: (data, error) =>
    `Ошибка изменения ${data}. ${generateError(data, error)}`,
  create: (data, error) =>
    `Ошибка создания ${data}. ${generateError(data, error)}`,
  delete: (data, error) =>
    `Ошибка удаления ${data}. ${generateError(data, error)}`
};

const generateError = (data, error) => {
  switch (error.status) {
    case 500:
      return `Ошибка сервера. Обратитесь к администртору`;
    case 400:
      return `Ошибка данных. Убедитесь, что все данные введены верно`;
    case 401:
      return `Ошибка прав доступа. Перезайдите в систему`;
    case 404:
      return `Страница не найдена`;
    default:
      return "";
  }
};

export const weightTypesConstants = {
  GRAM: "gram",
  HUNDRED__MILLILITERS: "hundred_milliliters",
  ITEM: "item",
  TEA_SPOON: "tea__spoon",
  TABLE_SPOON: "table__spoon"
};
