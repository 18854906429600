import React, { Component } from "react";

const Wizard = ModalComponent => {
  return class extends Component {
    constructor(props) {
      super(props);
      this.state = {
        currentPart: 1,
        isSubmit: false
      };
      this.child = React.createRef();
    }
    handlePrev = () => {
      this.setState(({ currentPart }) => {
        if (currentPart - 1 <= 0) return {};
        return {
          currentPart: currentPart - 1
        };
      });
    };

    handleNext = e => {
      if (
        this.child.current.isValid &&
        !this.child.current.isValid(this.state.currentPart)
      )
        return;
      this.setState(({ currentPart }, { partCount }) => {
        if (currentPart + 1 > partCount) return {};
        return {
          currentPart: currentPart + 1
        };
      });
    };

    toggleSubmit = () => {
      this.setState(({ isSubmit }) => ({
        isSubmit: !isSubmit
      }));
    };

    handleSubmit = e => {
      e.preventDefault();
      if (
        this.child.current.isValid &&
        !this.child.current.isValid(this.state.currentPart)
      )
        return;
      const { partCount } = this.props;
      const { currentPart } = this.state;
      if (partCount !== currentPart) return this.handleNext(e);
      this.toggleSubmit();
    };

    fillPartList = () => {
      let { partCount } = this.props;
      if (partCount === 1) return null;
      const { currentPart } = this.state;
      const partList = [];
      while (partCount > 0) {
        partList.unshift(
          <li
            key={partCount}
            className={
              partCount === currentPart
                ? "bullet current"
                : partCount < currentPart
                ? "bullet past"
                : "bullet future"
            }
          >
            <span className="icon">{partCount}</span>
            <div className="text">{partCount} Шаг</div>
          </li>
        );
        partCount -= 1;
      }
      return partList;
    };

    render() {
      const { wizardTitle, partCount } = this.props;
      const { currentPart, isSubmit } = this.state;
      const partList = this.fillPartList();
      return (
        <div className="wizard">
          <div className="wizard__header">
            <h3 className="wizard__title">{wizardTitle}</h3>
            <ul className="wizard__pagination pagination">{partList}</ul>
          </div>
          <div className={"wizard__form-wrapper"}>
            <form onSubmit={this.handleSubmit} className="wizard__form from">
              <ModalComponent
                ref={this.child}
                currentPart={currentPart}
                isSubmit={isSubmit}
                toggleSubmit={this.toggleSubmit}
                {...this.props}
              />
              <button
                type="button"
                onClick={this.handlePrev}
                className="wizard__button_back"
              />
              {currentPart !== partCount && (
                <button type="submit" className="wizard__button_next" />
              )}
            </form>
          </div>
          <div className="wizard__form-footer">
            <button
              type="submit"
              className="wizard__button_save"
              disabled={currentPart !== partCount}
              onClick={this.handleSubmit}
            >
              <div className="wizard__icon-save" />
              Сохранить
            </button>
          </div>
        </div>
      );
    }
  };
};

export default Wizard;
