import React, { Component } from "react";
import ShowForm from "../../../components/Form/ShowForm";

class MainPart extends Component {
  state = {
    formCollection: []
  };
  static getDerivedStateFromProps(nextProps, prevState) {
    const { firstName, middleName, lastName, phone } = nextProps;
    return {
      formCollection: [
        {
          type: "input_string",
          name: `lastName`,
          value: lastName,
          placeholder: "Фамилия",
          required: true
        },
        {
          type: "input_string",
          name: `firstName`,
          value: firstName,
          placeholder: "Имя",
          required: true
        },
        {
          type: "input_string",
          name: `middleName`,
          value: middleName,
          placeholder: `Отчество`,
          required: true
        },
        {
          type: "input_phone",
          name: `phone`,
          value: phone,
          placeholder: `Телефон`,
          required: true
        }
      ]
    };
  }

  render() {
    const { formCollection } = this.state;
    const { handleChange } = this.props;
    return (
      <div className="adding-admin__main">
        <h4 className="form__title adding-admin__title">
          Редактирование администратора
        </h4>
        <ShowForm formCollection={formCollection} handleChange={handleChange} />
      </div>
    );
  }
}

export default MainPart;
