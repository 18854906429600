import { ADMIN_URL, ADMINS_URL, SERVER_URL } from "../../commons/constants";
import fecthAPI from "../../commons/api";

export const getAdmins = () => fecthAPI.get(`${SERVER_URL}/${ADMINS_URL}`);

export const getAdmin = id => fecthAPI.get(`${SERVER_URL}/${ADMIN_URL}/${id}`);

export const putAdmin = (id, data) =>
  fecthAPI.put(`${SERVER_URL}/${ADMIN_URL}/${id}`, data);

export const blockAdmin = id =>
  fecthAPI.post(`${SERVER_URL}/${ADMIN_URL}/${id}/block`);

export const unblockAdmin = id =>
  fecthAPI.post(`${SERVER_URL}/${ADMIN_URL}/${id}/unblock`);
