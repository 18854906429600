import { getPureObjectFromHal, parseHrefToID } from "../../commons/utils";
import { DRAFT } from "../../commons/constants";
import { normaliseToPut as normaliseToPutProportion } from "../RecipeProportions/normalisers";
import { recipeByIdSelector } from "./selectors";
import { normaliseTag } from "../Tags/normalisers";

export function normaliseFromLoad(recipes) {
  const res = {};
  const resRecipes = [...recipes._embedded.items];
  resRecipes.forEach(recipe => {
    recipe = normaliseRecipe(recipe);
    Object.assign(res, {
      [recipe.id]: {
        ...recipe
      }
    });
  });
  return res;
}

export function normaliseRecipe(recipe) {
  const res = {};
  recipe = getPureObjectFromHal(recipe);
  Object.assign(res, {
    ...recipe,
    tags: recipe.tags.map(tag => normaliseTag(tag)),
    inventory: recipe.inventory.map(tag => normaliseTag(tag)),
    ingredientTags: recipe.ingredientTags.map(tag => normaliseTag(tag)),
    cookingSteps: normaliseCookingSteps(recipe.cookingSteps),
    ingredients: recipe.ingredients.map(ingredient =>
      parseHrefToID(ingredient.href)
    ),
    cover: {
      isChanged: false,
      path: recipe.cover ? recipe.cover.path : null
    },
    techCover: {
      isChanged: false,
      path: recipe.techCover ? recipe.techCover.path : ""
    },
    proportions: [
      ...recipe.proportions.map(proportion => ({
        ...proportion,
        doses: [
          ...proportion.doses.map((dose, index) => ({
            ...dose,
            ingredient: parseHrefToID(dose._links.ingredient.href),
            id: `${index}`
          }))
        ]
      }))
    ]
  });
  return res;
}

export function normaliseCookingSteps(cookingSteps) {
  return cookingSteps.map(step => {
    return {
      ...normaliseCookingStep(step)
    };
  });
}

export function normaliseCookingStep(cookingStep) {
  cookingStep = getPureObjectFromHal(cookingStep);
  return {
    ...cookingStep,
    cookingStepCover: {
      isChanged: false,
      path: cookingStep.cookingStepCover
        ? cookingStep.cookingStepCover.path
        : ""
    }
  };
}

export function normaliseToFetchCookingStep(cookingStep) {
  const { guide, position, hint, cookingStepCover } = cookingStep;
  const data = {
    guide,
    position,
    hint
  };
  if (cookingStepCover.isChanged)
    Object.assign(data, {
      cookingStepCover: parseHrefToID(cookingStepCover && cookingStepCover.path)
    });
  return data;
}

export function normaliseToFetch(recipe) {
  const { cover, ingredients, title, status } = recipe;
  return {
    cookingSteps: [],
    cover: parseHrefToID(cover) || "",
    ingredients: ingredients || [],
    title: title || "",
    status: status || DRAFT
  };
}

export function normaliseToPutRecipe(recipe) {
  const {
    cookingSteps,
    cover,
    ingredients,
    title,
    tags,
    techDescription,
    cookingTime,
    preparingTime,
    difficulty,
    notificationDesc,
    notificationTime,
    serving,
    preparingDesc,
    numbersOfServings,
    techCover,
    massChange,
    inventory
  } = recipe;
  let newCookingSteps = cookingSteps ? [...cookingSteps] : [];
  newCookingSteps = newCookingSteps.map(step => step.id);
  const data = {
    numberOfServings: numbersOfServings || 1,
    tags: tags ? tags.map(tag => tag.id) : null,
    preparingDesc: preparingDesc || "",
    serving: serving || "",
    notificationDesc: notificationDesc || "",
    notificationTime: notificationTime || "",
    difficulty: difficulty || "",
    preparingTime: preparingTime || 0,
    cookingTime: cookingTime || 0,
    cookingSteps: newCookingSteps,
    techDescription: techDescription || "",
    ingredients: ingredients || [],
    title: title || "",
    massChange: massChange || null,
    inventory: inventory.map(({ id }) => id)
  };
  if (cover.isChanged)
    Object.assign(data, {
      cover: parseHrefToID(cover.path)
    });
  if (techCover.isChanged)
    Object.assign(data, {
      techCover: parseHrefToID(techCover.path)
    });
  return data;
}
