import { FAIL, START, SUCCESS } from "../../commons/constants";

const initialState = {
  data: {},
  lastChangedData: [],
  lastChangedCookingSteps: [],
  filteredData: {},
  loading: false,
  loadingCookingStep: false,
  filteredLoading: false,
  loaded: false,
  error: false
};

export const LOAD_RECIPES = "LOAD_RECIPES";
export const LOAD_RECIPE = "LOAD_RECIPE";
export const EDIT_RECIPE = "EDIT_RECIPE";
export const CREATE_RECIPE = "CREATE_RECIPE";

export const CREATE_COMPOUND_INGREDIENT = "CREATE_COMPOUND_INGREDIENT";
export const CREATE_COMPOUND_INGREDIENT_SUCCESS =
  "CREATE_COMPOUND_INGREDIENT_SUCCESS";
export const CREATE_COMPOUND_INGREDIENT_FAIL =
  "CREATE_COMPOUND_INGREDIENT_FAIL";

export const CREATE_COOKING_STEP = "CREATE_COOKING_STEP";
export const CREATE_COOKING_STEP_SUCCESS = "CREATE_COOKING_STEP_SUCCESS";

export const EDIT_COOKING_STEP = "EDIT_COOKING_STEP";
export const EDIT_COOKING_STEP_SUCCESS = "EDIT_COOKING_STEP_SUCCESS";
export const EDIT_COOKING_STEP_LAST = "EDIT_COOKING_STEP_LAST";
export const EDIT_COOKING_STEP_STORE = "EDIT_COOKING_STEP_STORE";
export const DELETE_COOKING_STEP = "DELETE_COOKING_STEP";
export const CLEAR_COOKING_STEP_LAST = "CLEAR_COOKING_STEP_LAST";

export const DELETE_RECIPE = "DELETE_RECIPE";
export const EDIT_RECIPE_STORE = "EDIT_RECIPE_STORE";
export const EDIT_RECIPE_DATABASE = "EDIT_RECIPE_DATABASE";
export const EDIT_RECIPES_CHANGED = "EDIT_RECIPES_CHANGED";
export const EDIT_RECIPE_LAST = "EDIT_RECIPE_LAST";
export const EDIT_RECIPE_STATUS = "EDIT_RECIPE_STATUS";
export const EDIT_RECIPE_WITH_PROPORTION = "EDIT_RECIPE_WITH_PROPORTION";

export const CLEAR_RECIPE_LAST = "CLEAR_RECIPE_LAST";
export const CLEAR_RECIPES_LAST = "CLEAR_RECIPES_LAST";
export const LOAD_RECIPES_FILTERED = "LOAD_RECIPES_FILTERED";
export const CLEAR_RECIPES_FILTERED = "CLEAR_RECIPES_FILTERED";

export function loadRecipes(filters) {
  return {
    type: LOAD_RECIPES,
    filters
  };
}

export function loadRecipe(id, url) {
  return {
    type: LOAD_RECIPE,
    url,
    payLoad: {
      id
    }
  };
}

export function editRecipe(id, data) {
  return {
    type: EDIT_RECIPE,
    payLoad: {
      id,
      data
    }
  };
}

export function editRecipeDatabase(id, data) {
  return {
    type: EDIT_RECIPE_DATABASE,
    payLoad: {
      id,
      data
    }
  };
}

export function editRecipeStore(id, data) {
  return {
    type: EDIT_RECIPE_STORE,
    isNotFetching: true,
    payLoad: {
      id,
      data
    }
  };
}

export function editRecipeLast(id) {
  return {
    type: EDIT_RECIPE_LAST,
    payLoad: {
      id
    }
  };
}

export function editRecipesChanged() {
  return {
    type: EDIT_RECIPES_CHANGED
  };
}

export const editCookingStep = (id, recipeId, data) => ({
  type: EDIT_COOKING_STEP,
  payLoad: {
    data,
    id,
    recipeId
  }
});

export const editCookingStepLast = id => ({
  type: EDIT_COOKING_STEP_LAST,
  payLoad: {
    id
  }
});

export const editCookingStepStore = (id, recipeId, data) => ({
  type: EDIT_COOKING_STEP_STORE,
  payLoad: {
    id,
    recipeId,
    data
  }
});

export const editCookingStepSuccess = (id, recipeId, data) => ({
  type: EDIT_COOKING_STEP_SUCCESS,
  payLoad: {
    data,
    id,
    recipeId
  }
});

export function editRecipeStatus(id, status) {
  return {
    type: EDIT_RECIPE_STATUS,
    payLoad: {
      id,
      status
    }
  };
}

export function editRecipeWithProportion(recipe, proportion, options) {
  return {
    type: EDIT_RECIPE_WITH_PROPORTION,
    payLoad: {
      recipe,
      proportion
    },
    options
  };
}

export function createRecipe(data, proportion, history) {
  return {
    type: CREATE_RECIPE,
    history,
    payLoad: {
      data,
      proportion
    }
  };
}

export const createCompoundIngredient = id => ({
  type: CREATE_COMPOUND_INGREDIENT,
  payload: {
    id
  }
});

export const createCompoundIngredientSuccess = () => ({
  type: CREATE_COMPOUND_INGREDIENT_SUCCESS
});

export const createCompoundIngredientFail = error => ({
  type: CREATE_COMPOUND_INGREDIENT_FAIL,
  error
});

export function createCookingStep(data, recipeId) {
  return {
    type: CREATE_COOKING_STEP,
    payLoad: {
      data,
      recipeId
    }
  };
}

export function createCookingStepSuccess(data, id) {
  return {
    type: CREATE_COOKING_STEP_SUCCESS,
    payLoad: {
      data,
      id
    }
  };
}

export function deleteCookingStep(id, recipeId) {
  return {
    type: DELETE_COOKING_STEP,
    payLoad: {
      id,
      recipeId
    }
  };
}

export function deleteRecipe(id) {
  return {
    type: DELETE_RECIPE,
    payLoad: {
      id
    }
  };
}

export function clearCookingStepLast(id) {
  return {
    type: CLEAR_COOKING_STEP_LAST,
    payLoad: { id }
  };
}

export function clearRecipesLast() {
  return {
    type: CLEAR_RECIPES_LAST
  };
}

export function clearFilteredRecipes() {
  return {
    type: CLEAR_RECIPES_FILTERED
  };
}

export default (
  recipes = initialState,
  { type, payLoad, error, count, total }
) => {
  switch (type) {
    case LOAD_RECIPES + START:
      return {
        ...recipes,
        loading: true,
        loaded: false,
        error: null
      };
    case LOAD_RECIPES + SUCCESS:
      return {
        ...recipes,
        data: { ...payLoad.data },
        count,
        total,
        loading: false,
        loaded: true
      };
    case LOAD_RECIPES + FAIL:
      return {
        ...recipes,
        loading: false,
        loaded: false,
        error: payLoad.data
      };
    case LOAD_RECIPES_FILTERED + START:
      return {
        ...recipes,
        filteredLoading: true
      };
    case LOAD_RECIPES_FILTERED + SUCCESS:
      return {
        ...recipes,
        filteredData: { ...payLoad.data },
        filteredLoading: false
      };
    case LOAD_RECIPES_FILTERED + FAIL:
      return {
        ...recipes,
        error: payLoad.data,
        filteredLoading: false
      };
    case LOAD_RECIPE + START:
      return {
        ...recipes,
        loading: true,
        loaded: false
      };
    case LOAD_RECIPE + SUCCESS:
      return {
        ...recipes,
        data: {
          ...recipes.data,
          [payLoad.id]: {
            ...payLoad.data
          }
        },
        loading: false,
        loaded: true
      };
    case LOAD_RECIPE + FAIL:
      return {
        ...recipes,
        loading: false,
        loaded: false
      };
    case EDIT_RECIPE + START:
    case EDIT_RECIPE_STORE + START:
      return {
        ...recipes,
        loading: true,
        loaded: false
      };
    case EDIT_RECIPE_DATABASE + SUCCESS:
    case EDIT_RECIPE_STORE + SUCCESS:
      return {
        ...recipes,
        data: {
          ...recipes.data,
          [payLoad.id]: {
            ...payLoad.data,
            id: payLoad.id
          }
        },
        loading: false,
        loaded: true
      };
    case EDIT_RECIPE + FAIL:
    case EDIT_RECIPE_DATABASE + FAIL:
    case EDIT_RECIPE_STORE + FAIL:
      return {
        ...recipes,
        loading: false,
        loaded: false
      };
    case EDIT_COOKING_STEP_SUCCESS:
      return {
        ...recipes,
        data: {
          ...recipes.data,
          [payLoad.recipeId]: {
            ...recipes.data[payLoad.recipeId],
            cookingSteps: recipes.data[payLoad.recipeId].cookingSteps.map(
              step => {
                if (step.id === payLoad.id)
                  return {
                    ...payLoad.data
                  };
                return { ...step };
              }
            )
          }
        }
      };
    case CREATE_COMPOUND_INGREDIENT:
      return {
        ...recipes,
        loading: true
      };
    case EDIT_RECIPE + SUCCESS:
    case CREATE_COMPOUND_INGREDIENT_SUCCESS:
      return {
        ...recipes,
        loading: false
      };
    case CREATE_COMPOUND_INGREDIENT_FAIL:
      return {
        ...recipes,
        loading: false
      };
    case EDIT_RECIPE_LAST:
      const lastChangedData = [...recipes.lastChangedData];
      if (!lastChangedData.find(data => data === payLoad.id))
        lastChangedData.push(payLoad.id);
      return {
        ...recipes,
        lastChangedData
      };
    case CREATE_RECIPE + START:
      return {
        ...recipes,
        loading: true,
        loaded: false
      };
    case CREATE_RECIPE + SUCCESS:
      return {
        ...recipes,
        data: {
          ...recipes.data,
          [payLoad.id]: {
            ...payLoad.data,
            id: payLoad.id
          }
        },
        loading: false,
        loaded: true
      };
    case CREATE_RECIPE + FAIL:
      return {
        ...recipes,
        loading: false,
        loaded: false,
        error
      };
    case CREATE_COOKING_STEP:
      return {
        ...recipes,
        loadingCookingStep: true
      };
    case CREATE_COOKING_STEP_SUCCESS:
      return {
        ...recipes,
        data: {
          ...recipes.data,
          [payLoad.id]: {
            ...recipes.data[payLoad.id],
            cookingSteps: [
              ...recipes.data[payLoad.id].cookingSteps,
              payLoad.data
            ]
          }
        },
        loadingCookingStep: false
      };
    case CLEAR_RECIPE_LAST:
      return {
        ...recipes,
        lastChangedData: [
          ...recipes.lastChangedData.filter(id => id !== payLoad.id)
        ]
      };
    case CLEAR_RECIPES_LAST:
      return {
        ...recipes,
        lastChangedData: []
      };
    case DELETE_RECIPE + START:
      return {
        ...recipes,
        loading: true,
        loaded: false
      };
    case DELETE_RECIPE + SUCCESS:
      const recipeDelete = { ...recipes.data };
      delete recipeDelete[payLoad.id];
      return {
        ...recipes,
        data: { ...recipeDelete },
        loading: false,
        loaded: true
      };
    case DELETE_RECIPE + FAIL:
      return {
        ...recipes,
        loading: false,
        loaded: false
      };

    case CLEAR_COOKING_STEP_LAST:
      return {
        ...recipes,
        lastChangedCookingSteps: recipes.lastChangedCookingSteps.filter(
          step => step !== payLoad.id
        )
      };

    case EDIT_COOKING_STEP_LAST:
      return {
        ...recipes,
        lastChangedCookingSteps: [
          ...recipes.lastChangedCookingSteps.filter(
            step => step !== payLoad.id
          ),
          payLoad.id
        ]
      };

    case DELETE_COOKING_STEP:
      return {
        ...recipes,
        data: {
          ...recipes.data,
          [payLoad.recipeId]: {
            ...recipes.data[payLoad.id],
            cookingSteps: recipes.data[payLoad.recipeId].cookingSteps.filter(
              ({ id }) => id !== payLoad.id
            )
          }
        }
      };
    case CLEAR_RECIPES_FILTERED:
      return {
        ...recipes,
        filteredData: {}
      };
    default:
      return recipes;
  }
};
