import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { statuses } from "./utils";
import FilterButton from "../../components/Button/FilterButton";
import Label from "../../components/Label/Label";
import InputRadio from "../../components/Input/InputRadio";
import CommentsList from "./CommentsList";
import { changeFilterComments, clearFilterComments } from "../Filters/ducks";

class CommentsMain extends Component {
  state = {
    status: ""
  };

  componentWillUnmount() {
    const { clearFilterComments } = this.props;
    clearFilterComments();
  }

  handleChange = e => {
    const { id, changeFilterComments } = this.props;
    const { value, name } = e.target;
    this.setState(
      () => ({ [name]: value }),
      () => changeFilterComments(id, { status: this.state.status })
    );
  };

  getFilters(curValue, arrValues, name) {
    let filters = [...arrValues];
    filters.push({
      name: "",
      title: "Все"
    });
    filters = filters.map((filter, index) => (
      <li key={index}>
        <InputRadio
          name={name}
          value={filter.name}
          handleChange={this.handleChange}
          checked={curValue === filter.name}
          label={filter.title}
        />
      </li>
    ));
    return filters;
  }

  handleBack = () => {
    const { history } = this.props;
    history.goBack();
  };

  render() {
    const { status } = this.state;
    const statusFilters = this.getFilters(status, statuses, "status");
    return (
      <Fragment>
        <div className="content__head header-content">
          <div className="title__content title-content">
            <button className="title-content__back" onClick={this.handleBack} />
            <h2 className="title-content__name">Комментарии</h2>
          </div>
          <div className="header-content__filter">
            <FilterButton>
              <div className="container-filter__item">
                <Label>Статус:</Label>
                <ul className="container-filter__list">{statusFilters}</ul>
              </div>
            </FilterButton>
          </div>
        </div>
        <CommentsList id={this.props.id} />
      </Fragment>
    );
  }
}

export default connect(
  null,
  { changeFilterComments, clearFilterComments }
)(CommentsMain);
