import React from "react";

const Label = props => {
  const { value, id, className, children } = props;
  return (
    <label className={"form-label " + className} data-id={id}>
      {value || children}
    </label>
  );
};

export default Label;
