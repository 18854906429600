import { FAIL, START, SUCCESS } from "../../commons/constants";

const initialState = {
  data: {},
  loading: false,
  loaded: false,
  error: false
};

export const LOAD_SELF_ACCOUNT = "LOAD_SELF_ACCOUNT";

export function loadSelfAccount() {
  return {
    type: LOAD_SELF_ACCOUNT
  };
}

export default (state = initialState, { type, payLoad, error }) => {
  switch (type) {
    case LOAD_SELF_ACCOUNT + START:
      return {
        ...state,
        loading: true,
        error: null
      };
    case LOAD_SELF_ACCOUNT + SUCCESS:
      return {
        ...state,
        data: { ...payLoad },
        loading: false
      };
    case LOAD_SELF_ACCOUNT + FAIL:
      return {
        ...state,
        loading: false,
        error
      };
    default:
      return state;
  }
};
