import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";

function sleep(delay = 0) {
  return new Promise(resolve => {
    setTimeout(resolve, delay);
  });
}

export default function GetTagAutocomplete({
  categoryId,
  handleSetTagId,
  ...props
}) {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);

  React.useEffect(
    () => {
      let active = true;

      (async () => {
        try {
          const ACCESS_TOKEN = localStorage.getItem("ACCESS_TOKEN");
          const response = await fetch(
            `${process.env.API_URL}/admin/tag/category/${categoryId}/list`,
            {
              headers: {
                Accept: "application/json",
                "Content-type": "application/json; charset=utf-8",
                Authorization: "Bearer " + ACCESS_TOKEN
              }
            }
          );
          if (response.ok) {
            const json = await response.json();
            const items = await json["_embedded"]["items"];
            if (active) {
              setOptions(items);
            }
          } else {
            const error = await response.json();
            throw new Error(JSON.stringify(error));
          }
        } catch (e) {}
      })();

      return () => {};
    },
    [categoryId]
  );

  return (
    <Autocomplete
      id="tag"
      style={{ width: 300, margin: "10px" }}
      open={open}
      onChange={(e, value) => {
        value && value.id && handleSetTagId(value.id);
        return value;
      }}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      getOptionLabel={option => option.title}
      options={options}
      renderInput={params => (
        <TextField
          {...params}
          label="Выбор тега"
          fullWidth
          variant="outlined"
          {...props}
        />
      )}
    />
  );
}
