import { all, fork } from "redux-saga/effects";
import watchIngredients from "./features/Ingredients/sagas";
import watchRecipes from "./features/Recipes/sagas";
import watchRecipeProportions from "./features/RecipeProportions/sagas";
import watchMenuProportions from "./features/MenuProportions/sagas";
import watchMenus from "./features/Menus/sagas";
import watchMeals from "./features/Meals/sagas";
import watchRecipesCover from "./features/Cover/sagas";
import watchSecurity from "./features/Authorisation/sagas";
import watchFilter from "./features/Filters/sagas";
import watchTags from "./features/Tags/sagas";
import watchMaterials from "./features/Materials/sagas";
import watchComments from "./features/Comments/sagas";
import watchAccounts from "./features/Accounts/sagas";
import watchProfiles from "./features/Profiles/sagas";
import watchAdmins from "./features/Admins/sagas";
import watchTagsCategory from "./features/TagsCategory/sagas";
import watchSelfAccount from "./features/SelfAccount/sagas";

export default function* rootSaga() {
  yield all(
    [
      watchIngredients,
      watchRecipes,
      watchRecipeProportions,
      watchMenuProportions,
      watchMenus,
      watchMeals,
      watchRecipesCover,
      watchSecurity,
      watchFilter,
      watchTags,
      watchMaterials,
      watchComments,
      watchAccounts,
      watchProfiles,
      watchAdmins,
      watchTagsCategory,
      watchSelfAccount
    ].map(saga => fork(saga))
  );
}
