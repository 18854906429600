const initialState = {
  isShown: false,
  error: null
};

export const MODAL_SHOW = "MODAL_SHOW";
export const MODAL_CLOSE = "MODAL_CLOSE";

export function showModal() {
  return {
    type: MODAL_SHOW
  };
}

export function closeModal() {
  return {
    type: MODAL_CLOSE
  };
}

export default (state = initialState, { type, payLoad }) => {
  switch (type) {
    case MODAL_SHOW:
      return {
        ...state,
        isShown: true
      };
    case MODAL_CLOSE:
      return {
        ...state,
        isShown: false
      };
    default:
      return state;
  }
};
