import { createSelector } from "reselect";
import { objFilter } from "../../commons/utils";

const recipesGetter = state => state.recipes;
const recipeGetter = (state, ownProps) => {
  return state.recipes.data[ownProps.id] || {};
};
const lastChangedRecipesGetter = state => {
  return state.recipes.lastChangedData;
};
const lastChangedCookingStepsGetter = state =>
  state.recipes.lastChangedCookingSteps;

const recipesFilteredGetter = state => state.recipes.filteredData;

export const filteredRecipesSelector = createSelector(
  recipesFilteredGetter,
  data => data
);

const lastChangedRecipeGetter = (state, ownProps) => {
  return state.recipes.lastChangedData.find(id => id === ownProps.id);
};

export const recipeByIdSelector = createSelector(
  recipeGetter,
  recipe => {
    return { ...recipe };
  }
);

export const recipesSelector = createSelector(
  recipesGetter,
  ({ data }) => data
);

export const recipesNotDraftSelector = createSelector(
  recipesGetter,
  ({ data }) => objFilter(data, recipe => !recipe.isDraft)
);

export const isLoadingRecipesSelector = createSelector(
  recipesGetter,
  ({ loading }) => {
    return loading;
  }
);

export const isFilteredLoadingRecipesSelector = createSelector(
  recipesGetter,
  ({ filteredLoading }) => {
    return filteredLoading;
  }
);

export const isLoadingRecipeStatusSelector = createSelector(
  recipesGetter,
  ({ loadingStatus }) => {
    return loadingStatus;
  }
);

export const isLoadingCookingStepSelector = createSelector(
  recipesGetter,
  ({ loadingCookingStep }) => {
    return loadingCookingStep;
  }
);

export const isLoadedRecipesSelector = createSelector(
  recipesGetter,
  ({ loaded }) => {
    return loaded;
  }
);

export const getLastChangedRecipes = createSelector(
  recipesGetter,
  lastChangedRecipesGetter,
  ({ data }, lastChangedRecipes) => {
    const res = [];
    lastChangedRecipes.forEach(lastChangedRecipe => {
      if (data[lastChangedRecipe]) res.push(data[lastChangedRecipe]);
    });
    return res;
  }
);

export const getLastChangedCookingSteps = createSelector(
  recipeGetter,
  lastChangedCookingStepsGetter,
  (data, lastChangedCookingSteps) => {
    const res = [];
    lastChangedCookingSteps.forEach(lastChangedCookingStep => {
      const recipeCookingStep = data.cookingSteps.find(
        ({ id }) => id === lastChangedCookingStep
      );
      if (recipeCookingStep) res.push(recipeCookingStep);
    });
    return res;
  }
);

export const getCookingSteps = createSelector(
  recipeGetter,
  ({ cookingSteps }) => {
    return cookingSteps || [];
  }
);

export const getCountRecipesSelector = createSelector(
  recipesGetter,
  ({ count }) => {
    return count;
  }
);

export const getTotalRecipesSelector = createSelector(
  recipesGetter,
  ({ total }) => {
    return total;
  }
);

export const isLastChangedRecipe = createSelector(
  lastChangedRecipeGetter,
  lastChangedRecipe => {
    return !!lastChangedRecipe;
  }
);
