import { createSelector } from "reselect";

const ingredientsGetter = state => state.ingredients;
const ingredientFilteredGetter = state => state.ingredients.filteredData;
const ingredientByIDGetter = (state, ownProps) =>
  state.ingredients.data[ownProps.id];

export const filteredIngredientsSelector = createSelector(
  ingredientFilteredGetter,
  data => data
);

export const ingredientsSelector = createSelector(
  ingredientsGetter,
  ({ data }) => {
    return data;
  }
);

export const ingredientByIDSelector = createSelector(
  ingredientByIDGetter,
  ingredient => {
    return ingredient;
  }
);

export const isLoadingIngredientsSelector = createSelector(
  ingredientsGetter,
  ({ loading }) => {
    return loading;
  }
);

export const getTotalIngredientsSelector = createSelector(
  ingredientsGetter,
  ({ total }) => {
    return total;
  }
);

export const getCountIngredientsSelector = createSelector(
  ingredientsGetter,
  ({ count }) => {
    return count;
  }
);

export const isLoadingFilteredIngredientsSelector = createSelector(
  ingredientsGetter,
  ({ loadingFilteredData }) => {
    return loadingFilteredData;
  }
);

export const isLoadedIngredientsSelector = createSelector(
  ingredientsGetter,
  ({ loaded }) => {
    return loaded;
  }
);
