import React, { Component } from "react";
import SearchWrapper from "../../../components/Search/SearchWrapper";
import { connect } from "react-redux";
import { changeSearchTag, clearSearchTag } from "../../Filters/ducks";
import { clearFilteredTags } from "../ducks";
import { filteredTagsSelector } from "../selectors";

const SearchTagsContainer = WrappedComponent => {
  return class extends Component {
    afterSelect = tag => {
      const { handleSelect, clearFilteredTags } = this.props;
      clearFilteredTags();
      handleSelect(tag.id);
    };

    afterSearch = search => {
      this.props.changeSearchTag(search);
      this.props.clearSearchTag();
    };

    render() {
      return (
        <WrappedComponent
          {...this.props}
          afterSearch={this.afterSearch}
          afterSelect={this.afterSelect}
        />
      );
    }
  };
};

export default connect(
  state => ({
    items: filteredTagsSelector(state)
  }),
  { changeSearchTag, clearSearchTag, clearFilteredTags }
)(SearchTagsContainer(SearchWrapper));
