import { call, put, takeLatest, select } from "redux-saga/effects";
import * as API from "./api";
import { START, SUCCESS, FAIL } from "../../commons/constants";
import {
  LOAD_COVER,
  EDIT_RECIPE_COVER,
  EDIT_RECIPE_COVER_LAST,
  LOAD_RECIPE_COVER,
  LOAD_INGREDIENT_COVER,
  EDIT_INGREDIENT_COVER_LAST,
  EDIT_COOKINGSTEP_COVER,
  EDIT_RECIPE_TECHCOVER,
  EDIT_INGREDIENT_ICON_LAST,
  EDIT_TAG_ICON
} from "./ducks";
import { recipeByIdSelector } from "../Recipes/selectors";
import { fetchReqAsync } from "../../commons/api";
import { editCookingStep, editRecipeDatabase } from "../Recipes/ducks";
import { tagsByIdSelector, tagsChildByIdSelector } from "../Tags/selectors";
import { editTag } from "../Tags/ducks";

export function* createRecipeCover({ payLoad: { data } }) {
  try {
    if (data) {
      const res = yield fetchReqAsync(API.postRecipeCover, data);
      return res.id;
    }
    return "";
  } catch (error) {
    return "";
  }
}

export function* createRecipeTechCover({ payLoad: { data } }) {
  try {
    if (data) {
      const res = yield fetchReqAsync(API.postRecipeTechCover, data);
      return res.id;
    }
    return "";
  } catch (error) {
    return "";
  }
}

export function* createTagIcon({ payLoad: { data } }) {
  try {
    if (data) {
      const res = yield fetchReqAsync(API.postTagIcon, data);
      return res.id;
    }
    return "";
  } catch (error) {
    return "";
  }
}

export function* createRecipeCookingStepsCover({ payLoad: { data } }) {
  try {
    if (data) {
      const res = yield fetchReqAsync(API.postRecipeCookingStepsCover, data);
      return res.id;
    }
    return "";
  } catch (error) {
    return "";
  }
}

export function* createIngredientCover({ payLoad: { data } }) {
  try {
    yield put({ type: LOAD_COVER + START });
    if (data) {
      const res = yield fetchReqAsync(API.postIngredientCover, data);
      yield put({ type: LOAD_COVER + SUCCESS });
      return res.id;
    }
    return "";
  } catch (error) {
    yield put({ type: LOAD_COVER + FAIL, error });
  }
}

export function* createIngredientIcon({ payLoad: { data } }) {
  try {
    yield put({ type: LOAD_COVER + START });
    if (data) {
      const res = yield fetchReqAsync(API.postIngredientIcon, data);
      yield put({ type: LOAD_COVER + SUCCESS });
      return res.id;
    }
    return "";
  } catch (error) {
    yield put({ type: LOAD_COVER + FAIL, error });
  }
}

export function* editRecipeCover({ payLoad: { data, id } }) {
  try {
    yield put({ type: LOAD_COVER + START });
    const coverID = yield call(createRecipeCover, {
      payLoad: {
        data
      }
    });
    const recipe = yield select(recipeByIdSelector, { id });
    yield put(
      editRecipeDatabase(id, {
        ...recipe,
        cover: { isChanged: true, path: coverID }
      })
    );
    yield put({ type: LOAD_COVER + SUCCESS });
  } catch (error) {
    console.log(error);
    yield put({ type: LOAD_COVER + FAIL, error });
  }
}

export function* editRecipeTechCover({ payLoad: { data, id } }) {
  try {
    yield put({ type: LOAD_COVER + START });
    const coverID = yield call(createRecipeTechCover, {
      payLoad: {
        data
      }
    });
    const recipe = yield select(recipeByIdSelector, { id });
    yield put(
      editRecipeDatabase(id, {
        ...recipe,
        techCover: { isChanged: true, path: coverID }
      })
    );
    yield put({ type: LOAD_COVER + SUCCESS });
  } catch (error) {
    yield put({ type: LOAD_COVER + FAIL, error });
  }
}

export function* editTagIcon({ payLoad: { data, id, childId } }) {
  try {
    const icon = yield call(createTagIcon, {
      payLoad: {
        data
      }
    });
    let tag = {};
    if (childId) tag = yield select(tagsChildByIdSelector, { id, childId });
    else tag = yield select(tagsByIdSelector, { id });
    yield put(editTag(id, { ...tag, icon }, childId));
  } catch (error) {
    console.log(error);
  }
}

export function* editCookingStepCover({ payLoad: { recipeId, data, stepId } }) {
  try {
    yield put({ type: LOAD_COVER + START });
    const coverID = yield call(createRecipeCookingStepsCover, {
      payLoad: {
        data
      }
    });
    const recipe = yield select(recipeByIdSelector, { id: recipeId });
    yield put(
      editCookingStep(stepId, recipeId, {
        ...recipe.cookingSteps.find(({ id }) => id === stepId),
        cookingStepCover: { isChanged: true, path: coverID }
      })
    );
    yield put({ type: LOAD_COVER + SUCCESS });
  } catch (error) {
    console.log(error);
    yield put({ type: LOAD_COVER + FAIL, error });
  }
}

function* editRecipeCoverLast({ payLoad: { data } }) {
  const coverID = yield call(createRecipeCover, {
    payLoad: {
      data
    }
  });
  if (coverID)
    yield put({
      type: EDIT_RECIPE_COVER_LAST + SUCCESS,
      payLoad: {
        data: coverID
      }
    });
}

function* editIngredientCoverLast({ payLoad: { data } }) {
  const coverID = yield call(createIngredientCover, {
    payLoad: {
      data
    }
  });
  yield put({
    type: EDIT_INGREDIENT_COVER_LAST + SUCCESS,
    payLoad: {
      data: { isChanged: true, path: coverID }
    }
  });
}

function* editIngredientIconLast({ payLoad: { data } }) {
  const coverID = yield call(createIngredientIcon, {
    payLoad: {
      data
    }
  });
  yield put({
    type: EDIT_INGREDIENT_ICON_LAST + SUCCESS,
    payLoad: {
      data: { isChanged: true, path: coverID }
    }
  });
}

function* watchRecipesCover() {
  yield takeLatest(LOAD_RECIPE_COVER, createRecipeCover);
  yield takeLatest(LOAD_INGREDIENT_COVER, createIngredientCover);
  yield takeLatest(EDIT_RECIPE_COVER, editRecipeCover);
  yield takeLatest(EDIT_RECIPE_TECHCOVER, editRecipeTechCover);
  yield takeLatest(EDIT_COOKINGSTEP_COVER, editCookingStepCover);
  yield takeLatest(EDIT_RECIPE_COVER_LAST, editRecipeCoverLast);
  yield takeLatest(EDIT_INGREDIENT_COVER_LAST, editIngredientCoverLast);
  yield takeLatest(EDIT_INGREDIENT_ICON_LAST, editIngredientIconLast);
  yield takeLatest(EDIT_TAG_ICON, editTagIcon);
}

export default watchRecipesCover;
