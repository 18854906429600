import React, { Component, Fragment } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import SideMenu from "./SideMenu";
import Content from "./Content";
import { connect } from "react-redux";
import { loadSelfAccount } from "../features/SelfAccount/ducks";

class Body extends Component {
  componentDidMount() {
    this.props.loadSelfAccount();
  }

  render() {
    return (
      <div className="main">
        <Router>
          <Fragment>
            <SideMenu />
            <Content />
          </Fragment>
        </Router>
      </div>
    );
  }
}

export default connect(
  null,
  { loadSelfAccount }
)(Body);
