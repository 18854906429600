import { FAIL, START, SUCCESS } from "../../commons/constants";

const initialState = {
  lastChangedData: {
    isChanged: false,
    path: ""
  },
  lastChangedIcon: {
    isChanged: false,
    path: ""
  },
  loading: false,
  loaded: false,
  error: false
};

export const LOAD_COVER = "LOAD_COVER";
export const LOAD_RECIPE_COVER = "LOAD_RECIPE_COVER";
export const LOAD_INGREDIENT_COVER = "LOAD_INGREDIENT_COVER";
export const EDIT_RECIPE_COVER = "EDIT_RECIPE_COVER";
export const EDIT_RECIPE_TECHCOVER = "EDIT_RECIPE_TECHCOVER";
export const EDIT_COOKINGSTEP_COVER = "EDIT_COOKINGSTEP_COVER";
export const EDIT_RECIPE_COVER_LAST = "EDIT_RECIPE_COVER_LAST";
export const EDIT_INGREDIENT_COVER_LAST = "EDIT_INGREDIENT_COVER_LAST";
export const EDIT_INGREDIENT_ICON_LAST = "EDIT_INGREDIENT_ICON_LAST";
export const EDIT_TAG_ICON = "EDIT_TAG_ICON";
export const CLEAR_COVER_LAST = "CLEAR_COVER_LAST";

export function loadRecipeCover(data) {
  return {
    type: LOAD_RECIPE_COVER,
    payLoad: {
      data
    }
  };
}

export function loadIngredientCover(data) {
  return {
    type: LOAD_INGREDIENT_COVER,
    payLoad: {
      data
    }
  };
}

export function editRecipeCover(id, data) {
  return {
    type: EDIT_RECIPE_COVER,
    payLoad: {
      id,
      data
    }
  };
}

export function editRecipeTechCover(id, data) {
  return {
    type: EDIT_RECIPE_TECHCOVER,
    payLoad: {
      id,
      data
    }
  };
}

export function editCookingStepCover(recipeId, data, stepId) {
  return {
    type: EDIT_COOKINGSTEP_COVER,
    payLoad: {
      recipeId,
      data,
      stepId
    }
  };
}

export function editRecipeCoverLast(data) {
  return {
    type: EDIT_RECIPE_COVER_LAST,
    payLoad: {
      data
    }
  };
}

export function editIngredientCoverLast(data) {
  return {
    type: EDIT_INGREDIENT_COVER_LAST,
    payLoad: {
      data
    }
  };
}

export function editIngredientIconLast(data) {
  return {
    type: EDIT_INGREDIENT_ICON_LAST,
    payLoad: {
      data
    }
  };
}

export const editTagIcon = (id, data, childId) => {
  return {
    type: EDIT_TAG_ICON,
    payLoad: {
      id,
      data,
      childId
    }
  };
};

export function clearCoverLast() {
  return {
    type: CLEAR_COVER_LAST
  };
}

export default (cover = initialState, { type, payLoad, error }) => {
  switch (type) {
    case EDIT_RECIPE_COVER_LAST + SUCCESS:
      return {
        ...cover,
        lastChangedData: {
          isChanged: true,
          url: payLoad.data
        }
      };
    case EDIT_INGREDIENT_COVER_LAST + SUCCESS:
      return {
        ...cover,
        lastChangedData: {
          ...payLoad.data
        }
      };
    case EDIT_INGREDIENT_ICON_LAST + SUCCESS:
      return {
        ...cover,
        lastChangedIcon: {
          ...payLoad.data
        }
      };
    case LOAD_COVER + START:
      return {
        ...cover,
        loading: true
      };
    case LOAD_COVER + SUCCESS:
      return {
        ...cover,
        loading: false
      };
    case LOAD_COVER + FAIL:
      return {
        ...cover,
        loading: false,
        error
      };
    case CLEAR_COVER_LAST:
      return {
        ...initialState
      };
    default:
      return cover;
  }
};
