import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { isShownModalSelector } from "../Modal/selectors";
import { closeModal, showModal } from "../Modal/ducks";
import AdminsList from "./AdminsList";
import EditMain from "./Edit/EditMain";

class AdminsMain extends Component {
  state = {
    search: "",
    editAdmin: null
  };

  handleChange = e => {
    const { value, name } = e.target;
    this.setState(() => ({ [name]: value }));
  };

  handleEdit = admin => {
    this.setState(
      {
        editAdmin: admin
      },
      () => this.props.showModal()
    );
  };

  handleNew = () => {
    this.setState(
      {
        editProfile: null
      },
      () => this.props.showModal()
    );
  };

  render() {
    const { editAdmin } = this.state;
    const { closeModal, isShownModal, id } = this.props;
    return (
      <Fragment>
        <div className="content__head header-content">
          <div className="title__content title-content">
            <h2 className="title-content__name">Администраторы</h2>
          </div>
        </div>
        <AdminsList handleEdit={this.handleEdit} accountId={id} />
        {isShownModal ? (
          <EditMain
            closeModal={closeModal}
            wizardTitle={"Редактировать администратора"}
            partCount={1}
            {...editAdmin}
          />
        ) : null}
      </Fragment>
    );
  }
}

export default connect(
  state => ({
    isShownModal: isShownModalSelector(state)
  }),
  {
    showModal,
    closeModal
  }
)(AdminsMain);
