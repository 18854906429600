import { FAIL, START, SUCCESS } from "../../commons/constants";

const initialState = {
  data: {},
  loading: false,
  loaded: false,
  error: false
};

export const LOAD_ACCOUNTS = "LOAD_ACCOUNTS";
export const EDIT_ACCOUNT = "EDIT_ACCOUNT";
export const ACCOUNT_CONFIRM_PHONE = "ACCOUNT_CONFIRM_PHONE";
export const ACCOUNT_CONFIRM_CODE = "ACCOUNT_CONFIRM_CODE";

export function loadAccounts() {
  return {
    type: LOAD_ACCOUNTS
  };
}

export function accountConfirmPhone(data) {
  return {
    type: ACCOUNT_CONFIRM_PHONE,
    payLoad: {
      data
    }
  };
}

export function accountConfirmCode(phone, code) {
  return {
    type: ACCOUNT_CONFIRM_CODE,
    payLoad: {
      phone,
      code
    }
  };
}

export function editAccount(id, data) {
  return {
    type: EDIT_ACCOUNT,
    payLoad: {
      id,
      data
    }
  };
}

export default (state = initialState, { type, payLoad, error }) => {
  switch (type) {
    case LOAD_ACCOUNTS + START:
      return {
        ...state,
        loading: true,
        error: null
      };
    case LOAD_ACCOUNTS + SUCCESS:
      return {
        ...state,
        data: { ...payLoad },
        loading: false
      };
    case LOAD_ACCOUNTS + FAIL:
      return {
        ...state,
        loading: false,
        error
      };
    case EDIT_ACCOUNT + START:
      return {
        ...state,
        loading: true
      };
    case EDIT_ACCOUNT + SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [payLoad.id]: {
            ...payLoad.data,
            id: payLoad.id
          }
        },
        loading: false
      };
    case EDIT_ACCOUNT + FAIL:
      return {
        ...state,
        loading: false,
        error
      };
    default:
      return state;
  }
};
