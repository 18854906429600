import {
  INGREDIENT_COVER,
  INGREDIENT_ICON,
  RECIPE_COOKINGSTEPS_COVER,
  RECIPE_COVER,
  RECIPE_TECHCOVER,
  SERVER_URL,
  TAG_ICON,
  UPLOADS_URL
} from "../../commons/constants";
import fecthAPI from "../../commons/api";

export const postRecipeCover = data =>
  fecthAPI.postFile(`${SERVER_URL}/${UPLOADS_URL}/${RECIPE_COVER}`, data);

export const postRecipeTechCover = data =>
  fecthAPI.postFile(`${SERVER_URL}/${UPLOADS_URL}/${RECIPE_TECHCOVER}`, data);

export const postRecipeCookingStepsCover = data =>
  fecthAPI.postFile(
    `${SERVER_URL}/${UPLOADS_URL}/${RECIPE_COOKINGSTEPS_COVER}`,
    data
  );

export const postIngredientCover = data =>
  fecthAPI.postFile(`${SERVER_URL}/${UPLOADS_URL}/${INGREDIENT_COVER}`, data);

export const postIngredientIcon = data =>
  fecthAPI.postFile(`${SERVER_URL}/${UPLOADS_URL}/${INGREDIENT_ICON}`, data);

export const postTagIcon = data =>
  fecthAPI.postFile(`${SERVER_URL}/${UPLOADS_URL}/${TAG_ICON}`, data);
