import React, { Component, Fragment } from "react";
import { createPortal } from "react-dom";
import "../../commons/styles/Icon.scss";
import IconButton from "@material-ui/core/IconButton";
import { AddPhotoAlternate } from "@material-ui/icons";

class IconPicker extends Component {
  state = {
    fullScreen: false
  };

  handlePreviewClick = () => {
    const { data, fullScreen } = this.state;
    if (!data) {
      return;
    }
    this.setState({ fullScreen: !fullScreen });
  };

  rightSize = (file, data) => {
    const { afterLoad } = this.props;
    afterLoad(file);
  };

  wrongSize = () => {
    alert("Неверный размер изображения");
  };

  checkRightSize = (width, height) => {
    const { checkRightSize } = this.props;
    if (checkRightSize)
      return (
        this.defaultRightSize(width, height) && checkRightSize(width, height)
      );
    return this.defaultRightSize(width, height);
  };

  defaultRightSize = (width, height) => {
    return width >= 15 && width <= 2048 && height >= 15 && height <= 2048;
  };

  handleFileChange = event => {
    const { files } = event.target;
    if (files && files[0]) {
      const reader = new FileReader();
      reader.onload = event => {
        const image = new Image();
        image.src = event.target.result;
        const parentThis = this;
        image.onload = function() {
          if (parentThis.checkRightSize(this.width, this.height)) {
            parentThis.rightSize(files[0], reader.result);
          } else {
            parentThis.wrongSize();
          }
        };
      };

      reader.readAsDataURL(files[0]);
    }
  };

  render() {
    const { fullScreen } = this.props;
    const { className, data, name } = this.props;
    const backgroundImage = data ? { backgroundImage: `url(${data})` } : null;
    return (
      <Fragment>
        <div className={"icon-picker " + className}>
          <input
            id={name}
            type="file"
            accept="image/*"
            capture="camera"
            onClick={event => {
              event.target.value = null;
            }}
            onChange={this.handleFileChange}
            className="picker__input"
          />
          <div
            onClick={this.handlePreviewClick}
            className="icon-picker__preview"
            style={backgroundImage}
          >
            {data ? (
              <label
                htmlFor={name}
                className="icon-picker__label icon-picker__label_fill"
              />
            ) : (
              <div className="icon-picker__action">
                <IconButton style={{ padding: "0" }}>
                  <label
                    htmlFor={name}
                    className="icon-picker__label"
                    onClick={e => {
                      e.stopPropagation();
                    }}
                  >
                    <AddPhotoAlternate fontSize="inherit" />
                  </label>
                </IconButton>
              </div>
            )}
          </div>
        </div>
        {fullScreen &&
          createPortal(
            <div
              className="image-fullscreen__wrapper"
              onClick={this.handlePreviewClick}
            >
              <img src={data} className="image-fullscreen" />
            </div>,
            document.querySelector("#image-dom")
          )}
      </Fragment>
    );
  }
}

export default IconPicker;
