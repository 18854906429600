import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { deleteMenu } from "../ducks";
import ListControls from "../../../components/ListControls/ListControls";

class Menu extends Component {
  getControlsButtons = () => {
    const { deleteMenu, id, handleEdit } = this.props;
    return [
      {
        title: "Редактировать",
        handleClick: e => handleEdit(e, id)
      },
      {
        title: "Удалить",
        handleClick: deleteMenu,
        isConfirm: true,
        confirmTitle: "Подтвердить удаление"
      }
    ];
  };

  render() {
    const {
      id,
      title,
      description,
      proportions,
      caloriesMin,
      caloriesMax
    } = this.props;
    return (
      <div className="table-list__row table-list__row_menu">
        <Link
          className="table-list__name table-list__name_link"
          to={`menu/${id}/month`}
        >
          {title}
        </Link>
        <div>{description}</div>
        <div>
          {caloriesMin && caloriesMax
            ? `${caloriesMin} - ${caloriesMax}`
            : proportions && proportions.length
            ? // proportion может быть null
              proportions
                .map(proportion =>
                  proportion ? proportion.kilocalories : null
                )
                .join(", ")
            : null}
        </div>
        <ListControls id={id} buttons={this.getControlsButtons()} />
      </div>
    );
  }
}

export default connect(
  null,
  { deleteMenu }
)(Menu);
