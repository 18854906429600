import { createSelector } from "reselect";

const tagsGetter = state => state.tags;
const tagsPropsGetter = (state, ownProps) => ownProps;

export const tagsSelector = createSelector(
  tagsGetter,
  ({ data }) => data || {}
);

export const tagsByIdSelector = createSelector(
  tagsGetter,
  tagsPropsGetter,
  ({ data }, props) => {
    return data[props.id] || {};
  }
);

export const tagsChildByIdSelector = createSelector(
  tagsGetter,
  tagsPropsGetter,
  ({ data }, props) => {
    return data[props.id].childContainer[props.childId] || {};
  }
);

export const antiTagsSelector = createSelector(
  tagsGetter,
  ({ antiTags }) => antiTags || {}
);

export const friendlyTagsSelector = createSelector(
  tagsGetter,
  ({ friendlyTags }) => friendlyTags || {}
);

export const filteredTagsSelector = createSelector(
  tagsGetter,
  ({ filteredData }) => filteredData
);

export const isLoadingTagsSelector = createSelector(
  tagsGetter,
  ({ loading }) => {
    return loading;
  }
);

export const isLoadingAntiTagsSelector = createSelector(
  tagsGetter,
  ({ loadingAntiTags }) => {
    return loadingAntiTags;
  }
);

export const isLoadingTagsRecipeSelector = createSelector(
  tagsGetter,
  ({ loadingRecipe }) => {
    return loadingRecipe;
  }
);
