import React, { Component } from "react";
import ShowForm from "../../../components/Form/ShowForm";
import { CALORIES_MEASURE } from "../../../commons/constants";
import { weightTypes } from "../utils";
import {
  celluloseShema,
  numberNutriensShema,
  numberPositiveShema,
  stringShortShema
} from "../shema";
import ImagePicker from "../../../components/ImagePicker/ImagePicker";
import { connect } from "react-redux";
import { isLoadingCoverSelector } from "../../Cover/selectors";
import {
  editIngredientCoverLast,
  editIngredientIconLast
} from "../../Cover/ducks";

//все поля обязательные, нельзя перейти дальше, пока не заполнил
// проверка на ввод бжу и калорий через точку или запятую

class FirstPart extends Component {
  state = {
    formCollection: []
  };
  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      title,
      alternativeTitles,
      kilocaloriesPerHundredGrams,
      cellulosePerHundredGrams,
      handleChangeNutrients,
      nutrients,
      description,
      internalDescription,
      gramsPerVolumeUnit,
      gramsPerVolumeUnitAmount,
      manufacturer,
      barcode
    } = nextProps;
    return {
      formCollection: [
        {
          type: "input_string",
          name: `title`,
          value: title,
          placeholder: "Название",
          className: "adding-ingredient__name",
          shema: stringShortShema
        },
        {
          type: "input_string",
          name: `alternativeTitles`,
          value: alternativeTitles,
          placeholder: "Альтернативное название",
          className: "adding-ingredient__extraName",
          shema: stringShortShema
        },
        {
          type: "input_number",
          name: `kilocaloriesPerHundredGrams`,
          value: kilocaloriesPerHundredGrams,
          placeholder: `Ккал в ${CALORIES_MEASURE}г`,
          className: "adding-ingredient__calories",
          shema: numberPositiveShema
        },
        {
          type: "input_number",
          name: `gramsPerVolumeUnitAmount`,
          value: gramsPerVolumeUnitAmount,
          placeholder: "Грамм в ед.",
          className: "adding-ingredient__grams"
        },
        {
          type: "select",
          name: `gramsPerVolumeUnit`,
          value:
            gramsPerVolumeUnit &&
            weightTypes.find(type => type.name === gramsPerVolumeUnit).title,
          placeholder: "Ед. изм.",
          values: [...weightTypes.map(weightType => weightType.name)],
          options: [...weightTypes.map(weightType => weightType.title)],
          disable: true,
          className: "adding-ingredient__measure"
        },
        {
          type: "input_number",
          name: `cellulosePerHundredGrams`,
          value: cellulosePerHundredGrams,
          placeholder: "Клетч",
          className: "adding-ingredient__cellulose",
          shema: celluloseShema
        },
        {
          type: "input_number",
          name: `proteins`,
          value: nutrients.proteins && nutrients.proteins.amountPerHundredGrams,
          placeholder: "Б",
          className: "adding-ingredient__proteins",
          handleChange: handleChangeNutrients,
          shema: numberNutriensShema
        },
        {
          type: "input_number",
          name: `fats`,
          value: nutrients.fats && nutrients.fats.amountPerHundredGrams,
          placeholder: "Ж",
          className: "adding-ingredient__fats",
          handleChange: handleChangeNutrients,
          shema: numberNutriensShema
        },
        {
          type: "input_number",
          name: `carbohydrates`,
          value:
            nutrients.carbohydrates &&
            nutrients.carbohydrates.amountPerHundredGrams,
          placeholder: "У",
          className: "adding-ingredient__carbohydrates",
          handleChange: handleChangeNutrients,
          shema: numberNutriensShema
        },
        {
          type: "input_string",
          name: `barcode`,
          value: barcode,
          placeholder: "штрихкод",
          className: "adding-ingredient__barcode"
        },
        {
          type: "input_string",
          name: `manufacturer`,
          value: manufacturer,
          placeholder: "Изготовитель",
          className: "adding-ingredient__manufacturer"
        },
        {
          type: "input_string",
          name: `description`,
          value: description,
          placeholder: "Описание",
          className: "adding-ingredient__description"
        },
        {
          type: "textarea",
          name: `internalDescription`,
          value: internalDescription,
          placeholder: "Дополнительное описание",
          className: "adding-ingredient__extraDescription"
        }
      ]
    };
  }

  checkRightSizeOfIcon = (width, height) => {
    return width === height;
  };

  render() {
    const { formCollection } = this.state;
    const {
      handleChange,
      errors,
      cover,
      icon,
      loadingCover,
      editIngredientCoverLast,
      editIngredientIconLast
    } = this.props;
    return (
      <div className="adding-ingredient__first">
        <h4 className="form__title adding-ingredient__title">
          Добавление ингредиента
        </h4>
        <ImagePicker
          name="cover"
          title="Нажмите для добавления фотографии"
          loading={loadingCover}
          data={cover}
          afterLoad={data => editIngredientCoverLast(data)}
          afterClear={() => editIngredientCoverLast("")}
          className={"adding-ingredient__cover"}
          isNotPickLinkedCover
        />
        <ImagePicker
          name="icon"
          title="Нажмите для добавления иконки"
          data={icon}
          className={"adding-ingredient__icon"}
          afterLoad={data => editIngredientIconLast(data)}
          afterClear={() => editIngredientIconLast("")}
          checkRightSize={this.checkRightSizeOfIcon}
          isNotPickLinkedCover
        />
        <ShowForm
          formCollection={formCollection}
          handleChange={handleChange}
          errors={errors}
        />
      </div>
    );
  }
}

export default connect(
  state => ({
    loadingCover: isLoadingCoverSelector(state)
  }),
  { editIngredientCoverLast, editIngredientIconLast }
)(FirstPart);
