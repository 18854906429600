import React, { Component, Fragment } from "react";
import Input from "../Input/Input";
import TextArea from "../Textarea/TextArea";
import Select from "../Select/Select";
import Label from "../Label/Label";
import InputNumber from "../Input/InputNumber";
import InputString from "../Input/InputString";
import InputPhone from "../Input/InputPhone";

class ShowForm extends Component {
  getInputProps(widget, index) {
    const { handleChange, errors } = this.props;
    return {
      key: widget.key || widget.name,
      placeholder: widget.placeholder,
      name: widget.name,
      value: widget.value,
      handleChange: widget.handleChange || handleChange,
      id: widget.id,
      error: errors && errors[widget.name],
      isValid: errors && widget.value && !errors[widget.name],
      ...widget
    };
  }
  render() {
    const { handleChange, errors, formCollection } = this.props;
    const formList = formCollection.map((widget, index) => {
      switch (widget.type) {
        case "input":
          return <Input {...this.getInputProps(widget, index)} />;
        case "input_number":
          return <InputNumber {...this.getInputProps(widget, index)} />;
        case "input_string":
          return <InputString {...this.getInputProps(widget, index)} />;
        case "input_phone":
          return <InputPhone {...this.getInputProps(widget, index)} />;
        case "textarea":
          return (
            <TextArea
              key={widget.key || widget.name}
              placeholder={widget.placeholder}
              name={widget.name}
              value={widget.value}
              handleChange={formCollection.handleChange || handleChange}
              id={widget.id}
              error={
                errors &&
                Object.keys(errors).find(error => error === widget.name)
              }
              isValid={
                errors &&
                !Object.keys(errors).find(error => error === widget.name)
              }
              {...widget}
            />
          );
        case "select":
          return (
            <Select
              key={widget.key || widget.name}
              placeholder={widget.placeholder}
              name={widget.name}
              value={widget.value}
              handleChange={handleChange}
              options={widget.options}
              error={
                errors &&
                Object.keys(errors).find(error => error === widget.name)
              }
              isValid={
                errors &&
                !Object.keys(errors).find(error => error === widget.name)
              }
              {...widget}
            />
          );
        case "label":
          return (
            <Label
              key={widget.key || widget.name}
              name={widget.name}
              value={widget.value}
              {...widget}
            />
          );
      }
    });
    return <Fragment>{formList}</Fragment>;
  }
}

export default ShowForm;
