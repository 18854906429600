import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Wizard from "../../../HOCs/Wizard";
import FirstPart from "./FirstPart";
import PropTypes from "prop-types";
import { createMenu, editMenu } from "../ducks";
import { getChangedArrayOfObjects } from "../../../commons/utils";
import withConfirmModal from "../../../HOCs/withConfirmModal";

class AddMenu extends Component {
  constructor(props) {
    super(props);
    const {
      title,
      description,
      proportions,
      id,
      mealTypes,
      caloriesName
    } = props;
    this.state = {
      id: id || "",
      title: title || "",
      description: description || "",
      mealTypes: mealTypes || [],
      caloriesName: caloriesName || "",
      proportions: proportions
        ? proportions.map(proportion => ({
            id: proportion.id,
            kilocalories: proportion.kilocalories
          }))
        : [
            {
              id: "1",
              kilocalories: ""
            }
          ]
    };
  }

  componentWillReceiveProps(nextProps) {
    const { editMenu, createMenu, toggleSubmit } = this.props;
    if (nextProps.isSubmit === true) {
      const { id } = this.state;
      const data = this.prepareData();
      const proportions = this.prepareProportions();
      id
        ? editMenu(id, {
            ...data,
            proportions: this.props.proportions
          })
        : createMenu(data, proportions);
      toggleSubmit();
    }
  }

  prepareData = () => {
    const { title, description, mealTypes, caloriesName } = this.state;
    return {
      title,
      description,
      mealTypes,
      caloriesName
    };
  };

  prepareProportions = () => {
    const { proportions } = this.state;
    return proportions
      .filter(({ kilocalories }) => +kilocalories)
      .map(({ kilocalories }) => +kilocalories);
  };

  handleChange = e => {
    const { value, name } = e.target;
    this.setState({ [name]: value });
  };

  handleChangeMealTypes = e => {
    const { name } = e.target;
    this.setState(({ mealTypes }) => {
      const isHaveMealType = mealTypes.find(mealType => mealType === name);
      return {
        mealTypes: isHaveMealType
          ? mealTypes.filter(mealType => mealType !== name)
          : [...mealTypes, name]
      };
    });
  };

  addCalorie = e => {
    e.preventDefault();
    this.setState(({ proportions }) => ({
      proportions: proportions.concat([
        {
          id: `${Date.now()}`,
          kilocalories: ""
        }
      ])
    }));
  };

  handleChangeCalories = e => {
    const { value, name, dataset } = e.target;
    this.setState(({ proportions }) => {
      const arr = getChangedArrayOfObjects(
        proportions,
        dataset.id,
        name,
        value
      );
      return {
        proportions: arr
      };
    });
  };

  handleDeleteCalorie = (e, id) => {
    e.preventDefault();
    this.setState(({ proportions }) => ({
      proportions: proportions.filter(proportion => {
        return proportion.id !== id;
      })
    }));
  };

  render() {
    const { currentPart, isEditView } = this.props;
    const partList = [
      <FirstPart
        {...this.state}
        isEditView={isEditView}
        handleChange={this.handleChange}
        addCalorie={this.addCalorie}
        handleChangeCalories={this.handleChangeCalories}
        handleDeleteCalorie={this.handleDeleteCalorie}
        handleChangeMealTypes={this.handleChangeMealTypes}
      />
    ];
    return <Fragment>{partList[currentPart - 1]}</Fragment>;
  }
}

AddMenu.propTypes = {
  currentPart: PropTypes.number,
  isSubmit: PropTypes.bool
};

export default connect(
  null,
  { editMenu, createMenu }
)(withConfirmModal(Wizard(AddMenu)));
