import React, { Component } from "react";
import IconButton from "@material-ui/core/IconButton";
import { ArrowBack } from "@material-ui/icons";
import InputNumber from "../../../components/Input/InputNumber";
import { Button } from "@material-ui/core";
import { allIngredientsCalc } from "../../Ingredients/utils";
import { connect } from "react-redux";
import { recipeByIdSelector } from "../selectors";
import { recipeProportionByIdSelector } from "../../RecipeProportions/selectors";
import { ingredientsSelector } from "../../Ingredients/selectors";
import { rounding } from "../../../commons/utils";
import { withCalcDoseAmount } from "../../RecipeProportions/utils";

class SelectRecipeWeight extends Component {
  constructor(props) {
    super(props);
    const { recipeProportion, recipe, ingredients } = props;
    const weight = allIngredientsCalc(
      recipeProportion.doses
        ? withCalcDoseAmount(
            recipeProportion.doses.map(dose => ({
              ...ingredients[dose.ingredient],
              ...dose
            })),
            recipe
          )
        : []
    );
    this.state = {
      weight: rounding(
        weight.amountChanged *
          (recipe.massChange ? recipe.massChange / 100 : 1),
        10
      )
    };
  }

  handleChange = ({ target: { value, name } }) => {
    this.setState({
      [name]: value
    });
  };

  render() {
    const { recipe, handleBack, handleSelectWeight } = this.props;
    const { weight } = this.state;
    return (
      <div className="select-recipe__weight">
        <div className="select-recipe__header">
          <IconButton onClick={handleBack}>
            <ArrowBack />
          </IconButton>
          <div className="select-recipe__title">{recipe.title}</div>
        </div>
        <InputNumber
          name="weight"
          value={weight}
          handleChange={this.handleChange}
          placeholder="Вес рецепта в меню"
        />
        <div className="select-recipe__btn">
          <Button
            variant="contained"
            onClick={() => handleSelectWeight(weight)}
          >
            Добавить рецепт
          </Button>
        </div>
      </div>
    );
  }
}

export default connect((state, ownProps) => ({
  recipe: recipeByIdSelector(state, ownProps),
  recipeProportion: recipeProportionByIdSelector(state, {
    id: ownProps.proportion
  }),
  ingredients: ingredientsSelector(state)
}))(SelectRecipeWeight);
