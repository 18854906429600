import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { proportionMealsSelector } from "../selectors";
import MealDay from "./MealDay";
import { compareDates, getDateFromWeek } from "../../../commons/utils";

class MealsDay extends Component {
  render() {
    const {
      day,
      meals,
      currentWeek,
      currentCalorie,
      menu,
      proportion
    } = this.props;
    const mealsDay = menu.mealTypes.map(type => (
      <MealDay
        key={`${day.name}${type}`}
        currentCalorie={currentCalorie}
        menuId={menu.id}
        proportionId={proportion && proportion.id}
        type={type}
        currentDate={getDateFromWeek(currentWeek, day.id)}
        meal={Object.values(meals).find(meal => {
          const dateTime = new Date(meal.date);
          return (
            meal.mealType === type &&
            compareDates(
              dateTime,
              new Date(
                currentWeek.year,
                currentWeek.prevMonth,
                currentWeek.start + (day.id === 0 ? 6 : day.id - 1)
              )
            )
          );
        })}
      />
    ));
    return <Fragment>{mealsDay}</Fragment>;
  }
}

export default connect((state, ownProps) => ({
  meals: proportionMealsSelector(state, ownProps)
}))(MealsDay);
