import { FAIL, START, SUCCESS } from "../../commons/constants";

const initialState = {
  data: {},
  loading: false,
  loaded: false,
  error: false
};

export const LOAD_PROFILES = "LOAD_PROFILES";

export function loadProfiles(id) {
  return {
    type: LOAD_PROFILES,
    id
  };
}

export default (state = initialState, { type, payLoad }) => {
  switch (type) {
    case LOAD_PROFILES + START:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: null
      };
    case LOAD_PROFILES + SUCCESS:
      return {
        ...state,
        data: { ...payLoad },
        loading: false,
        loaded: true
      };
    case LOAD_PROFILES + FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: payLoad.data
      };
    default:
      return state;
  }
};
