import React, { Component } from "react";
import SearchWrapper from "../../../components/Search/SearchWrapper";
import FilterButton from "../../../components/Button/FilterButton";
import Label from "../../../components/Label/Label";
import { statuses } from "../utils";
import InputRadio from "../../../components/Input/InputRadio";

class Filter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: {
        ...props.filters
      }
    };
  }

  afterSearch = title => {
    this.setState(
      ({ filters }) => ({
        filters: {
          ...filters,
          title
        }
      }),
      () => this.props.handleChangeFilter(this.state.filters)
    );
  };

  changeRadio = e => {
    const { value } = e.target;
    this.setState(
      ({ filters }) => ({
        filters: {
          ...filters,
          status: value
        }
      }),
      () => this.props.handleChangeFilter(this.state.filters)
    );
  };

  getFilters(curValue, arrValues, name) {
    let filters = [...arrValues];
    filters.push({
      name: "",
      title: "Все"
    });
    filters = filters.map((filter, index) => (
      <li key={index}>
        <InputRadio
          name={name}
          value={filter.name}
          handleChange={this.changeRadio}
          checked={curValue === filter.name}
          label={filter.title}
        />
      </li>
    ));
    return filters;
  }

  render() {
    const {
      filters: { status }
    } = this.state;
    const statusFilters = this.getFilters(status, statuses, "status");
    return (
      <div className="header-content__filter">
        <SearchWrapper
          placeholder="Найти рецепт"
          className="search__main"
          afterSearch={this.afterSearch}
          minWordLength={3}
          time={400}
        />
        <FilterButton>
          <div className="container-filter__item">
            <Label>Статус:</Label>
            <ul className="container-filter__list">{statusFilters}</ul>
          </div>
        </FilterButton>
      </div>
    );
  }
}

export default Filter;
