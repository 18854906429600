import { FAIL, START, SUCCESS } from "../../commons/constants";

const initialState = {
  data: {},
  loading: false,
  loaded: false,
  error: false
};

export const LOAD_COMMENTS = "LOAD_COMMENTS";
export const LOAD_COMMENT = "LOAD_COMMENT";
export const PUBLISH_COMMENT = "PUBLISH_COMMENT";
export const UNPUBLISH_COMMENT = "UNPUBLISH_COMMENT";
export const DELETE_COMMENT = "DELETE_COMMENT";
export const COMMENT_CHANGE_STATUS = "COMMENT_CHANGE_STATUS";

export function loadComments(id, filters) {
  return {
    type: LOAD_COMMENTS,
    id,
    filters
  };
}

export function publishComment(id) {
  return {
    type: PUBLISH_COMMENT,
    payLoad: {
      id
    }
  };
}

export function unpublishComment(id) {
  return {
    type: UNPUBLISH_COMMENT,
    payLoad: {
      id
    }
  };
}

export function deleteComment(id) {
  return {
    type: DELETE_COMMENT,
    payLoad: {
      id
    }
  };
}

export default (state = initialState, { type, payLoad, error }) => {
  switch (type) {
    case LOAD_COMMENTS + START:
      return {
        ...state,
        loading: true,
        error: null
      };
    case LOAD_COMMENTS + SUCCESS:
      return {
        ...state,
        data: { ...payLoad },
        loading: false
      };
    case LOAD_COMMENTS + FAIL:
      return {
        ...state,
        loading: false,
        error
      };
    case LOAD_COMMENT + START:
      return {
        ...state,
        loading: true
      };
    case LOAD_COMMENT + SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [payLoad.id]: {
            ...payLoad.data
          }
        },
        loading: false
      };
    case LOAD_COMMENT + FAIL:
      return {
        ...state,
        loading: false,
        error
      };
    case COMMENT_CHANGE_STATUS + START:
      return {
        ...state,
        loading: true
      };
    case COMMENT_CHANGE_STATUS + SUCCESS:
      return {
        ...state,
        loading: false
      };
    case COMMENT_CHANGE_STATUS + FAIL:
      return {
        ...state,
        loading: false,
        error
      };
    default:
      return state;
  }
};
