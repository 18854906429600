import React, { Component } from "react";
import TextArea from "../../../components/Textarea/TextArea";
import ImagePicker from "../../../components/ImagePicker/ImagePicker";
import connect from "react-redux/es/connect/connect";
import { editRecipeCover } from "../../Cover/ducks";

class Description extends Component {
  changeCover = data => {
    const { editRecipeCover, recipe } = this.props;
    editRecipeCover(recipe.id, data);
  };

  render() {
    const { handleChange, recipe, disabled, description } = this.props;
    return (
      <div className="tail-dish__property property-dish__description">
        <ImagePicker
          name={`${recipe.id}_description`}
          data={recipe.cover && recipe.cover.path}
          afterLoad={this.changeCover}
          afterClear={this.changeCover}
          disabled={disabled}
        />
        <TextArea
          name="description"
          value={description}
          placeholder="Описание"
          handleChange={handleChange}
          disabled={disabled}
        />
      </div>
    );
  }
}

export default connect(
  null,
  { editRecipeCover }
)(Description);
