import {
  APPROVED_DRAFT,
  ARTICLE,
  DELETED,
  DRAFT,
  NEWS,
  PUBLISHED
} from "../../commons/constants";

export const statuses = [
  {
    name: DRAFT,
    title: "Черновик"
  },
  {
    name: APPROVED_DRAFT,
    title: "Черновик утверждён"
  },
  {
    name: PUBLISHED,
    title: "Опубликован"
  },
  {
    name: DELETED,
    title: "Удален"
  }
];

export const categories = [
  {
    name: ARTICLE,
    title: "Статьи"
  },
  {
    name: NEWS,
    title: "Новости"
  }
];
