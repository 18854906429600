import React, { Component } from "react";
import { connect } from "react-redux";
import MenuProportionRoute from "./MenuProportionRoute";
import {
  clearMenuProportions,
  loadMenuProportions,
  setCurrentMenuProportion
} from "./ducks";
import { clearMeals } from "../Meals/ducks";
import {
  isLoadedMenuProportionsSelector,
  isLoadingMenuProportionsSelector
} from "./selectors";
import Preloader from "../../HOCs/Preloader";

class MenuProportionsContent extends Component {
  componentDidMount() {
    const { loadMenuProportions, menu } = this.props;
    loadMenuProportions(menu.id);
  }

  componentWillUnmount() {
    const { clearMeals, clearMenuProportions } = this.props;
    clearMenuProportions();
    clearMeals();
  }

  handleSelectProportion = e => {
    const { value } = e.target;
    this.props.setCurrentMenuProportion(value);
  };

  render() {
    const { match, menu, loading, loaded } = this.props;
    // console.log(loading || !loaded);
    // if (loading || !loaded) return <Preloader />;
    return (
      <MenuProportionRoute
        menu={menu}
        match={match}
        handleSelectProportion={this.handleSelectProportion}
      />
    );
  }
}

export default connect(
  state => ({
    loading: isLoadingMenuProportionsSelector(state),
    loaded: isLoadedMenuProportionsSelector(state)
  }),
  {
    loadMenuProportions,
    clearMenuProportions,
    clearMeals,
    setCurrentMenuProportion
  }
)(Preloader(MenuProportionsContent));
