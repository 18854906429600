export const menuTypes = [
  {
    name: "breakfast",
    value: "Завтрак"
  },
  {
    name: "brunch",
    value: "Перекус1"
  },
  {
    name: "lunch",
    value: "Обед"
  },
  {
    name: "supper",
    value: "Перекус2"
  },
  {
    name: "dinner",
    value: "Ужин"
  }
];
