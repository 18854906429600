import { createSelector } from "reselect";

const recipeProportionsGetter = state => state.recipeProportions;
const recipeProportionGetter = (state, ownProps) => {
  return state.recipeProportions.data[ownProps.id];
};

const lastChangedRecipeProportionsGetter = state => {
  return state.recipeProportions.lastChangedData;
};

const lastChangedRecipeProportionGetter = (state, ownProps) => {
  return state.recipeProportions.lastChangedData.find(id => id === ownProps.id);
};

export const recipeProportionsSelector = createSelector(
  recipeProportionsGetter,
  ({ data }) => {
    return data || {};
  }
);

export const statusRecipeProportionsSelector = createSelector(
  recipeProportionsGetter,
  ({ statusData }) => {
    return statusData || {};
  }
);

export const recipeProportionByIdSelector = createSelector(
  recipeProportionGetter,
  recipeProportion => {
    return recipeProportion || {};
  }
);

export const isLoadingRecipeProportionsSelector = createSelector(
  recipeProportionsGetter,
  ({ loading }) => {
    return loading;
  }
);

export const isLoadedRecipeProportionsSelector = createSelector(
  recipeProportionsGetter,
  ({ loaded }) => {
    return loaded;
  }
);

export const getLastChangedRecipeProportions = createSelector(
  recipeProportionsGetter,
  lastChangedRecipeProportionsGetter,
  ({ data }, lastChangedRecipeProportions) => {
    const res = [];
    lastChangedRecipeProportions.forEach(lastChangedRecipeProportion => {
      if (data[lastChangedRecipeProportion])
        res.push(data[lastChangedRecipeProportion]);
    });
    return res;
  }
);

export const isLastChangedRecipeProportion = createSelector(
  lastChangedRecipeProportionGetter,
  lastChangedRecipeProportion => {
    return !!lastChangedRecipeProportion;
  }
);

export const isEmptyLastChangedRecipeProportions = createSelector(
  lastChangedRecipeProportionsGetter,
  lastChangedRecipeProportions => {
    return !lastChangedRecipeProportions.length;
  }
);
