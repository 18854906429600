import React, { Component } from "react";

const withPagination = WrappedComponent =>
  class extends Component {
    state = {
      filters: {
        page: 1,
        count: 20
      }
    };

    handleChangeFilter = filter => {
      this.changeFilter("page", 1);
      this.changeExternalFilter(filter, true);
    };

    changeExternalFilter = (filter, isFetch) => {
      this.setState(
        ({ filters }) => ({
          filters: {
            ...filters,
            ...filter
          }
        }),
        () => isFetch && this.props.afterChangeFilter(this.state.filters)
      );
    };

    changeFilter = (name, value, isFetch) => {
      this.setState(
        ({ filters }) => ({
          filters: {
            ...filters,
            [name]: value
          }
        }),
        () => isFetch && this.props.afterChangeFilter(this.state.filters)
      );
    };

    handleChangePage = (e, page) => this.changeFilter("page", page + 1, true);

    handleChangeRowsPerPage = ({ target: { value } }) => {
      this.changeFilter("count", value);
      this.changeFilter("page", 1, true);
    };

    render() {
      return (
        <WrappedComponent
          {...this.props}
          filters={this.state.filters}
          handleChangeFilter={this.handleChangeFilter}
          handleChangePage={this.handleChangePage}
          handleChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
      );
    }
  };

export default withPagination;
