import { normaliseToFetch } from "./normalisers";
import { INGREDIENTS_URL, SERVER_URL } from "../../commons/constants";
import fecthAPI from "../../commons/api";
import { setQueryFilterString } from "../../commons/utils";

export const getIngredients = filter => {
  return fecthAPI.get(
    `${SERVER_URL}/${INGREDIENTS_URL}${setQueryFilterString(filter)}`
  );
};

export const getIngredient = id =>
  fecthAPI.get(`${SERVER_URL}/${INGREDIENTS_URL}/${id}`);

export const postIngredient = data =>
  fecthAPI.post(`${SERVER_URL}/${INGREDIENTS_URL}`, normaliseToFetch(data));

export const putIngredient = (id, data) =>
  fecthAPI.put(
    `${SERVER_URL}/${INGREDIENTS_URL}/${id}`,
    normaliseToFetch(data)
  );

export const deleteIngredientAPI = id =>
  fecthAPI.delete(`${SERVER_URL}/${INGREDIENTS_URL}/${id}`);
