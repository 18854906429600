import React, { Component } from "react";
import ShowForm from "../../../components/Form/ShowForm";
import { categories } from "../utils";

class MainPart extends Component {
  state = {
    formCollection: []
  };
  static getDerivedStateFromProps(nextProps, prevState) {
    const { title, content, shortContent, category } = nextProps;
    return {
      formCollection: [
        {
          type: "input_string",
          name: `title`,
          value: title,
          placeholder: "Название",
          className: "adding-material__title",
          required: true
        },
        {
          type: "textarea",
          name: `content`,
          value: content,
          placeholder: "Содержание",
          className: "adding-material__content",
          required: true
        },
        {
          type: "textarea",
          name: `shortContent`,
          value: shortContent,
          placeholder: `Краткое содержание`,
          className: "adding-material__shortContent",
          required: true
        },
        {
          type: "select",
          name: `category`,
          value:
            category && categories.find(type => type.name === category).title,
          placeholder: "Категория",
          values: [...categories.map(weightType => weightType.name)],
          options: [...categories.map(weightType => weightType.title)],
          disable: true,
          className: "adding-material__category"
        }
      ]
    };
  }

  render() {
    const { formCollection } = this.state;
    const { handleChange } = this.props;
    return (
      <div className="adding-material__main">
        <h4 className="form__title adding-material__title">
          Добавление материала
        </h4>
        <ShowForm formCollection={formCollection} handleChange={handleChange} />
      </div>
    );
  }
}

export default MainPart;
