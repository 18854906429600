import React, { Component } from "react";
import classes from "./style.module.scss";
import Input from "../../../components/Input/Input";

class Step extends Component {
  state = {
    formCollection: []
  };
  static getDerivedStateFromProps(nextProps, prevState) {
    const { kilocalories, id } = nextProps;
    return {
      formCollection: [
        {
          type: "input",
          id: id,
          name: `kilocalories`,
          value: kilocalories,
          placeholder: "Калорийность"
        }
      ]
    };
  }
  render() {
    const { handleChange, deleteCalorie, id, kilocalories } = this.props;
    return (
      <div className={classes["calorie__wrapper"]}>
        <div className={classes["calorie__field"]}>
          <Input
            id={id}
            name="kilocalories"
            value={kilocalories}
            placeholder="Калорийность"
            handleChange={handleChange}
          />
        </div>
        <button
          onClick={e => deleteCalorie(e, id)}
          className={`${classes["calorie__btn"]} ${
            classes["calorie__btn_delete"]
          }`}
        >
          Удалить
        </button>
      </div>
    );
  }
}

export default Step;
