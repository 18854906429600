import { createSelector } from "reselect";
import { objFilter } from "../../commons/utils";

const filtersGetter = state => {
  return state.filters;
};
const menusGetter = state => state.menus;
const menuGetter = (state, ownProps) => state.menus.data[ownProps.id];

export const menusSelector = createSelector(
  menusGetter,
  ({ data }) => data
);

export const filtredMenusSelector = createSelector(
  menusGetter,
  filtersGetter,
  ({ data }, filters) => {
    const { searchMenu } = filters;
    return data
      ? objFilter(data, menu => {
          return (
            !searchMenu ||
            menu.title.toLowerCase().includes(searchMenu.toLowerCase())
          );
        })
      : {};
  }
);

export const menuByIdSelector = createSelector(
  menuGetter,
  menu => menu || {}
);

export const isLoadingMenusSelector = createSelector(
  menusGetter,
  ({ loading }) => loading
);

export const isLoadedMenusSelector = createSelector(
  menusGetter,
  ({ loaded }) => loaded
);
