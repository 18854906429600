import { connect } from "react-redux";
import MenuProportions from "./MenuProportions";
import {
  currentMenuProportionKilocalories,
  menuProportionsSelector
} from "../selectors";
import {
  getCurrentDateSelector,
  isLoadingMealsSelector,
  mealsSelector
} from "../../Meals/selectors";
import { loadMeals, setCurrentDate, loadMealComments } from "../../Meals/ducks";
import { withRouter } from "react-router-dom";

const mapStateToProps = state => ({
  meals: mealsSelector(state),
  currentCalorie: currentMenuProportionKilocalories(state),
  proportions: menuProportionsSelector(state),
  loading: isLoadingMealsSelector(state),
  date: getCurrentDateSelector(state)
});

const mapDispatchToProps = {
  loadMeals,
  loadMealComments,
  setCurrentDate
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MenuProportions));
