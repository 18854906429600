import {
  COMMENTS_URL,
  MATERIALS_COMMENTS_URL,
  MATERIALS_URL,
  SERVER_URL
} from "../../commons/constants";
import fecthAPI from "../../commons/api";
import { setQueryString } from "../../commons/utils";

export const getComments = (id, filter) =>
  fecthAPI.get(
    `${SERVER_URL}/${MATERIALS_URL}/${id}/${MATERIALS_COMMENTS_URL}${
      filter
        ? setQueryString({
            name: "status",
            value: filter.status
          })
        : ""
    }`
  );

export const getComment = (materialId, id) =>
  fecthAPI.get(`${SERVER_URL}/${COMMENTS_URL}/${id}`);

export const publishComment = id =>
  fecthAPI.post(`${SERVER_URL}/${COMMENTS_URL}/${id}/publish`);

export const unpublishComment = id =>
  fecthAPI.post(`${SERVER_URL}/${COMMENTS_URL}/${id}/unpublish`);

export const deleteComment = id =>
  fecthAPI.delete(`${SERVER_URL}/${COMMENTS_URL}/${id}`);
