import { SERVER_URL, MENUS_URL } from "../../commons/constants";
import { normaliseToPostMenu, normaliseToPutMenu } from "./normalisers";
import fecthAPI from "../../commons/api";

export const getMenus = filter =>
  fecthAPI.get(`${SERVER_URL}/${MENUS_URL}${filter ? `?title=${filter}` : ""}`);

export const getMenu = id => fecthAPI.get(`${SERVER_URL}/${MENUS_URL}/${id}`);

export const postMenu = data =>
  fecthAPI.post(`${SERVER_URL}/${MENUS_URL}`, normaliseToPostMenu(data));

export const putMenu = (id, data) =>
  fecthAPI.put(`${SERVER_URL}/${MENUS_URL}/${id}`, normaliseToPutMenu(data));

export const deleteMenu = id =>
  fecthAPI.delete(`${SERVER_URL}/${MENUS_URL}/${id}`);
