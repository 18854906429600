import React, { Component } from "react";
import { connect } from "react-redux";
import {
  isLoadingMaterialsSelector,
  materialsDependOnStatusSelector
} from "./selectors";
import Preloader from "../../components/Preloader/Preloader";
import Material from "./Material";
import { loadMaterials } from "./ducks";
import { clearFilterMaterials } from "../Filters/ducks";

const columns = ["Название", "Статус", "", ""];

class MaterialsList extends Component {
  componentDidMount() {
    this.props.loadMaterials();
  }

  componentWillUnmount() {
    this.props.clearFilterMaterials();
  }

  handleEdit = (e, id) => {
    const { handleEdit, materials } = this.props;
    handleEdit({
      ...materials[id]
    });
  };

  render() {
    const { loading, materials, filters } = this.props;
    if (loading) return <Preloader />;
    const materialList = Object.values(materials).map(material => (
      <Material
        key={material.id}
        material={material}
        handleEdit={this.handleEdit}
        filters={filters}
      />
    ));
    let index = 0;
    return (
      <div>
        <div className="table-list table-list__material">
          <div className="table-list__row table-list__row_head">
            {columns.map(column => (
              <div key={index++}>{column}</div>
            ))}
          </div>
          {materialList}
        </div>
      </div>
    );
  }
}

export default connect(
  (state, ownProps) => ({
    materials: materialsDependOnStatusSelector(state, ownProps),
    loading: isLoadingMaterialsSelector(state)
  }),
  { loadMaterials, clearFilterMaterials }
)(MaterialsList);
