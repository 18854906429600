import React, { Component } from "react";
import { connect } from "react-redux";
import { deleteIngredient } from "./ducks";
import ListControls from "../../components/ListControls/ListControls";
import { isEmpty, rounding } from "../../commons/utils";

class Ingredient extends Component {
  getControlsButtons = () => {
    const { deleteIngredient, id, handleEdit } = this.props;
    return [
      {
        title: "Редактировать",
        handleClick: e => handleEdit(e, id)
      },
      {
        title: "Удалить",
        handleClick: deleteIngredient,
        isConfirm: true,
        confirmTitle: "Подтвердить удаление"
      }
    ];
  };
  render() {
    const {
      id,
      kilocaloriesPerHundredGrams,
      title,
      linkedRecipe,
      cellulosePerHundredGrams,
      nutrients
    } = this.props;
    const proteins = nutrients["proteins"];
    const fats = nutrients["fats"];
    const carbohydrates = nutrients["carbohydrates"];
    return (
      <div className="table-list__row table-list__row_ingredient">
        <div className="table-list__name">
          {title} {linkedRecipe ? "(рецепт)" : ""}
        </div>
        <div>
          {isEmpty(kilocaloriesPerHundredGrams)
            ? "-"
            : rounding(kilocaloriesPerHundredGrams)}
        </div>
        <div>
          {isEmpty(cellulosePerHundredGrams)
            ? "-"
            : rounding(cellulosePerHundredGrams)}
        </div>
        <div>
          {proteins && !isEmpty(proteins.amountPerHundredGrams)
            ? rounding(proteins.amountPerHundredGrams, 100)
            : "-"}
        </div>
        <div>
          {fats && !isEmpty(fats.amountPerHundredGrams)
            ? rounding(fats.amountPerHundredGrams, 100)
            : "-"}
        </div>
        <div>
          {carbohydrates && !isEmpty(carbohydrates.amountPerHundredGrams)
            ? rounding(carbohydrates.amountPerHundredGrams, 100)
            : "-"}
        </div>
        <ListControls id={id} buttons={this.getControlsButtons()} />
      </div>
    );
  }
}

export default connect(
  null,
  { deleteIngredient }
)(Ingredient);
