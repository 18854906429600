import React, { Component } from "react";
import PropTypes from "prop-types";
import { createPortal } from "react-dom";

const Dialog = DialogComponent => {
  return class extends Component {
    handleOutsideClick = e => {
      const { closeDialog } = this.props;
      let target = e.target;
      while (target.className !== "dialog") {
        if (target.className === "dialog__main") {
          return;
        }
        target = target.parentNode;
      }
      closeDialog();
    };
    render() {
      return createPortal(
        <div className="dialog" onClick={this.handleOutsideClick}>
          <div className="dialog__main">
            <DialogComponent {...this.props} />
          </div>
        </div>,
        document.querySelector("#dialog-dom")
      );
    }
  };
};

Dialog.propTypes = {
  closeDialog: PropTypes.func
};

export default Dialog;
