import React from "react";
import { objFilter } from "../../commons/utils";
import Search from "./Search";
import debounce from "lodash/debounce";
import Loader from "../../components/Preloader/Preloader";

class DropDownSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      isOpen: false,
      minWordLength: props.minWordLength || 0
    };
    this.debounceAfterSearch = debounce(
      value => props.afterSearch(value),
      props.time || 0,
      {
        leading: false,
        trailing: true
      }
    );
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleOutsideClick);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleOutsideClick);
  }

  openList = () => {
    this.setState({
      isOpen: true
    });
  };

  closeList = () => {
    this.setState({
      isOpen: false
    });
  };

  handleOutsideClick = ({ target }) => {
    if (!this.node.contains(target)) this.closeList();
  };

  handleChange = e => {
    const { value } = e.target;
    const { minWordLength, search } = this.state;
    let curValue = value;
    let isChangeSearch = false;
    if (value.length >= minWordLength) isChangeSearch = true;
    // if (search.length >= minWordLength && value.length < minWordLength) {
    //   isChangeSearch = true;
    //     curValue = "";
    // }
    this.setState(
      () => ({ search: value }),
      () => {
        if (isChangeSearch) this.debounceAfterSearch(curValue);
      }
    );
  };

  handleSelect = e => {
    const { afterSelect, items } = this.props;
    const id = e.target.dataset.id;
    this.setState(
      {
        search: "",
        isOpen: false
      },
      () => afterSelect(items[id])
    );
  };

  getList() {
    const { items, pickedItems, isRequesting } = this.props;
    const options = Object.values(
      objFilter(items, item => {
        return pickedItems && pickedItems.length
          ? !pickedItems.find(pick => pick === item.id)
          : true;
      })
    );
    let list = [];
    if (isRequesting) return <Loader small />;
    if (options && options.length) {
      list = options.map(option => (
        <li
          key={option.id}
          onClick={this.handleSelect}
          data-id={option.id}
          className="dropdownsearch__option"
        >
          {option.title}
        </li>
      ));
    }
    return list;
  }

  render() {
    const { search, isOpen } = this.state;
    const { placeholder, disabled } = this.props;
    const list = this.getList();
    return (
      <div className="dropdownsearch__wrapper" ref={node => (this.node = node)}>
        <Search
          handleChange={this.handleChange}
          onFocus={this.openList}
          value={search}
          placeholder={placeholder}
          disabled={disabled ? "disabled" : null}
          afterSearch={this.afterSearch}
          className="input"
        />
        {isOpen ? <ul className="dropdownsearch__select">{list}</ul> : null}
      </div>
    );
  }
}

export default DropDownSearch;
