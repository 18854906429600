import React, { Component } from "react";
import TagsCategoryMain from "../../TagsCategory/TagsCategoryMain";
import TagsList from "../../Tags/List/TagsList";

class TagsPart extends Component {
  isPickedTreeNode = (parent, child) => {
    const { selectedTags } = this.props;
    return selectedTags.find(
      selectedTag => selectedTag.parent === child || selectedTag.id === child
    );
  };
  // getPickedTreeNodes() {
  //   const { selectedTags } = this.props;
  //   const res = [];
  //   selectedTags.forEach(tag => {
  //       res.push(tag);
  //       if (selectedTags.parent)
  //   })
  // }

  render() {
    const { handleUnselect, handleSelect, selectedTags } = this.props;
    console.log(selectedTags);
    return (
      <div className="adding-material__tags">
        <h4 className="form__title adding-material__title">Добавление тегов</h4>
        <TagsList tags={selectedTags} handleDelete={handleUnselect} />
        <TagsCategoryMain
          handleSelect={handleSelect}
          handleUnselect={handleUnselect}
          selectedTags={selectedTags}
          isPickedTreeNode={this.isPickedTreeNode}
          isSelectView
        />
      </div>
    );
  }
}

export default TagsPart;
