import React, { Component, Fragment } from "react";
import { Route, Switch } from "react-router-dom";
import Menus from "./List/Menus";
import MenuEdit from "./Edit/MenuEdit";

class MenuRoute extends Component {
  render() {
    const { match } = this.props;
    return (
      <Fragment>
        <Switch>
          <Route exact path={`${match.url}`} component={Menus} />
          <Route
            path={`${match.url}/:id/`}
            render={({ match, history }) => (
              <MenuEdit id={match.params.id} match={match} history={history} />
            )}
          />
          <Route path="/*" render={() => <h3>Page not found</h3>} />
        </Switch>
      </Fragment>
    );
  }
}

export default MenuRoute;
