import React from "react";
import { stringShema } from "../../features/Ingredients/shema";
import Input from "./Input";

const InputNumber = props => {
  const { value } = props;
  const isValid = stringShema.isValidSync(value);
  return <Input {...props} isValid={isValid} />;
};

export default InputNumber;
