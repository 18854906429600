import React, { Component } from "react";
import Modal from "../../HOCs/Modal";

class Confirm extends Component {
  render() {
    const { title, handleConfirm } = this.props;
    return (
      <div className="confirm">
        <h4 className="form__title">{title}</h4>
        <div className="confirm__btngroup">
          <button
            onClick={() => handleConfirm(true)}
            className="confirm__btn confirm__btn_confirm"
          >
            Подтвердить
          </button>
          <button
            onClick={() => handleConfirm(false)}
            className="confirm__btn confirm__btn_cancel"
          >
            Отмена
          </button>
        </div>
      </div>
    );
  }
}

export default Modal(Confirm);
