import React, { Component } from "react";
import RecipeProportionTail from "./RecipeProportionTail/RecipeProportionTail";
import { PUBLISHED, weightTypesConstants } from "../../commons/constants";
import IngredientsTable from "../Ingredients/IngredientsTable/IngredientsTable";
import { statuses } from "../Recipes/utils";
import Button from "../../components/Button/Button";
import { isEmpty, onlyUnique, rounding } from "../../commons/utils";
import ButtonConfirm from "../../components/Button/ButtonConfirm";
import TechPart from "./TechPart";
import { Prompt } from "react-router-dom";
import InputNumber from "../../components/Input/InputNumber";
import { allIngredientsCalc } from "../Ingredients/utils";
import Input from "../../components/Input/Input";
import { withCalcDoseAmount } from "./utils";

class RecipeProportion extends Component {
  constructor(props) {
    super(props);
    const { recipeProportion, recipes, ingredients, weight } = props;
    const { recipe, doses } = recipeProportion;
    const recipeData = recipes[recipe] || {};

    const newDoses = doses
      ? doses.map(dose => {
          const ingredient = ingredients[dose.ingredient];
          return {
            ...ingredient,
            ...dose,
            title:
              ingredient &&
              (ingredient.linkedRecipe
                ? `${ingredient.title} (рецепт)`
                : ingredient.title)
          };
        })
      : [];
    this.state = {
      proportion: recipeProportion || {},
      doses: withCalcDoseAmount(newDoses, recipeData),
      recipe: {
        ...recipeData,
        status: {
          value: recipeData.status,
          isChanged: false
        }
      },
      disabled: recipeData.status === PUBLISHED,
      weight,
      showTail: true
    };
  }

  static getDerivedStateFromProps = (nextProps, prevState) => {
    const { recipeProportion, recipes } = nextProps;
    const { recipe } = recipeProportion;
    const recipeData = recipes[recipe] || {};
    return {
      recipe: {
        ...prevState.recipe,
        cookingSteps: recipeData.cookingSteps,
        totalWeight:
          allIngredientsCalc(prevState.doses).amountChanged *
          (prevState.recipe.massChange ? prevState.recipe.massChange / 100 : 1)
      }
    };
  };

  handleChangeNumber = ({ target: { value, name } }) => {
    this.setState({
      [name]: +value || 0
    });
  };

  handleChangeRecipe = ({ target: { value, name } }) => {
    this.setState(({ recipe }) => ({
      recipe: {
        ...recipe,
        [name]: value
      }
    }));
  };

  handleChangeRecipeProportion = ({ target: { value, name } }) => {
    this.setState(({ proportion }) => ({
      proportion: {
        ...proportion,
        [name]: value
      }
    }));
  };

  handleChangeNumberRecipe = ({ target: { value, name } }) => {
    this.setState(({ recipe }) => ({
      recipe: {
        ...recipe,
        [name]: +value || 0
      }
    }));
  };

  handleChangeDose = ({ target: { value, dataset, name } }) => {
    const { doses } = this.state;
    const arr = doses.map(ingredient => {
      if (ingredient.id === dataset.id) {
        return {
          ...ingredient,
          [name]: value
        };
      } else {
        return {
          ...ingredient
        };
      }
    });
    this.setState({
      doses: [...arr]
    });
  };

  handleDeleteDose = id => {
    const { recipeProportion } = this.props;
    const { doses, recipe } = this.state;
    const arr = doses.filter(ingredient => ingredient.id !== id);
    this.setState({
      ...recipeProportion,
      doses: [...arr]
    });
    this.setState({
      recipe: {
        ...recipe,
        ingredients: arr.map(({ ingredient }) => ingredient).filter(onlyUnique)
      }
    });
  };

  handleSelectDose = ingredient => {
    const { doses, recipe } = this.state;
    const newDoses = [
      {
        ...ingredient,
        amount: 1,
        isNew: true,
        ingredient: ingredient.id,
        unit: weightTypesConstants.GRAM,
        id: `${new Date().getTime()}`
      },
      ...doses
    ];
    this.setState({
      recipe: {
        ...recipe,
        ingredients: newDoses
          .map(({ ingredient }) => ingredient)
          .filter(onlyUnique)
      },
      doses: newDoses
    });
  };

  handleToggleTail = () => {
    this.setState(({ showTail }) => ({
      showTail: !showTail
    }));
  };

  handleSave = () => {
    const {
      editRecipeWithProportion,
      id,
      handleChangeRecipeWeight
    } = this.props;
    const { recipe, proportion, doses, weight } = this.state;
    editRecipeWithProportion(
      recipe,
      { ...proportion, doses },
      { withIngredients: true }
    );
    if (weight) handleChangeRecipeWeight(id, weight);
  };

  render() {
    const { ingredients, handleDeleteRecipe, isEditView, id } = this.props;
    const { doses, disabled, recipe, showTail, weight } = this.state;
    return (
      <div className="dish">
        <div className="dish__header">
          <div className="dish__status">
            Статус:{" "}
            {recipe.status.value &&
              statuses.find(({ name }) => name === recipe.status.value).title}
          </div>
          {!isEditView && (
            <ButtonConfirm
              handleConfirm={() => handleDeleteRecipe(id)}
              confirmTitle="Подтвердить удаление из меню"
              className="dish__delete"
            />
          )}
        </div>
        <Input
          name="title"
          value={recipe.title}
          handleChange={this.handleChangeRecipe}
          placeholder="Название"
          className="dish__title"
          disabled={disabled}
        />
        {weight !== undefined ? (
          <InputNumber
            name="weight"
            value={weight}
            handleChange={this.handleChangeNumber}
            placeholder="Вес рецепта в меню"
            disabled={disabled}
          />
        ) : null}
        <TechPart
          recipe={recipe}
          handleChange={this.handleChangeRecipe}
          disabled={disabled}
        />
        <IngredientsTable
          ingredients={ingredients}
          handleSelect={this.handleSelectDose}
          doses={doses}
          disabled={disabled}
          handleChangeDose={this.handleChangeDose}
          handleDeleteIngredient={this.handleDeleteDose}
        />
        <div className="property-dish__weight">
          <InputNumber
            name="massChange"
            value={recipe.massChange}
            handleChange={this.handleChangeNumberRecipe}
            placeholder="Процент уменьшения веса после приготовления"
          />
          <InputNumber
            value={rounding(recipe.totalWeight, 10)}
            placeholder="Итоговый вес"
            disabled
          />
        </div>
        {!isEditView && (
          <button onClick={this.handleToggleTail} className="dish__btn">
            {showTail ? "Скрыть детали" : "Показать детали"}
          </button>
        )}
        {showTail ? (
          <RecipeProportionTail
            {...this.state}
            handleChangeRecipe={this.handleChangeRecipe}
            handleChangeNumberRecipe={this.handleChangeNumberRecipe}
            handleChangeRecipeProportion={this.handleChangeRecipeProportion}
          />
        ) : null}
        <Button className="dish__btn" onClick={this.handleSave}>
          Сохранить
        </Button>
        <Prompt
          //when={isRecipeChanged || isRecipeProportionChanged}
          message="Вы действительно хотите выйти? Все несохраненные данные удалятся."
        />
      </div>
    );
  }
}

export default RecipeProportion;
