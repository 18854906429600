import { FormControl } from "@material-ui/core";
import { DatePicker as DatePickerX } from "@material-ui/pickers";
import moment from "moment";
import React, { useEffect, useState } from "react";

export const DatePicker = ({
  input: { onChange, value },
  label,
  fullWidth,
  maxWidth
}) => {
  const [selectedDate, handleDateChange] = useState(value || new Date());
  useEffect(
    () => {
      onChange(moment(selectedDate).format("YYYY-MM-DD"));
    },
    [onChange, selectedDate]
  );
  return (
    <FormControl
      variant={"outlined"}
      fullWidth={fullWidth}
      style={{
        height: "60px",
        margin: "10px 0",
        width: "100%",
        maxWidth: `${maxWidth}px`
      }}
    >
      <DatePickerX
        autoOk
        ampm={false}
        showTodayButton
        inputVariant="outlined"
        label={label}
        value={selectedDate}
        onChange={handleDateChange}
      />
    </FormControl>
  );
};
