import React, { Component } from "react";
import TagsCategoryMain from "../../TagsCategory/TagsCategoryMain";
import { tagsCategorySelector } from "../../TagsCategory/selectors";
import { connect } from "react-redux";
import { objFilter } from "../../../commons/utils";

class InventoryTags extends Component {
  getFilteredTagsCategory() {
    const { tagsCategory } = this.props;
    return objFilter(tagsCategory, ({ title }) => title === "Инвентарь");
  }
  render() {
    const {
      handleUnselect,
      handleSelect,
      selectedTags,
      isPickedTreeNode
    } = this.props;
    return (
      <TagsCategoryMain
        handleSelect={handleSelect}
        handleUnselect={handleUnselect}
        selectedTags={selectedTags}
        tagsCategory={this.getFilteredTagsCategory()}
        isPickedTreeNode={isPickedTreeNode}
        isSelectView
      />
    );
  }
}

export default connect(state => ({
  tagsCategory: tagsCategorySelector(state)
}))(InventoryTags);
