import React from "react";
import { numberShema } from "../../features/Ingredients/shema";
import Input from "./Input";

const InputNumber = props => {
  const { value, shema } = props;
  const isValid = shema
    ? shema.isValidSync(value)
    : numberShema.isValidSync(value);
  return <Input {...props} isValid={isValid} />;
};
export default InputNumber;
