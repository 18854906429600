import { createSelector } from "reselect";
import { objFilter } from "../../commons/utils";

const tagsCategoryGetter = state => state.tagsCategory;
const filteredTagsCategoryGetter = (state, ownProps) =>
  objFilter(state.tagsCategory.data, ({ id }) => id !== ownProps.id);

export const tagsCategorySelector = createSelector(
  tagsCategoryGetter,
  ({ data }) => data || {}
);

export const filteredTagsCategorySelector = createSelector(
  filteredTagsCategoryGetter,
  data => data || {}
);

export const tagsOfCategorySelector = createSelector(
  tagsCategoryGetter,
  ({ tags }) => tags || {}
);

export const isLoadingTagsCategorySelector = createSelector(
  tagsCategoryGetter,
  ({ loading }) => {
    return loading;
  }
);
