import { createSelector } from "reselect";

const selfAccountGetter = state => state.selfAccount;

export const selfAccountSelector = createSelector(
  selfAccountGetter,
  ({ data }) => data
);

export const isLoadingSelfAccountSelector = createSelector(
  selfAccountGetter,
  ({ loading }) => loading
);
