import React, { Component } from "react";
import Dialog from "../../HOCs/Dialog";
import ConfirmCode from "./ConfirmCode";
import ConfirmPhone from "./ConfirmPhone";
import { connect } from "react-redux";
import {
  confirmationErrorSelector,
  getConfirmationCodeSelector,
  getConfirmationPhoneSelector,
  isLoadingConfirmationSelector
} from "../Confirmation/selectors";
import Preloader from "../../components/Preloader/Preloader";
import { unConfirmPhone, unConfirmCode } from "../Confirmation/ducks";

class EditPhone extends Component {
  constructor(props) {
    super(props);
    const { phone } = props;
    this.state = {
      phone: phone.e164Format || "",
      code: ""
    };
  }

  componentWillUnmount() {
    const { unConfirmCode, unConfirmPhone } = this.props;
    unConfirmCode();
    unConfirmPhone();
  }

  handleChange = e => {
    const { value, name } = e.target;
    this.setState({
      [name]: value
    });
  };

  handleChangePhone = () => {
    this.setState({
      phone: ""
    });
  };

  render() {
    const { phone, code } = this.state;
    const { isConfirmPhone, isConfirmCode, loading, error } = this.props;
    if (loading) return <Preloader />;
    return (
      <div className="dialog-phone">
        <h4 className="form__title">Сменить номер</h4>
        {isConfirmPhone ? (
          <ConfirmCode
            handleChange={this.handleChange}
            code={code}
            error={error}
            phone={phone}
            handleChangePhone={this.handleChangePhone}
          />
        ) : (
          <ConfirmPhone
            handleChange={this.handleChange}
            error={error}
            phone={phone}
          />
        )}
        {isConfirmCode && <div>Код подтвержден. Номер изменен успешно.</div>}
      </div>
    );
  }
}

export default connect(
  state => ({
    isConfirmPhone: getConfirmationPhoneSelector(state),
    isConfirmCode: getConfirmationCodeSelector(state),
    loading: isLoadingConfirmationSelector(state),
    error: confirmationErrorSelector(state)
  }),
  { unConfirmPhone, unConfirmCode }
)(Dialog(EditPhone));
