import React, { Component } from "react";
import TagsView from "../../Tags/TagsPart";
import TagsCategoryMain from "../../TagsCategory/TagsCategoryMain";
import TagsList from "../../Tags/List/TagsList";
import TagsBubbleController from "../../Tags/TagsBubbleController";

class TagsPart extends Component {
  state = {
    isTagsTreeViewOpen: true
  };

  handleUnselect = (parent, children, tag) => {
    this.props.handleDeleteTag(tag.id);
  };

  render() {
    const { handleDeleteTag, handleCreateTag, selectedTags } = this.props;
    const { isTagsTreeViewOpen } = this.state;
    return (
      <div>
        {/*<button onClick={this.handleViewToggle}>Toggle</button>*/}
        <TagsList tags={selectedTags} handleDelete={this.handleUnselect} />
        <TagsBubbleController
          createTag={handleCreateTag}
          deleteTag={handleDeleteTag}
          selectedTags={selectedTags}
        >
          <TagsCategoryMain />
        </TagsBubbleController>
      </div>
    );
  }
}

export default TagsPart;
