import fecthAPI from "../../commons/api";
import {
  CATEGORY_URL,
  SERVER_URL,
  TAG_URL,
  TAGS_URL
} from "../../commons/constants";
import { setQueryString } from "../../commons/utils";
import { normaliseToPut } from "./normalisers";

export const getTags = filter =>
  fecthAPI.get(
    `${SERVER_URL}/${TAGS_URL}${setQueryString({
      name: "title",
      value: filter
    })}`
  );

export const getAntiTags = id =>
  fecthAPI.get(`${SERVER_URL}/${TAG_URL}/${id}/antitag`);

export const getFriendlyTags = id =>
  fecthAPI.get(`${SERVER_URL}/${TAG_URL}/${id}/friendly`);

export const getTag = id => fecthAPI.get(`${SERVER_URL}/${TAG_URL}/${id}`);

export const getTagsOfCategory = id =>
  fecthAPI.get(`${SERVER_URL}/${TAG_URL}/${CATEGORY_URL}/${id}/list`);

export const putTag = (id, data) =>
  fecthAPI.put(`${SERVER_URL}/${TAG_URL}/${id}`, normaliseToPut(data));

export const postTag = data => fecthAPI.post(`${SERVER_URL}/${TAG_URL}`, data);

export const postAntiTag = (id, data) =>
  fecthAPI.post(`${SERVER_URL}/${TAG_URL}/${id}/antitag`, data);

export const postFriendlyTag = (id, data) =>
  fecthAPI.post(`${SERVER_URL}/${TAG_URL}/${id}/friendly`, data);

export const postChildTag = (id, data) =>
  fecthAPI.post(`${SERVER_URL}/${TAG_URL}/${id}/child`, data);

export const deleteTag = id =>
  fecthAPI.delete(`${SERVER_URL}/${TAG_URL}/${id}`);

export const deleteAntiTag = (id, antiTagId) =>
  fecthAPI.delete(`${SERVER_URL}/${TAG_URL}/${id}/antitag/${antiTagId}`);

export const deleteFriendlyTag = (id, antiTagId) =>
  fecthAPI.delete(`${SERVER_URL}/${TAG_URL}/${id}/friendly/${antiTagId}`);
