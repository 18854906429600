import { FAIL, START, SUCCESS } from "../../commons/constants";

const initialState = {
  isConfirmPhone: false,
  isConfirmCode: false,
  loading: false,
  loaded: false,
  error: null
};

export const CONFIRM_PHONE = "CONFIRM_PHONE";
export const UNCONFIRM_PHONE = "UNCONFIRM_PHONE";
export const UNCONFIRM_CODE = "UNCONFIRM_CODE";
export const CONFIRM_CODE = "CONFIRM_CODE";
export const CLEAR_ERROR = "CLEAR_ERROR";

export function unConfirmPhone() {
  return {
    type: UNCONFIRM_PHONE
  };
}

export function unConfirmCode() {
  return {
    type: UNCONFIRM_CODE
  };
}

export function clearError() {
  return {
    type: CLEAR_ERROR
  };
}

export default (state = initialState, { type, payLoad, error }) => {
  switch (type) {
    case CONFIRM_PHONE + START:
      return {
        ...state,
        loading: true
      };
    case CONFIRM_PHONE + SUCCESS:
      return {
        ...state,
        isConfirmPhone: true,
        loading: false
      };
    case CONFIRM_PHONE + FAIL:
      return {
        ...state,
        loading: false,
        error
      };
    case CONFIRM_CODE + START:
      return {
        ...state,
        loading: true
      };
    case CONFIRM_CODE + SUCCESS:
      return {
        ...state,
        isConfirmCode: true,
        loading: false
      };
    case CONFIRM_CODE + FAIL:
      return {
        ...state,
        loading: false,
        error
      };
    case UNCONFIRM_PHONE:
      return {
        ...state,
        isConfirmPhone: false
      };
    case UNCONFIRM_CODE:
      return {
        ...state,
        isConfirmCode: false
      };
    case CLEAR_ERROR:
      return {
        ...state,
        error: null
      };
    default:
      return state;
  }
};
