import React, { Component } from "react";
import ImagePicker from "../../components/ImagePicker/ImagePicker";
import TextArea from "../../components/Textarea/TextArea";
import { editRecipeTechCover } from "../Cover/ducks";
import { connect } from "react-redux";

class TechPart extends Component {
  changeCover = data => {
    const { recipe, editRecipeTechCover } = this.props;
    editRecipeTechCover(recipe.id, data);
  };

  render() {
    const { recipe, disabled, handleChange } = this.props;
    const { techCover, techDescription } = recipe;
    return (
      <div className="dish__tech">
        <ImagePicker
          name={`${recipe.id}_techDescription`}
          title="Нажмите для добавления технической фотографии"
          data={techCover && techCover.path}
          afterLoad={this.changeCover}
          afterClear={this.changeCover}
          disabled={disabled}
        />
        <TextArea
          name="techDescription"
          value={techDescription}
          placeholder="Технический комментарий"
          handleChange={handleChange}
          disabled={disabled}
        />
      </div>
    );
  }
}

export default connect(
  null,
  { editRecipeTechCover }
)(TechPart);
