import {
  getPureObjectFromHal,
  isEmpty,
  parseHrefToID
} from "../../commons/utils";

export function normaliseMeals(meals) {
  const res = {};
  const resMeals = [...meals._embedded.items];
  resMeals.forEach(meal => {
    meal = normaliseMeal(meal);
    Object.assign(res, {
      [meal.id]: {
        ...meal
      }
    });
  });
  return res;
}

export function normaliseMeal(meal) {
  const newMeal = getPureObjectFromHal({ ...meal });
  return {
    ...newMeal,
    menuProportion: parseHrefToID(newMeal.menuProportion.href),
    recipeProportions: newMeal.recipeProportions.map(({ href }) =>
      parseHrefToID(href)
    ),
    mealRecipes: meal.recipeProportions.map(
      ({ recipeProportionId, weight }) => ({
        recipeProportion: recipeProportionId,
        weight
      })
    )
  };
}

export function normaliseMealComments(data) {
  return data._embedded.items;
}

export function normaliseToFetch(meal) {
  const { mealRecipes, date, mealType } = meal;
  return {
    date: date || "",
    mealType: mealType || "",
    mealRecipes:
      mealRecipes.map(mealRecipe =>
        isEmpty(parseFloat(mealRecipe.weight))
          ? { ...mealRecipe, weight: 0 }
          : { ...mealRecipe, weight: +mealRecipe.weight }
      ) || []
  };
}
