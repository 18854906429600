import React, { Component } from "react";
import InventoryTags from "./InventoryTags";
import TagsBubbleController from "../../Tags/TagsBubbleController";

class Inventory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTags: props.selectedTags || []
    };
  }

  handleChange = value => {
    const { handleChange } = this.props;
    handleChange({ target: { name: "inventory", value } });
  };

  handleDeleteTag = delId => {
    this.setState(
      ({ selectedTags }) => ({
        selectedTags: selectedTags.filter(({ id }) => id !== delId)
      }),
      () => this.handleChange(this.state.selectedTags)
    );
  };

  handleCreateTag = tag => {
    this.setState(
      ({ selectedTags }) => ({
        selectedTags: [...selectedTags, { ...tag }]
      }),
      () => this.handleChange(this.state.selectedTags)
    );
  };

  render() {
    const { selectedTags } = this.state;
    return (
      <div className="tail-dish__property property-dish__inventory">
        <div className="inventory-dish__title">Инвентарь</div>
        <TagsBubbleController
          selectedTags={selectedTags}
          deleteTag={this.handleDeleteTag}
          createTag={this.handleCreateTag}
        >
          <InventoryTags />
        </TagsBubbleController>
      </div>
    );
  }
}

export default Inventory;
