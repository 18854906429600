import {
  DELETE_INGREDIENT,
  LOAD_INGREDIENTS,
  EDIT_INGREDIENT,
  CREATE_INGREDIENT,
  LOAD_INGREDIENTS_FILTERED,
  LOAD_INGREDIENT
} from "./ducks";
import { call, put, takeLatest, select, takeEvery } from "redux-saga/effects";
import { errorMassages, FAIL, START, SUCCESS } from "../../commons/constants";
import * as API from "./api";
import { normaliseFromLoad, normaliseItem } from "./normalisers";
import fetchAPI, { fetchReqAsync, fetchResAsync } from "../../commons/api";
import { ingredientFilterSelector } from "../Filters/selectors";
import { MODAL_CLOSE } from "../Modal/ducks";

export function* getIngredients({ filter }) {
  try {
    yield put({ type: LOAD_INGREDIENTS + START });
    const res = yield fetchReqAsync(API.getIngredients, filter);
    const ingredients = normaliseFromLoad(res);
    yield put({
      type: LOAD_INGREDIENTS + SUCCESS,
      payLoad: { ...ingredients },
      count: res.count,
      total: res.total
    });
  } catch (error) {
    yield call(alert, errorMassages.load("Ингридиентов", error));
    yield put({ type: LOAD_INGREDIENTS + FAIL, error });
  }
}

export function* getIngredient({ payLoad, url }) {
  try {
    yield put({ type: LOAD_INGREDIENT + START });
    const res = normaliseItem(
      url
        ? yield fetchReqAsync(fetchAPI.get, url)
        : yield fetchReqAsync(API.getIngredient, payLoad.id)
    );
    yield put({
      type: LOAD_INGREDIENT + SUCCESS,
      payLoad: { id: res.id, data: res }
    });
  } catch (error) {
    yield call(alert, errorMassages.load("Ингридиентов", error));
    yield put({ type: LOAD_INGREDIENT + FAIL, error });
  }
}

export function* getFilteredIngredients() {
  try {
    yield put({ type: LOAD_INGREDIENTS_FILTERED + START });
    const filter = yield select(ingredientFilterSelector);
    const ingredients = normaliseFromLoad(
      yield fetchReqAsync(API.getIngredients, filter)
    );
    yield put({
      type: LOAD_INGREDIENTS_FILTERED + SUCCESS,
      payLoad: { ...ingredients }
    });
  } catch (error) {
    yield call(alert, errorMassages.load("Ингридиентов", error));
    yield put({ type: LOAD_INGREDIENTS_FILTERED + FAIL, error });
  }
}

function* editIngredient({ payLoad: { id, data } }) {
  try {
    yield put({ type: EDIT_INGREDIENT + START });
    yield fetchResAsync(API.putIngredient, id, data);
    yield put({
      type: EDIT_INGREDIENT + SUCCESS
    });
    yield call(getIngredient, { payLoad: { id } });
    yield put({ type: MODAL_CLOSE });
  } catch (error) {
    console.log(error);
    yield call(alert, errorMassages.edit("Ингридиентов", error));
    yield put({ type: EDIT_INGREDIENT + FAIL, error });
  }
}

function* createIngredient({ payLoad: { data } }) {
  try {
    yield put({ type: CREATE_INGREDIENT + START });
    const res = yield fetchResAsync(API.postIngredient, data);
    yield put({
      type: CREATE_INGREDIENT + SUCCESS
    });
    yield call(getIngredient, { url: res.headers.get("Location") });
    yield put({ type: MODAL_CLOSE });
  } catch (error) {
    console.log(error);
    yield call(alert, errorMassages.create("Ингридиентов", error));
    yield put({ type: CREATE_INGREDIENT + FAIL, error });
  }
}

function* deleteIngredient({ payLoad: { id } }) {
  try {
    yield put({ type: DELETE_INGREDIENT + START });
    yield fetchResAsync(API.deleteIngredientAPI, id);
    yield put({
      type: DELETE_INGREDIENT + SUCCESS,
      payLoad: {
        id
      }
    });
  } catch (error) {
    yield call(alert, errorMassages.delete("Ингридиентов", error));
    yield put({ type: DELETE_INGREDIENT + FAIL, error });
  }
}

function* watchIngredients() {
  yield takeLatest(LOAD_INGREDIENTS, getIngredients);
  yield takeEvery(LOAD_INGREDIENT, getIngredient);
  yield takeLatest(LOAD_INGREDIENTS_FILTERED, getFilteredIngredients);
  yield takeLatest(EDIT_INGREDIENT, editIngredient);
  yield takeLatest(CREATE_INGREDIENT, createIngredient);
  yield takeLatest(DELETE_INGREDIENT, deleteIngredient);
}

export default watchIngredients;
