import { createSelector } from "reselect";

const adminsGetter = state => state.admins;
const adminGetter = (state, ownProps) => state.admins.data[ownProps.id];

export const adminsSelector = createSelector(
  adminsGetter,
  ({ data }) => data
);

export const adminByIdSelector = createSelector(
  adminGetter,
  admin => admin
);

export const isLoadingAdminsSelector = createSelector(
  adminsGetter,
  ({ loading }) => {
    return loading;
  }
);
