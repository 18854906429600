import React from "react";

const Search = props => {
  const { name, value, handleChange, placeholder, className, ...attrs } = props;
  return (
    <input
      className={"search " + className}
      name={name}
      type="search"
      value={value}
      onChange={handleChange}
      placeholder={placeholder}
      autoComplete="off"
      {...attrs}
    />
  );
};

export default Search;
