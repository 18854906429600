import React from "react";
import Button from "../../components/Button/Button";

const TreeNodeExpand = ({ isOpen, handleCloseNode, handleOpenNode }) => {
  return (
    <Button
      className={
        "node-tree__expand" +
        (isOpen ? " node-tree__expand_open" : " node-tree__expand_close")
      }
      onClick={isOpen ? handleCloseNode : handleOpenNode}
    />
  );
};

export default TreeNodeExpand;
