import React, { Component } from "react";
import { createPortal } from "react-dom";

const withConfirmModal = WrappedComponent => {
  return class extends Component {
    closeModal = () => {
      const { closeModal } = this.props;
      const isClose = window.confirm("Вы действительно хотите закрыть окно?");
      if (isClose) closeModal();
    };

    render() {
      return createPortal(
        <div>
          <div className="modal" onClick={this.closeModal} />
          <section className="modal__main">
            <WrappedComponent {...this.props} />
          </section>
        </div>,
        document.querySelector("#modal-dom")
      );
    }
  };
};

export default withConfirmModal;
