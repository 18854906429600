import { call, put, takeLatest, select } from "redux-saga/effects";
import { errorMassages, FAIL, START, SUCCESS } from "../../commons/constants";
import * as API from "./api";
import {
  COMMENT_CHANGE_STATUS,
  DELETE_COMMENT,
  LOAD_COMMENT,
  LOAD_COMMENTS,
  PUBLISH_COMMENT,
  UNPUBLISH_COMMENT
} from "./ducks";
import { normaliseFromLoad } from "./normalisers";
import fetchAPI, { fetchReqAsync, fetchResAsync } from "../../commons/api";
import { commentByIdSelector } from "./selectors";
import { normaliseMaterial } from "../Materials/normalisers";

export function* getComments({ id, filter }) {
  try {
    yield put({ type: LOAD_COMMENTS + START });
    const res = normaliseFromLoad(
      yield fetchReqAsync(API.getComments, id, filter)
    );
    yield put({
      type: LOAD_COMMENTS + SUCCESS,
      payLoad: res
    });
  } catch (error) {
    console.log(error);
    yield call(alert, errorMassages.load("comments", error));
    yield put({
      type: LOAD_COMMENTS + FAIL,
      error
    });
  }
}

export function* getComment({ materialId, id, url }) {
  try {
    yield put({ type: LOAD_COMMENT + START });
    const res = normaliseMaterial(
      url
        ? yield fetchReqAsync(fetchAPI.get, url)
        : yield fetchReqAsync(API.getComment, materialId, id)
    );
    yield put({
      type: LOAD_COMMENT + SUCCESS,
      payLoad: { data: { ...res }, id: res.id }
    });
  } catch (error) {
    yield call(alert, errorMassages.load("comment", error));
    yield put({
      type: LOAD_COMMENT + FAIL,
      error
    });
  }
}

function* publishComment({ payLoad: { id } }) {
  try {
    yield put({ type: COMMENT_CHANGE_STATUS + START });
    const comment = yield select(commentByIdSelector, { id });
    yield fetchResAsync(API.publishComment, id);
    yield put({
      type: COMMENT_CHANGE_STATUS + SUCCESS
    });
    yield call(getComment, { url: comment.self.href });
  } catch (error) {
    console.log(error);
    yield call(alert, errorMassages.edit("comment", error));
    yield put({
      type: COMMENT_CHANGE_STATUS + FAIL,
      error
    });
  }
}

function* unpublishComment({ payLoad: { id } }) {
  try {
    yield put({ type: COMMENT_CHANGE_STATUS + START });
    const comment = yield select(commentByIdSelector, { id });
    yield fetchResAsync(API.unpublishComment, id);
    yield put({
      type: COMMENT_CHANGE_STATUS + SUCCESS
    });
    yield call(getComment, { url: comment.self.href });
  } catch (error) {
    console.log(error);
    yield call(alert, errorMassages.edit("comment", error));
    yield put({
      type: COMMENT_CHANGE_STATUS + FAIL,
      error
    });
  }
}

function* deleteComment({ payLoad: { id } }) {
  try {
    yield put({ type: COMMENT_CHANGE_STATUS + START });
    const comment = yield select(commentByIdSelector, { id });
    yield fetchResAsync(API.deleteComment, id);
    yield put({
      type: COMMENT_CHANGE_STATUS + SUCCESS
    });
    yield call(getComment, { url: comment.self.href });
  } catch (error) {
    yield call(alert, errorMassages.delete("material", error));
    yield put({
      type: COMMENT_CHANGE_STATUS + FAIL,
      error
    });
  }
}

function* watchComments() {
  yield takeLatest(LOAD_COMMENTS, getComments);
  yield takeLatest(LOAD_COMMENT, getComment);
  yield takeLatest(PUBLISH_COMMENT, publishComment);
  yield takeLatest(UNPUBLISH_COMMENT, unpublishComment);
  yield takeLatest(DELETE_COMMENT, deleteComment);
}

export default watchComments;
