import React from "react";

const InputSubmit = ({ children, className, disabled, value, ...attrs }) => {
  return (
    <input
      type="submit"
      className={`input__submit ${className} ${
        disabled ? `${className}_disabled` : ""
      }`}
      disabled={disabled}
      value={value}
      {...attrs}
    />
  );
};

export default InputSubmit;
