import React, { Component } from "react";
import PreloaderSpinner from "../components/Preloader/Preloader";

const Preloader = ChildComponent => {
  return class extends Component {
    render() {
      const { loading } = this.props;
      return (
        <div>
          {loading ? <PreloaderSpinner /> : null}
          <div className={loading ? "display_none" : ""}>
            <ChildComponent {...this.props} />
          </div>
        </div>
      );
    }
  };
};

export default Preloader;
