import React, { Component, Fragment } from "react";
import RecipeList from "./RecipeList";
import Button from "../../../components/Button/Button";
import RecipeAddModal from "../Add/RecipeAddModal";
import Filter from "../Filter/Filter";
import withPagination from "../../../components/Table/withPagination";
import { connect } from "react-redux";
import { loadRecipes } from "../ducks";

const countArr = [10, 20, 50];

class Recipes extends Component {
  state = {
    filters: {
      title: "",
      status: ""
    },
    isOpenModal: false
  };

  componentDidMount() {
    const { filters } = this.props;
    this.props.afterChangeFilter({ ...filters, ...this.state.filters });
  }

  handleChangeFilter = externalFilters => {
    const { handleChangeFilter } = this.props;
    this.setState(
      ({ filters }) => ({
        filters: {
          ...filters,
          ...externalFilters
        }
      }),
      () => handleChangeFilter(this.state.filters)
    );
  };

  openModal = () => {
    this.setState({
      isOpenModal: true
    });
  };

  closeModal = () => {
    this.setState({
      isOpenModal: false
    });
  };

  render() {
    const { isOpenModal, filters } = this.state;
    const {
      history,
      filters: { page, count },
      handleChangePage,
      handleChangeRowsPerPage
    } = this.props;
    return (
      <Fragment>
        <div className="content__head header-content">
          <div className="title__content title-content">
            <h2 className="title-content__name">Рецепты</h2>
            <Button className="title-content__add" onClick={this.openModal} />
          </div>
          <Filter
            handleChangeFilter={this.handleChangeFilter}
            filters={filters}
          />
        </div>

        <RecipeList
          page={page - 1}
          rowsPerPage={count}
          countArr={countArr}
          handleEdit={this.handleEdit}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
        {isOpenModal && (
          <RecipeAddModal closeModal={this.closeModal} history={history} />
        )}
      </Fragment>
    );
  }
}

export default connect(
  null,
  { afterChangeFilter: loadRecipes }
)(withPagination(Recipes));
