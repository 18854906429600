import React from "react";
import DropZone from "react-dropzone";

class TagsDropZone extends React.Component {
  onDrop = (acceptedFiles, rejectedFiles) => {
    const { acceptFile, rejectFile } = this.props;
    if (acceptedFiles && acceptedFiles[0]) acceptFile(acceptedFiles[0]);
    if (rejectedFiles && rejectedFiles[0]) rejectFile(rejectedFiles[0]);
  };
  render() {
    return (
      <DropZone onDrop={this.onDrop} accept=".csv">
        {({ getRootProps, getInputProps, isDragActive }) => {
          return (
            <div
              {...getRootProps()}
              className={`dropzone-tags__wrapper ${
                isDragActive ? "dropzone-tags__wrapper_active" : ""
              }`}
            >
              <input {...getInputProps()} type="file" />
              {isDragActive ? (
                <div className="dropzone-tags__drag dropzone-tags__drag_active">
                  Загрузить CSV файл...
                </div>
              ) : (
                <div>Загрузить CSV файл</div>
              )}
            </div>
          );
        }}
      </DropZone>
    );
  }
}

export default TagsDropZone;
