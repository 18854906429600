import React, { Component, Fragment } from "react";
import { Route, Switch } from "react-router-dom";
import MaterialsMain from "./MaterialsMain";
import CommentsMain from "../Comments/CommentsMain";

class MaterialRoute extends Component {
  render() {
    const { match } = this.props;
    return (
      <Fragment>
        <Switch>
          <Route exact path={`${match.url}`} component={MaterialsMain} />
          <Route
            path={`${match.url}/:id/`}
            render={({ match, history }) => (
              <CommentsMain id={match.params.id} history={history} />
            )}
          />
          <Route path="/*" render={() => <h3>Page not found</h3>} />
        </Switch>
      </Fragment>
    );
  }
}

export default MaterialRoute;
