import { createSelector } from "reselect";

const coverGetter = state => state.cover;

export const isLoadingCoverSelector = createSelector(
  coverGetter,
  ({ loading }) => {
    return loading;
  }
);

export const getCoverSelector = createSelector(
  coverGetter,
  ({ lastChangedData }) => {
    return lastChangedData;
  }
);

export const getIconSelector = createSelector(
  coverGetter,
  ({ lastChangedIcon }) => {
    return lastChangedIcon;
  }
);

export const isChangedLastCover = createSelector(
  coverGetter,
  ({ lastChangedData }) => {
    return lastChangedData.isChanged;
  }
);

export const isLoadedCoverSelector = createSelector(
  coverGetter,
  ({ loaded }) => {
    return loaded;
  }
);
