import { getPureObjectFromHal, parseHrefToID } from "../../commons/utils";

export function normaliseMenuProportions(menuProportions) {
  const res = {};
  menuProportions = [...menuProportions._embedded.items];
  menuProportions.forEach(menuProportion => {
    menuProportion = normaliseMenuProportion(menuProportion);
    Object.assign(res, {
      [menuProportion.id]: {
        ...menuProportion
      }
    });
  });
  return res;
}

export function normaliseMenuProportion(menuProportion) {
  menuProportion = getPureObjectFromHal(menuProportion);
  return {
    ...menuProportion,
    meals: menuProportion.meals.map(meal => parseHrefToID(meal.href))
  };
}
