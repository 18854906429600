import { getPureObjectFromHal, parseHrefToID } from "../../commons/utils";

export function normaliseFromLoad(items) {
  const res = {};
  const resItems = [...items._embedded.items];
  resItems.forEach(item => {
    item = normaliseItem(item);
    Object.assign(res, {
      [item.id]: {
        ...item
      }
    });
  });
  return res;
}

export function normaliseItem(item) {
  const res = {};
  item = getPureObjectFromHal(item);
  Object.assign(res, {
    ...item,
    category: parseHrefToID(item.category.href),
    parent: item.parent && item.parent.parent_id
  });
  return res;
}

export function normaliseTag(tag) {
  const res = {};
  tag = getPureObjectFromHal(tag);
  Object.assign(res, {
    ...tag,
    parent: tag.parent && tag.parent.parent_id
  });
  return res;
}

export function normaliseToPut({ title, category, description, icon }) {
  const data = {
    title,
    categoryId: category,
    description
  };
  if (icon) Object.assign(data, { icon });
  return data;
}
