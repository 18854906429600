import React from "react";
import "./style.scss";
import { menuTypes } from "../../features/Meals/utils";
import { Close } from "@material-ui/icons";

const options = {
  year: "numeric",
  month: "numeric",
  day: "numeric",
  timezone: "UTC",
  hour: "numeric",
  minute: "numeric",
  second: "numeric"
};

function Comment({
  name,
  createdAt,
  text,
  id,
  mealType,
  deleteComment,
  isDeleteComment
}) {
  return (
    <div className="comment__wrapper">
      <div className="comment__header">
        <div>
          {name} <b>{menuTypes.find(({ name }) => name === mealType).value}</b>
        </div>
        {isDeleteComment && isDeleteComment(id) ? (
          <div className="comment__btn" onClick={deleteComment}>
            <Close fontSize="small" />
          </div>
        ) : null}
      </div>
      <div className="comment__date">
        {new Date(createdAt).toLocaleString("ru", options)}
      </div>
      <div className="comment__text">{text}</div>
    </div>
  );
}

export default Comment;
