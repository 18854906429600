import React from "react";

const TableCell = ({ children, isHeadCell, className, ...attrs }) => {
  return isHeadCell ? (
    <th className={className} {...attrs}>
      {children}
    </th>
  ) : (
    <td className={className} {...attrs}>
      {children}
    </td>
  );
};

export default TableCell;
