import React, { Component } from "react";
import { createPortal } from "react-dom";

const Modal = ModalComponent => {
  return class extends Component {
    handleOutsideClick = e => {
      const { closeModal } = this.props;
      let target = e.target;
      while (target.className !== "modal") {
        if (target.className === "modal__main") {
          return;
        }
        target = target.parentNode;
        if (!target) return;
      }
      closeModal();
    };
    render() {
      return createPortal(
        <div>
          <div className="modal" onClick={this.handleOutsideClick} />
          <section className="modal__main">
            <ModalComponent {...this.props} />
          </section>
        </div>,
        document.querySelector("#modal-dom")
      );
    }
  };
};

export default Modal;
