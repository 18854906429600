import React, { Component } from "react";
import ShowForm from "../../../components/Form/ShowForm";
import { cookTypes } from "../utils";
import { cookingMethodInputTypes as inputTypes } from "../utils";

class ThirdPart extends Component {
  state = {
    formCollection: []
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { cookingMethods } = nextProps;
    const formCollection = [];
    cookTypes.forEach(item => {
      formCollection.push({
        type: "label",
        name: item.name,
        value: item.title,
        className: "adding-ingredient__label"
      });
      formCollection.push(
        ...inputTypes.map(type => ({
          type: "input_number",
          key: type.name + item.name,
          name: type.name,
          value:
            (cookingMethods[item.name] &&
              cookingMethods[item.name][type.name]) ||
            "",
          placeholder: type.placeholder,
          id: item.name
        }))
      );
    });
    return {
      formCollection
    };
  }

  render() {
    return (
      <div className="adding-ingredient__third">
        <h4 className="form__title adding-ingredient__title">
          Способы приготовления
        </h4>
        <ShowForm
          formCollection={this.state.formCollection}
          handleChange={this.props.handleChangeCooks}
          errors={this.props.errors}
        />
      </div>
    );
  }
}

export default ThirdPart;
