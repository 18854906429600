import React, { Component } from "react";
import { connect } from "react-redux";
import Input from "../../components/Input/Input";
import { accountConfirmCode } from "./ducks";
import { clearError, unConfirmPhone } from "../Confirmation/ducks";

class ConfirmCode extends Component {
  handleConfirm = () => {
    const { phone, code, accountConfirmCode, clearError } = this.props;
    accountConfirmCode(phone, code);
    clearError();
  };
  handleChangePhone = () => {
    const { handleChangePhone, unConfirmPhone, clearError } = this.props;
    handleChangePhone();
    unConfirmPhone();
    clearError();
  };
  render() {
    const { handleChange, code, error } = this.props;
    return (
      <div>
        <Input
          value={code}
          placeholder="Код"
          name="code"
          handleChange={handleChange}
          error={error === 400 && { message: "Неверный код" }}
        />
        <button onClick={this.handleConfirm} className="authorisation__button">
          Отправить код из SMS
        </button>
        <button
          onClick={this.handleChangePhone}
          className="authorisation__button"
        >
          Сменить номер телефона
        </button>
      </div>
    );
  }
}

export default connect(
  null,
  { clearError, accountConfirmCode, unConfirmPhone }
)(ConfirmCode);
