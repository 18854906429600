import { FAIL, START, SUCCESS } from "../../commons/constants";

const initialState = {
  data: {},
  antiTags: {},
  friendlyTags: {},
  dataRecipe: {},
  filteredData: {},
  loading: false,
  loadingAntiTags: false,
  loadingRecipe: false,
  loaded: false,
  error: false
};

export const LOAD_TAGS = "LOAD_TAGS";
export const LOAD_ANTITAGS = "LOAD_ANTITAGS";
export const LOAD_FRIENDLYTAGS = "LOAD_FRIENDLYTAGS";

export const LOAD_TAG = "LOAD_TAG";

export const EDIT_TAG = "EDIT_TAG";
export const EDIT_TAG_SUCCESS = "EDIT_TAG_SUCCESS";
export const EDIT_CHILD_TAG_SUCCESS = "EDIT_CHILD_TAG_SUCCESS";
export const EDIT_TAG_FAIL = "EDIT_TAG_FAIL";

export const LOAD_TAGS_FILTERED = "LOAD_TAGS_FILTERED";
export const LOAD_TAGS_OF_CATEGORY = "LOAD_TAGS_OF_CATEGORY";
export const LOAD_CHILD_TAGS_OF_CATEGORY = "LOAD_CHILD_TAGS_OF_CATEGORY";

export const CREATE_TAG = "CREATE_TAG";
export const CREATE_ANTITAG = "CREATE_ANTITAG";
export const CREATE_FRIENDLYTAG = "CREATE_FRIENDLYTAG";
export const CREATE_CHILD_TAG = "CREATE_CHILD_TAG";

export const CLEAR_CHILD_TAGS_OF_CATEGORY = "CLEAR_CHILD_TAGS_OF_CATEGORY";
export const CLEAR_TAGS_FILTERED = "CLEAR_TAGS_FILTERED";

export const DELETE_TAG = "DELETE_TAG";
export const DELETE_ANTITAG = "DELETE_ANTITAG";
export const DELETE_FRIENDLYTAG = "DELETE_FRIENDLYTAG";
export const DELETE_CHILD_TAG = "DELETE_CHILD_TAG";

export function loadTags(url) {
  return {
    type: LOAD_TAGS,
    url
  };
}

export function loadAntiTags(id) {
  return {
    type: LOAD_ANTITAGS,
    payLoad: { id }
  };
}

export function loadFriendlyTags(id) {
  return {
    type: LOAD_FRIENDLYTAGS,
    payLoad: { id }
  };
}

export function loadTagsOfCategory(categoryId) {
  return {
    type: LOAD_TAGS_OF_CATEGORY,
    payLoad: {
      categoryId
    }
  };
}

export const editTag = (id, data, childId) => ({
  type: EDIT_TAG,
  payLoad: {
    id,
    data,
    childId
  }
});

export const editTagSuccess = (id, data) => ({
  type: EDIT_TAG_SUCCESS,
  payLoad: {
    id,
    data
  }
});

export const editChildTagSuccess = (id, childId, data) => ({
  type: EDIT_CHILD_TAG_SUCCESS,
  payLoad: {
    id,
    childId,
    data
  }
});

export const editTagFail = error => ({
  type: EDIT_TAG_FAIL,
  error
});

export function loadChildTags(id, url) {
  return {
    type: LOAD_CHILD_TAGS_OF_CATEGORY,
    url,
    payLoad: {
      id
    }
  };
}

export function createTag(data) {
  return {
    type: CREATE_TAG,
    payLoad: {
      data
    }
  };
}

export function createAntiTag(id, antiTagId) {
  return {
    type: CREATE_ANTITAG,
    payLoad: {
      id,
      antiTagId
    }
  };
}

export function createFriendlyTag(id, friendlyTagId) {
  return {
    type: CREATE_FRIENDLYTAG,
    payLoad: {
      id,
      friendlyTagId
    }
  };
}

export function createChildTag(id, data) {
  return {
    type: CREATE_CHILD_TAG,
    payLoad: {
      data,
      id
    }
  };
}

export function deleteTag(id) {
  return {
    type: DELETE_TAG,
    payLoad: {
      id
    }
  };
}

export function deleteAntiTag(id, antiTagId) {
  return {
    type: DELETE_ANTITAG,
    payLoad: {
      id,
      antiTagId
    }
  };
}

export function deleteFriendlyTag(id, friendlyTagId) {
  return {
    type: DELETE_FRIENDLYTAG,
    payLoad: {
      id,
      friendlyTagId
    }
  };
}

export function deleteChildTag(parentId, id) {
  return {
    type: DELETE_CHILD_TAG,
    payLoad: {
      parentId,
      id
    }
  };
}

export function clearChildTags(id) {
  return {
    type: CLEAR_CHILD_TAGS_OF_CATEGORY,
    payLoad: {
      id
    }
  };
}

export function clearFilteredTags() {
  return {
    type: CLEAR_TAGS_FILTERED
  };
}

export default (state = initialState, { type, payLoad, error }) => {
  switch (type) {
    case EDIT_TAG:
    case LOAD_TAGS + START:
    case LOAD_TAG + START:
    case LOAD_TAGS_OF_CATEGORY + START:
    case LOAD_CHILD_TAGS_OF_CATEGORY + START:
    case CREATE_TAG + START:
    case CREATE_CHILD_TAG + START:
    case DELETE_TAG + START:
    case DELETE_CHILD_TAG + START:
      return {
        ...state,
        loading: true,
        error: null
      };
    case LOAD_TAGS + SUCCESS:
    case LOAD_TAGS_OF_CATEGORY + SUCCESS:
      return {
        ...state,
        data: { ...payLoad },
        loading: false
      };
    case LOAD_TAG + SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [payLoad.id]: {
            ...payLoad.data
          }
        },
        loading: false
      };
    case LOAD_CHILD_TAGS_OF_CATEGORY + SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [payLoad.id]: {
            ...state.data[payLoad.id],
            childContainer: {
              ...payLoad.data
            }
          }
        },
        loading: false
      };
    case EDIT_TAG_SUCCESS:
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          [payLoad.id]: {
            ...state.data[payLoad.id],
            ...payLoad.data
          }
        }
      };
    case EDIT_CHILD_TAG_SUCCESS:
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          [payLoad.id]: {
            ...state.data[payLoad.id],
            childContainer: {
              ...state.data[payLoad.id].childContainer,
              [payLoad.childId]: { ...payLoad.data }
            }
          }
        }
      };
    case CREATE_TAG + SUCCESS:
      return {
        ...state,
        loading: false
      };
    case CREATE_CHILD_TAG + SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [payLoad.id]: {
            ...state.data[payLoad.id],
            childContainer: {
              ...state.data[payLoad.id].childContainer,
              [payLoad.data.id]: {
                ...payLoad.data
              }
            }
          }
        },
        loading: false
      };
    case DELETE_TAG + SUCCESS:
      const tagDelete = { ...state.data };
      delete tagDelete[payLoad.id];
      return {
        ...state,
        data: { ...tagDelete },
        loading: false,
        loaded: true
      };
    case DELETE_CHILD_TAG + SUCCESS:
      const tagChildDelete = { ...state.data };
      delete tagChildDelete[payLoad.parentId].childContainer[payLoad.id];
      return {
        ...state,
        data: { ...tagChildDelete },
        loading: false
      };
    case EDIT_TAG_FAIL:
    case LOAD_TAGS + FAIL:
    case LOAD_TAG + FAIL:
    case LOAD_TAGS_OF_CATEGORY + FAIL:
    case LOAD_CHILD_TAGS_OF_CATEGORY + FAIL:
    case CREATE_CHILD_TAG + FAIL:
    case DELETE_TAG + FAIL:
    case DELETE_CHILD_TAG + FAIL:
      return {
        ...state,
        loading: false,
        error
      };
    case LOAD_ANTITAGS + START:
    case LOAD_FRIENDLYTAGS + START:
    case CREATE_ANTITAG + START:
    case CREATE_FRIENDLYTAG + START:
    case DELETE_ANTITAG + START:
    case DELETE_FRIENDLYTAG + START:
      return {
        ...state,
        loadingAntiTags: true,
        error: null
      };
    case LOAD_ANTITAGS + SUCCESS:
      return {
        ...state,
        antiTags: { ...payLoad },
        loadingAntiTags: false
      };
    case LOAD_FRIENDLYTAGS + SUCCESS:
      return {
        ...state,
        friendlyTags: { ...payLoad },
        loadingAntiTags: false
      };
    case CREATE_ANTITAG + SUCCESS:
      return {
        ...state,
        loadingAntiTags: false,
        antiTags: {
          ...state.antiTags,
          [payLoad.id]: {
            ...payLoad.data
          }
        }
      };
    case CREATE_FRIENDLYTAG + SUCCESS:
      return {
        ...state,
        loadingAntiTags: false,
        friendlyTags: {
          ...state.friendlyTags,
          [payLoad.id]: {
            ...payLoad.data
          }
        }
      };
    case DELETE_ANTITAG + SUCCESS:
      const antiTagDelete = { ...state.antiTags };
      delete antiTagDelete[payLoad.id];
      return {
        ...state,
        antiTags: { ...antiTagDelete },
        loadingAntiTags: false
      };
    case DELETE_FRIENDLYTAG + SUCCESS:
      const friendlyTagDelete = { ...state.friendlyTags };
      delete friendlyTagDelete[payLoad.id];
      return {
        ...state,
        friendlyTags: { ...friendlyTagDelete },
        loadingAntiTags: false
      };
    case LOAD_ANTITAGS + FAIL:
    case LOAD_FRIENDLYTAGS + FAIL:
    case CREATE_ANTITAG + FAIL:
    case CREATE_FRIENDLYTAG + FAIL:
    case DELETE_ANTITAG + FAIL:
    case DELETE_FRIENDLYTAG + FAIL:
      return {
        ...state,
        loadingAntiTags: false,
        error
      };
    case LOAD_TAGS_FILTERED + START:
      return {
        ...state
      };
    case LOAD_TAGS_FILTERED + SUCCESS:
      return {
        ...state,
        filteredData: { ...payLoad.data }
      };
    case LOAD_TAGS_FILTERED + FAIL:
      return {
        ...state,
        error
      };
    case CLEAR_CHILD_TAGS_OF_CATEGORY:
      return {
        ...state,
        data: {
          ...state.data,
          [payLoad.id]: {
            ...state.data[payLoad.id],
            childContainer: null
          }
        }
      };
    case CLEAR_TAGS_FILTERED:
      return {
        ...state,
        filteredData: {}
      };
    default:
      return state;
  }
};
