import { FAIL, START, SUCCESS } from "../../commons/constants";

const initialState = {
  data: {},
  loading: false,
  loaded: false,
  error: false
};

export const LOAD_ADMINS = "LOAD_ADMINS";
export const LOAD_ADMIN = "LOAD_ADMIN";
export const EDIT_ADMIN = "EDIT_ADMIN";
export const BLOCK_ADMIN = "BLOCK_ADMIN";
export const UNBLOCK_ADMIN = "UNBLOCK_ADMIN";
export const ADMIN_CHANGE_STATUS = "ADMIN_CHANGE_STATUS";

export function loadAdmins() {
  return {
    type: LOAD_ADMINS
  };
}

export function loadAdmin(id) {
  return {
    type: LOAD_ADMIN,
    payLoad: {
      id
    }
  };
}

export function editAdmin(id, data) {
  return {
    type: EDIT_ADMIN,
    payLoad: {
      id,
      data
    }
  };
}

export function blockAdmin(id) {
  return {
    type: BLOCK_ADMIN,
    payLoad: {
      id
    }
  };
}

export function unblockAdmin(id) {
  return {
    type: UNBLOCK_ADMIN,
    payLoad: {
      id
    }
  };
}

export default (state = initialState, { type, payLoad, error }) => {
  switch (type) {
    case LOAD_ADMINS + START:
      return {
        ...state,
        loading: true,
        error: null
      };
    case LOAD_ADMINS + SUCCESS:
      return {
        ...state,
        data: { ...payLoad },
        loading: false
      };
    case LOAD_ADMINS + FAIL:
      return {
        ...state,
        loading: false,
        error
      };

    case LOAD_ADMIN + START:
      return {
        ...state,
        loading: true
      };
    case LOAD_ADMIN + SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [payLoad.id]: {
            ...payLoad.data
          }
        },
        loading: false
      };
    case LOAD_ADMIN + FAIL:
      return {
        ...state,
        loading: false,
        error
      };

    case EDIT_ADMIN + START:
      return {
        ...state,
        loading: true
      };
    case EDIT_ADMIN + SUCCESS:
      return {
        ...state,
        loading: false
      };
    case EDIT_ADMIN + FAIL:
      return {
        ...state,
        loading: false,
        error
      };

    case ADMIN_CHANGE_STATUS + START:
      return {
        ...state,
        loading: true
      };
    case ADMIN_CHANGE_STATUS + SUCCESS:
      return {
        ...state,
        loading: false
      };
    case ADMIN_CHANGE_STATUS + FAIL:
      return {
        ...state,
        loading: false,
        error
      };
    default:
      return state;
  }
};
