import { selfAccountSelector } from "../SelfAccount/selectors";
import { connect } from "react-redux";
import { ROLE_SUPER_ADMIN } from "../SelfAccount/utils";

const SuperAdminAccess = ({ selfAccount, children }) => {
  return selfAccount.roles &&
    selfAccount.roles.find(role => role === ROLE_SUPER_ADMIN)
    ? children
    : null;
};

export default connect(state => ({
  selfAccount: selfAccountSelector(state)
}))(SuperAdminAccess);
