import React, { Component } from "react";
import Button from "../../components/Button/Button";
import More from "../../HOCs/More";
import TagsAddModal from "../Tags/Create/TagsCreateModal";
import { Link } from "react-router-dom";

class TreeNodeControls extends Component {
  state = {
    showTagsModal: false
  };

  openTagsModal = () => {
    this.setState({
      showTagsModal: true
    });
  };

  closeTagsModal = () => {
    this.setState(
      () => ({
        showTagsModal: false
      }),
      () => this.props.handleToggle()
    );
  };

  handleCreate = data => {
    const { handleCreate } = this.props;
    handleCreate(data);
  };

  handleDelete = id => {
    const { handleDelete } = this.props;
    handleDelete(id);
  };

  render() {
    const { node, handleClearIcon } = this.props;
    const { showTagsModal } = this.state;
    return (
      <div className="node-tree__controls">
        {(node.parent || node.children) && (
          <Link to={`tags/${node.category}/tag/${node.id}/friendlytag`}>
            <Button className="node-tree__btn">
              Добавить дружественный тег
            </Button>
          </Link>
        )}
        {(node.parent || node.children) && (
          <Link to={`tags/${node.category}/tag/${node.id}/antitag`}>
            <Button className="node-tree__btn">Добавить антитег</Button>
          </Link>
        )}
        {node.icon && (
          <Button
            className="node-tree__btn"
            onClick={() => handleClearIcon(node)}
          >
            Очистить иконку
          </Button>
        )}
        {!node.parent && (
          <Button className="node-tree__btn" onClick={this.openTagsModal}>
            Добавить тег
          </Button>
        )}
        <Button className="node-tree__btn" onClick={this.handleDelete}>
          Удалить
        </Button>
        {showTagsModal && (
          <TagsAddModal
            closeModal={this.closeTagsModal}
            handleCreate={this.handleCreate}
          />
        )}
      </div>
    );
  }
}

export default More(TreeNodeControls);
