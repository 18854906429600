import React, { Component, Fragment } from "react";
import ButtonConfirm from "../Button/ButtonConfirm";
import Button from "../Button/Button";

class ListControls extends Component {
  createButton = ({ handleClick, title, options }) => {
    return (
      <Button
        key={title}
        onClick={handleClick}
        className="list-controls__btn"
        {...options}
      >
        {title}
      </Button>
    );
  };
  createConfirmButton = ({ handleClick, title, confirmTitle }) => {
    const { id } = this.props;
    return (
      <ButtonConfirm
        key={title}
        className="list-controls__btn"
        confirmTitle={confirmTitle}
        handleConfirm={isConfirm => isConfirm && handleClick(id)}
      >
        {title}
      </ButtonConfirm>
    );
  };
  render() {
    const { buttons, children } = this.props;
    return (
      <Fragment>
        {children}
        {buttons.map(button =>
          button.isConfirm
            ? this.createConfirmButton(button)
            : this.createButton(button)
        )}
      </Fragment>
    );
  }
}

export default ListControls;
