import React, { Fragment } from "react";
import { CALORIES_MEASURE } from "../../commons/constants";
import { rounding } from "../../commons/utils";
import { allIngredientsCalc } from "./utils";

const IngredientCalc = props => {
  const { data, isShort } = props;
  let iterator = 0;
  const calculations = [
    {
      title: `Вес блюда`,
      value: rounding(data.amount, 10)
    },
    {
      title: `Вес готового блюда`,
      value: rounding(data.amountChanged, 10)
    },
    {
      title: `Вес блюда в меню`,
      short: `В:`,
      value: rounding(data.weight, 10)
    },
    {
      title: `Ккал на ${CALORIES_MEASURE} гр`,
      short: `Кал:`,
      value: rounding(data.calories, 10)
    },
    {
      title: `Белки`,
      short: `Б:`,
      value: rounding(data.proteins, 10)
    },
    {
      title: `Жиры`,
      short: `Ж:`,
      value: rounding(data.fats, 10)
    },
    {
      title: `Углеводы`,
      short: `У:`,
      value: rounding(data.carbohydrates, 10)
    },
    {
      title: `Клетч`,
      value: rounding(data.cellulose, 10)
    }
  ];
  let calcList = [];
  if (isShort) {
    calcList = calculations.map((calc, iterator) => {
      return calc.short ? calcTemplate(calc, iterator, true) : null;
    });
  } else {
    calcList = calculations.map(calc => {
      iterator += 1;
      return calcTemplate(calc, iterator);
    });
  }

  return <ul className="calc__items">{calcList}</ul>;
};

function calcTemplate(calc, key, isShort) {
  return (
    <li
      className={
        isShort
          ? "calc__item item-calc item-calc_short"
          : "calc__item item-calc"
      }
      key={key}
    >
      <Fragment>
        <div
          className={
            isShort
              ? "item-calc__name item-calc__name_short"
              : "item-calc__name"
          }
        >
          {isShort ? calc.short : calc.title}
        </div>
        <div
          className={
            isShort
              ? "item-calc__value item-calc__value_short"
              : "item-calc__value"
          }
        >
          {calc.value} гр
        </div>
      </Fragment>
    </li>
  );
}

export default IngredientCalc;
