import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { statuses, categories } from "./utils";
import MaterialsList from "./MaterialsList";
import FilterButton from "../../components/Button/FilterButton";
import Label from "../../components/Label/Label";
import InputRadio from "../../components/Input/InputRadio";
import AddMaterial from "./AddMaterial/AddMaterial";
import DatePicker, { registerLocale } from "react-datepicker";
import ru from "date-fns/locale/ru";
import InputCheckbox from "../../components/Input/InputCheckbox";
import { isShownModalSelector } from "../Modal/selectors";
import { closeModal, showModal } from "../Modal/ducks";
import { changeFilterMaterials } from "../Filters/ducks";

registerLocale("ru", ru);

class MaterialsMain extends Component {
  state = {
    search: "",
    status: "",
    category: "",
    isDateFiltrating: false,
    dateFrom: new Date(),
    dateTo: new Date(),
    editMaterial: {}
  };

  afterFilterAction = () => {
    const { changeFilterMaterials } = this.props;
    changeFilterMaterials(this.prepareFilters());
  };

  prepareFilters = () => {
    const {
      search,
      category,
      status,
      dateTo,
      dateFrom,
      isDateFiltrating
    } = this.state;
    if (isDateFiltrating) return { search, category, status, dateFrom, dateTo };
    return { search, category, status };
  };

  handleChange = e => {
    const { value, name } = e.target;
    this.setState(() => ({ [name]: value }), () => this.afterStatusAction());
  };

  handleEdit = material => {
    this.setState(
      {
        editMaterial: material
      },
      () => this.props.showModal()
    );
  };

  handleNew = () => {
    this.setState(
      {
        editMaterial: {}
      },
      () => this.props.showModal()
    );
  };

  changeRadio = e => {
    const { value, name } = e.target;
    this.setState(
      () => ({
        [name]: value
      }),
      () => this.afterFilterAction()
    );
  };

  getFilters(curValue, arrValues, name) {
    let filters = [...arrValues];
    filters.push({
      name: "",
      title: "Все"
    });
    filters = filters.map((filter, index) => (
      <li key={index}>
        <InputRadio
          name={name}
          value={filter.name}
          handleChange={this.changeRadio}
          checked={curValue === filter.name}
          label={filter.title}
        />
      </li>
    ));
    return filters;
  }

  handleChangeStartDate = date => {
    this.setState(
      () => ({
        dateFrom: date
      }),
      () => this.afterFilterAction()
    );
  };

  handleChangeEndDate = date => {
    this.setState(
      () => ({
        dateTo: date
      }),
      () => this.afterFilterAction()
    );
  };

  toggleIsDateFiltrating = () => {
    this.setState(
      ({ isDateFiltrating }) => ({
        isDateFiltrating: !isDateFiltrating
      }),
      () => this.afterFilterAction()
    );
  };

  render() {
    const {
      status,
      category,
      dateFrom,
      dateTo,
      isDateFiltrating,
      editMaterial
    } = this.state;
    const { closeModal, isShownModal } = this.props;
    const statusFilters = this.getFilters(status, statuses, "status");
    const categoryFilters = this.getFilters(category, categories, "category");
    return (
      <Fragment>
        <div className="content__head header-content">
          <div className="title__content title-content">
            <h2 className="title-content__name">Материалы</h2>
            <button onClick={this.handleNew} className="title-content__add" />
          </div>
          <div className="header-content__filter">
            <FilterButton>
              <div className="container-filter__item">
                <Label>Статус:</Label>
                <ul className="container-filter__list">{statusFilters}</ul>
              </div>
              <div className="container-filter__item">
                <Label>Категория:</Label>
                <ul className="container-filter__list">{categoryFilters}</ul>
              </div>
              <div className="container-filter__item">
                <InputCheckbox
                  label="Дата:"
                  value={isDateFiltrating}
                  handleChange={this.toggleIsDateFiltrating}
                />
                <div className="container-filter__list">
                  <Label>
                    <span>От: </span>
                    <DatePicker
                      selected={dateFrom}
                      selectsStart
                      startDate={dateFrom}
                      endDate={dateTo}
                      dateFormat="dd/MM/yyyy"
                      onChange={this.handleChangeStartDate}
                      disabled={!isDateFiltrating}
                      locale="ru"
                    />
                  </Label>
                  <Label>
                    <span>До: </span>
                    <DatePicker
                      selected={dateTo}
                      selectsEnd
                      startDate={dateFrom}
                      endDate={dateTo}
                      dateFormat="dd/MM/yyyy"
                      onChange={this.handleChangeEndDate}
                      disabled={!isDateFiltrating}
                      locale="ru"
                    />
                  </Label>
                </div>
              </div>
            </FilterButton>
          </div>
        </div>
        <MaterialsList
          status={status}
          filters={this.prepareFilters()}
          handleEdit={this.handleEdit}
        />
        {isShownModal ? (
          <AddMaterial
            closeModal={closeModal}
            wizardTitle={
              Object.values(editMaterial).length
                ? "Редактировать материал"
                : "Новый материал"
            }
            partCount={2}
            material={{ ...editMaterial }}
          />
        ) : null}
      </Fragment>
    );
  }
}

export default connect(
  state => ({
    isShownModal: isShownModalSelector(state)
  }),
  {
    changeFilterMaterials,
    showModal,
    closeModal
  }
)(MaterialsMain);
