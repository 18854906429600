import { call, put, takeLatest, select } from "redux-saga/effects";
import { errorMassages, FAIL, START, SUCCESS } from "../../commons/constants";
import * as API from "./api";
import {
  ADMIN_CHANGE_STATUS,
  BLOCK_ADMIN,
  EDIT_ADMIN,
  LOAD_ADMIN,
  LOAD_ADMINS,
  UNBLOCK_ADMIN
} from "./ducks";
import { normaliseFromLoad, normaliseItem } from "./normalisers";
import fetchAPI, { fetchReqAsync, fetchResAsync } from "../../commons/api";
import { adminByIdSelector } from "./selectors";
import { MODAL_CLOSE } from "../Modal/ducks";

export function* getAdmins() {
  try {
    yield put({ type: LOAD_ADMINS + START });
    const res = normaliseFromLoad(yield fetchReqAsync(API.getAdmins));
    yield put({
      type: LOAD_ADMINS + SUCCESS,
      payLoad: res
    });
  } catch (error) {
    yield call(alert, errorMassages.load("admins"));
    yield put({
      type: LOAD_ADMINS + FAIL,
      error
    });
  }
}

export function* getAdmin({ payLoad, url }) {
  try {
    yield put({ type: LOAD_ADMIN + START });
    const res = url
      ? normaliseItem(yield fetchReqAsync(fetchAPI.get, url))
      : normaliseItem(yield fetchReqAsync(API.getAdmin, payLoad.id));
    yield put({
      type: LOAD_ADMIN + SUCCESS,
      payLoad: {
        id: res.id,
        data: res
      }
    });
  } catch (e) {
    console.log(e);
    yield call(alert, errorMassages.load("admins"));
    yield put({
      type: LOAD_ADMIN + FAIL,
      payLoad: {
        data: e
      }
    });
  }
}

export function* putAdmin({ payLoad }) {
  try {
    yield put({ type: EDIT_ADMIN + START });
    const { id, data } = payLoad;
    yield fetchResAsync(API.putAdmin, id, data);
    const admin = yield select(adminByIdSelector, { id });
    yield put({
      type: EDIT_ADMIN + SUCCESS
    });
    yield call(getAdmin, { url: admin.self.href });
    yield put({ type: MODAL_CLOSE });
  } catch (e) {
    console.log(e);
    yield call(alert, errorMassages.edit("admins"));
    yield put({
      type: EDIT_ADMIN + FAIL,
      payLoad: {
        data: e
      }
    });
  }
}

export function* blockAdmin({ payLoad }) {
  try {
    yield put({ type: ADMIN_CHANGE_STATUS + START });
    const { id } = payLoad;
    yield fetchResAsync(API.blockAdmin, id);
    yield put({
      type: ADMIN_CHANGE_STATUS + SUCCESS
    });
    yield call(getAdmin, { payLoad: { id } });
  } catch (error) {
    yield call(alert, errorMassages.edit("admins", error));
    yield put({
      type: ADMIN_CHANGE_STATUS + FAIL,
      error
    });
  }
}

export function* unblockAdmin({ payLoad: { id } }) {
  try {
    yield put({ type: ADMIN_CHANGE_STATUS + START });
    yield fetchResAsync(API.unblockAdmin, id);
    yield put({
      type: ADMIN_CHANGE_STATUS + SUCCESS
    });
    yield call(getAdmin, { payLoad: { id } });
  } catch (error) {
    yield call(alert, errorMassages.edit("admins", error));
    yield put({
      type: ADMIN_CHANGE_STATUS + FAIL,
      payLoad: error
    });
  }
}

function* watchAdmins() {
  yield takeLatest(LOAD_ADMINS, getAdmins);
  yield takeLatest(LOAD_ADMIN, getAdmin);
  yield takeLatest(EDIT_ADMIN, putAdmin);
  yield takeLatest(BLOCK_ADMIN, blockAdmin);
  yield takeLatest(UNBLOCK_ADMIN, unblockAdmin);
}

export default watchAdmins;
