import { parseHrefToID, rounding } from "../../commons/utils";

export const listOfDifficulty = {
  easy: "низкий",
  normal: "средний",
  hard: "высокий"
};

export const listOfNotificationTime = {
  "0.5 day": "12 часов",
  "1 day": "1 день",
  "2 days": "2 дня",
  "3 days": "3 дня",
  "4 days": "4 дня",
  "5 days": "5 дней",
  "6 days": "6 дней",
  "7 days": "7 дней"
};

export const withCalcDoseAmount = (doses, recipe) => {
  const sumAmount = calcDosesProportion(doses);
  return doses.map(dose => {
    return {
      ...dose,
      amount: calcAmountOfDose(
        dose.amount,
        recipe.recommendedWeight,
        recipe.massChange,
        dose.gramsPerVolumeUnit ? dose.gramsPerVolumeUnit.amount : 1,
        sumAmount
      )
    };
  });
};

export const calcDosesProportion = doses => {
  return (
    doses &&
    doses.reduce((res, { cookingMethods, cookingMethod, amount }) => {
      const method = cookingMethods && cookingMethods[cookingMethod];
      return res + (amount * (method ? method.massChanges : 100)) / 100;
    }, 0)
  );
};

export const calcAmountOfDose = (
  amount,
  recommendedWeight,
  massChanges,
  ingredientWeight,
  sumAmount
) => {
  return rounding(
    ((amount * recommendedWeight) /
      massChanges /
      ingredientWeight /
      sumAmount) *
      100,
    0
  );
};
