import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import GetTagCategoryAutocomplete from "./GetTagCategoryAutocomplete";
import GetTagAutocomplete from "./GetTagAutocomplete.js";

function sleep(delay = 0) {
  return new Promise(resolve => {
    setTimeout(resolve, delay);
  });
}

export default function GetRecipeAutocomplete({
  input: { value, onChange },
  meta
}) {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [recipeId, setRecipeId] = useState(null);
  const [categoryId, setCategoryId] = useState(null);
  const [tagId, setTagId] = useState(null);
  const loading = open && options.length === 0;

  const showError =
    ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) &&
    meta.touched;

  useEffect(
    () => {
      onChange({
        recipe: recipeId,
        tag: tagId
      });
    },
    [recipeId, categoryId, tagId, setTagId]
  );

  useEffect(
    () => {
      let active = true;

      if (!loading) {
        return undefined;
      }

      (async () => {
        try {
          const ACCESS_TOKEN = localStorage.getItem("ACCESS_TOKEN");
          const response =
            inputValue === ""
              ? null
              : await fetch(
                  `${process.env.API_URL}/admin/recipes?title=${inputValue}`,
                  {
                    headers: {
                      Accept: "application/json",
                      "Content-type": "application/json; charset=utf-8",
                      Authorization: "Bearer " + ACCESS_TOKEN
                    }
                  }
                );
          if (response.ok) {
            const json = await response.json();
            const items = await json["_embedded"]["items"];
            if (active) {
              setOptions(items);
            }
          } else {
            const error = await response.json();
            throw new Error(JSON.stringify(error));
          }
        } catch (e) {
          // console.log(e);
        }
        // const items = await response["_embedded"]["items"];
        // console.log(items)
      })();

      return () => {
        active = false;
      };
    },
    [loading, inputValue]
  );

  useEffect(
    () => {
      if (!open) {
        setOptions([]);
      }
    },
    [open]
  );

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        margin: "20px",
        alignItems: "flex-start",
        flexWrap: "wrap",
        border: "1px solid rgba(60, 66, 82, 0.92)",
        borderRadius: "10px"
      }}
    >
      <Autocomplete
        id="recipe"
        style={{ width: 300, margin: "10px" }}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        onChange={(e, value) => {
          value && value.id && setRecipeId(value.id);
          return value;
        }}
        onInputChange={(e, value) => {
          setInputValue(value);
          return value;
        }}
        getOptionLabel={option => option.title}
        options={options}
        loading={loading}
        renderInput={params => (
          <TextField
            {...params}
            label="Выбор рецепта"
            fullWidth
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              )
            }}
          />
        )}
      />
      <div>
        {recipeId && (
          <GetTagCategoryAutocomplete
            recipeId={recipeId}
            handleSetCategoryId={setCategoryId}
          />
        )}
      </div>
      <div>
        {categoryId && (
          <GetTagAutocomplete
            categoryId={categoryId}
            handleSetTagId={setTagId}
          />
        )}
      </div>
      <div
        style={
          showError
            ? {
                color: "red",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "56px",
                margin: "10px"
              }
            : null
        }
      >
        {showError ? meta.error || meta.submitError : undefined}
      </div>
    </div>
  );
}
