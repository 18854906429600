import { getPureObjectFromHal, parseHrefToID } from "../../commons/utils";
import { weightTypesConstants } from "../../commons/constants";

export function normaliseRecipeProportions(recipeProportions) {
  const res = {};

  recipeProportions.forEach(proportion => {
    Object.assign(res, {
      [proportion.id]: {
        ...normaliseRecipeProportion(proportion)
      }
    });
  });
  return res;
}

export function normaliseRecipeProportion(recipeProportion) {
  const res = {};
  let index = 0;
  recipeProportion = getPureObjectFromHal(recipeProportion);
  recipeProportion.doses = recipeProportion.doses.map(dose =>
    getPureObjectFromHal(dose)
  );
  Object.assign(res, {
    ...recipeProportion,
    //meals: recipeProportion.meals.map(meal => parseHrefToID(meal.href)),
    recipe: parseHrefToID(recipeProportion.recipe.href),
    doses: [
      ...recipeProportion.doses.map(dose => {
        return {
          ...dose,
          link: dose.ingredient.href,
          ingredient: parseHrefToID(dose.ingredient.href),
          id: `${index++}`
        };
      })
    ]
  });
  return res;
}

export function normaliseToPost(recipeProportion) {
  const { description, doses, kilocalories } = recipeProportion;
  return {
    description: description || "",
    kilocalories: kilocalories || 1500,
    doses:
      (doses &&
        doses.map(dose => {
          const { amount, ingredient, cookingMethod, unit } = dose;
          return {
            amount: +amount,
            ingredient: ingredient || "",
            cookingMethod: cookingMethod || "",
            unit: unit || weightTypesConstants.GRAM
          };
        })) ||
      []
  };
}

export function normaliseToPut(recipeProportion) {
  const { description, doses } = recipeProportion;
  return {
    description: description || "",
    doses:
      doses.map(dose => {
        const { amount, ingredient, cookingMethod, unit } = dose;
        return {
          amount: +amount,
          ingredient: ingredient || "",
          cookingMethod: cookingMethod || "",
          unit: unit || weightTypesConstants.GRAM
        };
      }) || []
  };
}
