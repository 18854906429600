import React, { Component } from "react";
import TreeNode from "../TagsTree/TreeNode";
import Tree from "../TagsTree/Tree";
import { connect } from "react-redux";
import {
  clearChildTags,
  loadTagsOfCategory,
  loadChildTags,
  createTag,
  deleteTag,
  createChildTag,
  editTag
} from "../Tags/ducks";
import { editTagIcon } from "../Cover/ducks";
import { isLoadingTagsSelector, tagsSelector } from "../Tags/selectors";
import { deleteTagsCategory } from "../TagsCategory/ducks";
import Preloader from "../../components/Preloader/Preloader";

class TagsCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openTrees: []
    };
  }

  // если закроется категория, то должны быть закрыты все ее теги
  static getDerivedStateFromProps = nextProps => {
    if (!nextProps.isOpen) return { openTrees: [] };
    return {};
  };

  handleOpenNode = (e, id) => {
    e.preventDefault();
    const { tags, loadChildTags } = this.props;
    this.setState(
      ({ openTrees }) => ({
        openTrees: [...openTrees, id]
      }),
      () => loadChildTags(id, tags[id].children.href)
    );
  };

  handleCloseNode = (e, id) => {
    e.preventDefault();
    this.setState(
      ({ openTrees }) => ({
        openTrees: openTrees.filter(open => open !== id)
      }),
      () => this.props.clearChildTags(id)
    );
  };

  handleCreate = data => {
    const { category, createTag } = this.props;
    createTag({ ...data, category: category.id });
  };

  handleCreateChild = (id, data) => {
    const { category, createChildTag } = this.props;
    createChildTag(id, { ...data, category: category.id });
  };

  handleCreateIcon = (id, file, childId) => {
    this.props.editTagIcon(id, file, childId);
  };

  handleClearIcon = (node, child) => {
    if (child) this.props.editTag(node.id, { ...child, icon: null }, child.id);
    else this.props.editTag(node.id, { ...node, icon: null });
  };

  setNode() {
    const {
      category,
      handleOpenCategory,
      handleCloseCategory,
      isConstructor,
      isOpen,
      deleteTagsCategory
    } = this.props;
    return (
      <TreeNode
        key={category.id}
        isOpen={isOpen}
        node={category}
        handleCloseNode={handleCloseCategory}
        handleOpenNode={handleOpenCategory}
        handleDelete={() => deleteTagsCategory(category.id)}
        handleCreate={this.handleCreate}
        isConstructor={isConstructor}
      />
    );
  }

  setTree() {
    const {
      tags,
      deleteTag,
      isConstructor,
      handleSelect,
      handleUnselect,
      selectedTags,
      isDisabledTreeNode,
      isSelectChildView,
      isPickedTreeNode,
      isSelectView,
      withIcon
    } = this.props;
    const { openTrees } = this.state;
    const nodes = [];
    Object.values(tags).forEach(tag =>
      nodes.push(
        <Tree
          key={tag.id}
          tree={tag}
          openTrees={openTrees}
          selectedTags={selectedTags}
          isDisabledTreeNode={isDisabledTreeNode}
          handleCloseNode={e => this.handleCloseNode(e, tag.id)}
          handleOpenNode={e => this.handleOpenNode(e, tag.id)}
          handleSelect={handleSelect}
          handleUnselect={handleUnselect}
          handleDelete={() => deleteTag(tag.id)}
          handleCreate={data => this.handleCreateChild(tag.id, data)}
          handleCreateIcon={this.handleCreateIcon}
          handleClearIcon={this.handleClearIcon}
          isPickedTreeNode={isPickedTreeNode}
          isConstructor={isConstructor}
          isSelectChildView={isSelectChildView}
          isSelectView={isSelectView}
          withIcon={withIcon}
        />
      )
    );
    return nodes;
  }

  render() {
    const { isOpen, loading } = this.props;
    const tree = this.setTree();
    const categoryNode = this.setNode();
    return (
      <ul className="tree__container">
        {categoryNode}
        {isOpen ? loading ? <Preloader /> : tree : null}
      </ul>
    );
  }
}

export default connect(
  state => ({
    tags: tagsSelector(state),
    loading: isLoadingTagsSelector(state)
  }),
  {
    loadTagsOfCategory,
    clearChildTags,
    loadChildTags,
    deleteTagsCategory,
    deleteTag,
    createTag,
    editTagIcon,
    editTag,
    createChildTag
  }
)(TagsCategory);
