import TextField from "@material-ui/core/TextField";
import React from "react";

const FinalTextField = ({
  input: { name, onChange, value, ...restInput },
  meta,
  ...rest
}) => {
  const showError =
    ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) &&
    meta.touched;

  return (
    <TextField
      fullWidth={true}
      variant={"outlined"}
      style={{ margin: "20px 0" }}
      name={name}
      helperText={showError ? meta.error || meta.submitError : undefined}
      error={showError}
      inputProps={restInput}
      onChange={onChange}
      value={value}
      {...rest}
    />
  );
};

export { FinalTextField };
