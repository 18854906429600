import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Modal from "@material-ui/core/Modal";
import Paper from "@material-ui/core/Paper";
import arrayMutators from "final-form-arrays";
import React from "react";
import { Field } from "react-final-form";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { FieldArray } from "react-final-form-arrays";
import { DatePicker } from "../components/FinalDatePicker.js";
import { FormBody } from "../../../components/Form/FinalForm.js";
import { FinalTextField } from "../components/FinalTextField.js";
import GetRecipeAutocomplete from "../components/GetRecipeAutocomplete.js";
import moment from "moment";
import "moment/locale/ru";
import MomentUtils from "@date-io/moment";

const submitForm = closeModal => async data => {
  const ACCESS_TOKEN = localStorage.getItem("ACCESS_TOKEN");
  fetch(`${process.env.API_URL}/admin/recipe-set`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json; charset=utf-8",
      Authorization: "Bearer " + ACCESS_TOKEN
    },
    body: JSON.stringify(data)
  });
  await closeModal();
  await window.location.reload();
  //
};

function AddSpecialMenu({ isOpen, closeModal }) {
  return (
    <Modal
      open={isOpen}
      onClose={closeModal}
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center"
      }}
    >
      <MuiPickersUtilsProvider
        libInstance={moment}
        utils={MomentUtils}
        // locale={"ru"}
      >
        <Paper
          style={{
            width: "100%",
            height: "100%",
            maxHeight: "600px",
            maxWidth: "500px",
            margin: "1rem"
          }}
        >
          <div
            className="adding-menu__main"
            style={{ maxHeight: "100%", padding: "0 20px", overflow: "scroll" }}
          >
            <h4 style={{ margin: "20px" }} className="form__title">
              Добавление специального меню
            </h4>
            <FormBody
              submitFrom={submitForm(closeModal)}
              initialValues={{ recipes: [] }}
              mutators={{
                ...arrayMutators
              }}
              render={({ mutators: { pop, push, remove } }) => {
                return (
                  <>
                    <Field
                      name={"title"}
                      label={"Название"}
                      component={FinalTextField}
                    />

                    <Field
                      name={"price"}
                      label={"Цена"}
                      component={FinalTextField}
                      type={"number"}
                    />
                    <Field
                      multiline={true}
                      name={"Description"}
                      label={"Описание"}
                      component={FinalTextField}
                    />
                    <Field
                      name={"availableFrom"}
                      label={"Доступно от"}
                      component={DatePicker}
                    />
                    <Field
                      name={"availableTo"}
                      label={"Доступно до"}
                      component={DatePicker}
                    />
                    <div>
                      <div className="buttons">
                        <Button
                          type="button"
                          onClick={() => push("recipes", undefined)}
                        >
                          Добавить рецепт
                        </Button>
                      </div>
                    </div>
                    <FieldArray name={"recipes"}>
                      {({ fields }) =>
                        fields.map((name, index) => (
                          <div key={name}>
                            <hr />
                            <h4>Рецепт</h4>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                alignItems: "center"
                              }}
                            >
                              <Field
                                name={name}
                                component={GetRecipeAutocomplete}
                                validate={value =>
                                  value && value.tag ? null : "Требутеся тег"
                                }
                              />
                              <div>
                                <IconButton
                                  style={{ width: "50px", height: "50px" }}
                                  onClick={() => remove(index)}
                                >
                                  ❌
                                </IconButton>
                              </div>
                            </div>
                          </div>
                        ))
                      }
                    </FieldArray>
                    <Button type={"submit"}>Создать</Button>
                  </>
                );
              }}
            />
          </div>
        </Paper>
      </MuiPickersUtilsProvider>
    </Modal>
  );
}

export default AddSpecialMenu;
