import { START, SUCCESS, FAIL } from "../../commons/constants";

const initialState = {
  data: {},
  filteredData: {},
  loading: false,
  loaded: false,
  loadingFilteredData: false,
  error: null
};

export const LOAD_INGREDIENTS = "LOAD_INGREDIENTS";
export const LOAD_INGREDIENT = "LOAD_INGREDIENT";
export const EDIT_INGREDIENT = "EDIT_INGREDIENT";
export const CREATE_INGREDIENT = "CREATE_INGREDIENT";
export const DELETE_INGREDIENT = "DELETE_INGREDIENT";
export const LOAD_INGREDIENTS_FILTERED = "LOAD_INGREDIENTS_FILTERED";
export const CLEAR_INGREDIENTS_FILTERED = "CLEAR_INGREDIENTS_FILTERED";

export function loadIngredients(filter) {
  return {
    type: LOAD_INGREDIENTS,
    filter
  };
}

export function loadIngredient(id) {
  return {
    type: LOAD_INGREDIENT,
    payLoad: {
      id
    }
  };
}

export function editIngredient(id, data) {
  return {
    type: EDIT_INGREDIENT,
    payLoad: {
      id,
      data
    }
  };
}

export function createIngredient(data) {
  return {
    type: CREATE_INGREDIENT,
    payLoad: {
      data
    }
  };
}

export function deleteIngredient(id) {
  return {
    type: DELETE_INGREDIENT,
    payLoad: {
      id
    }
  };
}

export function clearFilteredIngredients() {
  return {
    type: CLEAR_INGREDIENTS_FILTERED
  };
}

export default (
  ingredients = initialState,
  { type, payLoad, error, count, total }
) => {
  switch (type) {
    case LOAD_INGREDIENTS + START:
      return {
        ...ingredients,
        loading: true,
        loaded: false,
        error: null
      };
    case LOAD_INGREDIENTS + SUCCESS:
      return {
        ...ingredients,
        data: { ...payLoad },
        count,
        total,
        loading: false
      };
    case LOAD_INGREDIENTS + FAIL:
      return {
        ...ingredients,
        loading: false,
        error
      };
    case LOAD_INGREDIENT + START:
      return {
        ...ingredients,
        loading: true,
        loaded: false,
        error: null
      };
    case LOAD_INGREDIENT + SUCCESS:
      return {
        ...ingredients,
        data: {
          ...ingredients.data,
          [payLoad.id]: {
            ...payLoad.data
          }
        },
        loading: false
      };
    case LOAD_INGREDIENT + FAIL:
      return {
        ...ingredients,
        loading: false,
        error
      };
    case LOAD_INGREDIENTS_FILTERED + START:
      return {
        ...ingredients,
        loadingFilteredData: true
      };
    case LOAD_INGREDIENTS_FILTERED + SUCCESS:
      return {
        ...ingredients,
        filteredData: { ...payLoad },
        loadingFilteredData: false
      };
    case LOAD_INGREDIENTS_FILTERED + FAIL:
      return {
        ...ingredients,
        loadingFilteredData: false,
        error
      };
    case EDIT_INGREDIENT + START:
      return {
        ...ingredients,
        loading: true
      };
    case EDIT_INGREDIENT + SUCCESS:
      return {
        ...ingredients,
        loading: false
      };
    case EDIT_INGREDIENT + FAIL:
      return {
        ...ingredients,
        loading: false,
        error
      };
    case CREATE_INGREDIENT + START:
      return {
        ...ingredients,
        loading: true
      };
    case CREATE_INGREDIENT + SUCCESS:
      return {
        ...ingredients,
        loading: false
      };
    case CREATE_INGREDIENT + FAIL:
      return {
        ...ingredients,
        loading: false,
        error
      };
    case DELETE_INGREDIENT + START:
      return {
        ...ingredients,
        loading: true
      };
    case DELETE_INGREDIENT + SUCCESS:
      const ingredientDelete = { ...ingredients.data };
      delete ingredientDelete[payLoad.id];
      return {
        ...ingredients,
        data: { ...ingredientDelete },
        loading: false
      };
    case DELETE_INGREDIENT + FAIL:
      return {
        ...ingredients,
        loading: false,
        error
      };
    case CLEAR_INGREDIENTS_FILTERED:
      return {
        ...ingredients,
        filteredData: {}
      };
    default:
      return ingredients;
  }
};
