import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import {
  createTagsCategory,
  uploadTagsCategoryFile
} from "../TagsCategory/ducks";
import TagsCategoryMain from "../TagsCategory/TagsCategoryMain";
import TagsCategoryAddModal from "../TagsCategory/Add/TagsCategoryAddModal";
import TagsDropZone from "../../components/DropZone/TagsDropZone";

class TagsMain extends Component {
  state = {
    showModal: false
  };
  handleAdd = id => {
    const { createTagsCategory } = this.props;
    createTagsCategory(id);
  };

  openModal = () => {
    this.setState({
      showModal: true
    });
  };

  closeModal = () => {
    this.setState({
      showModal: false
    });
  };

  handleSelect = id => {
    this.handleAdd(id);
    this.closeDialog();
  };

  acceptFile = file => {
    const { uploadTagsCategoryFile } = this.props;
    uploadTagsCategoryFile(file);
  };

  rejectFile = () => {
    alert("Неверный формат файла");
  };

  render() {
    const { showModal } = this.state;
    return (
      <Fragment>
        <div className="content__head tags-main__header">
          <div className="title__content title-content">
            <h2 className="title-content__name">Теги</h2>
            <button onClick={this.openModal} className="title-content__add" />
          </div>
        </div>
        <TagsDropZone
          rejectFile={this.rejectFile}
          acceptFile={this.acceptFile}
        />
        <TagsCategoryMain isConstructor />
        {showModal && <TagsCategoryAddModal closeModal={this.closeModal} />}
      </Fragment>
    );
  }
}

export default connect(
  null,
  { createTagsCategory, uploadTagsCategoryFile }
)(TagsMain);
