import React, { Component } from "react";
import "../../commons/styles/Filter.scss";
import Button from "./Button";

class FilterButton extends Component {
  state = {
    showContainer: false
  };
  handleToggleContainer = () => {
    this.setState(({ showContainer }) => ({
      showContainer: !showContainer
    }));
  };
  render() {
    const { children } = this.props;
    const { showContainer } = this.state;
    return (
      <div className="filter__wrapper">
        <Button
          className={
            showContainer ? "filter__btn filter__btn_active" : "filter__btn"
          }
          onClick={this.handleToggleContainer}
        />
        {showContainer && <div className="filter__container">{children}</div>}
      </div>
    );
  }
}

export default FilterButton;
