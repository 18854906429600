import { FAIL, START, SUCCESS } from "../../commons/constants";

const initialState = {
  data: {},
  loading: false,
  loaded: false,
  error: false
};

export const LOAD_MATERIALS = "LOAD_MATERIALS";
export const LOAD_MATERIAL = "LOAD_MATERIAL";
export const EDIT_MATERIAL = "EDIT_MATERIAL";
export const PUBLISH_MATERIAL = "PUBLISH_MATERIAL";
export const UNPUBLISH_MATERIAL = "UNPUBLISH_MATERIAL";
export const CREATE_MATERIAL = "CREATE_MATERIAL";
export const DELETE_MATERIAL = "DELETE_MATERIAL";
export const MATERIAL_CHANGE_STATUS = "MATERIAL_CHANGE_STATUS";

export function loadMaterials(filter) {
  return {
    type: LOAD_MATERIALS,
    filter
  };
}

export function loadMaterial(id) {
  return {
    type: LOAD_MATERIAL,
    id
  };
}

export function editMaterial(id, data) {
  return {
    type: EDIT_MATERIAL,
    payLoad: {
      id,
      data
    }
  };
}

export function publishMaterial(id, filters) {
  return {
    type: PUBLISH_MATERIAL,
    payLoad: {
      id,
      filters
    }
  };
}

export function unpublishMaterial(id, filters) {
  return {
    type: UNPUBLISH_MATERIAL,
    payLoad: {
      id,
      filters
    }
  };
}

export function createMaterial(data) {
  return {
    type: CREATE_MATERIAL,
    payLoad: {
      data
    }
  };
}

export function deleteMaterial(id, filters) {
  return {
    type: DELETE_MATERIAL,
    payLoad: {
      id,
      filters
    }
  };
}

export default (state = initialState, { type, payLoad, error }) => {
  switch (type) {
    case LOAD_MATERIALS + START:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: null
      };
    case LOAD_MATERIALS + SUCCESS:
      return {
        ...state,
        data: { ...payLoad },
        loading: false,
        loaded: true
      };
    case LOAD_MATERIALS + FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: payLoad.data
      };
    case LOAD_MATERIAL + START:
      return {
        ...state,
        loading: true,
        loaded: false
      };
    case LOAD_MATERIAL + SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [payLoad.id]: {
            ...payLoad.data
          }
        },
        loading: false,
        loaded: true
      };
    case LOAD_MATERIAL + FAIL:
      return {
        ...state,
        loading: false,
        loaded: false
      };
    case EDIT_MATERIAL + START:
      return {
        ...state,
        loading: true,
        loaded: false
      };
    case EDIT_MATERIAL + SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [payLoad.id]: {
            ...payLoad.data,
            id: payLoad.id
          }
        },
        loading: false,
        loaded: true
      };
    case EDIT_MATERIAL + FAIL:
      return {
        ...state,
        loading: false,
        loaded: false
      };
    case CREATE_MATERIAL + START:
      return {
        ...state,
        loading: true,
        loaded: false
      };
    case CREATE_MATERIAL + SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true
      };
    case CREATE_MATERIAL + FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: payLoad
      };
    case MATERIAL_CHANGE_STATUS + START:
      return {
        ...state,
        loading: true,
        loaded: false
      };
    case MATERIAL_CHANGE_STATUS + SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true
      };
    case MATERIAL_CHANGE_STATUS + FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: payLoad
      };
    case DELETE_MATERIAL + START:
      return {
        ...state,
        loading: true
      };
    case DELETE_MATERIAL + SUCCESS:
      const materialDelete = { ...state.data };
      delete materialDelete[payLoad.id];
      return {
        ...state,
        data: { ...materialDelete },
        loading: false
      };
    case DELETE_MATERIAL + FAIL:
      return {
        ...state,
        loading: false,
        error
      };
    default:
      return state;
  }
};
