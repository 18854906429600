import { START, SUCCESS, FAIL } from "../../commons/constants";

const initialState = {
  data: {},
  comments: [],
  currentDate: "",
  loading: false,
  loadingComments: false,
  loadingCreateComment: false,
  loadingDeleteComment: false,
  loadingItem: false,
  loaded: false,
  error: false
};

export const LOAD_MEALS = "LOAD_MEALS";
export const LOAD_MEAL = "LOAD_MEAL";

export const LOAD_MEAL_COMMENTS = "LOAD_MEAL_COMMENTS";
export const LOAD_MEAL_COMMENTS_SUCCESS = "LOAD_MEAL_COMMENTS_SUCCESS";
export const LOAD_MEAL_COMMENTS_FAIL = "LOAD_MEAL_COMMENTS_FAIL";

export const CREATE_MEAL_COMMENT = "CREATE_MEAL_COMMENT";
export const CREATE_MEAL_COMMENT_SUCCESS = "CREATE_MEAL_COMMENT_SUCCESS";
export const CREATE_MEAL_COMMENT_FAIL = "CREATE_MEAL_COMMENT_FAIL";

export const EDIT_MEAL = "EDIT_MEAL";
export const CREATE_MEAL = "CREATE_MEAL";
export const DELETE_MEAL = "DELETE_MEAL";
export const DELETE_RECIPE_FROM_MEAL = "DELETE_RECIPE_FROM_MEAL";

export const DELETE_MEAL_COMMENT = "DELETE_MEAL_COMMENT";
export const DELETE_MEAL_COMMENT_SUCCESS = "DELETE_MEAL_COMMENT_SUCCESS";
export const DELETE_MEAL_COMMENT_FAIL = "DELETE_MEAL_COMMENT_FAIL";

export const EDIT_MEAL_CREATE_RECIPEPROPORTION =
  "EDIT_MEAL_CREATE_RECIPEPROPORTION";
export const SET_CURRENT_DATE = "SET_CURRENT_DATE";
export const CLEAR_MEALS = "CLEAR_MEALS";

export const loadMeals = (menuId, proportionId, dateFrom, dateTo, options) => ({
  type: LOAD_MEALS,
  payLoad: {
    menuId,
    proportionId,
    dateFrom,
    dateTo
  },
  options
});

export const loadMeal = (menuId, proportionId, id, withIngredients) => ({
  type: LOAD_MEAL,
  payLoad: {
    menuId,
    proportionId,
    id,
    withIngredients
  }
});

export const loadMealComments = (dateFrom, dateTo) => ({
  type: LOAD_MEAL_COMMENTS,
  payLoad: {
    dateFrom,
    dateTo
  }
});

export const loadMealCommentsSuccess = data => ({
  type: LOAD_MEAL_COMMENTS_SUCCESS,
  payLoad: {
    data
  }
});

export const loadMealCommentsFail = error => ({
  type: LOAD_MEAL_COMMENTS_FAIL,
  error
});

export const createMealComment = (data, meal, options) => ({
  type: CREATE_MEAL_COMMENT,
  payLoad: {
    data,
    meal
  },
  options
});

export const createMealCommentSuccess = data => ({
  type: CREATE_MEAL_COMMENT_SUCCESS,
  payLoad: {
    data
  }
});

export const createMealCommentFail = error => ({
  type: CREATE_MEAL_COMMENT_FAIL,
  error
});

export const createMeal = (
  menuId,
  proportionId,
  data,
  recipeProportion,
  options
) => ({
  type: CREATE_MEAL,
  payLoad: {
    menuId,
    proportionId,
    data,
    recipeProportion
  },
  options
});

export const editMeal = (
  menuId,
  proportionId,
  id,
  data,
  isNewRecipeProportion,
  recipe
) => ({
  type: EDIT_MEAL,
  payLoad: {
    id,
    data,
    menuId,
    proportionId,
    isNewRecipeProportion,
    recipe
  }
});

export const editMealCreateRecipeProportion = (
  menuId,
  proportionId,
  id,
  data,
  recipe
) => ({
  type: EDIT_MEAL_CREATE_RECIPEPROPORTION,
  payLoad: {
    id,
    data,
    menuId,
    proportionId,
    recipe
  }
});

export const deleteMeal = (menuId, proportionId, id, data) => ({
  type: DELETE_MEAL,
  payLoad: {
    menuId,
    proportionId,
    id,
    data
  }
});

export const deleteRecipeFromMeal = (menuId, proportionId, mealId, id) => ({
  type: DELETE_RECIPE_FROM_MEAL,
  payLoad: {
    menuId,
    proportionId,
    id,
    mealId
  }
});

export const deleteMealComment = id => ({
  type: DELETE_MEAL_COMMENT,
  payLoad: {
    id
  }
});

export const deleteMealCommentSuccess = id => ({
  type: DELETE_MEAL_COMMENT_SUCCESS,
  payLoad: {
    id
  }
});

export const deleteMealCommentFail = error => ({
  type: DELETE_MEAL_COMMENT_FAIL,
  payLoad: {
    error
  }
});

export const setCurrentDate = date => ({
  type: SET_CURRENT_DATE,
  payLoad: {
    date
  }
});

export const clearMeals = () => ({
  type: CLEAR_MEALS
});

export default (meals = initialState, { type, payLoad }) => {
  switch (type) {
    case LOAD_MEALS + START:
      return {
        ...meals,
        loading: true,
        loaded: false
      };
    case LOAD_MEALS + SUCCESS:
      return {
        ...meals,
        data: { ...payLoad.data },
        loading: false,
        loaded: true
      };
    case LOAD_MEALS + FAIL:
      return {
        ...meals,
        loading: false,
        loaded: false
      };
    case LOAD_MEAL + START:
      return {
        ...meals,
        loadingItem: true
      };
    case LOAD_MEAL + SUCCESS:
      return {
        ...meals,
        data: {
          ...meals.data,
          [payLoad.id]: {
            ...payLoad.data
          }
        },
        loadingItem: false
      };
    case LOAD_MEAL + FAIL:
      return {
        ...meals,
        loadingItem: false
      };
    case DELETE_MEAL_COMMENT:
      return {
        ...meals,
        loadingDeleteComment: true
      };
    case LOAD_MEAL_COMMENTS:
      return {
        ...meals,
        loadingComments: true
      };
    case LOAD_MEAL_COMMENTS_SUCCESS:
      return {
        ...meals,
        comments: [...payLoad.data],
        loadingComments: false
      };
    case DELETE_MEAL_COMMENT_SUCCESS:
      return {
        ...meals,
        comments: meals.comments.filter(({ id }) => id !== payLoad.id),
        loadingDeleteComment: false
      };
    case DELETE_MEAL_COMMENT_FAIL:
      return {
        ...meals,
        loadingDeleteComment: false
      };
    case LOAD_MEAL_COMMENTS_FAIL:
      return {
        ...meals,
        loadingComments: false
      };
    case CREATE_MEAL_COMMENT:
      return {
        ...meals,
        loadingCreateComment: true
      };
    case CREATE_MEAL_COMMENT_SUCCESS:
      return {
        ...meals,
        comments: [...meals.comments, payLoad.data],
        loadingCreateComment: false
      };
    case CREATE_MEAL_COMMENT_FAIL:
      return {
        ...meals,
        loadingCreateComment: false
      };
    case EDIT_MEAL + START:
      return {
        ...meals,
        loading: true,
        loaded: false
      };
    case EDIT_MEAL + SUCCESS:
      return {
        ...meals,
        data: {
          ...meals.data,
          [payLoad.id]: {
            id: payLoad.id,
            ...payLoad.data
          }
        },
        loading: false,
        loaded: true
      };
    case EDIT_MEAL + FAIL:
      return {
        ...meals,
        loading: false,
        loaded: false
      };
    case CREATE_MEAL + START:
      return {
        ...meals,
        loading: true,
        loaded: false
      };
    case CREATE_MEAL + SUCCESS:
      return {
        ...meals,
        loading: false,
        loaded: true
      };
    case CREATE_MEAL + FAIL:
      return {
        ...meals,
        loading: false,
        loaded: false
      };
    case DELETE_MEAL + START:
      return {
        ...meals,
        loading: true,
        loaded: false
      };
    case DELETE_MEAL + SUCCESS:
      const mealDelete = { ...meals.data };
      delete mealDelete[payLoad.id];
      return {
        ...meals,
        data: { ...mealDelete },
        loading: false,
        loaded: true
      };
    case DELETE_MEAL + FAIL:
      return {
        ...meals,
        loading: false,
        loaded: false
      };
    case SET_CURRENT_DATE:
      return {
        ...meals,
        currentDate: payLoad.date
      };
    case CLEAR_MEALS:
      return {
        ...initialState
      };
    default:
      return meals;
  }
};
