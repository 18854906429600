import {
  APPROVED_DRAFT,
  DRAFT,
  PUBLISHED,
  RECIPE_APPROVED,
  PHOTO_ADDED
} from "../../commons/constants";

export const statuses = [
  {
    name: DRAFT,
    title: "Черновик"
  },
  {
    name: APPROVED_DRAFT,
    title: "Черновик утверждён"
  },
  {
    name: PHOTO_ADDED,
    title: "Фото добавлено"
  },
  {
    name: RECIPE_APPROVED,
    title: "Рецепт готов"
  },
  {
    name: PUBLISHED,
    title: "Рецепт добавлен"
  }
];
