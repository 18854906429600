import React, { Component } from "react";
import { connect } from "react-redux";
import { accountsSelector, isLoadingAccountsSelector } from "./selectors";
import Preloader from "../../components/Preloader/Preloader";
import { loadAccounts } from "./ducks";
import Account from "./Account";

const columns = ["Телефон", "Email", "Пользователь", "", "", ""];

class AccountsList extends Component {
  componentDidMount() {
    this.props.loadAccounts();
  }

  handleEditPhone = (e, id) => {
    const { handleEditPhone, accounts } = this.props;
    handleEditPhone({
      ...accounts[id]
    });
  };

  render() {
    const { loading, accounts } = this.props;
    if (loading) return <Preloader />;
    const accountsList = Object.values(accounts).map(account => (
      <Account
        key={account.id}
        account={account}
        handleEditPhone={this.handleEditPhone}
      />
    ));
    let index = 0;
    return (
      <div>
        <div className="table-list table-list__account">
          <div className="table-list__row table-list__row_head">
            {columns.map(column => (
              <div key={index++}>{column}</div>
            ))}
          </div>
          {accountsList}
        </div>
      </div>
    );
  }
}

export default connect(
  state => ({
    accounts: accountsSelector(state),
    loading: isLoadingAccountsSelector(state)
  }),
  { loadAccounts }
)(AccountsList);
