import React, { Component } from "react";
import Input from "../../../components/Input/Input";
import { createTagsCategory } from "../ducks";
import { connect } from "react-redux";
import Form from "../../../components/Form/Form";
import classes from "./style.module.scss";
import withConfirmModal from "../../../HOCs/withConfirmModal";

class TagsCategoryAddModal extends Component {
  state = {
    title: ""
  };
  handleChange = e => {
    const { name, value } = e.target;
    this.setState({
      [name]: value
    });
  };
  handleCreateTagsCategory = () => {
    const { createTagsCategory, closeModal } = this.props;
    const { title } = this.state;
    createTagsCategory({
      title
    });
    closeModal();
  };
  handleCancel = () => {
    this.props.closeModal();
  };
  render() {
    return (
      <div className={classes["add-modal"]}>
        <Form
          title="Добавление категории"
          handleCancel={this.handleCancel}
          handleSubmit={this.handleCreateTagsCategory}
        >
          <Input
            name="title"
            value={this.state.title}
            handleChange={this.handleChange}
            placeholder="Введите название категории"
            required
            autofocus
          />
        </Form>
      </div>
    );
  }
}

export default connect(
  null,
  { createTagsCategory }
)(withConfirmModal(TagsCategoryAddModal));
