import React from "react";
import Label from "../Label/Label";

const InputRadio = ({ label, checked, name, value, handleChange }) => {
  return (
    <Label className="input-radio__container">
      {label}
      <input
        type="radio"
        name={name}
        checked={checked}
        value={value}
        onChange={handleChange}
      />
      <span className="input-radio__checkmark" />
    </Label>
  );
};

export default InputRadio;
