import { MENUS_URL, SERVER_URL } from "../../commons/constants";
import fecthAPI from "../../commons/api";

export const getMenuProportions = id =>
  fecthAPI.get(`${SERVER_URL}/${MENUS_URL}/${id}/proportions`);

export const getMenuProportion = (menuId, id) =>
  fecthAPI.get(`${SERVER_URL}/${MENUS_URL}/${menuId}/proportions/${id}`);

export const postMenuProportion = (id, data) =>
  fecthAPI.post(`${SERVER_URL}/${MENUS_URL}/${id}/proportions`, data);

export const putMenuProportion = (menuId, id, data) =>
  fecthAPI.put(`${SERVER_URL}/${MENUS_URL}/${menuId}/proportions/${id}`, data);

export const deleteMenuProportion = (menuId, id) =>
  fecthAPI.delete(`${SERVER_URL}/${MENUS_URL}/${menuId}/proportions/${id}`);
