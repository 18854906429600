import { START, SUCCESS, FAIL } from "../../commons/constants";

const initialState = {
  data: {},
  statusData: {},
  lastChangedData: [],
  loading: false,
  loaded: false,
  error: false
};

export const LOAD_RECIPEPROPORTIONS = "LOAD_RECIPEPROPORTIONS";
export const LOAD_RECIPEPROPORTIONS_STATUS = "LOAD_RECIPEPROPORTIONS_STATUS";
export const LOAD_RECIPEPROPORTION = "LOAD_RECIPEPROPORTION";
export const EDIT_RECIPEPROPORTION = "EDIT_RECIPEPROPORTION";
export const CREATE_RECIPEPROPORTION = "CREATE_RECIPEPROPORTION";
export const DELETE_RECIPEPROPORTION = "DELETE_RECIPEPROPORTION";
export const EDIT_RECIPEPROPORTIONE_CHANGED = "EDIT_RECIPEPROPORTIONE_CHANGED";
export const EDIT_RECIPEPROPORTIONE_LAST = "EDIT_RECIPEPROPORTIONE_LAST";
export const CLEAR_RECIPEPROPORTIONE_LAST = "CLEAR_RECIPEPROPORTIONE_LAST";
export const CLEAR_RECIPEPROPORTIONS = "CLEAR_RECIPEPROPORTIONS";
export const CLEAR_RECIPEPROPORTIONS_LAST = "CLEAR_RECIPEPROPORTIONS_LAST";

export function loadRecipeProportions(recipeId) {
  return {
    type: LOAD_RECIPEPROPORTIONS,
    payLoad: {
      recipeId
    }
  };
}

export function loadRecipeProportion(recipeId, id, options) {
  return {
    type: LOAD_RECIPEPROPORTION,
    payLoad: {
      recipeId,
      id
    },
    options
  };
}

export function createRecipeProportion(id, data) {
  return {
    type: CREATE_RECIPEPROPORTION,
    payLoad: {
      id,
      data
    }
  };
}

export function editRecipeProportion(recipeId, id, data) {
  return {
    type: EDIT_RECIPEPROPORTION,
    payLoad: {
      recipeId,
      id,
      data
    }
  };
}

export function editRecipeProportionLast(id) {
  return {
    type: EDIT_RECIPEPROPORTIONE_LAST,
    payLoad: {
      id
    }
  };
}

export function editRecipeProportionChanged(recipeId, id) {
  return {
    type: EDIT_RECIPEPROPORTIONE_CHANGED,
    payLoad: {
      recipeId,
      id
    }
  };
}

export function deleteRecipeProportion(menuId, proportionId, mealId, id) {
  return {
    type: DELETE_RECIPEPROPORTION,
    payLoad: {
      menuId,
      proportionId,
      mealId,
      id
    }
  };
}

export function clearRecipeProportionsLast() {
  return {
    type: CLEAR_RECIPEPROPORTIONS_LAST
  };
}

export default (recipeProportions = initialState, action) => {
  const { type, payLoad } = action;
  switch (type) {
    case LOAD_RECIPEPROPORTIONS + START:
      return {
        ...recipeProportions,
        loading: true,
        loaded: false
      };
    case LOAD_RECIPEPROPORTIONS + SUCCESS:
      return {
        ...recipeProportions,
        data: { ...payLoad.data },
        loading: false,
        loaded: true
      };
    case LOAD_RECIPEPROPORTIONS + FAIL:
      return {
        ...recipeProportions,
        loading: false,
        loaded: false
      };
    case LOAD_RECIPEPROPORTIONS_STATUS + START:
      return {
        ...recipeProportions,
        loading: true,
        loaded: false
      };
    case LOAD_RECIPEPROPORTIONS_STATUS + SUCCESS:
      return {
        ...recipeProportions,
        statusData: { ...payLoad.data },
        loading: false,
        loaded: true
      };
    case LOAD_RECIPEPROPORTIONS_STATUS + FAIL:
      return {
        ...recipeProportions,
        loading: false,
        loaded: false
      };
    case LOAD_RECIPEPROPORTION:
      return {
        ...recipeProportions,
        loading: true,
        loaded: false
      };
    case LOAD_RECIPEPROPORTION + SUCCESS:
      return {
        ...recipeProportions,
        data: {
          ...recipeProportions.data,
          [payLoad.id]: {
            id: payLoad.id,
            ...payLoad.data
          }
        },
        loading: false,
        loaded: true
      };
    case LOAD_RECIPEPROPORTION + FAIL:
      return {
        ...recipeProportions,
        loading: true,
        loaded: false
      };
    case EDIT_RECIPEPROPORTION + START:
      return {
        ...recipeProportions,
        loading: true,
        loaded: false
      };
    case EDIT_RECIPEPROPORTION + SUCCESS:
      return {
        ...recipeProportions,
        loading: false,
        loaded: true
      };
    case EDIT_RECIPEPROPORTION + FAIL:
      return {
        ...recipeProportions,
        loading: false,
        loaded: false
      };
    case EDIT_RECIPEPROPORTIONE_CHANGED + START:
      return {
        ...recipeProportions,
        loading: true,
        loaded: false
      };
    case EDIT_RECIPEPROPORTIONE_CHANGED + SUCCESS:
      return {
        ...recipeProportions,
        loading: false,
        loaded: true
      };
    case EDIT_RECIPEPROPORTIONE_CHANGED + FAIL:
      return {
        ...recipeProportions,
        loading: false,
        loaded: false
      };
    case EDIT_RECIPEPROPORTIONE_LAST:
      const lastChangedData = [...recipeProportions.lastChangedData];
      if (!lastChangedData.find(data => data === payLoad.id))
        lastChangedData.push(payLoad.id);
      return {
        ...recipeProportions,
        lastChangedData
      };
    case CREATE_RECIPEPROPORTION + START:
      return {
        ...recipeProportions,
        loading: true,
        loaded: false
      };
    case CREATE_RECIPEPROPORTION + SUCCESS:
      return {
        ...recipeProportions,
        data: {
          ...recipeProportions.data,
          [payLoad.id]: {
            ...payLoad.data,
            id: payLoad.id
          }
        },
        loading: false,
        loaded: true
      };
    case CREATE_RECIPEPROPORTION + FAIL:
      return {
        ...recipeProportions,
        loading: false,
        loaded: false
      };
    case CLEAR_RECIPEPROPORTIONE_LAST:
      return {
        ...recipeProportions,
        lastChangedData: [
          ...recipeProportions.lastChangedData.filter(id => id !== payLoad.id)
        ]
      };
    case CLEAR_RECIPEPROPORTIONS_LAST:
      return {
        ...recipeProportions,
        lastChangedData: []
      };
    case DELETE_RECIPEPROPORTION + START:
      return {
        ...recipeProportions,
        loading: true,
        loaded: false
      };
    case DELETE_RECIPEPROPORTION + SUCCESS:
      const recipeDelete = { ...recipeProportions.data };
      delete recipeDelete[payLoad.id];
      return {
        ...recipeProportions,
        data: { ...recipeDelete },
        loading: false,
        loaded: true
      };
    case DELETE_RECIPEPROPORTION + FAIL:
      return {
        ...recipeProportions,
        loading: false,
        loaded: false
      };
    case CLEAR_RECIPEPROPORTIONS: {
      return {
        ...recipeProportions,
        data: {}
      };
    }
    default:
      return recipeProportions;
  }
};
