import React, { Component } from "react";
import { menuTypes } from "../utils";
import { weekDays } from "../../../commons/constants";
import MealsDay from "./MealsDay";
import { formatShowDate } from "../../../commons/utils";
import { Link } from "react-router-dom";
import Table from "../../../components/Table/Table";
import TableHead from "../../../components/Table/TableHead";
import TableBody from "../../../components/Table/TableBody";
import TableRow from "../../../components/Table/TableRow";
import TableCell from "../../../components/Table/TableCell";
import Preloader from "../../../HOCs/Preloader";
import { isLoadingRecipesSelector } from "../../Recipes/selectors";
import { connect } from "react-redux";
import { editRecipesChanged } from "../../Recipes/ducks";
import { isLoadingMealsSelector } from "../selectors";
import { setCurrentDate } from "../ducks";

class MealsWeek extends Component {
  setCurrentDate = date => {
    const { setCurrentDate } = this.props;
    setCurrentDate(date.toISOString());
  };

  render() {
    const { currentWeek, proportion, currentCalorie, menu } = this.props;
    const mealsWeek = weekDays.map((day, index) => {
      const date = new Date(
        currentWeek.year,
        currentWeek.prevMonth,
        currentWeek.start + index
      );
      return (
        <TableRow key={day.name}>
          <TableCell>
            <Link
              to={`day`}
              onClick={() => this.setCurrentDate(date)}
              className="table-month__day"
            >
              <div>{day.short}</div>
              <div>{formatShowDate(date)}</div>
            </Link>
          </TableCell>
          <MealsDay
            day={day}
            currentWeek={currentWeek}
            menu={menu}
            proportion={proportion}
            currentCalorie={currentCalorie}
          />
        </TableRow>
      );
    });
    const menuTypesHead = menu.mealTypes.map(mealType => {
      return (
        <TableCell key={mealType} isHeadCell>
          {menuTypes.find(({ name }) => name === mealType).value}
        </TableCell>
      );
    });
    return (
      <Table className="table-month">
        <TableHead>
          <TableRow>
            <TableCell isHeadCell />
            {menuTypesHead}
          </TableRow>
        </TableHead>
        <TableBody>{mealsWeek}</TableBody>
      </Table>
    );
  }
}

export default connect(
  state => ({
    loading: isLoadingRecipesSelector(state) || isLoadingMealsSelector(state)
  }),
  { editRecipesChanged, setCurrentDate }
)(Preloader(MealsWeek));
