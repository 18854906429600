import React, { Component } from "react";
import { connect } from "react-redux";
import Label from "../../../components/Label/Label";
import Select from "../../../components/Select/Select";
import { statuses } from "../../Recipes/utils";
import { editRecipeStatus } from "../../Recipes/ducks";
import { isLoadingRecipeStatusSelector } from "../../Recipes/selectors";
import Preloader from "../../../components/Preloader/Preloader";

class SelectStatus extends Component {
  handleChange = ({ target: { value, name } }) => {
    const { handleChange } = this.props;
    handleChange({ target: { name, value: { value, isChanged: true } } });
  };
  render() {
    const {
      recipe: { status },
      loading,
      disabled
    } = this.props;
    if (loading) return <Preloader />;
    return (
      <div className="tail-dish__property property-dish__status status-dish">
        <Label value="Выберите статус" />
        <Select
          name="status"
          value={
            status.value &&
            statuses.find(({ name }) => name === status.value).title
          }
          values={statuses.map(status => status.name)}
          options={statuses.map(status => status.title)}
          handleChange={this.handleChange}
          disabled={disabled}
        />
      </div>
    );
  }
}

export default connect(
  state => ({
    loading: isLoadingRecipeStatusSelector(state)
  }),
  { editRecipeStatus }
)(SelectStatus);
