import { connect } from "react-redux";
import { editRecipeWithProportion } from "../Recipes/ducks";
import {
  isLoadingRecipeProportionsSelector,
  recipeProportionByIdSelector
} from "./selectors";
import { ingredientsSelector } from "../Ingredients/selectors";
import {
  isLoadingRecipesSelector,
  recipesSelector
} from "../Recipes/selectors";
import RecipeProportion from "./RecipeProportion";
import Preloader from "../../HOCs/Preloader";

const mapsStateToProps = (state, ownProps) => {
  const recipeProportion = recipeProportionByIdSelector(state, ownProps);
  return {
    recipeProportion,
    loading:
      isLoadingRecipeProportionsSelector(state) ||
      isLoadingRecipesSelector(state),
    ingredients: ingredientsSelector(state),
    recipes: recipesSelector(state)
  };
};

export default connect(
  mapsStateToProps,
  {
    editRecipeWithProportion
  }
)(Preloader(RecipeProportion));
