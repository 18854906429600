import { FAIL, START, SUCCESS } from "../../commons/constants";

const initialState = {
  data: {},
  currentProportion: null,
  loading: false,
  loaded: false,
  error: false
};

export const LOAD_MENUPROPORTIONS = "LOAD_MENUPROPORTIONS";
export const LOAD_MENUPROPORTION = "LOAD_MENUPROPORTION";
export const CREATE_MENUPROPORTION = "CREATE_MENUPROPORTION";
export const EDIT_MENUPROPORTION = "EDIT_MENUPROPORTION";
export const DELETE_MENUPROPORTION = "DELETE_MENUPROPORTION";
export const CLEAR_MENUPROPORTIONS = "CLEAR_MENUPROPORTIONS";
export const SET_CURRENT_MENUPROPORTION = "SET_CURRENT_MENUPROPORTION";

export function loadMenuProportions(menuId) {
  return {
    type: LOAD_MENUPROPORTIONS,
    payLoad: {
      menuId
    }
  };
}

export const setCurrentMenuProportion = id => ({
  type: SET_CURRENT_MENUPROPORTION,
  payLoad: id
});

export function createMenuProportion(id, data) {
  return {
    type: CREATE_MENUPROPORTION,
    payLoad: {
      id,
      data
    }
  };
}

export function editMenuProportion(menuId, id, data) {
  return {
    type: EDIT_MENUPROPORTION,
    payLoad: {
      id,
      data,
      menuId
    }
  };
}

export function deleteMenuProportion(menuId, id) {
  return {
    type: DELETE_MENUPROPORTION,
    payLoad: {
      menuId,
      id
    }
  };
}

export function clearMenuProportions() {
  return {
    type: CLEAR_MENUPROPORTIONS
  };
}

export default (state = initialState, action) => {
  const { type, payLoad } = action;
  switch (type) {
    case LOAD_MENUPROPORTIONS + START:
      return {
        ...state,
        loading: true,
        loaded: false
      };
    case LOAD_MENUPROPORTIONS + SUCCESS:
      return {
        ...state,
        data: {
          ...payLoad.data
        },
        loading: false,
        loaded: true
      };
    case LOAD_MENUPROPORTIONS + FAIL:
      return {
        ...state,
        loading: false,
        loaded: false
      };
    case LOAD_MENUPROPORTION + START:
      return {
        ...state,
        loading: true,
        loaded: false
      };
    case LOAD_MENUPROPORTION + SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [payLoad.id]: {
            ...payLoad.data
          }
        },
        loading: false,
        loaded: true
      };
    case LOAD_MENUPROPORTION + FAIL:
      return {
        ...state,
        loading: false,
        loaded: false
      };
    case CREATE_MENUPROPORTION + START:
      return {
        ...state,
        loading: true,
        loaded: false
      };
    case CREATE_MENUPROPORTION + SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [payLoad.id]: {
            id: payLoad.id,
            ...payLoad.data
          }
        },
        loading: false,
        loaded: true
      };
    case CREATE_MENUPROPORTION + FAIL:
      return {
        ...state,
        loading: true,
        loaded: false
      };
    case EDIT_MENUPROPORTION + START:
      return {
        ...state,
        loading: true,
        loaded: false
      };
    case EDIT_MENUPROPORTION + SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [payLoad.id]: {
            ...payLoad.data
          }
        },
        loading: false,
        loaded: true
      };
    case EDIT_MENUPROPORTION + FAIL:
      return {
        ...state,
        loading: false,
        loaded: false
      };
    case DELETE_MENUPROPORTION + START:
      return {
        ...state,
        loading: true,
        loaded: false
      };
    case DELETE_MENUPROPORTION + SUCCESS:
      const proportionDelete = { ...state.data };
      delete proportionDelete[payLoad.id];
      return {
        ...state,
        data: { ...proportionDelete },
        loading: false,
        loaded: true
      };
    case DELETE_MENUPROPORTION + FAIL:
      return {
        ...state,
        loading: false,
        loaded: false
      };
    case SET_CURRENT_MENUPROPORTION:
      return {
        ...state,
        currentProportion: payLoad
      };
    case CLEAR_MENUPROPORTIONS:
      return {
        ...initialState
      };
    default:
      return state;
  }
};
