import { createSelector } from "reselect";

const profilesGetter = state => state.profiles;

export const profilesSelector = createSelector(
  profilesGetter,
  ({ data }) => data
);

export const isLoadingProfilesSelector = createSelector(
  profilesGetter,
  ({ loading }) => {
    return loading;
  }
);
