import { getPureObjectFromHal } from "../../commons/utils";

export function normaliseFromLoad(data) {
  const res = {};
  const resData = [...data._embedded.items];
  resData.forEach(item => {
    item = normaliseItem(item);
    Object.assign(res, {
      [item.id]: {
        ...item
      }
    });
  });
  return res;
}

export function normaliseItem(item) {
  const res = {};
  item = getPureObjectFromHal(item);
  Object.assign(res, {
    ...item
  });
  return res;
}
