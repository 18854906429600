import React from "react";
import { ReactComponent as ImgPreloader } from "./preloader.svg";
import "./style.scss";

function Preloader({ small }) {
  return (
    <div className={`preloader__wrapper`}>
      <div className={`preloader ${small ? "preloader_small" : ""}`}>
        <ImgPreloader />
      </div>
    </div>
  );
}

export default Preloader;
