import React, { Component, Fragment } from "react";
import { format } from "date-fns";
import DatePicker, { registerLocale } from "react-datepicker/es";
import ShowForm from "../../../components/Form/ShowForm";
import "react-datepicker/dist/react-datepicker.css";
import {
  clearURLQueryParams,
  compareDates,
  dateFormat
} from "../../../commons/utils";
import PreloaderHOC from "../../../HOCs/Preloader";
import { menuTypes } from "../../Meals/utils";
import Dashboard from "../../Meals/Dashboard";
import Meal from "../../Meals/Meal";

registerLocale("ru", format.ru);

class MenuProportions extends Component {
  constructor(props) {
    super(props);
    const params = new URLSearchParams(props.location.search);
    const mealType = params.get("mealType");
    this.state = {
      selectedMeal: mealType ? mealType : menuTypes[0].name,
      currentDate: props.date
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.currentCalorie !== this.props.currentCalorie)
      this.loadMealsOfDay();
  }

  componentWillMount() {
    this.loadMealsOfDay();
  }

  handleChangeMeal = meal => {
    this.setState(
      {
        selectedMeal: meal
      },
      () => {
        clearURLQueryParams();
      }
    );
  };

  loadMealsOfDay = () => {
    const {
      loadMeals,
      menu,
      proportions,
      currentCalorie,
      loadMealComments
    } = this.props;
    const { currentDate } = this.state;
    const proportion = Object.values(proportions).find(
      proportion => proportion.kilocalories === currentCalorie
    );
    if (proportion) {
      loadMeals(
        menu.id,
        proportion.id,
        dateFormat(currentDate),
        dateFormat(currentDate),
        {
          withIngredients: true
        }
      );
      loadMealComments(
        `${dateFormat(currentDate)}`,
        `${dateFormat(currentDate)}`
      );
    }
  };

  handleSelectDate = date => {
    this.setState(
      {
        currentDate: new Date(date)
      },
      () => {
        this.loadMealsOfDay();
        this.props.setCurrentDate(this.state.currentDate);
      }
    );
  };

  getMenuNavigation() {
    const { currentDate, selectedMeal } = this.state;
    const { meals, menu } = this.props;
    return menu.mealTypes.map(mealType => {
      const meal =
        Object.values(meals).find(
          meal =>
            meal.mealType === mealType &&
            compareDates(new Date(meal.date), currentDate)
        ) || {};
      return (
        <li
          key={mealType}
          className={
            mealType === selectedMeal
              ? "navigation-edit__item navigation-edit__item_active"
              : "navigation-edit__item"
          }
          onClick={() => this.handleChangeMeal(mealType)}
        >
          <div>{menuTypes.find(({ name }) => name === mealType).value}</div>
          <Dashboard recipeProportions={meal.mealRecipes || []} isShort />
        </li>
      );
    });
  }

  getCurrentMeal() {
    const { currentDate, selectedMeal } = this.state;
    const { meals } = this.props;
    return (
      Object.values(meals).find(
        meal =>
          //proportion.meals.find(item => item === meal.id) &&
          meal.mealType === selectedMeal &&
          compareDates(new Date(meal.date), currentDate)
      ) || {}
    );
  }

  getCurrentProportion() {
    const { proportions, currentCalorie } = this.props;
    return Object.values(proportions).find(
      ({ kilocalories }) => kilocalories === currentCalorie
    );
  }

  getFormCollection() {
    const { proportions, currentCalorie } = this.props;
    return [
      {
        type: "select",
        name: "calories",
        value: currentCalorie,
        placeholder: "Каллории",
        disabled: false,
        values: [
          ...Object.values(proportions).map(proportion => proportion.id)
        ],
        options: [
          ...Object.values(proportions).map(
            proportion => proportion.kilocalories
          )
        ],
        className: "header-edit__calories"
      }
    ];
  }

  render() {
    const { menu, meals, currentCalorie, handleSelectProportion } = this.props;
    const { currentDate, selectedMeal } = this.state;
    const menuNavigation = this.getMenuNavigation();
    const meal = this.getCurrentMeal();
    const proportion = this.getCurrentProportion();
    const formCollection = this.getFormCollection();
    const recipeProportions = [];
    Object.values(meals).forEach(meal => {
      meal.mealRecipes.forEach(recipeProportion => {
        recipeProportions.push(recipeProportion);
      });
    });
    return (
      <Fragment>
        <div className="menu-edit__header header-edit">
          <div className="header-edit__day">
            <ShowForm
              formCollection={formCollection}
              handleChange={handleSelectProportion}
            />
            <DatePicker
              selected={currentDate}
              onSelect={this.handleSelectDate}
              locale="ru"
            />
            <Dashboard
              className="header-edit__dashboard"
              recipeProportions={recipeProportions}
            />
          </div>
          <nav>
            <ul className="header-edit__navigation navigation-edit">
              {menuNavigation}
            </ul>
          </nav>
        </div>
        {selectedMeal ? (
          <div className="menu__meal meal-menu">
            <Meal
              key={meal.id}
              meal={meal}
              menuId={menu.id}
              proportionId={proportion ? proportion.id : ""}
              currentCalorie={currentCalorie}
              currentDate={currentDate}
              type={selectedMeal}
            />
          </div>
        ) : (
          "Веберите тип меню"
        )}
      </Fragment>
    );
  }
}

export default PreloaderHOC(MenuProportions);
