import { combineReducers } from "redux";
import ingredients from "./features/Ingredients/ducks";
import filters from "./features/Filters/ducks";
import recipes from "./features/Recipes/ducks";
import menus from "./features/Menus/ducks";
import menuProportions from "./features/MenuProportions/ducks";
import recipeProportions from "./features/RecipeProportions/ducks";
import meals from "./features/Meals/ducks";
import cover from "./features/Cover/ducks";
import security from "./features/Authorisation/ducks";
import tags from "./features/Tags/ducks";
import materials from "./features/Materials/ducks";
import comments from "./features/Comments/ducks";
import modal from "./features/Modal/ducks";
import accounts from "./features/Accounts/ducks";
import profiles from "./features/Profiles/ducks";
import confirmation from "./features/Confirmation/ducks";
import admins from "./features/Admins/ducks";
import tagsCategory from "./features/TagsCategory/ducks";
import selfAccount from "./features/SelfAccount/ducks";

export default combineReducers({
  ingredients,
  filters,
  recipes,
  menus,
  recipeProportions,
  menuProportions,
  meals,
  cover,
  security,
  tags,
  materials,
  comments,
  modal,
  accounts,
  profiles,
  confirmation,
  admins,
  tagsCategory,
  selfAccount
});
