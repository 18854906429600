import React, { Component } from "react";
import TreeNodeExpand from "./TreeNodeExpand";
import TreeNodeControls from "./TreeNodeControls";
import TreeNodeSelect from "./TreeNodeSelect";
import IconPicker from "../../components/ImagePicker/IconPicker";
import { Image } from "@material-ui/icons";

class TreeNode extends Component {
  render() {
    const {
      node,
      isOpen,
      isList,
      handleOpenNode,
      handleCloseNode,
      handleDelete,
      handleCreate,
      isConstructor,
      isSelectView,
      isPicked,
      isDisabled,
      isSelected,
      handleSelect,
      handleUnselect,
      withIcon,
      handleCreateIcon,
      handleClearIcon
    } = this.props;
    return (
      <li
        className={
          "tree__node node-tree " + (isPicked ? "node-tree_picked" : "")
        }
      >
        {!isList && (
          <TreeNodeExpand
            isOpen={isOpen}
            handleCloseNode={handleCloseNode}
            handleOpenNode={handleOpenNode}
          />
        )}
        {withIcon && (
          <div className="node-tree__icon">
            {isConstructor ? (
              <IconPicker
                data={node.icon && node.icon.path}
                name={node.id}
                afterLoad={file => handleCreateIcon(node.id, file)}
              />
            ) : (
              <img
                className="node-tree__img"
                src={node.icon ? node.icon.path : "/images/picture.svg"}
              />
            )}
          </div>
        )}
        <div className="node-tree__content">{node.title}</div>
        {isConstructor && (
          <TreeNodeControls
            node={node}
            handleClearIcon={handleClearIcon}
            handleDelete={handleDelete}
            handleCreate={handleCreate}
          />
        )}
        {isSelectView && (
          <TreeNodeSelect
            isSelected={isSelected}
            isDisabled={isDisabled}
            handleSelect={handleSelect}
            handleUnselect={handleUnselect}
          />
        )}
      </li>
    );
  }
}

export default TreeNode;
