import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import AddIngredient from "./AddNewIngredient/AddIngredient";
import IngredientList from "./IngredientList";
import { loadIngredients } from "./ducks";
import { isShownModalSelector } from "../Modal/selectors";
import { closeModal, showModal } from "../Modal/ducks";
import SearchWrapper from "../../components/Search/SearchWrapper";
import withPagination from "../../components/Table/withPagination";

const countArr = [10, 20, 50];

class Ingredients extends Component {
  state = {
    editIngredient: {},
    filters: {
      title: ""
    }
  };

  componentDidMount() {
    const { filters } = this.props;
    this.props.afterChangeFilter({ ...filters, ...this.state.filters });
  }

  handleChangeFilter = (name, value) => {
    this.setState(
      ({ filters }) => ({
        filters: {
          ...filters,
          [name]: value
        }
      }),
      () => this.props.handleChangeFilter(this.state.filters)
    );
  };

  handleEdit = ingredient => {
    this.setState(
      {
        editIngredient: ingredient
      },
      () => this.props.showModal()
    );
  };

  handleNew = () => {
    this.setState(
      {
        editIngredient: {}
      },
      () => this.props.showModal()
    );
  };

  render() {
    const { editIngredient } = this.state;
    const {
      closeModal,
      isShownModal,
      filters: { page, count },
      handleChangePage,
      handleChangeRowsPerPage
    } = this.props;
    return (
      <Fragment>
        <div className="content__head head-content">
          <div className="head-content__title title-content">
            <h2 className="title-content__name">Ингредиенты</h2>
            <button onClick={this.handleNew} className="title-content__add" />
          </div>
          <SearchWrapper
            placeholder="Найти ингредиент"
            className="search__main"
            afterSearch={value => this.handleChangeFilter("title", value)}
            minWordLength={3}
            time={400}
          />
        </div>
        <IngredientList
          handleEdit={this.handleEdit}
          page={page - 1}
          rowsPerPage={count}
          countArr={countArr}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
        {isShownModal ? (
          <AddIngredient
            closeModal={closeModal}
            wizardTitle={
              Object.values(editIngredient).length
                ? "Редактировать ингредиент"
                : "Новый ингредиент"
            }
            partCount={4}
            {...editIngredient}
          />
        ) : null}
      </Fragment>
    );
  }
}

export default connect(
  state => ({
    isShownModal: isShownModalSelector(state)
  }),
  { afterChangeFilter: loadIngredients, showModal, closeModal }
)(withPagination(Ingredients));
