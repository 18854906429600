import { createSelector } from "reselect";
import { objFilter } from "../../commons/utils";

const mealsGetter = state => state.meals;
const mealGetter = (state, ownProps) => {
  return state.meals.data[ownProps.id];
};
const proportionGetter = (state, ownProps) => {
  return ownProps.proportion || {};
};

export const mealsSelector = createSelector(
  mealsGetter,
  ({ data }) => {
    return data || {};
  }
);

export const mealCommentsSelector = createSelector(
  mealsGetter,
  ({ comments }) => {
    return comments || [];
  }
);

export const getCurrentDateSelector = createSelector(
  mealsGetter,
  ({ currentDate }) => {
    return currentDate ? new Date(currentDate) : new Date();
  }
);

export const mealsOfMenuProportionSelector = createSelector(
  mealsGetter,
  proportionGetter,
  ({ data }, proportion) => {
    return (
      objFilter(data, meal =>
        proportion.meals.find(item => item === meal.id)
      ) || {}
    );
  }
);

export const mealByIdSelector = createSelector(
  mealGetter,
  meal => {
    return meal || {};
  }
);

export const proportionMealsSelector = createSelector(
  mealsGetter,
  proportionGetter,
  ({ data }, proportion) => {
    return objFilter(data, meal => {
      return (
        proportion.meals &&
        proportion.meals.find(proportionMeal => proportionMeal === meal.id)
      );
    });
  }
);

export const isLoadingMealsSelector = createSelector(
  mealsGetter,
  ({ loading }) => {
    return loading;
  }
);

export const isLoadingMealCommentsSelector = createSelector(
  mealsGetter,
  ({ loadingComments }) => {
    return loadingComments;
  }
);

export const isLoadingDeleteMealCommentsSelector = createSelector(
  mealsGetter,
  ({ loadingDeleteComment }) => {
    return loadingDeleteComment;
  }
);

export const isLoadingCreateMealCommentSelector = createSelector(
  mealsGetter,
  ({ loadingCreateComment }) => {
    return loadingCreateComment;
  }
);

export const isLoadingMealSelector = createSelector(
  mealsGetter,
  ({ loadingItem }) => {
    return loadingItem;
  }
);

export const isLoadedMealsSelector = createSelector(
  mealsGetter,
  ({ loaded }) => {
    return loaded;
  }
);
