import { getPureObjectFromHal } from "../../commons/utils";

export function normaliseFromLoad(recipes) {
  const res = {};
  const resMaterials = [...recipes._embedded.items];
  resMaterials.forEach(material => {
    material = normaliseMaterial(material);
    Object.assign(res, {
      [material.id]: {
        ...material
      }
    });
  });
  return res;
}

export function normaliseMaterial(material) {
  const res = {};
  material = getPureObjectFromHal(material);
  Object.assign(res, {
    ...material,
    tags: material.tags.map(tag => normaliseTag(tag))
  });
  return res;
}

export function normaliseTag(tag) {
  const res = {};
  tag = getPureObjectFromHal(tag);
  Object.assign(res, {
    ...tag,
    parent: tag.parent && tag.parent.parent_id
  });
  return res;
}

export function normaliseToFetch(recipe) {
  const { title, content, shortContent, category, tags } = recipe;
  return {
    content: content || "",
    shortContent: shortContent || "",
    category: category || "",
    title: title || "",
    tags: tags.map(({ id }) => id) || []
  };
}
