import React, { Component } from "react";
import { rounding } from "../../../commons/utils";
import { allIngredientsCalc } from "../utils";
import TableRow from "../../../components/Table/TableRow";
import TableCell from "../../../components/Table/TableCell";

class IngredientsTableGeneralRow extends Component {
  render() {
    const { ingredients } = this.props;
    const calc = allIngredientsCalc(ingredients);
    return (
      <TableRow className="table-ingredient__general">
        <TableCell colspan="2">Общие показатели блюда</TableCell>
        <TableCell />
        <TableCell />
        <TableCell>{rounding(calc.amount, 10)}</TableCell>
        <TableCell>{rounding(calc.amountChanged, 10)}</TableCell>
        <TableCell>{rounding(calc.proteins, 10)}</TableCell>
        <TableCell>{rounding(calc.fats, 10)}</TableCell>
        <TableCell>{rounding(calc.carbohydrates, 10)}</TableCell>
        <TableCell>{rounding(calc.cellulose, 10)}</TableCell>
        <TableCell>{rounding(calc.calories, 10)}</TableCell>
        <TableCell />
      </TableRow>
    );
  }
}

export default IngredientsTableGeneralRow;
