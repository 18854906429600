import React, { Component, Fragment } from "react";
import RecipeProportion from "../../RecipeProportions/RecipeProportionContainer";
import { connect } from "react-redux";
import {
  clearRecipeProportionsLast,
  loadRecipeProportion
} from "../../RecipeProportions/ducks";
import { isLoadingRecipeProportionsSelector } from "../../RecipeProportions/selectors";
import Preloader from "../../../components/Preloader/Preloader";
import { clearRecipesLast } from "../ducks";
import { clearSearchIngredient } from "../../Filters/ducks";
import { isLoadingRecipesSelector } from "../selectors";

class RecipeEdit extends Component {
  componentWillMount() {
    const { loadRecipeProportion, recipeId, id } = this.props;
    loadRecipeProportion(recipeId, id, {
      withIngredients: true
    });
  }
  componentWillUnmount() {
    const {
      clearRecipesLast,
      clearRecipeProportionsLast,
      clearSearchIngredient
    } = this.props;
    clearRecipesLast();
    clearRecipeProportionsLast();
    clearSearchIngredient();
  }
  handleBack = () => {
    const { history } = this.props;
    history.goBack();
  };
  render() {
    //TODO загружается список категорий тегов до того, как поменяется loading на true
    const { id, loading } = this.props;
    if (loading) return <Preloader />;
    return (
      <Fragment>
        <div className="content__head header-content">
          <div className="title__content title-content">
            <button className="title-content__back" onClick={this.handleBack} />
            <h2 className="title-content__name">Редактирование рецепта</h2>
          </div>
        </div>
        <RecipeProportion
          handleDeleteRecipe={this.openModal}
          id={id}
          isEditView
        />
      </Fragment>
    );
  }
}

export default connect(
  state => ({
    loading:
      isLoadingRecipeProportionsSelector(state) ||
      isLoadingRecipesSelector(state)
  }),
  {
    loadRecipeProportion,
    clearRecipesLast,
    clearRecipeProportionsLast,
    clearSearchIngredient
  }
)(RecipeEdit);
