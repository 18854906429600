import {
  clearObjectFromEmptyFields,
  getPureObjectFromHal,
  objToArray,
  parseHrefToID
} from "../../commons/utils";
import { normaliseTag } from "../Tags/normalisers";

export function normaliseFromLoad(items) {
  const resItems = [...items._embedded.items];
  const res = {};
  resItems.forEach(item => {
    item = normaliseItem(item);
    Object.assign(res, {
      [item.id]: {
        ...item
      }
    });
  });
  return res;
}

export function normaliseItem(item) {
  const res = {};
  item = getPureObjectFromHal(item);
  const nutrients = {};
  const cookingMethods = {};
  const cookingMethodsNutrietns = {};
  item.nutrients.forEach(nutrient => {
    Object.assign(nutrients, {
      [nutrient.type]: {
        ...nutrient
      }
    });
  });
  item.cookingMethods.forEach(cookingMethod => {
    cookingMethod.nutrientsChanges.forEach(nutrient => {
      Object.assign(cookingMethodsNutrietns, {
        [nutrient.type]: nutrient.change
      });
    });
    const type = cookingMethod.type;
    delete cookingMethod.nutrientsChanges;
    delete cookingMethod.type;
    Object.assign(cookingMethods, {
      [type]: {
        ...cookingMethod,
        ...cookingMethodsNutrietns
      }
    });
  });
  Object.assign(res, {
    ...item,
    nutrients: { ...nutrients },
    cookingMethods: { ...cookingMethods },
    cover: {
      isChanged: false,
      path: item.cover ? item.cover.path : ""
    },
    tags: item.tags.map(tag => normaliseTag(tag)),
    icon: {
      isChanged: false,
      path: item.icon ? item.icon.path : ""
    }
  });
  return res;
}

export function normaliseToFetch(ingredient) {
  const {
    title,
    cookingMethods,
    nutrients,
    gramsPerVolumeUnit,
    cover,
    icon,
    alternativeTitles,
    cellulosePerHundredGrams,
    description,
    internalDescription,
    kilocaloriesPerHundredGrams,
    tags,
    manufacturer,
    ingredientGroup,
    barcode
  } = {
    ...ingredient
  };

  let newCookingMethods = [];
  Object.entries({ ...cookingMethods }).forEach(([key, value]) => {
    const obj = clearObjectFromEmptyFields(value);
    if (!Object.entries(obj).length) return;
    newCookingMethods.push({
      type: key,
      ...obj
    });
  });

  newCookingMethods = newCookingMethods.map(cookingMethod => {
    const method = { ...cookingMethod };
    const cookingNutrients = [
      {
        name: "proteins",
        value: method.proteins
      },
      {
        name: "carbohydrates",
        value: method.carbohydrates
      },
      {
        name: "fats",
        value: method.fats
      }
    ];
    delete method.proteins;
    delete method.carbohydrates;
    delete method.fats;
    const nutrientsChanges = cookingNutrients
      .filter(cookingNutrient => cookingNutrient.value)
      .map(cookingNutrient => ({
        change: cookingNutrient.value,
        type: cookingNutrient.name
      }));
    if (nutrientsChanges.length) {
      return {
        ...method,
        nutrientsChanges
      };
    }
    return {
      ...method
    };
  });

  const data = {
    title: title || "",
    ingredientTags: tags.map(({ id }) => id) || [],
    alternativeTitles: alternativeTitles || [],
    cellulosePerHundredGrams: cellulosePerHundredGrams,
    description: description || "",
    internalDescription: internalDescription || "",
    kilocaloriesPerHundredGrams: kilocaloriesPerHundredGrams,
    nutrients: objToArray(nutrients) || [],
    cookingMethods: newCookingMethods,
    gramsPerVolumeUnit: gramsPerVolumeUnit,
    manufacturer: manufacturer || "",
    barcode: barcode || ""
  };

  if (cover.isChanged)
    Object.assign(data, {
      cover: parseHrefToID(cover.path)
    });
  if (icon.isChanged)
    Object.assign(data, {
      icon: parseHrefToID(icon.path)
    });

  return data;
}
