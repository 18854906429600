import { call, put, takeLatest, select } from "redux-saga/effects";
import { errorMassages, FAIL, START, SUCCESS } from "../../commons/constants";
import * as API from "./api";
import {
  LOAD_MATERIALS,
  LOAD_MATERIAL,
  EDIT_MATERIAL,
  CREATE_MATERIAL,
  DELETE_MATERIAL,
  PUBLISH_MATERIAL,
  UNPUBLISH_MATERIAL,
  MATERIAL_CHANGE_STATUS
} from "./ducks";
import { normaliseFromLoad, normaliseMaterial } from "./normalisers";
import fetchAPI, { fetchReqAsync, fetchResAsync } from "../../commons/api";
import { MODAL_CLOSE } from "../Modal/ducks";
import { materialsFilterSelector } from "../Filters/selectors";

export function* getMaterials({ filter }) {
  try {
    yield put({ type: LOAD_MATERIALS + START });
    const res = normaliseFromLoad(
      yield fetchReqAsync(API.getMaterials, filter)
    );
    yield put({
      type: LOAD_MATERIALS + SUCCESS,
      payLoad: res
    });
  } catch (e) {
    console.log(e);
    yield call(alert, errorMassages.load("materials"));
    yield put({
      type: LOAD_MATERIALS + FAIL,
      payLoad: {
        data: e
      }
    });
  }
}

export function* getMaterial({ id, url }) {
  try {
    yield put({ type: LOAD_MATERIAL + START });
    const res = normaliseMaterial(
      url
        ? yield fetchReqAsync(fetchAPI.get, url)
        : yield fetchReqAsync(API.getMaterial, id)
    );
    yield put({
      type: LOAD_MATERIAL + SUCCESS,
      payLoad: { data: { ...res }, id: res.id }
    });
  } catch (error) {
    console.log(error);
    yield call(alert, errorMassages.load("material", error));
    yield put({
      type: LOAD_MATERIAL + FAIL,
      payLoad: {
        data: error
      }
    });
  }
}

export function* editMaterial(action) {
  try {
    const { id, data } = action.payLoad;
    yield put({ type: EDIT_MATERIAL + START });
    yield fetchResAsync(API.putMaterial, id, data);
    yield put({
      type: EDIT_MATERIAL + SUCCESS,
      payLoad: {
        id,
        data
      }
    });
    yield put({ type: MODAL_CLOSE });
  } catch (error) {
    console.log(error);
    yield call(alert, errorMassages.edit("material", error));
    yield put({
      type: EDIT_MATERIAL + FAIL,
      payLoad: {
        data: error
      }
    });
  }
}

function* publishMaterial({ payLoad: { id } }) {
  try {
    yield put({ type: MATERIAL_CHANGE_STATUS + START });
    yield fetchResAsync(API.publishMaterial, id);
    yield put({
      type: MATERIAL_CHANGE_STATUS + SUCCESS
    });
    const filter = yield select(materialsFilterSelector);
    console.log(filter);
    // если фильтр у материла пустой, то это значит что выводим с любым статусом
    if (filter && filter.status) yield call(deleteMaterialStore, { id });
    else yield call(getMaterial, { id });
  } catch (error) {
    console.log(error);
    yield call(alert, errorMassages.edit("material", error));
    yield put({
      type: MATERIAL_CHANGE_STATUS + FAIL,
      payLoad: error
    });
  }
}

function* unpublishMaterial({ payLoad: { id } }) {
  try {
    yield put({ type: MATERIAL_CHANGE_STATUS + START });
    yield fetchResAsync(API.unpublishMaterial, id);
    yield put({
      type: MATERIAL_CHANGE_STATUS + SUCCESS
    });
    const filter = yield select(materialsFilterSelector);
    console.log(filter);
    // если фильтр у материла пустой, то это значит что выводим с любым статусом
    if (filter && filter.status) yield call(deleteMaterialStore, { id });
    else yield call(getMaterial, { id });
  } catch (error) {
    console.log(error);
    yield call(alert, errorMassages.edit("material", error));
    yield put({
      type: MATERIAL_CHANGE_STATUS + FAIL,
      payLoad: error
    });
  }
}

export function* createMaterial(action) {
  try {
    yield put({ type: CREATE_MATERIAL + START });
    const { data } = action.payLoad;
    const res = yield fetchResAsync(API.postMaterial, data);
    yield put({
      type: CREATE_MATERIAL + SUCCESS
    });
    yield call(getMaterial, { url: res.headers.get("Location") });
    yield put({ type: MODAL_CLOSE });
  } catch (error) {
    console.log(error);
    yield call(alert, errorMassages.create("material", error));
    yield put({
      type: CREATE_MATERIAL + FAIL,
      payLoad: error
    });
  }
}

function* deleteMaterialStore({ id }) {
  try {
    yield put({ type: DELETE_MATERIAL + START });
    yield put({
      type: DELETE_MATERIAL + SUCCESS,
      payLoad: { id }
    });
  } catch (error) {
    console.log(error);
    yield call(alert, errorMassages.delete("material", error));
    yield put({
      type: DELETE_MATERIAL + FAIL,
      error
    });
  }
}

function* deleteMaterial({ payLoad: { id } }) {
  try {
    yield put({ type: MATERIAL_CHANGE_STATUS + START });
    yield fetchResAsync(API.deleteMaterial, id);
    yield put({
      type: MATERIAL_CHANGE_STATUS + SUCCESS
    });
    const filter = yield select(materialsFilterSelector);
    // если фильтр у материла пустой, то это значит что выводим с любым статусом
    if (filter && filter.status) yield call(deleteMaterialStore, { id });
    else yield call(getMaterial, { id });
  } catch (error) {
    console.log(error);
    yield call(alert, errorMassages.delete("material", error));
    yield put({
      type: MATERIAL_CHANGE_STATUS + FAIL,
      payLoad: error
    });
  }
}

function* watchMaterials() {
  yield takeLatest(LOAD_MATERIALS, getMaterials);
  yield takeLatest(LOAD_MATERIAL, getMaterial);
  yield takeLatest(EDIT_MATERIAL, editMaterial);
  yield takeLatest(PUBLISH_MATERIAL, publishMaterial);
  yield takeLatest(UNPUBLISH_MATERIAL, unpublishMaterial);
  yield takeLatest(CREATE_MATERIAL, createMaterial);
  yield takeLatest(DELETE_MATERIAL, deleteMaterial);
}

export default watchMaterials;
