import React from "react";
import { stringShema } from "../../features/Ingredients/shema";

const Select = props => {
  const {
    name,
    value,
    handleChange,
    placeholder,
    options,
    error,
    id,
    disabled,
    className,
    values
  } = props;
  const isValid = stringShema.isValidSync(value);
  return (
    <div className={"select__wrapper " + className}>
      <select
        name={name}
        defaultValue={value}
        onChange={handleChange}
        data-id={id}
        disabled={disabled}
        className={
          !isValid || (error && !value)
            ? "select select_error"
            : value
            ? "select select_valid"
            : "select"
        }
      >
        <option value={value || placeholder} hidden selected disabled>
          {value || placeholder}
        </option>
        {options.map((option, index) => {
          return (
            <option
              key={option}
              value={values ? values[index] : option}
              label={option}
              className="option"
            >
              {option}
            </option>
          );
        })}
      </select>
      <span className="select__label">{placeholder}</span>
      <span className="select__bar" />
    </div>
  );
};

export default Select;
