import React, { Component } from "react";
import PortionsData from "./PortionsData";
import Description from "./Description";
import CookingSteps from "./CookingSteps";
import SelectStatus from "./SelectStatus";
import Tags from "./Tags";
import TimeToCook from "./TimeToCook";
import Difficulty from "./Dificulty";
import Inventory from "./Inventory";
import TextArea from "../../../components/Textarea/TextArea";
import Select from "../../../components/Select/Select";
import { listOfNotificationTime } from "../utils";

class RecipeProportionTail extends Component {
  render() {
    const {
      doses,
      recipe,
      disabled,
      proportion,
      handleChangeRecipe,
      handleChangeNumberRecipe,
      handleChangeRecipeProportion
    } = this.props;
    return (
      <div className="tail-dish">
        <PortionsData
          recipe={recipe}
          ingredients={doses}
          disabled={disabled}
          handleChange={handleChangeNumberRecipe}
        />
        <TimeToCook
          disabled={disabled}
          recipe={recipe}
          handleChange={handleChangeNumberRecipe}
        />
        <Difficulty
          recipe={recipe}
          disabled={disabled}
          handleChange={handleChangeRecipe}
        />
        <div className="tail-dish__property property-dish__preparing">
          <TextArea
            name="preparingDesc"
            value={recipe.preparingDesc}
            placeholder="Требуется подготовка"
            className="portions-dish__input"
            handleChange={handleChangeRecipe}
            disabled={disabled}
          />
        </div>
        <div className="tail-dish__property property-dish__notification">
          <Select
            name="notificationTime"
            value={listOfNotificationTime[recipe.notificationTime] || "нет"}
            placeholder="Дата оповещения"
            handleChange={handleChangeRecipe}
            disabled={disabled}
            options={[...Object.values(listOfNotificationTime), "нет"]}
            values={[...Object.keys(listOfNotificationTime), ""]}
          />
          {recipe.notificationTime && (
            <TextArea
              name="notificationDesc"
              value={recipe.notificationDesc}
              placeholder="Комментарий оповещения"
              className="portions-dish__input"
              handleChange={handleChangeRecipe}
              disabled={disabled}
            />
          )}
        </div>
        <Description
          recipe={recipe}
          description={proportion.description}
          handleChange={handleChangeRecipeProportion}
          disabled={disabled}
        />
        <CookingSteps recipe={recipe} disabled={disabled} />
        <div className="tail-dish__property property-dish__serving">
          <TextArea
            name="serving"
            value={recipe.serving}
            placeholder="Сервировка"
            className="portions-dish__input"
            handleChange={handleChangeRecipe}
            disabled={disabled}
          />
        </div>
        <Inventory
          handleChange={handleChangeRecipe}
          selectedTags={recipe.inventory}
        />
        <Tags
          ingredientTags={recipe.ingredientTags}
          tags={recipe.tags}
          handleChange={handleChangeRecipe}
        />
        <SelectStatus
          recipe={recipe}
          id={recipe.id}
          disabled={disabled}
          handleChange={handleChangeRecipe}
        />
      </div>
    );
  }
}

export default RecipeProportionTail;
