import { createSelector } from "reselect";

const accountsGetter = state => state.accounts;

export const accountsSelector = createSelector(
  accountsGetter,
  ({ data }) => data
);

export const isLoadingAccountsSelector = createSelector(
  accountsGetter,
  ({ loading }) => {
    return loading;
  }
);
