import { call, put, takeLatest } from "redux-saga/effects";
import {
  CHANGE_FILTER_COMMENTS,
  CHANGE_FILTER_MATERIALS,
  CHANGE_FILTER_RECIPES,
  CHANGE_FILTER_INGREDIENT,
  CHANGE_SEARCH_MENU,
  CHANGE_SEARCH_TAG
} from "./ducks";
import { SUCCESS } from "../../commons/constants";
import { getFilteredIngredients } from "../Ingredients/sagas";
import { getFilteredRecipes } from "../Recipes/sagas";
import { getMenus } from "../Menus/sagas";
import { getFilteredTags } from "../Tags/sagas";
import { getComments } from "../Comments/sagas";
import { getMaterials } from "../Materials/sagas";

function* searchIngredient({ payLoad: { filter } }) {
  yield put({
    type: CHANGE_FILTER_INGREDIENT + SUCCESS,
    payLoad: { filter }
  });
  yield call(getFilteredIngredients, {});
}

function* filterRecipes({ filter }) {
  yield put({
    type: CHANGE_FILTER_RECIPES + SUCCESS,
    filter
  });
  yield call(getFilteredRecipes, { filter });
}

function* searchMenu({ payLoad: { search } }) {
  yield put({
    type: CHANGE_SEARCH_MENU + SUCCESS,
    payLoad: { search }
  });
  yield call(getMenus, {});
}

function* searchTag({ payLoad: { search } }) {
  yield put({
    type: CHANGE_SEARCH_TAG + SUCCESS,
    payLoad: { search }
  });
  yield call(getFilteredTags, {});
}

function* filterComments({ id, filter }) {
  yield put({
    type: CHANGE_FILTER_COMMENTS + SUCCESS,
    filter
  });
  yield call(getComments, { id, filter });
}

function* filterMaterials({ filter }) {
  yield put({
    type: CHANGE_FILTER_MATERIALS + SUCCESS,
    filter
  });
  yield call(getMaterials, { filter });
}

function* watchFilter() {
  yield takeLatest(CHANGE_FILTER_INGREDIENT, searchIngredient);
  yield takeLatest(CHANGE_SEARCH_MENU, searchMenu);
  yield takeLatest(CHANGE_FILTER_RECIPES, filterRecipes);
  yield takeLatest(CHANGE_SEARCH_TAG, searchTag);
  yield takeLatest(CHANGE_FILTER_COMMENTS, filterComments);
  yield takeLatest(CHANGE_FILTER_MATERIALS, filterMaterials);
}

export default watchFilter;
