import React, { Component } from "react";
import { connect } from "react-redux";
import Recipe from "./Recipe";
import { recipesSelector, isLoadingRecipesSelector } from "../selectors";
import { loadRecipes } from "../ducks";
import Preloader from "../../../components/Preloader/Preloader";
import Pagination from "../../../components/Table/Pagination";
import {
  getCountRecipesSelector,
  getTotalRecipesSelector
} from "../../Recipes/selectors";

const columns = ["Название", "", "", ""];

class RecipeList extends Component {
  render() {
    const {
      loading,
      recipes,
      total,
      handleChangePage,
      handleChangeRowsPerPage,
      rowsPerPage,
      page,
      countArr
    } = this.props;
    if (loading) return <Preloader />;
    const recipeList = Object.values(recipes).map(recipe => (
      <Recipe key={recipe.id} {...recipe} />
    ));
    return (
      <div>
        <div className="table-list">
          <div className="table-list__row table-list__row_recipe table-list__row_head">
            {columns.map((column, index) => (
              <div key={index + 1}>{column}</div>
            ))}
          </div>
          {recipeList}
          <div className="table-list__pagination">
            <Pagination
              total={total}
              page={page}
              countArr={countArr}
              rowsPerPage={rowsPerPage}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  state => ({
    recipes: recipesSelector(state),
    loading: isLoadingRecipesSelector(state),
    total: getTotalRecipesSelector(state),
    count: getCountRecipesSelector(state)
  }),
  { loadRecipes }
)(RecipeList);
