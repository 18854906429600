import React, { Component } from "react";
import { connect } from "react-redux";
import { deleteMaterial, publishMaterial, unpublishMaterial } from "./ducks";
import ListControls from "../../components/ListControls/ListControls";
import Button from "../../components/Button/Button";
import { DELETED, DRAFT, PUBLISHED } from "../../commons/constants";
import { Link } from "react-router-dom";
import { statuses } from "./utils";

class Material extends Component {
  unpublishMaterial = () => {
    const { unpublishMaterial, material, filters } = this.props;
    unpublishMaterial(material.id, filters);
  };
  publishMaterial = () => {
    const { publishMaterial, material, filters } = this.props;
    publishMaterial(material.id, filters);
  };

  getControlsButtons = () => {
    const { material, handleEdit } = this.props;
    return [
      {
        title: "Редактировать",
        handleClick: e => handleEdit(e, material.id)
      },
      ...this.getStatusButton(material.status)
    ];
  };

  getStatusButton = status => {
    const { deleteMaterial } = this.props;
    let statusArr = [];
    switch (status) {
      case PUBLISHED:
        statusArr = statusArr.concat([
          {
            title: "Отменить публикацию",
            handleClick: this.unpublishMaterial
          },
          {
            title: "Удалить",
            handleClick: deleteMaterial,
            isConfirm: true,
            confirmTitle: "Подтвердить удаление"
          }
        ]);
        break;
      case DRAFT:
        statusArr = statusArr.concat([
          {
            title: "Опубликовать",
            handleClick: this.publishMaterial
          },
          {
            title: "Удалить",
            handleClick: deleteMaterial,
            isConfirm: true,
            confirmTitle: "Подтвердить удаление"
          }
        ]);
        break;
      case DELETED:
        statusArr = statusArr.concat([
          {
            title: "Опубликовать",
            handleClick: this.publishMaterial
          }
        ]);
        break;
    }
    return statusArr;
  };

  render() {
    const { material } = this.props;
    const { title, id, status } = material;
    return (
      <div className="table-list__row">
        <div className="table-list__name">{title}</div>
        <div className="table-list__status">
          {statuses.find(({ name }) => name === status).title}
        </div>
        <ListControls id={id} buttons={this.getControlsButtons()}>
          <Link to={`materials/${id}/comments`} className="list-controls__link">
            <Button className="list-controls__btn list-controls__btn_comments">
              Комментарии
            </Button>
          </Link>
        </ListControls>
      </div>
    );
  }
}

export default connect(
  null,
  { deleteMaterial, publishMaterial, unpublishMaterial }
)(Material);
