import React, { Component } from "react";
import TablePagination from "@material-ui/core/TablePagination";

class Pagination extends Component {
  render() {
    const {
      handleChangePage,
      handleChangeRowsPerPage,
      total,
      page,
      rowsPerPage,
      countArr
    } = this.props;
    return (
      <TablePagination
        rowsPerPageOptions={countArr}
        component="div"
        count={total}
        rowsPerPage={rowsPerPage}
        page={page}
        backIconButtonProps={{
          "aria-label": "previous page"
        }}
        nextIconButtonProps={{
          "aria-label": "next page"
        }}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    );
  }
}

export default Pagination;
