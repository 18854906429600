import MomentUtils from "@date-io/moment";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelActions from "@material-ui/core/ExpansionPanelActions";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import IconButton from "@material-ui/core/IconButton";
import Modal from "@material-ui/core/Modal";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import arrayMutators from "final-form-arrays";
import moment from "moment";
import React, { Component } from "react";
import { Field } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import { DatePicker } from "../components/FinalDatePicker.js";
import { FinalTextField } from "../components/FinalTextField.js";
import { FormBody } from "../../../components/Form/FinalForm.js";

import GetRecipeAutocomplete from "../components/GetRecipeAutocomplete.js";
import Preloader from "../../../components/Preloader/Preloader";
import { Remove, ExpandMore, Add, Update } from "@material-ui/icons";
const columns = [
  "Название",
  "Описание",
  "цена",
  "Доступно от",
  "Доступно до",
  "Рецепты",
  "Действия"
];

const test = [
  "title",
  "description",
  "price",
  "availableFrom",
  "availableTo",
  "recipes",
  "action"
];

const fetchData = async () => {
  const ACCESS_TOKEN = localStorage.getItem("ACCESS_TOKEN");
  const responce = await fetch(`${process.env.API_URL}/admin/recipe-set`, {
    headers: {
      Accept: "application/json",
      "Content-type": "application/json; charset=utf-8",
      Authorization: "Bearer " + ACCESS_TOKEN
    }
  });
  const json = responce.json();
  return json;
};

const removeRecipeSet = async id => {
  const ACCESS_TOKEN = localStorage.getItem("ACCESS_TOKEN");
  const responce = await fetch(
    `${process.env.API_URL}/admin/recipe-set/${id}`,
    {
      headers: {
        Accept: "application/json",
        "Content-type": "application/json; charset=utf-8",
        Authorization: "Bearer " + ACCESS_TOKEN
      },
      method: "DELETE"
    }
  );
};

const removeRecipeFromRecipeSet1 = async (recipeSet, recipeId) => {
  let newRecipeSet = {
    ...recipeSet,
    availableFrom: moment(recipeSet.availableFrom).format("YYYY-MM-DD"),
    availableTo: moment(recipeSet.availableTo).format("YYYY-MM-DD"),
    recipes: recipeSet.recipes
      .filter(item => item.id !== recipeId)
      .map(item => ({ recipe: item.recipe.id, tag: item.tag.id }))
  };
  delete newRecipeSet.id;

  const ACCESS_TOKEN = localStorage.getItem("ACCESS_TOKEN");
  const responce = await fetch(
    `${process.env.API_URL}/admin/recipe-set/${recipeSet.id}`,
    {
      headers: {
        Accept: "application/json",
        "Content-type": "application/json; charset=utf-8",
        Authorization: "Bearer " + ACCESS_TOKEN
      },
      body: JSON.stringify(newRecipeSet),
      method: "PUT"
    }
  );
  if (responce.ok) {
    window.location.reload();
  }
};

const addRecipesToRecipeSet = async (recipeSet, { recipes }) => {
  const formatOldRecipe = recipeSet.recipes.map(item => ({
    recipe: item.recipe.id,
    tag: item.tag.id
  }));

  let newRecipeSet = {
    ...recipeSet,
    availableFrom: moment(recipeSet.availableFrom).format("YYYY-MM-DD"),
    availableTo: moment(recipeSet.availableTo).format("YYYY-MM-DD"),
    recipes: [...formatOldRecipe, ...recipes]
  };
  delete newRecipeSet.id;

  const ACCESS_TOKEN = localStorage.getItem("ACCESS_TOKEN");
  const responce = await fetch(
    `${process.env.API_URL}/admin/recipe-set/${recipeSet.id}`,
    {
      headers: {
        Accept: "application/json",
        "Content-type": "application/json; charset=utf-8",
        Authorization: "Bearer " + ACCESS_TOKEN
      },
      body: JSON.stringify(newRecipeSet),
      method: "PUT"
    }
  );
  if (responce.ok) {
    window.location.reload();
  }
};

const updateRecipeSet = async (recipeSet, updater) => {
  const formatOldRecipe = recipeSet.recipes.map(item => ({
    recipe: item.recipe.id,
    tag: item.tag.id
  }));
  let newRecipeSet = {
    ...recipeSet,
    ...updater,
    availableFrom: moment(updater.availableFrom).format("YYYY-MM-DD"),
    availableTo: moment(updater.availableTo).format("YYYY-MM-DD"),
    recipes: formatOldRecipe
  };

  console.log(newRecipeSet);
  delete newRecipeSet.id;

  const ACCESS_TOKEN = localStorage.getItem("ACCESS_TOKEN");
  const responce = await fetch(
    `${process.env.API_URL}/admin/recipe-set/${recipeSet.id}`,
    {
      headers: {
        Accept: "application/json",
        "Content-type": "application/json; charset=utf-8",
        Authorization: "Bearer " + ACCESS_TOKEN
      },
      body: JSON.stringify(newRecipeSet),
      method: "PUT"
    }
  );
  if (responce.ok) {
    window.location.reload();
  }
};

class MenuList2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      dialogOpen: false,
      dialogUpdateOpen: false
    };
  }

  componentDidMount() {
    (async () => {
      const data = await fetchData();

      this.setState(() => ({
        data: data["_embedded"]["items"]
      }));
    })();
  }

  handleEdit = (e, id) => {
    const { handleEdit, menus } = this.props;
    handleEdit({
      ...menus[id]
    });
  };

  handleRemoveRecipe = async id => {
    try {
      await removeRecipeSet(id);
      const data = await fetchData();
      this.setState(() => ({
        data: data["_embedded"]["items"]
      }));
    } catch (e) {
      alert(`Произошла ошибка ${e}`);
    }
  };
  handleToggleDialog = () => {
    this.setState(() => ({
      dialogOpen: !this.state.dialogOpen
    }));
  };
  handleToggleDialogUpdateOpen = () => {
    this.setState(() => ({
      dialogUpdateOpen: !this.state.dialogUpdateOpen
    }));
  };

  render() {
    console.log(this.state.dialogOpen);
    const { data } = this.state;
    if (data.length === 0) return <Preloader />;
    return (
      <MuiPickersUtilsProvider
        libInstance={moment}
        utils={MomentUtils}
        // locale={"ru"}
      >
        <div>
          <table className="table-list" style={{ borderCollapse: "collapse" }}>
            <tr>
              {columns.map((column, index) => (
                <th
                  style={{
                    borderBottom: "1px solid black",
                    textAlign: "start",
                    fontSize: "1.1em",
                    fontWeight: "normal",
                    fontFamily: ` -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif`
                  }}
                  key={index}
                >
                  {column}
                </th>
              ))}
            </tr>
            {data.map(item => {
              return (
                <tr>
                  {test.map(key => {
                    if (key === "availableFrom" || key === "availableTo") {
                      return (
                        <td
                          style={{
                            textAlign: "start",
                            minHeight: "56px",
                            borderBottom: "1px solid black"
                          }}
                        >
                          {moment(item[key]).format("DD-MM-YYYY")}
                        </td>
                      );
                    }
                    if (key === "action") {
                      return (
                        <td
                          style={{
                            textAlign: "start",
                            minHeight: "56px",
                            borderBottom: "1px solid black"
                          }}
                        >
                          <Tooltip title={"Удалить рецепт"}>
                            <IconButton
                              onClick={() => this.handleRemoveRecipe(item.id)}
                            >
                              <Remove />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title={"рекатирование рецепта"}>
                            <IconButton
                              onClick={() =>
                                this.handleToggleDialogUpdateOpen()
                              }
                            >
                              <Update />
                            </IconButton>
                          </Tooltip>
                          <Dialog
                            onClose={this.handleToggleDialogUpdateOpen}
                            aria-labelledby="simple-dialog-title"
                            open={this.state.dialogUpdateOpen}
                          >
                            <DialogTitle id="simple-dialog-title">
                              <h4
                                style={{ margin: "20px" }}
                                className="form__title"
                              >
                                Добавление рецепта в специальное меню
                              </h4>
                            </DialogTitle>
                            <FormBody
                              initialValues={{
                                title: item.title,
                                example: item.description,
                                price: item.price,
                                availableFrom: moment(
                                  item.availableFrom
                                ).format("YYYY-MM-DD"),
                                availableTo: moment(item.availableTo).format(
                                  "YYYY-MM-DD"
                                )
                              }}
                              submitFrom={data => updateRecipeSet(item, data)}
                              render={() => {
                                return (
                                  <div>
                                    <Field
                                      name={"title"}
                                      label={"Название"}
                                      component={FinalTextField}
                                    />

                                    <Field
                                      name={"price"}
                                      label={"Цена"}
                                      component={FinalTextField}
                                      type={"number"}
                                    />
                                    <Field
                                      multiline={true}
                                      name={"Description"}
                                      label={"Описание"}
                                      component={FinalTextField}
                                    />
                                    <Field
                                      name={"availableFrom"}
                                      label={"Доступно от"}
                                      component={DatePicker}
                                    />
                                    <Field
                                      name={"availableTo"}
                                      label={"Доступно до"}
                                      component={DatePicker}
                                    />
                                    <Button type={"submit"}>Обновить</Button>
                                  </div>
                                );
                              }}
                            />
                          </Dialog>
                        </td>
                      );
                    }
                    if (key === "recipes") {
                      return (
                        <td
                          style={{
                            textAlign: "start",
                            minHeight: "56px",
                            borderBottom: "1px solid black"
                          }}
                        >
                          <ExpansionPanel style={{ margin: "10px 0" }}>
                            <ExpansionPanelSummary
                              expandIcon={<ExpandMore />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              <Typography>Просмотр рецептов</Typography>
                            </ExpansionPanelSummary>
                            {item[key].map(obj => {
                              return (
                                <>
                                  <ExpansionPanelSummary
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        width: "100%"
                                      }}
                                    >
                                      <Typography>
                                        {obj.recipe.title}
                                      </Typography>

                                      <Chip
                                        style={{ margin: "0 20px" }}
                                        label={obj.tag.title}
                                      />
                                      <div
                                        style={{
                                          marginLeft: "auto",
                                          marginRight: "-12px"
                                        }}
                                      >
                                        <Tooltip title={"Удалить из меню"}>
                                          <IconButton
                                            onClick={() =>
                                              removeRecipeFromRecipeSet1(
                                                item,
                                                obj.id
                                              )
                                            }
                                            variant={"contained"}
                                            color={"primary"}
                                          >
                                            <Remove />
                                          </IconButton>
                                        </Tooltip>
                                      </div>
                                    </div>
                                  </ExpansionPanelSummary>
                                </>
                              );
                            })}
                            <ExpansionPanelActions>
                              <Tooltip title={"Добавить рецепт"}>
                                <IconButton
                                  onClick={this.handleToggleDialog}
                                  variant={"contained"}
                                  color={"primary"}
                                >
                                  <Add />
                                </IconButton>
                              </Tooltip>
                              <Dialog
                                onClose={this.handleToggleDialog}
                                aria-labelledby="simple-dialog-title"
                                open={this.state.dialogOpen}
                              >
                                <DialogTitle id="simple-dialog-title">
                                  <h4
                                    style={{ margin: "20px" }}
                                    className="form__title"
                                  >
                                    Добавление рецепта в специальное меню
                                  </h4>
                                </DialogTitle>

                                {(() => (
                                  <FormBody
                                    init
                                    submitFrom={data =>
                                      addRecipesToRecipeSet(item, data)
                                    }
                                    mutators={{
                                      ...arrayMutators
                                    }}
                                    render={({ mutators: { pop, push } }) => {
                                      return (
                                        <div>
                                          <FieldArray name={"recipes"}>
                                            {({ fields, ...props }) => {
                                              return (
                                                <>
                                                  {fields.map((name, index) => {
                                                    return (
                                                      <div key={`${name}`}>
                                                        <hr />
                                                        <h4>Рецепт</h4>
                                                        <div
                                                          style={{
                                                            display: "flex",
                                                            flexDirection:
                                                              "row",
                                                            justifyContent:
                                                              "center",
                                                            alignItems: "center"
                                                          }}
                                                        >
                                                          <Field
                                                            name={`${name}`}
                                                            component={
                                                              GetRecipeAutocomplete
                                                            }
                                                            validate={value =>
                                                              value && value.tag
                                                                ? null
                                                                : "Требутеся тег"
                                                            }
                                                          />
                                                        </div>
                                                      </div>
                                                    );
                                                  })}
                                                </>
                                              );
                                            }}
                                          </FieldArray>
                                          <div>
                                            <div className="buttons">
                                              <Button
                                                type="button"
                                                onClick={() =>
                                                  push("recipes", {
                                                    recipes: null,
                                                    tag: null
                                                  })
                                                }
                                              >
                                                Добавить рецепт
                                              </Button>
                                              <Field name={"recipes"}>
                                                {({ input: { onChange } }) => {
                                                  return (
                                                    <Button
                                                      type="button"
                                                      onClick={() =>
                                                        onChange([])
                                                      }
                                                    >
                                                      Очистить
                                                    </Button>
                                                  );
                                                }}
                                              </Field>
                                            </div>
                                          </div>
                                          <Button type={"submit"}>
                                            Создать
                                          </Button>
                                        </div>
                                      );
                                    }}
                                  />
                                ))()}
                              </Dialog>
                            </ExpansionPanelActions>
                          </ExpansionPanel>
                        </td>
                      );
                    }
                    return (
                      <td
                        style={{
                          textAlign: "start",
                          minHeight: "56px",
                          borderBottom: "1px solid black"
                        }}
                      >
                        {item[key]}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </table>
        </div>
      </MuiPickersUtilsProvider>
    );
  }
}

export default MenuList2;
