import { FAIL, START, SUCCESS } from "../../commons/constants";

const initialState = {
  data: {},
  tags: {},
  loading: false,
  loaded: false,
  error: false
};

export const LOAD_TAGS_CATEGORIES = "LOAD_TAGS_CATEGORIES";
export const LOAD_TAGS_CATEGORY = "LOAD_TAGS_CATEGORY";
export const CREATE_TAGS_CATEGORY = "CREATE_TAGS_CATEGORY";
export const DELETE_TAGS_CATEGORY = "DELETE_TAGS_CATEGORY";

export const UPLOAD_TAGS_CATEGORY_FILE = "UPLOAD_TAGS_CATEGORY_FILE";
export const UPLOAD_TAGS_CATEGORY_FILE_SUCCESS =
  "UPLOAD_TAGS_CATEGORY_FILE_SUCCESS";
export const UPLOAD_TAGS_CATEGORY_FILE_FAIL = "UPLOAD_TAGS_CATEGORY_FILE_FAIL";

export function loadTagsCategories() {
  return {
    type: LOAD_TAGS_CATEGORIES
  };
}

export function loadTagsCategory() {
  return {
    type: LOAD_TAGS_CATEGORY
  };
}

export function createTagsCategory(data) {
  return {
    type: CREATE_TAGS_CATEGORY,
    payLoad: {
      data
    }
  };
}

export function deleteTagsCategory(id) {
  return {
    type: DELETE_TAGS_CATEGORY,
    payLoad: {
      id
    }
  };
}

export const uploadTagsCategoryFile = file => ({
  type: UPLOAD_TAGS_CATEGORY_FILE,
  payLoad: file
});

export const uploadTagsCategoryFileSuccess = () => ({
  type: UPLOAD_TAGS_CATEGORY_FILE_SUCCESS
});

export const uploadTagsCategoryFileFail = error => ({
  type: UPLOAD_TAGS_CATEGORY_FILE_FAIL,
  error
});

export default (state = initialState, { type, payLoad, error }) => {
  switch (type) {
    case UPLOAD_TAGS_CATEGORY_FILE:
    case LOAD_TAGS_CATEGORIES + START:
      return {
        ...state,
        loading: true,
        error: null
      };
    case LOAD_TAGS_CATEGORIES + SUCCESS:
      return {
        ...state,
        data: { ...payLoad },
        loading: false
      };
    case UPLOAD_TAGS_CATEGORY_FILE_FAIL:
    case LOAD_TAGS_CATEGORIES + FAIL:
      return {
        ...state,
        loading: false,
        error
      };
    case LOAD_TAGS_CATEGORY + START:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: null
      };
    case LOAD_TAGS_CATEGORY + SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [payLoad.id]: {
            ...payLoad.data
          }
        },
        loading: false,
        loaded: true
      };
    case LOAD_TAGS_CATEGORY + FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: payLoad.data
      };
    case CREATE_TAGS_CATEGORY + START:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: null
      };
    case UPLOAD_TAGS_CATEGORY_FILE_SUCCESS:
    case CREATE_TAGS_CATEGORY + SUCCESS:
      return {
        ...state,
        loading: false
      };
    case CREATE_TAGS_CATEGORY + FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: payLoad.data
      };
    case DELETE_TAGS_CATEGORY + START:
      return {
        ...state,
        loading: true
      };
    case DELETE_TAGS_CATEGORY + SUCCESS:
      const categoryDelete = { ...state.data };
      delete categoryDelete[payLoad.id];
      return {
        ...state,
        data: { ...categoryDelete },
        loading: false
      };
    case DELETE_TAGS_CATEGORY + FAIL:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
};
