import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import {
  PATH_INGREDIENTS_ALL,
  PATH_RECIPES_ALL,
  PATH_MENU,
  PATH_ADMINS_ALL,
  PATH_TAGS,
  PATH_MATERIALS,
  PATH_ACCOUNTS
} from "../commons/constants";
import SuperAdminAccess from "../features/Admins/SuperAdminAccess";

class SideMenu extends Component {
  render() {
    return (
      <div className="main__menu menu-side">
        {/*<h3 className="menu-side__title">Menu</h3>*/}
        <nav className="menu-side__navigation">
          <NavLink to={PATH_INGREDIENTS_ALL}>Ингредиенты</NavLink>
          <NavLink to={PATH_RECIPES_ALL}>Рецепты</NavLink>
          <NavLink to={`${PATH_MENU}`}>Меню</NavLink>
          <NavLink to={PATH_TAGS}>Теги</NavLink>
          <NavLink to={PATH_MATERIALS}>Материалы</NavLink>
          <NavLink to={PATH_ACCOUNTS}>Пользователи</NavLink>
          <SuperAdminAccess>
            <NavLink to={PATH_ADMINS_ALL}>Админы</NavLink>
          </SuperAdminAccess>
        </nav>
      </div>
    );
  }
}

export default SideMenu;
