import React, { Component } from "react";

class TagsItem extends Component {
  render() {
    const { title, handleDelete } = this.props;
    return (
      <div className="tags-item__wrapper">
        <div className="tags-item__title">{title}</div>
        <button
          className="tags-item__btn tags-item__btn_delete"
          onClick={handleDelete}
        />
      </div>
    );
  }
}

export default TagsItem;
