import React, { Component } from "react";
import { Route } from "react-router-dom";
import Ingredients from "../features/Ingredients/Ingredients";
import {
  PATH_INGREDIENTS_ALL,
  PATH_RECIPES_ALL,
  PATH_MENU,
  PATH_ADMINS_ALL,
  PATH_TAGS,
  PATH_MATERIALS,
  PATH_ACCOUNTS
} from "../commons/constants";
import AdminsMain from "../features/Admins/AdminsMain";
import MenuRoute from "../features/Menus/MenuRoute";
import MaterialRoute from "../features/Materials/MaterialRoute";
import AccountsRoute from "../features/Accounts/AccountsRoute";
import RecipeRoute from "../features/Recipes/RecipeRoute";
import TagsRoute from "../features/Tags/TagsRoute";

class Content extends Component {
  render() {
    return (
      <div className="main__content content">
        <Route path={PATH_INGREDIENTS_ALL} component={Ingredients} />
        <Route path={PATH_RECIPES_ALL} component={RecipeRoute} />
        <Route path={PATH_MENU} component={MenuRoute} />
        <Route path={PATH_MATERIALS} component={MaterialRoute} />
        <Route path={PATH_ACCOUNTS} component={AccountsRoute} />
        <Route path={PATH_ADMINS_ALL} component={AdminsMain} />
        <Route path={PATH_TAGS} component={TagsRoute} />
      </div>
    );
  }
}

export default Content;
