import React, { Component } from "react";
import Input from "../../../components/Input/Input";
import Form from "../../../components/Form/Form";
import withConfirmModal from "../../../HOCs/withConfirmModal";

class TagsAddModal extends Component {
  state = {
    title: ""
  };
  handleChange = e => {
    const { name, value } = e.target;
    this.setState({
      [name]: value
    });
  };
  handleCreateTags = () => {
    const { handleCreate, closeModal } = this.props;
    const { title } = this.state;
    handleCreate({
      title
    });
    closeModal();
  };
  handleCancel = () => {
    this.props.closeModal();
  };
  render() {
    return (
      <Form
        title="Добавление тега"
        handleCancel={this.handleCancel}
        handleSubmit={this.handleCreateTags}
      >
        <Input
          name="title"
          value={this.state.title}
          handleChange={this.handleChange}
          placeholder="Введите название тега"
          required
          autofocus
        />
      </Form>
    );
  }
}

export default withConfirmModal(TagsAddModal);
