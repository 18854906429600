import { call, put, takeLatest, select, all } from "redux-saga/effects";
import { errorMassages, FAIL, START, SUCCESS } from "../../commons/constants";
import * as API from "./api";
import {
  CLEAR_RECIPEPROPORTIONE_LAST,
  CREATE_RECIPEPROPORTION,
  EDIT_RECIPEPROPORTION,
  EDIT_RECIPEPROPORTIONE_CHANGED,
  EDIT_RECIPEPROPORTIONE_STORE,
  LOAD_RECIPEPROPORTIONS,
  LOAD_RECIPEPROPORTION,
  DELETE_RECIPEPROPORTION
} from "./ducks";
import { recipeProportionByIdSelector } from "./selectors";
import {
  normaliseRecipeProportions,
  normaliseRecipeProportion
} from "./normalisers";
import { fetchReqAsync, fetchResAsync } from "../../commons/api";
import fecthAPI from "../../commons/api";
import { getRecipe } from "../Recipes/sagas";
import { getIngredient } from "../Ingredients/sagas";

function* getRecipeProportions(action) {
  const { recipeId } = action.payLoad;
  try {
    yield put({ type: LOAD_RECIPEPROPORTIONS + START });
    const recipeProportions = yield fetchReqAsync(
      API.getRecipeProportions,
      recipeId
    );
    yield put({
      type: LOAD_RECIPEPROPORTIONS + SUCCESS,
      payLoad: { data: { ...normaliseRecipeProportions(recipeProportions) } }
    });
  } catch (error) {
    yield call(alert, errorMassages.load("Рецептов", error));
    yield put({ type: LOAD_RECIPEPROPORTIONS + FAIL });
  }
}

export function* getRecipeProportion({
  payLoad: { recipeId, id, url },
  options
}) {
  try {
    const res = normaliseRecipeProportion(
      url
        ? yield fetchReqAsync(fecthAPI.get, url)
        : yield fetchReqAsync(API.getRecipeProportion, recipeId, id)
    );
    yield call(getRecipe, {
      payLoad: { id: res.recipe },
      options
    });
    if (options && options.withIngredients)
      yield all(res.doses.map(dose => call(getIngredient, { url: dose.link })));
    yield put({
      type: LOAD_RECIPEPROPORTION + SUCCESS,
      payLoad: {
        data: res,
        id: res.id
      }
    });
  } catch (e) {
    console.log(e);
    yield put({ type: LOAD_RECIPEPROPORTION + FAIL });
  }
}

function* editRecipeProportionChanged(action) {
  const { recipeId, id } = action.payLoad;
  try {
    yield put({ type: EDIT_RECIPEPROPORTIONE_CHANGED + START });
    const recipeProportion = yield select(recipeProportionByIdSelector, { id });
    yield fetchResAsync(
      API.putRecipeProportion,
      recipeId,
      id,
      recipeProportion
    );
    yield put({
      type: CLEAR_RECIPEPROPORTIONE_LAST,
      payLoad: {
        id
      }
    });
    yield put({ type: EDIT_RECIPEPROPORTIONE_CHANGED + SUCCESS });
  } catch (error) {
    yield call(alert, errorMassages.edit("Рецепта", error));
    yield put({ type: EDIT_RECIPEPROPORTIONE_CHANGED + FAIL });
  }
}

export function* createRecipeProportion(action) {
  const { data, id } = action.payLoad;
  try {
    yield put({ type: CREATE_RECIPEPROPORTION + START });
    const res = yield fetchResAsync(API.postRecipeProportion, id, data || {});
    const resRecipeProportion = yield fetchReqAsync(
      fecthAPI.get,
      res.headers.get("Location")
    );
    const recipeProportionID = resRecipeProportion.id;
    yield put({
      type: CREATE_RECIPEPROPORTION + SUCCESS,
      payLoad: {
        data: { ...data, recipe: id, doses: [] },
        id: recipeProportionID
      }
    });
    return recipeProportionID;
  } catch (e) {
    console.log(e);
    yield put({ type: CREATE_RECIPEPROPORTION + FAIL });
    throw e;
  }
}

export function* editRecipeProportion(action) {
  const { data, recipeId, id } = action.payLoad;
  try {
    yield put({ type: EDIT_RECIPEPROPORTION + START });
    yield fetchResAsync(API.putRecipeProportion, recipeId, id, data);
    yield call(getRecipeProportion, {
      payLoad: { recipeId, id },
      options: action.options
    });
    yield put({
      type: EDIT_RECIPEPROPORTION + SUCCESS
    });
  } catch (error) {
    yield call(alert, errorMassages.edit("Рецепта", error));
    yield put({ type: EDIT_RECIPEPROPORTION + FAIL });
    throw error;
  }
}

export function* deleteRecipeProportion(action) {
  try {
    const { recipeId, id } = action.payLoad;
    yield put({ type: DELETE_RECIPEPROPORTION + START });
    //const meal = yield select(mealByIdSelector, { id: mealId });
    //let newMeal = { ...meal };
    // newMeal.recipeProportions = newMeal.recipeProportions.filter(
    //   recipeProportion => recipeProportion !== id
    // );
    // yield call(editMeal, {
    //   payLoad: {
    //     menuId,
    //     proportionId,
    //     id: mealId,
    //     data: newMeal
    //   }
    // });
    yield fetchResAsync(API.deleteRecipeProportion, recipeId, id);
    yield put({
      type: DELETE_RECIPEPROPORTION + SUCCESS,
      payLoad: { id }
    });
  } catch (e) {
    console.log(e);
    yield put({ type: DELETE_RECIPEPROPORTION + FAIL, payLoad: { data: e } });
  }
}

function* watchRecipeProportions() {
  yield takeLatest(LOAD_RECIPEPROPORTIONS, getRecipeProportions);
  yield takeLatest(LOAD_RECIPEPROPORTION, getRecipeProportion);
  yield takeLatest(CREATE_RECIPEPROPORTION, createRecipeProportion);
  yield takeLatest(EDIT_RECIPEPROPORTION, editRecipeProportion);
  yield takeLatest(EDIT_RECIPEPROPORTIONE_CHANGED, editRecipeProportionChanged);
  yield takeLatest(DELETE_RECIPEPROPORTION, deleteRecipeProportion);
}

export default watchRecipeProportions;
