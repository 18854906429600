import React, { Component } from "react";
import { dateFormat, getWeeksInMonth } from "../../../commons/utils";
import MenuProportionWeek from "./MenuProportionWeek";

class MenuProportionWeekWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentWeek: getCurrentWeek(props.date)
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.currentCalorie !== this.props.currentCalorie ||
      prevState.currentWeek !== this.state.currentWeek
    ) {
      this.props.editRecipesChanged();
      this.loadMealsOfWeek();
    }
  }

  componentDidMount() {
    this.loadMealsOfWeek();
  }

  componentWillUnmount() {
    this.props.editRecipesChanged();
  }

  loadMealsOfWeek = () => {
    const { loadMeals, menu, proportions, currentCalorie } = this.props;
    const { currentWeek } = this.state;
    const dateFrom = new Date(
      currentWeek.year,
      currentWeek.prevMonth,
      currentWeek.start
    );
    const dateTo = new Date(
      currentWeek.year,
      currentWeek.nextMonth,
      currentWeek.end
    );
    const proportion = Object.values(proportions).find(
      proportion => proportion.kilocalories === currentCalorie
    );
    if (proportion)
      loadMeals(
        menu.id,
        proportion.id,
        dateFormat(dateFrom),
        dateFormat(dateTo)
      );
  };

  handleDecrMonth = () => {
    const { currentWeek } = this.state;
    const newDate = new Date(
      currentWeek.year,
      currentWeek.prevMonth,
      currentWeek.start
    );
    newDate.setDate(currentWeek.start - 7);
    this.setState(
      {
        currentWeek: getCurrentWeek(newDate)
      },
      () => this.props.setCurrentDate(newDate)
    );
  };

  handleIncrMonth = () => {
    const { currentWeek } = this.state;
    const newDate = new Date(
      currentWeek.year,
      currentWeek.prevMonth,
      currentWeek.start
    );
    newDate.setDate(currentWeek.start + 7);
    this.setState(
      {
        currentWeek: getCurrentWeek(newDate)
      },
      () => this.props.setCurrentDate(newDate)
    );
  };

  render() {
    const {
      match,
      history,
      location,
      menu,
      currentCalorie,
      handleSelectProportion
    } = this.props;
    const { currentWeek } = this.state;
    return (
      <MenuProportionWeek
        menu={menu}
        match={match}
        history={history}
        location={location}
        currentCalorie={currentCalorie}
        currentWeek={currentWeek}
        handleSelectProportion={handleSelectProportion}
        handleIncrMonth={this.handleIncrMonth}
        handleDecrMonth={this.handleDecrMonth}
      />
    );
  }
}

function getCurrentWeek(date) {
  const weeks = getWeeksInMonth(date.getMonth(), date.getFullYear());
  if (date.getDate() < 15) {
    return weeks.find(
      week =>
        (date.getDate() - week.start <= 6 &&
          date.getDate() - week.start >= 0) ||
        (week.end - date.getDate() <= 6 && week.end - date.getDate() >= 0)
    );
  }
  return weeks
    .reverse()
    .find(
      week =>
        (date.getDate() - week.start <= 6 &&
          date.getDate() - week.start >= 0) ||
        (week.end - date.getDate() <= 6 && week.end - date.getDate() >= 0)
    );
}

export default MenuProportionWeekWrapper;
