import React, { Component, Fragment } from "react";
import InputNumber from "../../../components/Input/InputNumber";

class TimeToCook extends Component {
  state = {
    preparingTime: 0,
    cookingTime: 0
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { recipe } = nextProps;
    return {
      cookingTime: recipe.cookingTime || 0,
      preparingTime: recipe.preparingTime || 0
    };
  }
  getInputFields() {
    const { disabled, handleChange } = this.props;
    const { preparingTime, cookingTime } = this.state;
    return (
      <Fragment>
        <InputNumber
          name="preparingTime"
          value={preparingTime}
          placeholder="Время на подготовку"
          className="portions-dish__input"
          handleChange={handleChange}
          disabled={disabled}
        />
        <InputNumber
          name="cookingTime"
          value={cookingTime}
          placeholder="Время приготовления"
          className="portions-dish__input"
          handleChange={handleChange}
          disabled={disabled}
        />
        <InputNumber
          name="sumOfCook"
          value={preparingTime + cookingTime}
          placeholder="Общее время приготовления"
          className="portions-dish__input"
          disabled
        />
      </Fragment>
    );
  }
  render() {
    const fields = this.getInputFields();
    return (
      <div className="tail-dish__property property-dish__time">{fields}</div>
    );
  }
}

export default TimeToCook;
