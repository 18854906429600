import React, { Component, Fragment } from "react";
import { Route, Switch } from "react-router-dom";
import Recipes from "./List/Recipes";
import RecipeEdit from "./Edit/RecipeEdit";

class RecipeRoute extends Component {
  render() {
    const { match } = this.props;
    return (
      <Fragment>
        <Switch>
          <Route
            exact
            path={`${match.url}`}
            render={({ match, history }) => (
              <Recipes match={match} history={history} />
            )}
          />
          <Route
            path={`${match.url}/:recipeId/proportions/:id/`}
            render={({ match, history }) => (
              <RecipeEdit
                id={match.params.id}
                recipeId={match.params.recipeId}
                match={match}
                history={history}
              />
            )}
          />
          <Route path="/*" render={() => <h3>Page not found</h3>} />
        </Switch>
      </Fragment>
    );
  }
}

export default RecipeRoute;
