import React, { Component } from "react";
import ListControls from "../../components/ListControls/ListControls";
import Button from "../../components/Button/Button";
import { Link } from "react-router-dom";

class Account extends Component {
  handleEditPhone = (e, id) => {
    const { handleEditPhone } = this.props;
    handleEditPhone(e, id);
  };

  getControlsButtons = () => {
    const { account } = this.props;
    return [
      {
        title: "Сменить номер",
        handleClick: e => this.handleEditPhone(e, account.id)
      },
      {
        title: "Удалить",
        isConfirm: true,
        confirmTitle: "Подтвердить удаление"
      }
    ];
  };

  render() {
    const { account } = this.props;
    const { phone, id, email, fullName } = account;
    return (
      <div className="table-list__row">
        <div className="table-list__phone">{phone.e164Format}</div>
        <div className="table-list__email">{email}</div>
        <div className="table-list__name">
          {fullName.lastName} {fullName.firstName}
        </div>
        <ListControls id={id} buttons={this.getControlsButtons()}>
          <Link to={`accounts/${id}/profiles`} className="list-controls__link">
            <Button className="list-controls__btn">Профили</Button>
          </Link>
        </ListControls>
      </div>
    );
  }
}

export default Account;
