import { SUCCESS } from "../../commons/constants";

export const CHANGE_FILTER_RECIPES = "CHANGE_FILTER_RECIPES";
export const CHANGE_FILTER_INGREDIENT = "CHANGE_FILTER_INGREDIENT";
export const CHANGE_FILTER_COMMENTS = "CHANGE_FILTER_COMMENTS";
export const CHANGE_FILTER_MATERIALS = "CHANGE_FILTER_MATERIALS";
export const CLEAR_SEARCH_INGREDIENT = "CLEAR_SEARCH_INGREDIENT";
export const CLEAR_FILTER_RECIPES = "CLEAR_FILTER_RECIPES";
export const CHANGE_SEARCH_MENU = "CHANGE_SEARCH_MENU";
export const CLEAR_SEARCH_MENU = "CLEAR_SEARCH_MENU";
export const CHANGE_SEARCH_TAG = "CHANGE_SEARCH_TAG";
export const CLEAR_SEARCH_TAG = "CLEAR_SEARCH_TAG";
export const CLEAR_FILTER_COMMENTS = "CLEAR_FILTER_COMMENTS";
export const CLEAR_FILTER_MATERIALS = "CLEAR_FILTER_MATERIALS";

const initialState = {
  filterIngredient: "",
  filterRecipes: null,
  filterComments: null,
  filterMaterials: null,
  searchMenu: "",
  searchTag: ""
};

export function changeFilterRecipes(filter) {
  return {
    type: CHANGE_FILTER_RECIPES,
    filter
  };
}

export function changeFilterIngredient(filter) {
  return {
    type: CHANGE_FILTER_INGREDIENT,
    payLoad: {
      filter
    }
  };
}

export function changeFilterComments(id, filter) {
  return {
    type: CHANGE_FILTER_COMMENTS,
    filter,
    id
  };
}

export function changeFilterMaterials(filter) {
  return {
    type: CHANGE_FILTER_MATERIALS,
    filter
  };
}

export function clearSearchIngredient() {
  return {
    type: CLEAR_SEARCH_INGREDIENT
  };
}

export function clearFilterRecipes() {
  return {
    type: CLEAR_FILTER_RECIPES
  };
}

export function changeSearchMenu(search) {
  return {
    type: CHANGE_SEARCH_MENU,
    payLoad: {
      search
    }
  };
}

export function clearSearchMenu() {
  return {
    type: CLEAR_SEARCH_MENU
  };
}

export function changeSearchTag(search) {
  return {
    type: CHANGE_SEARCH_TAG,
    payLoad: {
      search
    }
  };
}

export function clearSearchTag() {
  return {
    type: CLEAR_SEARCH_TAG
  };
}

export function clearFilterComments() {
  return {
    type: CLEAR_FILTER_COMMENTS
  };
}

export function clearFilterMaterials() {
  return {
    type: CLEAR_FILTER_MATERIALS
  };
}

export default (filters = initialState, { filter, type, payLoad }) => {
  switch (type) {
    case CHANGE_FILTER_INGREDIENT + SUCCESS:
      return {
        ...filters,
        filterIngredient: {
          ...filters.filterIngredient,
          ...payLoad.filter
        }
      };
    case CHANGE_FILTER_RECIPES + SUCCESS:
      return {
        ...filters,
        filterRecipes: filter
      };
    case CHANGE_SEARCH_MENU + SUCCESS:
      return {
        ...filters,
        searchMenu: payLoad.search
      };
    case CHANGE_SEARCH_TAG + SUCCESS:
      return {
        ...filters,
        searchTag: payLoad.search
      };
    case CHANGE_FILTER_COMMENTS + SUCCESS:
      return {
        ...filters,
        filterComments: filter
      };
    case CHANGE_FILTER_MATERIALS + SUCCESS:
      return {
        ...filters,
        filterMaterials: filter
      };
    case CLEAR_SEARCH_INGREDIENT:
      return {
        ...filters,
        searchIngredient: null
      };
    case CLEAR_FILTER_RECIPES:
      return {
        ...filters,
        filterRecipes: null
      };
    case CLEAR_SEARCH_MENU:
      return {
        ...filters,
        searchMenu: null
      };
    case CLEAR_SEARCH_TAG:
      return {
        ...filters,
        searchTag: null
      };
    case CLEAR_FILTER_COMMENTS:
      return {
        ...filters,
        filterComments: null
      };
    case CLEAR_FILTER_MATERIALS:
      return {
        ...filters,
        filterMaterials: null
      };
    default:
      return filters;
  }
};
