import React, { Component } from "react";
import IngredientsTableRow from "./IngredientsTableRow";
import IngredientsTableGeneralRow from "./IngredientTableGeneralRow";
import SearchIngredientsContainer from "../SearchIngredientsContainer";
import Table from "../../../components/Table/Table";
import TableHead from "../../../components/Table/TableHead";
import TableBody from "../../../components/Table/TableBody";
import TableRow from "../../../components/Table/TableRow";
import TableCell from "../../../components/Table/TableCell";

const columns = [
  "",
  "Способ приготовления",
  "Количество",
  "Ед. изм",
  "Вес",
  "Вес готового",
  "Белки",
  "Жиры",
  "Углев.",
  "Клетч",
  "Калории",
  ""
];

class IngredientsTable extends Component {
  render() {
    const {
      doses,
      disabled,
      handleChangeDose,
      handleSelect,
      handleDeleteIngredient
    } = this.props;
    let index = 0;
    const ingredientsList = doses.map(ingredient => {
      return (
        <IngredientsTableRow
          key={index++}
          ingredient={ingredient}
          handleChangeDose={handleChangeDose}
          handleDelete={handleDeleteIngredient}
          disabled={disabled}
        />
      );
    });
    return (
      <Table className="ingredient__table table-ingredient">
        <TableHead>
          <TableRow>
            {columns.map(column => (
              <TableCell
                className="table-ingredient__th"
                key={index++}
                isHeadCell
              >
                {column}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <IngredientsTableGeneralRow ingredients={doses} />
          <TableRow>
            <TableCell colspan="2">
              <SearchIngredientsContainer
                handleSelect={handleSelect}
                disabled={disabled}
                placeholder="Выберите ингредиент"
              />
            </TableCell>
          </TableRow>
          {ingredientsList}
        </TableBody>
      </Table>
    );
  }
}

export default IngredientsTable;
