import { call, put, takeLatest, select, all } from "redux-saga/effects";
import {
  DRAFT,
  errorMassages,
  FAIL,
  START,
  SUCCESS
} from "../../commons/constants";
import * as API from "./api";
import * as ducks from "./ducks";
import { createRecipe } from "../Recipes/sagas";
import {
  normaliseMeals,
  normaliseMeal,
  normaliseMealComments
} from "./normalisers";
import { menuProportionByIdSelector } from "../MenuProportions/selectors";
import fecthAPI, { fetchReqAsync, fetchResAsync } from "../../commons/api";
import fetchAPI from "../../commons/api";
import { getMenuProportion } from "../MenuProportions/sagas";
import { mealByIdSelector } from "./selectors";
import { loadMealCommentsSuccess } from "./ducks";
import { loadMealCommentsFail } from "./ducks";
import { createMealCommentFail } from "./ducks";
import { LOAD_RECIPE } from "../Recipes/ducks";
import { normaliseRecipe } from "../Recipes/normalisers";
import { LOAD_RECIPEPROPORTION } from "../RecipeProportions/ducks";
import { normaliseRecipeProportion } from "../RecipeProportions/normalisers";
import { getIngredient } from "../Ingredients/sagas";
import { createMealCommentSuccess } from "./ducks";

function* getMeals({
  payLoad: { menuId, proportionId, dateFrom, dateTo },
  options = {}
}) {
  try {
    yield put({ type: ducks.LOAD_MEALS + START });
    const res = normaliseMeals(
      yield fetchReqAsync(API.getMeals, menuId, proportionId, dateFrom, dateTo)
    );
    if (!options.isNotLoadRecipeProportions) {
      for (let meal of Object.values(res)) {
        yield call(parseMeal, meal, options);
      }
    }
    yield put({
      type: ducks.LOAD_MEALS + SUCCESS,
      payLoad: { data: res }
    });
  } catch (e) {
    console.log(e);
    yield call(alert, errorMassages.load("meals", e));
    yield put({ type: ducks.LOAD_MEALS + FAIL, payLoad: { data: e } });
  }
}

function* getMeal({ payLoad = {}, url }) {
  try {
    yield put({ type: ducks.LOAD_MEAL + START });
    const res = normaliseMeal(
      url
        ? yield fetchReqAsync(fetchAPI.get, url)
        : yield fetchReqAsync(
            API.getMeal,
            payLoad.menuId,
            payLoad.proportionId,
            payLoad.id
          )
    );
    yield call(parseMeal, res, { withIngredients: payLoad.withIngredients });
    yield put({
      type: ducks.LOAD_MEAL + SUCCESS,
      payLoad: { data: res, id: res.id }
    });
    return res;
  } catch (error) {
    console.log(error);
    yield call(alert, errorMassages.load("meals", error));
    yield put({ type: ducks.LOAD_MEAL + FAIL, payLoad: { data: error } });
  }
}

function* getMealComments({ payLoad: { dateFrom, dateTo } }) {
  try {
    const res = yield fetchReqAsync(API.getMealComments, { dateFrom, dateTo });
    yield put(loadMealCommentsSuccess(normaliseMealComments(res)));
  } catch (error) {
    console.log(error);
    yield call(alert, errorMassages.load("meals", error));
    yield put(loadMealCommentsFail(error));
  }
}

function* createMealComment({ payLoad: { data, meal }, options = {} }) {
  try {
    let { id } = meal;
    if (options.withCreateMeal) {
      const resMeal = yield call(createMeal, {
        payLoad: {
          menuId: options.menuId,
          proportionId: options.proportionId,
          data: options.data
        }
      });
      id = resMeal.id;
    }
    const res = yield fetchResAsync(API.postMealComment, { ...data, meal: id });
    const comment = yield fetchReqAsync(
      fecthAPI.get,
      res.headers.get("Location")
    );
    yield put(createMealCommentSuccess(comment));
  } catch (error) {
    console.log(error);
    yield call(alert, errorMassages.create("комментария", error));
    yield put(createMealCommentFail(error));
  }
}

function* createMeal({
  payLoad: { data, menuId, proportionId, recipeProportion },
  options = {}
}) {
  try {
    yield put({
      type: ducks.CREATE_MEAL + START
    });
    let newData = data;
    if (options.withCreateRecipe)
      newData = yield call(
        createRecipeProportionToMeal,
        data,
        recipeProportion || {},
        proportionId
      );
    const res = yield fetchResAsync(
      API.postMeal,
      menuId,
      proportionId,
      newData
    );
    yield put({
      type: ducks.CREATE_MEAL + SUCCESS
    });
    const meal = yield call(getMeal, { url: res.headers.get("Location") });
    yield call(getMenuProportion, { payLoad: { menuId, id: proportionId } });
    return meal;
  } catch (error) {
    console.log(error);
    yield call(alert, errorMassages.create("meal", error));
    yield put({ type: ducks.CREATE_MEAL + FAIL });
  }
}

export function* editMeal(action) {
  const { data, id, menuId, proportionId } = action.payLoad;
  try {
    yield put({
      type: ducks.EDIT_MEAL + START
    });
    yield fetchResAsync(API.putMeal, menuId, proportionId, id, data);
    yield put({
      type: ducks.EDIT_MEAL + SUCCESS,
      payLoad: {
        id,
        data
      }
    });
  } catch (error) {
    yield call(alert, errorMassages.edit("meal", error));
    yield put({ type: ducks.EDIT_MEAL + FAIL });
  }
}

function* editMealCreateRecipeProportion(action) {
  const { data, id, menuId, proportionId, recipe = {} } = action.payLoad;
  try {
    yield put({
      type: ducks.EDIT_MEAL + START
    });
    const newData = yield call(
      createRecipeProportionToMeal,
      data,
      recipe,
      proportionId
    );
    yield call(editMeal, {
      payLoad: {
        menuId,
        proportionId,
        id,
        data: newData
      }
    });
  } catch (error) {
    console.log(error);
    yield call(alert, errorMassages.edit("meal", error));
    yield put({ type: ducks.EDIT_MEAL + FAIL });
  }
}

export function* deleteMeal(action) {
  const { id, menuId, proportionId } = action.payLoad;
  try {
    yield put({ type: ducks.DELETE_MEAL + START });
    yield fetchResAsync(API.deleteMeal, menuId, proportionId, id);
    yield put({
      type: ducks.DELETE_MEAL + SUCCESS,
      payLoad: { id }
    });
  } catch (error) {
    yield call(alert, errorMassages.delete("meal", error));
    yield put({ type: ducks.DELETE_MEAL + FAIL });
  }
}

export function* deleteMealComment({ payLoad: { id } }) {
  try {
    yield fetchResAsync(API.deleteMealComment, id);
    yield put(ducks.deleteMealCommentSuccess(id));
  } catch (error) {
    yield call(alert, errorMassages.delete("комментария", error));
    yield put(ducks.deleteMealCommentFail(error));
  }
}

export function* deleteRecipeFromMeal({
  payLoad: { menuId, proportionId, mealId, id }
}) {
  const meal = yield select(mealByIdSelector, { id: mealId });
  yield put(
    ducks.editMeal(menuId, proportionId, mealId, {
      ...meal,
      mealRecipes: [
        ...meal.mealRecipes.filter(
          ({ recipeProportion }) => recipeProportion !== id
        )
      ]
    })
  );
}

function* watchMeals() {
  yield takeLatest(ducks.LOAD_MEALS, getMeals);
  yield takeLatest(ducks.LOAD_MEAL, getMeal);
  yield takeLatest(ducks.LOAD_MEAL_COMMENTS, getMealComments);
  yield takeLatest(ducks.CREATE_MEAL, createMeal);
  yield takeLatest(ducks.CREATE_MEAL_COMMENT, createMealComment);
  yield takeLatest(ducks.EDIT_MEAL, editMeal);
  yield takeLatest(
    ducks.EDIT_MEAL_CREATE_RECIPEPROPORTION,
    editMealCreateRecipeProportion
  );
  yield takeLatest(ducks.DELETE_MEAL, deleteMeal);
  yield takeLatest(ducks.DELETE_RECIPE_FROM_MEAL, deleteRecipeFromMeal);
  yield takeLatest(ducks.DELETE_MEAL_COMMENT, deleteMealComment);
}

function* createRecipeProportionToMeal(
  data,
  { recipeProportion, weight = 0 },
  proportionId
) {
  let recipeProportionID = "";
  const menuProportion = yield select(menuProportionByIdSelector, {
    id: proportionId
  });
  if (recipeProportion) {
    recipeProportionID = recipeProportion.id;
  } else {
    recipeProportionID = yield call(createRecipe, {
      payLoad: {
        data: {
          title: "Default title",
          cookingSteps: [],
          status: DRAFT
        },
        proportion: {
          kilocalories: menuProportion.kilocalories
        }
      }
    });
  }
  return {
    ...data,
    mealRecipes: [
      ...(data.mealRecipes || []),
      {
        recipeProportion: recipeProportionID,
        weight
      }
    ]
  };
}

function* parseMeal(meal, options = {}) {
  for (let recipeWeight of meal.recipeWeights) {
    const recipe = normaliseRecipe(recipeWeight.recipe);
    yield put({
      type: LOAD_RECIPE + SUCCESS,
      payLoad: { data: recipe, id: recipe.id }
    });
    for (let proportion of recipe.proportions) {
      if (
        meal.mealRecipes.find(
          ({ recipeProportion }) => recipeProportion === proportion.id
        )
      ) {
        const recipeProportion = normaliseRecipeProportion(proportion);
        yield put({
          type: LOAD_RECIPEPROPORTION + SUCCESS,
          payLoad: {
            data: recipeProportion,
            id: proportion.id
          }
        });
        if (options.withIngredients)
          yield all(
            recipeProportion.doses.map(dose =>
              call(getIngredient, { url: dose.link })
            )
          );
      }
    }
  }
}

export default watchMeals;
