import { call, put, takeLatest } from "redux-saga/effects";
import { errorMassages, FAIL, START, SUCCESS } from "../../commons/constants";
import * as API from "./api";
import { LOAD_SELF_ACCOUNT } from "./ducks";
import { normaliseItem } from "./normalisers";
import { fetchReqAsync } from "../../commons/api";

export function* getSelfAccount() {
  try {
    yield put({ type: LOAD_SELF_ACCOUNT + START });
    const res = normaliseItem(yield fetchReqAsync(API.getSelfAccount));
    yield put({
      type: LOAD_SELF_ACCOUNT + SUCCESS,
      payLoad: res
    });
  } catch (error) {
    yield call(alert, errorMassages.load("Аккаунтов", error));
    yield put({
      type: LOAD_SELF_ACCOUNT + FAIL,
      error
    });
  }
}

function* watchSelfAccount() {
  yield takeLatest(LOAD_SELF_ACCOUNT, getSelfAccount);
}

export default watchSelfAccount;
