import { SERVER_URL, ACCESS_TOKEN_URL } from "../../commons/constants";
import fecthAPI from "../../commons/api";

export const postSecurityAdmin = data =>
  fecthAPI.post(`${SERVER_URL}/request-sms/admin-login`, data);

export const postSecurityConfirmAdmin = (phone, smsConfirmationCode) =>
  fecthAPI.post(`${SERVER_URL}/${ACCESS_TOKEN_URL}`, {
    phone,
    smsConfirmationCode
  });
