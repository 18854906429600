import React from "react";
import { Form } from "react-final-form";

function FormBody({
  children,
  submitFrom,
  name,
  render,
  initialValues,
  ...props
}) {
  return (
    <Form
      onSubmit={submitFrom || null}
      initialValues={initialValues || {}}
      render={({ handleSubmit, form }) => {
        return (
          <form
            onSubmit={handleSubmit}
            className="form"
            name={name}
            style={{ height: "100%", margin: "20px 0" }}
            noValidate
          >
            {render
              ? form.mutators
                ? render({ mutators: form.mutators })
                : render()
              : null}
          </form>
        );
      }}
      {...props}
    />
  );
}

export { FormBody };
