import { DELETED, PUBLISHED, UNPUBLISHED } from "../../commons/constants";

export const statuses = [
  {
    name: PUBLISHED,
    title: "Опубликован"
  },
  {
    name: UNPUBLISHED,
    title: "Не опубликован"
  },
  {
    name: DELETED,
    title: "Удален"
  }
];
