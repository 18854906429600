import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { loadTagsCategories } from "./ducks";
import { loadTagsOfCategory } from "../Tags/ducks";
import {
  isLoadingTagsCategorySelector,
  tagsCategorySelector
} from "./selectors";
import PreloaderHOC from "../../HOCs/Preloader";
import TagsCategory from "./TagsCategory";

class TagsCategoryMain extends Component {
  state = {
    openCategory: ""
  };
  componentDidMount() {
    const { loadTagsCategories } = this.props;
    loadTagsCategories();
  }

  handleOpenCategory = (e, id) => {
    e.preventDefault();
    this.setState(
      () => ({
        openCategory: id
      }),
      () => this.props.loadTagsOfCategory(id)
    );
  };

  handleCloseCategory = e => {
    e.preventDefault();
    this.setState(() => ({
      openCategory: ""
    }));
  };

  render() {
    const {
      tagsCategory,
      isConstructor,
      handleSelect,
      handleUnselect,
      selectedTags,
      isPickedTreeNode,
      isDisabledTreeNode,
      isSelectChildView,
      isSelectView
    } = this.props;
    const { openCategory } = this.state;
    let tagsCategoryList = [];
    if (Object.values(tagsCategory).length)
      tagsCategoryList = Object.values(tagsCategory).map(category => (
        <div className="tree" key={category.id}>
          <TagsCategory
            category={category}
            isOpen={openCategory === category.id}
            handleOpenCategory={e => this.handleOpenCategory(e, category.id)}
            handleCloseCategory={e => this.handleCloseCategory(e)}
            handleSelect={handleSelect}
            handleUnselect={handleUnselect}
            selectedTags={selectedTags}
            isPickedTreeNode={isPickedTreeNode}
            isDisabledTreeNode={isDisabledTreeNode}
            isConstructor={isConstructor}
            isSelectChildView={isSelectChildView}
            isSelectView={isSelectView}
            withIcon
          />
        </div>
      ));
    return <Fragment>{tagsCategoryList}</Fragment>;
  }
}

export default connect(
  (state, ownProps) => ({
    tagsCategory: ownProps.tagsCategory || tagsCategorySelector(state),
    loading: isLoadingTagsCategorySelector(state)
  }),
  {
    loadTagsCategories,
    loadTagsOfCategory
  }
)(PreloaderHOC(TagsCategoryMain));
