import React, { Component } from "react";
import { connect } from "react-redux";
import { isLoadingProfilesSelector, profilesSelector } from "./selectors";
import Preloader from "../../components/Preloader/Preloader";
import { loadProfiles } from "./ducks";
import Profile from "./Profile";

const columns = ["Имя", "Профиль", "", ""];

class ProfilesList extends Component {
  componentDidMount() {
    const { accountId, loadProfiles } = this.props;
    loadProfiles(accountId);
  }

  handleEdit = (e, id) => {
    const { handleEdit, profiles } = this.props;
    handleEdit({
      ...profiles[id]
    });
  };

  render() {
    const { loading, profiles } = this.props;
    if (loading) return <Preloader />;
    const profilesList = Object.values(profiles).map(profile => (
      <Profile
        key={profile.id}
        profile={profile}
        handleEdit={this.handleEdit}
      />
    ));
    let index = 0;
    return (
      <div>
        <div className="table-list table-list__profile">
          <div className="table-list__row table-list__row_head">
            {columns.map(column => (
              <div key={index++}>{column}</div>
            ))}
          </div>
          {profilesList}
        </div>
      </div>
    );
  }
}

export default connect(
  state => ({
    profiles: profilesSelector(state),
    loading: isLoadingProfilesSelector(state)
  }),
  { loadProfiles }
)(ProfilesList);
