import React from "react";
import InputCheckbox from "../../components/Input/InputCheckbox";

const TreeNodeSelect = ({
  isSelected,
  handleSelect,
  handleUnselect,
  isDisabled
}) => {
  const handleToggle = () => {
    if (isSelected) return handleUnselect;
    return handleSelect;
  };
  return (
    <InputCheckbox
      checked={isSelected || !!isDisabled}
      disabled={isDisabled}
      handleChange={handleToggle()}
      className="tags-tree__checkbox"
    />
  );
};

export default TreeNodeSelect;
