import React, { Component } from "react";
import Input from "./Input";

const demaskFromPhone = masked =>
  masked
    .split("")
    .filter(char => /[0-9]/.test(char))
    .join("");
const maskToPhone = value =>
  value.length > 0
    ? value.split("").reduce((total, char, index) => {
        const maskedChar =
          index === 1
            ? `(${char}`
            : index === 4
            ? `)${char}`
            : index === 7
            ? `-${char}`
            : char;

        return String(total) + String(maskedChar);
      }, "+")
    : "";
const validatePhone = phone => phone.length === 11 && /[0-9]/.test(phone);

class InputPhone extends Component {
  constructor(props) {
    super(props);
    const { value } = props;
    this.state = {
      value: value || "",
      maskedValue: (value && maskToPhone(demaskFromPhone(value))) || ""
    };
  }
  handleChange = ({ target: { value } }) => {
    const { maskedValue } = this.state;
    this.setState(
      {
        maskedValue:
          value.length < 16 ? maskToPhone(demaskFromPhone(value)) : maskedValue
      },
      () => {
        this.props.handleChange({
          target: {
            name: this.props.name,
            value: this.props.validate
              ? validatePhone(demaskFromPhone(this.state.maskedValue))
                ? demaskFromPhone(this.state.maskedValue)
                : false
              : demaskFromPhone(this.state.maskedValue)
          }
        });
      }
    );
  };
  render() {
    const { maskedValue } = this.state;
    const { name, placeholder, className, error } = this.props;
    return (
      <Input
        className={className}
        value={maskedValue}
        name={name}
        placeholder={placeholder}
        handleChange={this.handleChange}
        error={error}
      />
    );
  }
}

export default InputPhone;
