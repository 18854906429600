import fecthAPI from "../../commons/api";
import {
  CATEGORY_URL,
  SERVER_URL,
  TAG_CSV,
  TAG_URL,
  UPLOADS_URL
} from "../../commons/constants";

export const getTagsCategories = () =>
  fecthAPI.get(`${SERVER_URL}/${TAG_URL}/${CATEGORY_URL}/list`);

export const getTagsCategory = id =>
  fecthAPI.get(`${SERVER_URL}/${TAG_URL}/${CATEGORY_URL}/${id}`);

export const postTagsCategory = data =>
  fecthAPI.post(`${SERVER_URL}/${TAG_URL}/${CATEGORY_URL}`, data);

export const postTagsCategoryFile = data =>
  fecthAPI.postFile(`${SERVER_URL}/${UPLOADS_URL}/${TAG_CSV}`, data);

export const deleteTagsCategory = id =>
  fecthAPI.delete(`${SERVER_URL}/${TAG_URL}/${CATEGORY_URL}/${id}`);
