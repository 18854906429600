import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Preloader from "../../../components/Preloader/Preloader";
import {
  isLoadedMenusSelector,
  isLoadingMenusSelector,
  menuByIdSelector
} from "../selectors";
import { loadMenu } from "../ducks";
import MenuProportionsContent from "../../MenuProportions/MenuProportionsContent";
import { isEmptyObject } from "../../../commons/utils";

//TODO если мы при создании меню не добавили калории, то вылезет ошибка, т.к. не передали массив калорий, обработать ее

class MenuEdit extends Component {
  componentDidMount() {
    const { loadMenu, id, menu } = this.props;
    if (isEmptyObject(menu)) loadMenu(id);
  }

  handleSave = () => {
    this.handleBack();
  };

  handleBack = () => {
    const { history } = this.props;
    history.push(`/menu`);
  };

  render() {
    const { menu, match, loading, loaded } = this.props;
    if (loading || !loaded) return <Preloader />;
    return (
      <div className="menu-edit">
        <div className="content__title title-content menu-edit__title">
          <button className="title-content__back" onClick={this.handleSave} />
          <h2 className="title-content__name">{menu.title}</h2>
          <nav className="menu-edit__navigation">
            <ul className="menu-edit__list">
              <li className="menu-edit__link menu-edit__link_day">
                <Link to="day" title="day">
                  Меню день
                </Link>
              </li>
              <li className="menu-edit__link menu-edit__link_week">
                <Link to="week" title="week">
                  Меню неделя
                </Link>
              </li>
              <li className="menu-edit__link menu-edit__link_month">
                <Link to="month" title="month">
                  Меню месяц
                </Link>
              </li>
            </ul>
          </nav>
        </div>
        <MenuProportionsContent menu={menu} match={match} />
      </div>
    );
  }
}

export default connect(
  (state, ownProps) => ({
    menu: menuByIdSelector(state, ownProps),
    loading: isLoadingMenusSelector(state),
    loaded: isLoadedMenusSelector(state)
  }),
  {
    loadMenu
  }
)(MenuEdit);
