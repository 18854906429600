import { put, takeLatest } from "redux-saga/effects";
import { CONFIRM_PHONE, CONFIRM_CODE } from "../Confirmation/ducks";
import { FAIL, START, SUCCESS } from "../../commons/constants";
import * as API from "./api";
import { fetchReqAsync, fetchResAsync } from "../../commons/api";
import {
  SECURITY_AUTHORISE,
  SECURITY_CONFIRM_PHONE,
  SECURITY_CONFIRM_CODE
} from "./ducks";

function* confirmPhone(action) {
  try {
    const { payLoad } = action;
    yield put({ type: CONFIRM_PHONE + START });
    yield fetchResAsync(API.postSecurityAdmin, { root: { phone: `+${payLoad.data}` }, phone: `+${payLoad.data}` });
    yield put({
      type: CONFIRM_PHONE + SUCCESS
    });
  } catch (e) {
    console.log(e);
    yield put({ type: CONFIRM_PHONE + FAIL, error: e.status });
  }
}

function* confirmCode(action) {
  try {
    const { payLoad } = action;
    yield put({ type: CONFIRM_CODE + START });
    const res = yield fetchReqAsync(
      API.postSecurityConfirmAdmin,
      `+${payLoad.phone}`,
      payLoad.code
    );
    yield put({
      type: CONFIRM_CODE + SUCCESS
    });
    localStorage.setItem("ACCESS_TOKEN", res.accessToken);
    yield put({
      type: SECURITY_AUTHORISE
    });
  } catch (e) {
    console.log(e);
    yield put({
      type: CONFIRM_CODE + FAIL,
      error: e.status
    });
  }
}

function* watchSecurity() {
  yield takeLatest(SECURITY_CONFIRM_PHONE, confirmPhone);
  yield takeLatest(SECURITY_CONFIRM_CODE, confirmCode);
}

export default watchSecurity;
