import { createSelector } from "reselect";
import { objFilter } from "../../commons/utils";
import { DELETED } from "../../commons/constants";

const materialsGetter = state => state.materials;
const propsStatusGetter = (state, ownProps) => ownProps.status;
const materialGetter = (state, ownProps) => state.materials.data[ownProps.id];

export const materialsSelector = createSelector(
  materialsGetter,
  ({ data }) => data
);

export const materialsDependOnStatusSelector = createSelector(
  materialsGetter,
  propsStatusGetter,
  ({ data }, propsStatus) => {
    if (propsStatus !== DELETED)
      return objFilter(data, ({ status }) => status !== DELETED);
    return data;
  }
);

export const materialByIdSelector = createSelector(
  materialGetter,
  data => data
);

export const isLoadingMaterialsSelector = createSelector(
  materialsGetter,
  ({ loading }) => {
    return loading;
  }
);
