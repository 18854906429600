import React, { Component } from "react";
import classes from "./style.module.scss";
import Input from "../../../components/Input/Input";
import { createRecipe } from "../ducks";
import { connect } from "react-redux";
import Form from "../../../components/Form/Form";
import withConfirmModal from "../../../HOCs/withConfirmModal";

class RecipeAddModal extends Component {
  state = {
    title: ""
  };
  handleChange = e => {
    const { name, value } = e.target;
    this.setState({
      [name]: value
    });
  };
  handleCreateRecipe = () => {
    const { createRecipe, closeModal, history } = this.props;
    const { title } = this.state;
    createRecipe({ title }, {}, history);
    closeModal();
  };
  handleCancel = () => {
    this.props.closeModal();
  };
  render() {
    return (
      <div className={classes["add-modal"]}>
        <Form
          title="Добавление рецепта"
          handleCancel={this.handleCancel}
          handleSubmit={this.handleCreateRecipe}
        >
          <Input
            name="title"
            value={this.state.title}
            handleChange={this.handleChange}
            placeholder="Введите название рецепта"
            required
          />
        </Form>
      </div>
    );
  }
}

export default connect(
  null,
  { createRecipe }
)(withConfirmModal(RecipeAddModal));
