import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { isLoadingTagsSelector, tagsSelector } from "./selectors";
import { loadTags } from "./ducks";
import Preloader from "../../HOCs/Preloader";
import SearchTagsContainer from "./Search/SearchTagsContainer";

class TagsPart extends Component {
  componentDidMount() {
    this.props.loadTags();
  }

  render() {
    const { pickedTags, handleDeleteTag, handleAddTag, tags } = this.props;
    let objTags = {};
    Object.values(tags).forEach(tag => {
      if (
        !(
          tag.category === "Углеводы" &&
          pickedTags.find(pick => tag[pick].category === "Углеводы")
        )
      )
        Object.assign(objTags, {
          [tag.id]: {
            ...tag
          }
        });
    });

    return (
      <Fragment>
        <h4 className="form__title">Добавление тэгов</h4>
        <SearchTagsContainer
          handleSelect={handleAddTag}
          tags={objTags}
          pickedItems={pickedTags}
        />
        <ul>
          {Object.values(tags).length
            ? pickedTags.map(picked => (
                <li key={picked}>
                  <div>{tags[picked].title}</div>
                  <button
                    onClick={() => handleDeleteTag(picked)}
                    className="tags__btn_delete"
                  >
                    delete
                  </button>
                </li>
              ))
            : []}
        </ul>
      </Fragment>
    );
  }
}

export default connect(
  state => ({
    tags: tagsSelector(state),
    loading: isLoadingTagsSelector(state)
  }),
  { loadTags }
)(Preloader(TagsPart));
