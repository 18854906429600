import React, { Component } from "react";
import ShowForm from "../../../components/Form/ShowForm";
import { elementsTypes } from "../utils";
import { numberNutriensShema } from "../shema";

//поля не обязательные

class SecondPart extends Component {
  state = {
    formCollection: []
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { nutrients } = nextProps;
    const formCollection = [];
    for (let key of elementsTypes) {
      formCollection.push({
        key: key.name,
        type: "input_number",
        name: key.name,
        value:
          (nutrients[key.name] && nutrients[key.name].amountPerHundredGrams) ||
          "",
        placeholder: key.title,
        shema: numberNutriensShema
      });
    }
    return {
      formCollection
    };
  }
  render() {
    return (
      <div className="adding-ingredient__second">
        <h4 className="form__title adding-ingredient__title">
          Макро и микро-элементы на 100 гр.
        </h4>
        <ShowForm
          formCollection={this.state.formCollection}
          handleChange={this.props.handleChangeNutrients}
          errors={this.props.errors}
        />
      </div>
    );
  }
}

export default SecondPart;
