import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Wizard from "../../../HOCs/Wizard";
import { editMaterial, createMaterial } from "../ducks";
import MainPart from "./MainPart";
import { categories } from "../utils";
import TagsPart from "./TagsPart";
import withConfirmModal from "../../../HOCs/withConfirmModal";

class AddMaterial extends Component {
  constructor(props) {
    super(props);
    const { material } = props;
    const { id, title, content, shortContent, category, tags } = material;

    this.state = {
      id: id || "",
      title: title || "",
      content: content || "",
      shortContent: shortContent || "",
      category: category || categories[0].name,
      tags: tags || [],
      errors: {}
    };
  }

  componentWillReceiveProps(nextProps) {
    const { editMaterial, createMaterial, toggleSubmit } = this.props;
    const { id } = this.state;
    if (nextProps.isSubmit === true) {
      const data = this.prepareData();
      id ? editMaterial(id, data) : createMaterial(data);
      toggleSubmit();
    }
  }

  prepareData() {
    const { material } = this.props;
    const { title, content, shortContent, category, tags } = this.state;
    return {
      ...material,
      title,
      content,
      shortContent,
      category,
      tags
    };
  }

  handleChange = e => {
    const { value, name } = e.target;
    this.setState(() => ({ [name]: value }));
  };

  deleteTag = delId => {
    this.setState(({ tags }) => ({
      tags: tags.filter(({ id }) => id !== delId)
    }));
  };

  createTag = tag => {
    this.setState(({ tags }) => ({
      tags: [...tags, tag]
    }));
  };

  handleSelect = (parent, children, tag) => {
    const { tags } = this.state;
    // если выбираем потомка, а родитель уже выбран => нужно удалить родителя и выбрать потомка
    if (tags.find(({ id }) => id === parent)) this.deleteTag(parent);
    // если выбираем родителя, а выбраны потомки у этого родителя => удаляем потомков и выбираем родителя
    if (children)
      Object.values(children).forEach(child => {
        if (tags.find(({ id }) => id === child.id)) this.deleteTag(child.id);
      });
    this.createTag(tag);
  };

  handleUnselect = (parent, children, tag) => {
    this.deleteTag(tag.id);
  };

  render() {
    const { currentPart } = this.props;
    const { tags } = this.state;
    const partList = [
      <MainPart handleChange={this.handleChange} {...this.state} />,
      <TagsPart
        selectedTags={tags}
        handleSelect={this.handleSelect}
        handleUnselect={this.handleUnselect}
      />
    ];
    return <Fragment>{partList[currentPart - 1]}</Fragment>;
  }
}

export default connect(
  null,
  { editMaterial, createMaterial }
)(withConfirmModal(Wizard(AddMaterial)));
