import React, { Component } from "react";
import { connect } from "react-redux";
import { changeFilterRecipes, clearFilterRecipes } from "../../Filters/ducks";
import { clearFilteredRecipes } from "../ducks";
import SelectRecipeList from "../SelectRecipeList";
import SearchWrapper from "../../../components/Search/SearchWrapper";
import withConfirmModal from "../../../HOCs/withConfirmModal";
import Loader from "../../../components/Preloader/Preloader";
import SelectRecipeWeight from "./SelectRecipeWeight";
import { isLoadingRecipeProportionsSelector } from "../../RecipeProportions/selectors";
import { loadRecipeProportion } from "../../RecipeProportions/ducks";

class SelectRecipe extends Component {
  state = {
    title: "",
    currentStep: 1,
    selectedRecipe: null
  };
  componentDidMount() {
    // this.props.changeFilterRecipes({ status: APPROVED_DRAFT });
    //this.props.changeFilterRecipes();
  }

  componentWillUnmount() {
    this.props.clearFilterRecipes();
    this.props.clearFilteredRecipes();
  }

  afterSearch = title => {
    this.setState(
      {
        title
      },
      () =>
        this.props.changeFilterRecipes({
          title
        })
    );
  };

  handleSelectRecipe = recipe => {
    this.setState(
      {
        selectedRecipe: recipe,
        currentStep: 2
      },
      () => {
        this.props.loadRecipeProportion(recipe.id, recipe.proportions[0].id, {
          withIngredients: true
        });
      }
    );
  };

  handleBack = () => {
    this.setState(({ currentStep }) => ({
      currentStep: currentStep - 1
    }));
  };

  handleSelectWeight = weight => {
    const { selectedRecipe } = this.state;
    this.props.selectRecipe(selectedRecipe, +weight || 0);
  };

  render() {
    const { title, currentStep, selectedRecipe } = this.state;
    const { isLoadingRecipe, ingredients } = this.props;
    return (
      <div className="recipe__select select-recipe">
        <h4 className="form__title select-recipe__title">Выберите рецепт</h4>
        {currentStep === 1 ? (
          <>
            <div className="search__recipe">
              <SearchWrapper
                value={title}
                placeholder="Искать рецепт"
                className="search_large"
                afterSearch={this.afterSearch}
                minWordLength={3}
                time={400}
              />
            </div>
            <SelectRecipeList
              selectRecipe={this.handleSelectRecipe}
              search={title}
              minWordLength={3}
            />
          </>
        ) : null}
        {currentStep === 2 ? (
          isLoadingRecipe ? (
            <Loader />
          ) : (
            <SelectRecipeWeight
              id={selectedRecipe.id}
              proportion={selectedRecipe.proportions[0].id}
              handleBack={this.handleBack}
              handleSelectWeight={this.handleSelectWeight}
            />
          )
        ) : null}
      </div>
    );
  }
}

export default connect(
  state => ({
    isLoadingRecipe: isLoadingRecipeProportionsSelector(state)
  }),
  {
    changeFilterRecipes,
    clearFilterRecipes,
    clearFilteredRecipes,
    loadRecipeProportion
  }
)(withConfirmModal(SelectRecipe));
