import React, { Component } from "react";
import { rounding } from "../../../commons/utils";
import { ingredientCalc } from "../utils";
import Dashboard from "../Dashboard";
import { weightTypes, cookTypes } from "../utils";
import Select from "../../../components/Select/Select";
import Button from "../../../components/Button/Button";
import TableRow from "../../../components/Table/TableRow";
import TableCell from "../../../components/Table/TableCell";
import InputNumber from "../../../components/Input/InputNumber";
import { numberPositiveShema } from "../shema";

class IngredientsTableRow extends Component {
  state = {
    showDashboard: false
  };

  handleToggleDashboard = () => {
    this.setState(({ showDashboard }) => ({
      showDashboard: !showDashboard
    }));
  };

  render() {
    const { ingredient, handleChangeDose, disabled, handleDelete } = this.props;
    const calc = ingredientCalc(ingredient);
    const cookType = cookTypes.find(
      cookType => cookType.name === ingredient.cookingMethod
    );
    const weightType = weightTypes.find(
      weightType =>
        ingredient.gramsPerVolumeUnit &&
        weightType.name === ingredient.gramsPerVolumeUnit.type
    );
    return (
      <TableRow key={ingredient.id}>
        <TableCell>
          <div className="table-ingredient__name">
            {ingredient.title}
            <button
              onClick={this.handleToggleDashboard}
              className="table-ingredient__toggler"
            />
          </div>
          {this.state.showDashboard ? (
            <Dashboard ingredient={ingredient} />
          ) : null}
        </TableCell>
        <TableCell>
          <Select
            value={cookType ? cookType.title : "нет"}
            id={ingredient.id}
            name="cookingMethod"
            handleChange={handleChangeDose}
            disabled={disabled}
            values={
              ingredient.cookingMethods
                ? [
                    ...Object.keys(ingredient.cookingMethods).map(
                      cookType => cookType
                    ),
                    ""
                  ]
                : []
            }
            options={
              ingredient.cookingMethods
                ? [
                    ...Object.keys(ingredient.cookingMethods).map(
                      cookType =>
                        cookTypes.find(item => item.name === cookType).title
                    ),
                    "нет"
                  ]
                : []
            }
          />
        </TableCell>
        <TableCell>
          <InputNumber
            handleChange={handleChangeDose}
            name="amount"
            id={ingredient.id}
            value={ingredient.amount}
            disabled={disabled}
            shema={numberPositiveShema}
          />
        </TableCell>
        <TableCell>{weightType && weightType.title}</TableCell>
        <TableCell>{rounding(calc.amount, 10)}</TableCell>
        <TableCell>{rounding(calc.amountChanged, 10)}</TableCell>
        <TableCell>{rounding(calc.proteins, 10)}</TableCell>
        <TableCell>{rounding(calc.fats, 10)}</TableCell>
        <TableCell>{rounding(calc.carbohydrates, 10)}</TableCell>
        <TableCell>{rounding(calc.cellulose, 10)}</TableCell>
        <TableCell>{rounding(calc.calories, 10)}</TableCell>
        <TableCell>
          <Button
            onClick={() => handleDelete(ingredient.id)}
            className="menu__ingredient_delete"
            disabled={disabled}
          />
        </TableCell>
      </TableRow>
    );
  }
}

export default IngredientsTableRow;
