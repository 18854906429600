import { TEST_ACCESS_TOKEN } from "./constants";

export async function fetchReqAsync(func, ...res) {
  const response = await func(...res);
  if (response.ok) {
    return await response.json();
  } else {
    if (response.status === 401) {
      window.localStorage.removeItem("ACCESS_TOKEN");
      window.location.reload();
    } else throw response;
  }
}

export async function fetchResAsync(func, ...res) {
  const response = await func(...res);
  if (response.ok) {
    return await response;
  } else {
    throw response;
  }
}

function getFecth(url) {
  const ACCESS_TOKEN = localStorage.getItem("ACCESS_TOKEN");
  return fetch(`${url}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json; charset=utf-8",
      Authorization: "Bearer " + ACCESS_TOKEN
    }
  });
}

function putFetch(url, data) {
  const ACCESS_TOKEN = localStorage.getItem("ACCESS_TOKEN");
  return fetch(`${url}`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json; charset=utf-8",
      Authorization: "Bearer " + ACCESS_TOKEN
    },
    body: JSON.stringify(data)
  });
}

function patchFetch(url, data) {
  const ACCESS_TOKEN = localStorage.getItem("ACCESS_TOKEN");
  return fetch(`${url}`, {
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json; charset=utf-8",
      Authorization: "Bearer " + ACCESS_TOKEN
    },
    body: JSON.stringify(data)
  });
}

function postFetch(url, data) {
  const ACCESS_TOKEN = localStorage.getItem("ACCESS_TOKEN");
  return fetch(`${url}`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json; charset=utf-8",
      Authorization: "Bearer " + ACCESS_TOKEN
    },
    body: JSON.stringify(data)
  });
}

function postFileFetch(url, data) {
  const bodyData = new FormData();
  bodyData.append("file", data);

  return fetch(`${url}`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + TEST_ACCESS_TOKEN
    },
    body: bodyData
  });
}

function deleteFetch(url) {
  const ACCESS_TOKEN = localStorage.getItem("ACCESS_TOKEN");
  return fetch(`${url}`, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json; charset=utf-8",
      Authorization: "Bearer " + ACCESS_TOKEN
    }
  });
}

const fetchAPI = {
  get: getFecth,
  put: putFetch,
  patch: patchFetch,
  post: postFetch,
  postFile: postFileFetch,
  delete: deleteFetch
};

export default fetchAPI;
