import { PROTOCOL } from "./constants";

function YouTubeRegexp(link) {
  // eslint-disable-next-line no-useless-escape
  const regExp = /^.*(youtu.be\/|v\/|embed\/|watch\?|youtube.com\/user\/[^#]*#([^\/]*?\/)*)\??v?=?([^#\&\?]*).*/i;
  const match = regExp.exec(link);
  if (match && match[3].length > 0) {
    return match[3];
  }
  return false;
}

export function getYoutubeThumbnail(url) {
  let videoId = YouTubeRegexp(url);
  return videoId
    ? "//img.youtube.com/vi/" + videoId + "/maxresdefault.jpg"
    : false;
}

export function objToArray(obj, name) {
  const arr = [];
  for (let key in obj) {
    name
      ? arr.push({
          [name]: key,
          ...obj[key]
        })
      : arr.push(obj[key]);
  }
  return arr;
}

export function clearObjectFromEmptyFields(obj) {
  const newObj = {};
  for (let key in obj) {
    if (obj[key]) Object.assign(newObj, { [key]: obj[key] });
  }
  return newObj;
}

export function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

export function objFilter(obj, predicate) {
  return Object.keys(obj)
    .filter(key => {
      return predicate(obj[key]);
    })
    .reduce((res, key) => ((res[key] = obj[key]), res), {});
}

export function isEmptyObject(obj) {
  return !Object.values(obj).length;
}

export function searchPattern(name, extraName) {
  return `${name}(${extraName})`;
}

export function rounding(val, order) {
  if (!order) return Math.round(parseFloat(val));
  return Math.round(parseFloat(val) * order) / order;
}

export function getWeeksInMonth(month, year) {
  let weeks = [];
  const firstDate = new Date(year, month, 0);
  const lastDate = new Date(year, month + 1, 0);
  const numDays = lastDate.getDate();
  const lastDatePrevMonth = new Date(year, month, 0).getDate();
  let start = 1;
  let end = 7 - firstDate.getDay();
  while (start <= numDays) {
    let lastStart = start;
    let lastEnd = end;
    let nextMonth = month;
    let prevMonth = month;
    if (end > numDays) {
      lastEnd = 6 - (numDays - start);
      nextMonth += 1;
    }
    if (end - start < 6) {
      lastStart = lastDatePrevMonth + end - 6;
      prevMonth -= 1;
    }
    weeks.push({
      start: lastStart,
      end: lastEnd,
      month,
      year,
      nextMonth,
      prevMonth
    });
    start = end + 1;
    end = end + 7;
  }
  return weeks;
}

export function getQueryVariable(variable) {
  const query = window.location.search.substring(1);
  const vars = query.split("&");
  for (let i = 0; i < vars.length; i++) {
    let pair = vars[i].split("=");
    if (pair[0] === variable) {
      return pair[1];
    }
  }
  return false;
}

export function setQueryString(...variables) {
  let query = "";
  variables.forEach(variable => {
    if (variable.value) {
      if (query.length) {
        query += `&`;
      } else {
        query += `?`;
      }
      query += `${variable.name}=${variable.value}`;
    }
  });
  return query;
}

export function setQueryFilterString(filter, isContinueQuery) {
  let query = "";
  if (filter)
    Object.entries(filter).forEach(([name, value]) => {
      if (value !== null && value !== "" && value !== undefined) {
        if (query.length || isContinueQuery) {
          query += `&`;
        } else {
          query += `?`;
        }
        query += `${name}=${value}`;
      }
    });
  return query;
}

export function dateFormat(date) {
  if (!date) return "";
  const mm = date.getMonth() + 1;
  const dd = date.getDate();

  return [
    date.getFullYear(),
    (mm > 9 ? "" : "0") + mm,
    (dd > 9 ? "" : "0") + dd
  ].join("-");
}

export function formatShowDate(date) {
  const mm = date.getMonth() + 1;
  const dd = date.getDate();

  return [
    (dd > 9 ? "" : "0") + dd,
    (mm > 9 ? "" : "0") + mm,
    date.getFullYear()
  ].join(".");
}

export function compareDates(dateFirst, dateSecond) {
  return (
    dateFirst.getMonth() === dateSecond.getMonth() &&
    dateFirst.getDate() === dateSecond.getDate() &&
    dateFirst.getFullYear() === dateSecond.getFullYear()
  );
}

export function getDateFromWeek(currentWeek, day) {
  const date = new Date(
    currentWeek.year,
    currentWeek.prevMonth,
    currentWeek.start
  );
  return new Date(date.setDate(date.getDate() + (day !== 0 ? day - 1 : 6)));
}

export function parseHrefToID(href) {
  if (!href) return "";
  return href.split("/").pop();
}

export function leadToProtocolLink(link) {
  if (!link) return "";
  const arr = link.split("/");
  arr[0] = PROTOCOL;
  return arr.join("/");
}

export function clearURLQueryParams() {
  const newURL = window.location.href.split("?")[0];
  window.history.pushState("object", document.title, newURL);
}

export function getPureObjectFromHal(obj) {
  for (let key in obj) {
    //debugger;
    if (key === "_embedded") {
      for (let keyI in obj["_embedded"]) {
        if (Array.isArray(obj["_embedded"][keyI])) {
          obj[keyI] = obj["_embedded"][keyI].map(item =>
            getPureObjectFromHal(item)
          );
        } else {
          const embedded = {};
          Object.entries(obj["_embedded"][keyI]).map(([key, value]) => {
            Object.assign(embedded, {
              [key]: getPureObjectFromHal(value)
            });
          });
          obj[keyI] = embedded;
        }
      }
    }
    if (key === "_links") {
      for (let keyI in obj["_links"]) {
        if (Array.isArray(obj["_links"][keyI])) {
          obj[keyI] = obj["_links"][keyI].map(item =>
            getPureObjectFromHal(item)
          );
        } else {
          const links = {};
          Object.entries(obj["_links"][keyI]).map(([key, value]) => {
            Object.assign(links, {
              [key]: getPureObjectFromHal(value)
            });
          });
          obj[keyI] = links;
        }
      }
    }
  }
  return obj;
}

export function getChangedArrayOfObjects(items, id, name, value) {
  return items.map(proportion => {
    if (proportion.id === id) {
      return {
        ...proportion,
        [name]: value
      };
    } else {
      return {
        ...proportion
      };
    }
  });
}

export function objectMap(object, mapFn) {
  return Object.keys(object).reduce(function(result, key) {
    result[key] = mapFn(object[key]);
    return result;
  }, {});
}

export function isEmpty(value) {
  return value === null || value === undefined || value === "";
}
