import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Search from "../../components/Search/Search";
import { isShownModalSelector } from "../Modal/selectors";
import { closeModal, showModal } from "../Modal/ducks";
import ProfilesList from "./ProfilesList";

class ProfilesMain extends Component {
  state = {
    search: "",
    editProfile: null
  };

  handleChange = e => {
    const { value, name } = e.target;
    this.setState(() => ({ [name]: value }));
  };

  handleEdit = profile => {
    this.setState(
      {
        editProfile: profile
      },
      () => this.props.showModal()
    );
  };

  handleNew = () => {
    this.setState(
      {
        editProfile: null
      },
      () => this.props.showModal()
    );
  };

  handleBack = () => {
    const { history } = this.props;
    history.goBack();
  };

  render() {
    const { search } = this.state;
    const { id } = this.props;
    return (
      <Fragment>
        <div className="content__head header-content">
          <div className="title__content title-content">
            <button className="title-content__back" onClick={this.handleBack} />
            <h2 className="title-content__name">Профили</h2>
          </div>
          <div className="header-content__filter">
            <Search
              name="search"
              value={search}
              placeholder="Найти профиль"
              handleChange={this.handleChange}
            />
          </div>
        </div>
        <ProfilesList handleEdit={this.handleEdit} accountId={id} />
        {/*isShownModal ? (
          <AddMaterial
            closeModal={closeModal}
            wizardTitle={
              editAccount ? "Редактировать материал" : "Новый материал"
            }
            partCount={1}
            {...editAccount}
          />
        ) : null*/}
      </Fragment>
    );
  }
}

export default connect(
  state => ({
    isShownModal: isShownModalSelector(state)
  }),
  {
    showModal,
    closeModal
  }
)(ProfilesMain);
