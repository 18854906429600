import React from "react";
import PropTypes from "prop-types";
import { stringShema } from "../../features/Ingredients/shema";

const TextArea = props => {
  const {
    id,
    name,
    value,
    handleChange,
    placeholder,
    error,
    disabled,
    className,
    shema,
    required
  } = props;
  const isValid = shema
    ? shema.isValidSync(value)
    : stringShema.isValidSync(value);
  return (
    <div className={"textarea__wrapper " + className}>
      <textarea
        className={
          !isValid || (error && !value)
            ? "textarea textarea_error"
            : value
            ? "textarea textarea_valid"
            : "textarea"
        }
        name={name}
        value={value}
        onChange={handleChange}
        placeholder="&nbsp;"
        data-id={id}
        disabled={disabled ? "disabled" : null}
        required={required}
      />
      <span className="textarea__label">{placeholder}</span>
      <span className="textarea__border" />
    </div>
  );
};

TextArea.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
  handleChange: PropTypes.func,
  placeholder: PropTypes.string
};

export default TextArea;
