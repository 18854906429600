import * as yup from "yup";
import { elementsTypes } from "./utils";
import { cookingMethodInputTypes } from "./utils";
import { cookTypes } from "./utils";

const nutrients = {};
export const cookingMethods = {};
const cookingMethodInputTypesShema = {};
const elementsTypesShema = elementsTypes.concat([
  {
    name: "proteins"
  },
  {
    name: "fats"
  },
  {
    name: "carbohydrates"
  }
]);
cookingMethodInputTypes.forEach(cookingMethodInputType => {
  Object.assign(cookingMethodInputTypesShema, {
    [cookingMethodInputType.name]: numberShema
  });
});

elementsTypesShema.forEach(elementsType => {
  Object.assign(nutrients, {
    [elementsType.name]: yup.object().shape({
      amountPerHundredGrams: numberShema
    })
  });
});

cookTypes.forEach(cookType => {
  Object.assign(cookingMethods, {
    [cookType.name]: yup.object().shape({ ...cookingMethodInputTypesShema })
  });
});

export const stringShema = yup.string();
export const stringShortShema = yup.string().max(100);
export const celluloseShema = yup
  .number()
  .transform((cv, ov) => {
    return ov === "" ? undefined : cv;
  })
  .max(100)
  .min(0);
export const numberShema = yup
  .number("Числовое поле")
  .transform((cv, ov) => {
    return ov === "" ? undefined : cv;
  })
  .typeError("Числовое поле");
export const numberPositiveShema = yup
  .number("Числовое поле")
  .transform((cv, ov) => {
    return ov === "" ? undefined : cv;
  })
  .positive();

export const numberNutriensShema = numberShema.min(0);

export const ingredientRequiredShema = yup.object().shape({
  title: stringShortShema.required("Обязательное поле"),
  alternativeTitles: stringShortShema,
  kilocaloriesPerHundredGrams: numberPositiveShema,
  fats: numberNutriensShema.required("Обязательное поле"),
  proteins: numberNutriensShema.required("Обязательное поле"),
  carbohydrates: numberNutriensShema.required("Обязательное поле"),
  description: stringShema,
  internalDescription: stringShema,
  gramsPerVolumeUnit: yup.string(),
  gramsPerVolumeUnitAmount: numberPositiveShema,
  cellulosePerHundredGrams: celluloseShema
});
