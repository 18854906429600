import { call, put, takeLatest, select } from "redux-saga/effects";
import { errorMassages, FAIL, START, SUCCESS } from "../../commons/constants";
import * as API from "./api";
import {
  CREATE_MENU,
  DELETE_MENU,
  EDIT_MENU,
  LOAD_MENUS,
  LOAD_MENU
} from "./actionTypes";
import { createMenuProportion } from "../MenuProportions/sagas";
import { normaliseMenus, normaliseMenu } from "./normalisers";
import { fetchReqAsync, fetchResAsync } from "../../commons/api";
import fecthAPI from "../../commons/api";
import { menuFilterSelector } from "../Filters/selectors";
import { menuByIdSelector } from "./selectors";
import { deleteMenuProportion } from "../MenuProportions/sagas";
import { MODAL_CLOSE } from "../Modal/ducks";
import { loadMenusFail, loadMenusSuccess } from "./ducks";

export function* getMenus(action) {
  try {
    const filter = yield select(menuFilterSelector);
    const res = yield fetchReqAsync(API.getMenus, filter);
    yield put(loadMenusSuccess({ ...normaliseMenus(res) }));
  } catch (e) {
    yield call(alert, errorMassages.load("Меню", e));
    yield put(loadMenusFail(e.message));
  }
}

function* getMenu(action) {
  try {
    yield put({ type: LOAD_MENU + START });
    const { payLoad } = action;
    const menu = yield fetchReqAsync(API.getMenu, payLoad.id);

    yield put({
      type: LOAD_MENU + SUCCESS,
      payLoad: { data: normaliseMenu(menu), id: payLoad.id }
    });
  } catch (e) {
    console.log(e);
    yield call(alert, errorMassages.load("menu", e));
    yield put({ type: LOAD_MENU + FAIL, payLoad: { data: e } });
  }
}

function* editMenu(action) {
  try {
    yield put({ type: EDIT_MENU + START });
    const { id, data } = action.payLoad;
    yield fetchResAsync(API.putMenu, id, data);
    yield put({
      type: EDIT_MENU + SUCCESS,
      payLoad: {
        id,
        data
      }
    });
    yield put({ type: MODAL_CLOSE });
  } catch (e) {
    yield call(alert, errorMassages.edit("menu", e));
    yield put({ type: EDIT_MENU + FAIL, payLoad: { data: e } });
  }
}

function* createMenu(action) {
  try {
    yield put({ type: CREATE_MENU + START });
    const { data, proportions } = action.payLoad;
    if (!proportions.length) throw new Error("no proportions");
    const res = yield fetchResAsync(API.postMenu, data);
    const resMenu = yield fetchReqAsync(
      fecthAPI.get,
      res.headers.get("Location")
    );
    const id = resMenu.id;
    const resProportions = [];
    for (let proportion of proportions) {
      const resProportion = yield call(createMenuProportion, {
        payLoad: {
          id,
          data: { kilocalories: proportion }
        }
      });
      resProportions.push(resProportion);
    }
    yield put({
      type: CREATE_MENU + SUCCESS,
      payLoad: {
        data: {
          ...data,
          proportions: [...resProportions]
        },
        id
      }
    });
    yield put({ type: MODAL_CLOSE });
  } catch (e) {
    yield call(alert, errorMassages.create("menu", e));
    yield put({ type: CREATE_MENU + FAIL, payLoad: { data: e } });
  }
}

function* deleteMenu(action) {
  try {
    yield put({
      type: DELETE_MENU + START
    });
    const { id } = action.payLoad;
    const menu = yield select(menuByIdSelector, { id });
    for (let proportion of menu.proportions) {
      yield call(deleteMenuProportion, {
        payLoad: {
          menuId: id,
          data: proportion
        }
      });
    }
    yield fetchResAsync(API.deleteMenu, id);
    yield put({
      type: DELETE_MENU + SUCCESS,
      payLoad: {
        id
      }
    });
  } catch (e) {
    yield call(alert, errorMassages.delete("menu", e));
    yield put({ type: DELETE_MENU + FAIL, payLoad: { data: e } });
  }
}

function* watchMenus() {
  yield takeLatest(LOAD_MENUS, getMenus);
  yield takeLatest(LOAD_MENU, getMenu);
  yield takeLatest(EDIT_MENU, editMenu);
  yield takeLatest(CREATE_MENU, createMenu);
  //yield takeLatest(SAVE_MENU, saveMenu);
  yield takeLatest(DELETE_MENU, deleteMenu);
}

export default watchMenus;
