import React, { Component } from "react";
import ListControls from "../../components/ListControls/ListControls";
import { statuses } from "./utils";
import { ACTIVE } from "../../commons/constants";
import { blockAdmin, unblockAdmin } from "./ducks";
import { connect } from "react-redux";

class Admin extends Component {
  getControlsButtons = () => {
    const { handleEdit, admin } = this.props;
    return [
      {
        ...this.getStatusButton(admin.status)
      },
      {
        title: "Редактировать",
        handleClick: e => handleEdit(e, admin.id)
      }
    ];
  };

  getStatusButton = status => {
    const { unblockAdmin, blockAdmin, admin } = this.props;
    if (status === ACTIVE)
      return {
        title: "Заблокировать",
        handleClick: () => blockAdmin(admin.id)
      };
    return {
      title: "Разблокировать",
      handleClick: () => unblockAdmin(admin.id)
    };
  };

  render() {
    const { admin } = this.props;
    const { fullName, phone, status, id } = admin;
    return (
      <div className="table-list__row">
        <div className="table-list__title">
          {fullName.lastName} {fullName.firstName} {fullName.middleName}
        </div>
        <div className="table-list__phone">{phone.e164Format}</div>
        <div className="table-list__status">
          {statuses.find(({ name }) => name === status).title}
        </div>
        <ListControls id={id} buttons={this.getControlsButtons()} />
      </div>
    );
  }
}

export default connect(
  null,
  { blockAdmin, unblockAdmin }
)(Admin);
