import { getPureObjectFromHal, parseHrefToID } from "../../commons/utils";

export function normaliseMenus(menus) {
  const resMenus = [...menus._embedded.items];
  const res = {};
  resMenus.forEach(menu => {
    Object.assign(res, {
      [menu.id]: normaliseMenu(menu)
    });
  });
  return res;
}

export function normaliseMenu(menu) {
  const resMenu = getPureObjectFromHal(menu);
  return {
    ...resMenu,
    caloriesName: resMenu.calories && resMenu.calories.name,
    caloriesMin: resMenu.calories && resMenu.calories.min,
    caloriesMax: resMenu.calories && resMenu.calories.max,
    proportions: resMenu.proportions.map(proportion => ({
      ...proportion,
      meals: proportion.meals.map(meal => parseHrefToID(meal.href))
    }))
  };
}

export function normaliseToPostMenu(menu) {
  const { title, description, mealTypes, caloriesName } = menu;
  return {
    title: title || "",
    description: description || "",
    mealTypes: mealTypes || [],
    calories: caloriesName || ""
  };
}

export function normaliseToPutMenu(menu) {
  const { title, description, caloriesName } = menu;
  return {
    title: title || "",
    description: description || "",
    calories: caloriesName || ""
  };
}
